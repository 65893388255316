import React from 'react';

import PaymentInformationMessage from '../PaymentStepDefault/PaymentInformationMessage';
import AddCreditCardForm from './FormParts/AddCreditCardForm';

const StepForm = ({ handleSubmit, errors }) => (
  <div className="signup-step signup-step-3">
    <PaymentInformationMessage />

    <AddCreditCardForm onSubmit={handleSubmit} showNameOnCardFields errors={errors} />
  </div>
);

export default StepForm;
