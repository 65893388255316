import AbstractImageResolver from '@utils/images/image-resolvers/AbstractImageResolver';
import { storageURL } from '@jsv3/utils/urlUtils';

class DefaultImageResolver extends AbstractImageResolver {
  resolve() {
    const src = super.resolve();

    if (src) {
      return src;
    }

    const { path, name, resolutions } = this.data;
    const { size, density, blurred, squared, format } = this.config;

    if (resolutions) {
      const properResolution = resolutions.find(
        (resolution) =>
          resolution.resolution === 'w' + size + (density ? '-2x' : '') && // w900-2x
          resolution.dimension === (squared ? 'squared' : 'landscape') && // squared
          resolution.format === format, // webp | jpeg
      );

      if (properResolution && properResolution.link) {
        return properResolution.link;
      }
    }

    return storageURL(
      `/${path}/w${size + (density ? '-2x' : '')}${squared ? '/squared' : ''}${
        blurred ? '/blurred' : ''
      }/${name}.${this.format}`,
    );
  }
}

export default DefaultImageResolver;
