import CostParameter from '@jsv2/models/membershipModels/CostParameter';
import Parameter from '@jsv2/models/membershipModels/Parameter';
import Cost from '@jsv2/models/Cost';

export default class Summary {
  constructor({
    signupFee,
    signupBonus,
    recurringCharge,
    recurringBonus,
    recurringInterval,
    promoPeriod,
    moneybackPeriod,
    promoNote,
    membershipPlanId,
    isPromo,
    totalCharge,
    promoCampaignId,
    name,
    benefits,
    language
  }) {
    this._recurringCharge = new CostParameter(recurringCharge);
    this._recurringBonus = new CostParameter(recurringBonus);
    this._totalCharge = new Cost(totalCharge);

    this._recurringInterval = recurringInterval && new Parameter(recurringInterval);
    this._signupFee = signupFee && new CostParameter(signupFee);
    this._signupBonus = signupBonus && new CostParameter(signupBonus);

    this._promoPeriod = promoPeriod && new Parameter(promoPeriod);
    this._moneybackPeriod = moneybackPeriod && new Parameter(moneybackPeriod);

    this._promoNote = promoNote;
    this._membershipPlanId = membershipPlanId;
    this._isPromo = isPromo;

    this._promoCampaignId = promoCampaignId;

    this._name = name;
    this._benefits = benefits;
    this._language = language;
  }

  get signupFee() {
    return this._signupFee;
  }

  get signupBonus() {
    return this._signupBonus;
  }

  get recurringCharge() {
    return this._recurringCharge;
  }

  get recurringBonus() {
    return this._recurringBonus;
  }

  get recurringInterval() {
    return this._recurringInterval;
  }

  get promoPeriod() {
    return this._promoPeriod;
  }

  get moneybackPeriod() {
    return this._moneybackPeriod;
  }

  get promoNote() {
    return this._promoNote;
  }

  get membershipPlanId() {
    return this._membershipPlanId;
  }

  get isPromo() {
    return this._isPromo;
  }

  get totalCharge() {
    return this._totalCharge;
  }

  get promoCampaignId() {
    return this._promoCampaignId;
  }

  get name() {
    return this._name;
  }

  get benefits() {
    return this._benefits;
  }

  get language() {
    return this._language;
  }
}
