import React from 'react';
import PropTypes from 'prop-types';

import AuthMessage from '@jsv2/components/Authorization/AuthMessage';
import BaseMessageTemplate from '@jsv2/templates/vipTraveler/ModalMessages/BaseMessageTemplate';

/**
 * Pre Q Register Pop 1.
 */

const MESSAGE_CONTENT = {
  image: '/images/lp/promo2/vip_illustration-ok.svg',
  heading: 'Almost done!',
  tagline: 'You’re on your way to having your perfect trip. Just click below to make a deposit.',
  buttonText: 'Proceed',
};

const DepositRegistrationMessage = ({ step, getNextStep }) => (
  <AuthMessage
    step={step}
    getNextStep={getNextStep}
    render={({ handleClick }) => (
      <BaseMessageTemplate
        handleClick={handleClick}
        customClassName="deposit-registration-popup"
        {...MESSAGE_CONTENT}
      />
    )}
  />
);

DepositRegistrationMessage.propTypes = {
  step: PropTypes.shape({
    component: PropTypes.func.isRequired,
    onComplete: PropTypes.func.isRequired,
  }).isRequired,
  getNextStep: PropTypes.func.isRequired,
};

export default DepositRegistrationMessage;
