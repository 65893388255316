import React from 'react';
import PropTypes from 'prop-types';

import ImageResolverFactory from '@utils/images/ImageResolverFactory';
import ErrorBoundaryDecorator from '@components/decorators/ErrorBoundaryDecorator';

/**
 * Usage:
 *
 * Using 'path' and 'name' without an extension
 * (extension and image size will be determined automatically):
 *
 * const imageData = {
 *   name: '2027e31727d6',
 *   path: 'test-images/1'
 * };
 *
 * <Image data={imageData} background />
 *
 * ------------------
 * const imgSrc = '/media/image.png';
 *
 * <Image data={imgSrc} />
 *
 * ------------------
 * To force a specific image size:
 *
 * import { IMAGE_SIZES } from '@js/constants';
 *
 * <Image
 *   data={{
 *     name: '2027e31727d6',
 *     path: 'test-images/1'
 *   }}
 *   config={{
 *     size: IMAGE_SIZES.LARGE,
 *   }}
 * />
 *
 * ------------------
 *
 * If you use background={true}, you can pass child elements:
 * <Image
 *   data={...}
 *   background
 * >
 *   <a href="" />
 * </Image>
 *
 * ------------------
 * To add a timestamp to the output URL:
 *
 * <Image
 *   data={{
 *     path: 'test-images/1.jpeg',
 *     updated_at: '2020-12-11T09:22:38.000000Z',
 *   }}
 * />
 *
 * That will add such a query string: "...test-images/1.jpeg?2020-12-11T09:22:38.000000Z"
 *
 * @param Object|null data Should contain 'path' and 'name' fields
 * @param {string} type Image resolver name. See ImageResolverFactory
 * @param {boolean} background Whether to render <div /> instead of <img /> tag
 * @param {string} className
 * @return {*}
 * @constructor
 */
const Image = ({ data, type, background, className, config, lazy, children, ...props }) => {
  if (Object.keys(data || {}).length === 0) {
    return (
      <div className={className} {...props}>
        {children}
      </div>
    );
  }

  const renderBackgroundImage = (src) => {
    const srcAttribute = lazy
      ? { 'data-background': src }
      : { style: { backgroundImage: `url(${src})` } };

    return (
      <div {...props} className={className} {...srcAttribute}>
        {children}
      </div>
    );
  };

  if (data.link && !data.path) {
    return renderBackgroundImage(data.link);
  }

  const imageResolver = ImageResolverFactory.create(type, data, config);
  const src = imageResolver.getSrc();

  const renderImage = () => {
    const srcAttribute = lazy ? { 'data-src': src } : { src };

    return <img {...props} className={className} alt={data.name} {...srcAttribute} />;
  };

  if (background) {
    return renderBackgroundImage(src);
  }

  return renderImage();
};

Image.propTypes = {
  data: PropTypes.object,
  type: PropTypes.string,
  background: PropTypes.bool, // render a DIV with 'background-image' style instead of the IMG tag
  className: PropTypes.string,
  config: PropTypes.object,
  lazy: PropTypes.bool, // render the data-background/data-src attribute instead of src.
};

Image.defaultProps = {
  data: {},
  type: 'Default', // DefaultImageResolver
  background: false,
  className: '',
  config: {},
  lazy: false,
};

export default ErrorBoundaryDecorator()(Image);
