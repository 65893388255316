/* eslint-disable camelcase */
import { useContext, useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import ScreenTypeContext, { isDesktop } from '@js/context/ScreenTypeContext';

import { getCountriesList } from '@jsv2/utils/CountryUtils/APIRequests';
import ApiThrottleClient from '@js/ApiThrottleClient';

const { currentCountry } = window.__SERVER_DATA__.shared_data;

const AddressStepForm = ({ autoFocus, render, formRef }) => {
  const screenTypeContext = useContext(ScreenTypeContext);

  const fullNameField = useRef();
  const [formData, setFormData] = useState({
    currentCountry,
    countries: null,
    phoneCode: 'us',
  });

  useEffect(() => {
    getCountriesList().then(({ data }) => {
      const countries = data ? data.data : [];

      setFormData((prevState) => ({
        ...prevState,
        countries,
        countriesISO2: countries.map((c) => c.iso2.toLowerCase()),
      }));
    });
  }, []);

  useEffect(() => {
    if (autoFocus && isDesktop(screenTypeContext)) {
      fullNameField.current.focus();
    }
  }, [autoFocus]);

  const apiThrottleClient = new ApiThrottleClient();

  const handleFieldChange = (event, name, values) => {
    const { handleChange, setErrors, errors } = formRef.current;
    const { value } = event.target;

    if (errors[name] && value !== values[name]) {
      setErrors({ ...errors, [name]: null });
    }
    handleChange(event);
  };

  const getZipLabel = (countryId) => (+countryId === 230 ? 'zip' : 'post');

  const handleCountryCodeChange = (country_id, values) => {
    const countryId = Number.parseInt(country_id, 10);
    const { countries } = formData;

    const country = countries?.find(({ id }) => id === countryId);

    if (!values.phone && country) {
      setFormData((prevState) => ({
        ...prevState,
        phoneCode: country.iso2.toLowerCase(),
      }));
    }

    return formData.phoneCode;
  };

  const handleCountryIdChange = (event, values) => {
    const { handleChange } = formRef.current;
    const country_id = event.target.value;

    handleChange(event);
    handleCountryCodeChange(country_id, values);
  };

  return render({
    apiThrottleClient,
    fullNameField,
    formData,
    handleFieldChange,
    handleCountryIdChange,
    getZipLabel,
  });
};

AddressStepForm.propTypes = {
  render: PropTypes.func.isRequired,
  autoFocus: PropTypes.bool,
};

AddressStepForm.defaultProps = {
  autoFocus: true,
};

export default AddressStepForm;
