import React from 'react';
import PropTypes from 'prop-types';

import ErrorBoundaryDecorator from '@components/decorators/ErrorBoundaryDecorator';
import WizardContext from '@jsv2/context/WizardContext';
import STEPS from '@jsv2/config/SignUp/steps';
import Step from './Step';

const Wizard = ({ stepConfig, getStep, setStepUrl }) => {
  const isNextStepExist = (nextStep) => Object.values(STEPS).includes(nextStep);

  const getNextStep = (nextStep) => {
    if (isNextStepExist(nextStep)) {
      getStep(nextStep);
    }
  };

  return (
    <WizardContext.Provider
      value={{
        stepConfig,
        getNextStep,
      }}
    >
      <Step setStepUrl={setStepUrl} />
    </WizardContext.Provider>
  );
};

Wizard.propTypes = {
  stepConfig: PropTypes.shape({
    component: PropTypes.func.isRequired,
    onComplete: PropTypes.func.isRequired,
  }),
  getStep: PropTypes.func.isRequired,
  setStepUrl: PropTypes.func,
};

Wizard.defaultProps = {
  stepConfig: null,
  setStepUrl: null,
};

export default ErrorBoundaryDecorator()(Wizard);
