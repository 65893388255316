import ErrorBoundaryDecorator from '@components/decorators/ErrorBoundaryDecorator';
import SIGNUP_FLOW from './flows';
import disabledAuthFlowConfig from './flows/disabledAuthFlowConfig';
import profilerPaidFlowConfig from './flows/profilerPaidFlowConfig';
import profilerUpgradeFlowConfig from './flows/profilerUpgradeFlowConfig';
import upgradeFlowConfig from './flows/upgradeFlowConfig';
import paidFlowConfig from './flows/paidFlowConfig';
import freeFlowConfig from './flows/freeFlowConfig';
import welcomeFlowConfig from './flows/welcomeFlowConfig';
import ProfilerConfig from '../Profiler/index';
import welcomeFlowMultipleConfig from './flows/welcomeFlowMultipleConfig';
import welcomeFlowUpdatedConfig from './flows/welcomeFlowUpdatedConfig';
import upgradePlanConfig from './flows/upgradePlanConfig';
import onOfBookingFlowConfig from './flows/onOfBookingFlowConfig';
import depositFlowConfig from './flows/depositFlowConfig';
import newDepositFlowConfig from './flows/newDepositFlowConfig';

const SignUpConfig = {
  [SIGNUP_FLOW.PAID]: paidFlowConfig,
  [SIGNUP_FLOW.FREE]: freeFlowConfig,
  [SIGNUP_FLOW.UPGRADE]: upgradeFlowConfig,
  [SIGNUP_FLOW.WELCOME]: welcomeFlowConfig,
  [SIGNUP_FLOW.WELCOME_MULTIPLE]: welcomeFlowMultipleConfig,
  [SIGNUP_FLOW.WELCOME_UPDATED]: welcomeFlowUpdatedConfig,
  [SIGNUP_FLOW.PROFILER_PAID]: profilerPaidFlowConfig,
  [SIGNUP_FLOW.PROFILER_UPGRADE]: profilerUpgradeFlowConfig,
  [SIGNUP_FLOW.DISABLED]: disabledAuthFlowConfig,
  [SIGNUP_FLOW.UPGRADE_PLAN]: upgradePlanConfig,
  [SIGNUP_FLOW.ONE_OF_BOOKING]: onOfBookingFlowConfig,
  [SIGNUP_FLOW.DEPOSIT]: depositFlowConfig,
  [SIGNUP_FLOW.DEPOSIT_FLOW]: newDepositFlowConfig,
  ...ProfilerConfig,
};

export default ErrorBoundaryDecorator()(SignUpConfig);
