import React, { useContext } from 'react';
import PropTypes from 'prop-types';

import ScreenTypeContext, { isMobile } from '@js/context/ScreenTypeContext';
import BackButton from '@jsv2/components/Header/Items/Action/BackButton';
import ErrorBoundaryDecorator from '@components/decorators/ErrorBoundaryDecorator';
import LPContext from '@jsv2/LandingPage/context/LPContext';
import MenuItems from '@jsv2/LandingPage/components/MenuItems';
import { isAnonymous } from '@jsv2/utils/UserUtils/userStatuses';
import ProfileMenu from '@jsv2/config/Header/ProfileMenu';
import Account from '@jsv2/components/Header/Items/Account';

const {
  userRoles,
  __SERVER_DATA__: {
    shared_data: { defaultHomePageUrl, defaultHomePageUrlWithToken },
  },
} = window;

const Header = React.forwardRef(
  (
    {
      onClickMenu,
      isCurtainVisible,
      isHeaderHidden,
      topMenuIsVisible,
      goBackUrl,
      config,
      loginButton,
      joinUsButton,
      allowedHeaderInvert,
    },
    ref,
  ) => {
    const screenTypeContext = useContext(ScreenTypeContext);
    const { scrollTo } = useContext(LPContext);

    /**
     * Render the back link or logo, depending on whether the current link has a parameter
     */
    const renderLeftActionLink = () => {
      if (goBackUrl) {
        return <BackButton goBackUrl={goBackUrl} />;
      }

      if (!isAnonymous(userRoles)) {
        // eslint-disable-next-line jsx-a11y/anchor-has-content
        return <a href={defaultHomePageUrl} className="header__logotype" />;
      }

      return <div className="header__logotype" />;
    };

    const getMenuItems = () =>
      config.reduce((acc, { menuItemName, isVisible, name }, index) => {
        if (menuItemName && isVisible) {
          acc.push({
            id: index,
            name: menuItemName,
            sectionId: name,
          });
        }
        return acc;
      }, []);

    return (
      <header
        data-qa-id="qa_header_block"
        className={`header ${
          isCurtainVisible && !topMenuIsVisible && allowedHeaderInvert ? 'header--isInverted' : ''
        } ${isHeaderHidden ? 'header--isHidden' : ''}`}
        ref={ref}
      >
        <div className="header__wrapper">
          {renderLeftActionLink()}

          {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
          <div
            className={`header__navlist-wrap ${topMenuIsVisible ? 'isOpen' : ''}`}
            data-qa-id="header_menu"
            onClick={onClickMenu}
          >
            <nav className="navlist" data-qa-id="menu_list">
              {isMobile(screenTypeContext) && isAnonymous(userRoles) && (
                <a className="navlist__btn" href="/sign-in" data-qa-id="header-login">
                  {t('Login')}
                </a>
              )}

              <a href={defaultHomePageUrlWithToken} className="navlist__btn">
                VIP Exclusives
              </a>

              {/*<a href="/hotel-search" className="navlist__btn navlist__btn--with-badge">
                Luxury Listings
              </a>*/}

              <MenuItems items={getMenuItems()} scrollTo={scrollTo} />

              {!isAnonymous(userRoles) && <Account>{ProfileMenu}</Account>}
            </nav>
          </div>

          {!isMobile(screenTypeContext) && isAnonymous(userRoles) && loginButton()}
          {!isMobile(screenTypeContext) && joinUsButton()}
        </div>
      </header>
    );
  },
);

Header.propTypes = {
  onClickMenu: PropTypes.func.isRequired,
  isCurtainVisible: PropTypes.bool.isRequired,
  isHeaderHidden: PropTypes.bool.isRequired,
  topMenuIsVisible: PropTypes.bool.isRequired,
  goBackUrl: PropTypes.string,
};

Header.defaultProps = {
  goBackUrl: '',
};

Header.displayName = 'Header';

export default ErrorBoundaryDecorator()(Header);
