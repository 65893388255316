import { useContext } from 'react';
import PropTypes from 'prop-types';

import UserContext from '@jsv2/context/UserContext';

const ChoosePlanStepDefault = ({ step, getNextStep, render, ...rest }) => {
  const { updateUserRoles } = useContext(UserContext);

  const handleFreeChoice = () => {
    if (typeof step.onUpgrade === 'function') {
      getNextStep(step.onUpgrade());
      updateUserRoles();
    }
  };

  const handlePaidChoice = () => {
    getNextStep(step.onComplete());
    updateUserRoles();
  };

  return render({ handleFreeChoice, handlePaidChoice, ...rest });
};

ChoosePlanStepDefault.propTypes = {
  render: PropTypes.func.isRequired,
  step: PropTypes.shape({
    component: PropTypes.func.isRequired,
    onComplete: PropTypes.func.isRequired,
  }).isRequired,
  getNextStep: PropTypes.func.isRequired,
};

export default ChoosePlanStepDefault;
