import React, { useContext } from 'react';

import ErrorBoundaryDecorator from '@components/decorators/ErrorBoundaryDecorator';
import EditFieldContext, { Editable } from '@jsv2/LandingPage/context/EditFieldContext';
import LPContext from '../context/LPContext';

const AboutSection = React.forwardRef(({ id }, ref) => {
  const { editableFields } = useContext(EditFieldContext);
  const { renderBecomeButton } = useContext(LPContext);

  return (
    <>
      {editableFields && (
        <section className="about about--lp4" id={id} ref={ref}>
          <div className="main-container about__container about__container--lp4">
            <div className="section-title section-title--center">
              <Editable fieldValue={editableFields.about_heading} />
            </div>

            {editableFields.about_testimonial && (
              <div className="about__title about__title--lp4">
                <Editable fieldValue={editableFields.about_testimonial} />
              </div>
            )}

            {editableFields.about_description && (
              <div className="common-text common-text--center">
                <Editable fieldValue={editableFields.about_description} />
              </div>
            )}

            {renderBecomeButton(
              'become-btn become-btn--with-border',
              window.templateVariables.body_cta_text.content,
              'become-join-btn-1',
            )}
          </div>
        </section>
      )}
    </>
  );
});

export default ErrorBoundaryDecorator()(AboutSection);
