import React from 'react';
import PropTypes from 'prop-types';

import ErrorBoundaryDecorator from '@components/decorators/ErrorBoundaryDecorator';
import { ErrorMessage, FieldArray } from 'formik';
import CustomField from '@components/formComponents/CustomField';

const PLACEHOLDER = 'Type your answer here...';

/**
 * @param {object} question
 * @param {object} values
 * @param {string} error
 * @param {number} questionIndex
 * @param {boolean} isSingleSubmit
 * @return {JSX.Element}
 * @constructor
 */
const InputQuestion = ({ question, values, error, questionIndex, isSingleSubmit }) => {
  const questionName = isSingleSubmit ? 'answers' : `answers[${questionIndex}].answers`;

  return (
    <div className="input form-field" key={question.question}>
      {question.hint && (
        <div className="question-hint" data-qa-id="question_hint">
          {question.hint}
        </div>
      )}

      {error && <div className="error-message">{error}</div>}

      <FieldArray
        name={questionName}
        render={(arrayHelpers) =>
          question.options.length > 0 ? (
            question.options.map((answer, index) => (
              <CustomField
                key={answer.hint}
                name={`values.${questionName}[${index}]`}
                type="text"
                autoComplete="off"
                placeholder={answer.hint || PLACEHOLDER}
                value={values.answers[questionIndex].answers[index] || ''}
                onChange={(e) => arrayHelpers.replace(index, e.target.value)}
              />
            ))
          ) : (
            <CustomField
              name={`values.${questionName}[0]`}
              type="text"
              autoComplete="off"
              placeholder={PLACEHOLDER}
              onChange={(e) => arrayHelpers.replace(0, e.target.value)}
            />
          )
        }
      />

      <ErrorMessage name={question.type} />
    </div>
  );
};

InputQuestion.propTypes = {
  question: PropTypes.object.isRequired,
  values: PropTypes.object.isRequired,
  questionIndex: PropTypes.number.isRequired,
  error: PropTypes.string,
  isSingleSubmit: PropTypes.bool,
};

InputQuestion.defaultProps = {
  error: '',
  isSingleSubmit: false,
};

export default ErrorBoundaryDecorator()(InputQuestion);
