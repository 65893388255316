/**
 * Wraps window.location.reload into setTimout to fix bug
 * when page does not reload after modal has been closed with ESC
 *
 * See: https://stackoverflow.com/questions/13306492/location-reload-doesnt-reload-the-page-when-im-using-it-in-keydown-event-handl
 * @param force
 */
export default function locationReload(force) {
  return setTimeout(() => {
    window.location.reload(force);
  }, 0);
}
