import React, { useContext, useState, useEffect, useCallback } from 'react';
import ScrollAnimation from 'react-animate-on-scroll';
import classNames from 'classnames';

import { storageURL } from '@jsv3/utils/urlUtils';
import ErrorBoundaryDecorator from '@components/decorators/ErrorBoundaryDecorator';
import LPContext from '@jsv2/LandingPage/context/LPContext';
import EditFieldContext, { Editable } from '@jsv2/LandingPage/context/EditFieldContext';
import ScreenTypeContext, { isMobile } from '@js/context/ScreenTypeContext';
import Image from '@jsv2/components/Image';

const BannerSection = ({ nextSection, id }) => {
  const screenTypeContext = useContext(ScreenTypeContext);
  const { editableFields } = useContext(EditFieldContext);
  const { scrollToNextSection } = useContext(LPContext);

  const [isVideoPlaying, setStateVideo] = useState(false);
  const [hideTitle, setHideTitle] = useState(false);
  const [bannerHeight, setBannerHeight] = useState(0);

  const checkScrollPosition = useCallback(() => {
    const scrollHeight = window.pageYOffset;
    const { clientHeight } = document.documentElement;

    if (scrollHeight > clientHeight / 3 && !hideTitle) {
      setHideTitle(true);
    }

    if (scrollHeight < clientHeight / 3 && hideTitle) {
      setHideTitle(false);
    }
  }, [hideTitle]);

  useEffect(() => {
    checkScrollPosition();

    window.addEventListener('scroll', checkScrollPosition, { passive: true });

    return () => {
      window.removeEventListener('scroll', checkScrollPosition);
    };
  }, [checkScrollPosition]);

  const checkDeviceWidth = useCallback(() => {
    const secondBannerHeight = document.getElementById('banner-second-block').clientHeight;
    setBannerHeight(window.innerHeight + secondBannerHeight);
  }, []);

  useEffect(() => {
    checkDeviceWidth();

    window.addEventListener('resize', checkDeviceWidth, { passive: true });

    return () => {
      window.removeEventListener('scroll', checkDeviceWidth);
    };
  }, [checkDeviceWidth]);

  const renderBottomContent = () => (
    <div className="banner__bottom-sticky">
      <div className="banner__sub-title" role="button">
        <Editable fieldValue={editableFields.tagline} />
      </div>

      <button
        type="button"
        className="btn-scroll btn-scroll--white"
        onClick={() => scrollToNextSection(nextSection)}
        data-qa-id="btn-scroll"
      />
    </div>
  );

  return (
    editableFields && (
      <section className="banner banner--animation" id={id} style={{ height: bannerHeight }}>
        <Image
          className={classNames('banner__video-wrapper', { 'video-loaded': isVideoPlaying })}
          data={{
            link: storageURL('/cms-page/banner-preview-low.jpg'),
          }}
          background
        >
          <video
            loop="loop"
            muted
            playsInline="playsinline"
            autoPlay="autoplay"
            className="banner__video"
            poster="/images/lp/transparent-icon.png"
            onPlay={() => setStateVideo(true)}
          >
            <source src={storageURL('/lp-videos/VIPBanner.mp4')} type="video/mp4" />
          </video>
        </Image>

        <div className="banner__block">
          <div className="main-container">
            <div className="banner__logotype-wrapper">
              <div className="banner__logotype-img" />
            </div>

            <ScrollAnimation
              animateIn="fadeInUp"
              duration={1}
              className={`banner__title-wrap ${hideTitle ? 'hide-title fadeOut' : ''}`}
              animateOnce
              initiallyVisible={!isMobile(screenTypeContext)}
            >
              <div className="banner__title">
                <Editable fieldValue={editableFields.heading} />
              </div>

              <div className="banner__quote">
                <Editable fieldValue={editableFields.quote} />
              </div>
            </ScrollAnimation>
          </div>
        </div>

        <div className="banner__block banner__block--small" id="banner-second-block">
          <div className="main-container">
            <ScrollAnimation
              animateIn="fadeIn"
              duration={1}
              className={`banner__title ${hideTitle ? 'fadeIn' : 'fadeOut'}`}
              animateOnce
            >
              <Editable fieldValue={editableFields.banner_heading} />
            </ScrollAnimation>
          </div>
        </div>

        {renderBottomContent()}
      </section>
    )
  );
};

export default ErrorBoundaryDecorator()(BannerSection);
