import React from 'react';

import { isAnonymous } from '@jsv2/utils/UserUtils/userStatuses';
import { getLinkToGo } from '@jsv3/utils/urlUtils';
import ErrorBoundaryDecorator from '@components/decorators/ErrorBoundaryDecorator';

const { userRoles, __SERVER_DATA__ } = window;
const {
  shared_data: { defaultHomePageUrl },
} = __SERVER_DATA__;

const FooterLogo = () => (
  <a href={isAnonymous(userRoles) ? getLinkToGo() : defaultHomePageUrl} className="logotype__wrap">
    <img
      src="/images/lp/promo2/logotype-white.svg"
      alt="VIP-traveler logotype"
      className="logotype"
    />
  </a>
);

export default ErrorBoundaryDecorator()(FooterLogo);
