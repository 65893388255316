import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import { alert } from '@utils/Dialogs';
import ErrorBoundaryDecorator from '@components/decorators/ErrorBoundaryDecorator';

/**
 * Render audio button
 *
 * @param toggleAudioPlayback
 * @param url
 * @param isAudioPlay
 *
 * @return {*}
 *
 * @constructor
 */
const AudioButton = React.forwardRef((props, ref) => {
  const { toggleAudioPlayback, url, isAudioPlay } = props;
  const [audio] = useState(new Audio(url));
  const [offsetTop, setOffsetTop] = useState(null);

  /**
   * Play or pause audio based on isAudioPlay value.
   */
  useEffect(() => {
    audio.volume = 0.25;
    if (isAudioPlay) {
      audio.play().catch(() => {
        alert(t('Some error occurred. Please try again later.'));
        toggleAudioPlayback(false);
      });
    } else {
      audio.pause();
    }
  }, [audio, isAudioPlay, toggleAudioPlayback]);

  /**
   * Repeat audio automatically after it ended.
   */
  useEffect(() => {
    const repeatAudio = () => {
      audio.currentTime = 0;
      audio.play().catch(() => {
        toggleAudioPlayback(false);
      });
      toggleAudioPlayback(true);
    };

    audio.addEventListener('ended', repeatAudio);

    return () => {
      audio.removeEventListener('ended', repeatAudio);
    };
  }, [audio, toggleAudioPlayback]);

  const checkButtonPosition = () => {
    const bannerWithAnimation = document.getElementById('bannerWithAnimation');

    if (bannerWithAnimation) {
      const bannerHeight = bannerWithAnimation.clientHeight;
      const offsetBottom = 240;

      setOffsetTop(bannerHeight - offsetBottom);
    }
  };

  useEffect(() => {
    checkButtonPosition();
  });

  const handlerAudioButtonClick = () => {
    toggleAudioPlayback(!isAudioPlay);
  };

  return (
    <button
      ref={ref}
      type="button"
      className={`audio-btn ${isAudioPlay ? 'audio-btn--play' : ''}`}
      onClick={handlerAudioButtonClick}
      style={offsetTop ? { top: `${offsetTop}px` } : {}}
    />
  );
});

AudioButton.propTypes = {
  toggleAudioPlayback: PropTypes.func.isRequired,
  url: PropTypes.arrayOf(PropTypes.string).isRequired,
  isAudioPlay: PropTypes.bool.isRequired,
};

export default ErrorBoundaryDecorator()(AudioButton);
