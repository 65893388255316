/**
 * This file contains ads settings
 */

const SET_AD_CONFIG = 'ADS/SET_CONFIG';

export default function reducer(state = {}, action = {}) {
  switch (action.type) {
    case SET_AD_CONFIG:
      return action.config;
    default: return state;
  }
}

export function setAdConfig(config) {
  return {
    type: SET_AD_CONFIG,
    config,
  };
};
