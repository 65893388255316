import React from 'react';
import PropTypes from 'prop-types';

import AuthMessage from '@jsv2/components/Authorization/AuthMessage';
import BaseMessageTemplate from '@jsv2/templates/vipTraveler/ModalMessages/BaseMessageTemplate';

/**
 * One-of booking success abandoned message.
 */

const MESSAGE_CONTENT = {
  image: '/images/lp/promo2/vip_illustration-ok.svg',
  heading: 'Thank you!',
  tagline: 'We\'ve received your deposit and membership details. We\'ll be in touch regarding your custom booking via email.',
  buttonText: 'VIEW VIP EXCLUSIVES™',
};

const OneOfBookingSuccessPaidMessage = ({ step, getNextStep }) => (
  <AuthMessage
    step={step}
    getNextStep={getNextStep}
    render={({ handleClick }) => (
      <BaseMessageTemplate handleClick={handleClick} {...MESSAGE_CONTENT} />
    )}
  />
);

OneOfBookingSuccessPaidMessage.propTypes = {
  step: PropTypes.shape({
    component: PropTypes.func.isRequired,
    onComplete: PropTypes.func.isRequired,
  }).isRequired,
  getNextStep: PropTypes.func.isRequired,
};

export default OneOfBookingSuccessPaidMessage;
