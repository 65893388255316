const defaultHeaders = {
  'X-Requested-With': 'XMLHttpRequest',
};

export const tokenHeader = 'x-csrf-token';

export const instanceDefaults = {
  baseURL: window.API_URL,
  headers: defaultHeaders,
};
