import { storageURL } from '@jsv3/utils/urlUtils';

const iconsDir = '/icons/';

export const ICON_COLORS = {
  BLACK: 'black',
  RED: 'red',
  GOLD: 'gold',
  WHITE: 'white',
};

/**
 * @param color
 * @param {Object} icon
 */
export const getIconUrl = (color, icon) => {
  if (!color || !Object.values(ICON_COLORS).includes(color)) {
    return null;
  }

  if (!icon || !icon.path || !icon.name) {
    return null;
  }

  // TODO: need to add basePath somehow to build absolute url
  // return storageURL(`${icon.path}/${color}/${icon.name}`);

  return icon.link ? storageURL(icon.link.replace(iconsDir, `${iconsDir}${color}/`)) : null;
};

/**
 * @param {Object} icon
 */
export const getWhiteIconUrl = (icon) => getIconUrl(ICON_COLORS.WHITE, icon);

/**
 * @param {Object} icon
 */
export const getBlackIconUrl = (icon) => getIconUrl(ICON_COLORS.BLACK, icon);

/**
 * @param {Object} icon
 */
export const getGoldIconUrl = (icon) => getIconUrl(ICON_COLORS.GOLD, icon);

/**
 * @param {Object} icon
 */
export const getRedIconUrl = (icon) => getIconUrl(ICON_COLORS.RED, icon);
