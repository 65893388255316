import React from 'react';

import { ERRORS } from '@jsv3/enums/errorMessagesEnums';

/**
 * Render error message
 */
const ErrorBoundariesComponent = () => (
  <div className="error-boundaries">{ERRORS.INFORMATION_NOT_DISPLAYED}</div>
);

export default ErrorBoundariesComponent;
