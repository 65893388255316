export default class Parameter {
  constructor(parameter) {
    this._current = parameter?.current || null;
    this._initial = parameter?.initial || null;
    this._hasNoExpiration = parameter?.hasNoExpiration || false;
  }

  get current() {
    return this._current;
  }

  get initial() {
    return this._initial;
  }

  get hasNoExpiration() {
    return this._hasNoExpiration;
  }
}
