import React from 'react';

export const PlayButtonSvg = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="100"
    height="100"
    x="0"
    y="0"
    version="1.1"
    viewBox="0 0 100 100"
    xmlSpace="preserve"
    className="play-video__svg"
  >
    <path
      fill="none"
      stroke="#000"
      d="M49.9 2.5C23.6 2.8 2.1 24.4 2.5 50.4 2.9 76.5 24.7 98 50.3 97.5c26.4-.6 47.4-21.8 47.2-47.7-.2-26.1-21.8-47.5-47.6-47.3"
      className="stroke-solid"
    />
    <path
      d="M38 69c-1 .5-1.8 0-1.8-1.1V32.1c0-1.1.8-1.6 1.8-1.1l34 18c1 .5 1 1.4 0 1.9L38 69z"
      className="icon"
    />
  </svg>
);

export const PauseButtonSvg = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="100"
    height="100"
    x="0"
    y="0"
    version="1.1"
    viewBox="0 0 100 100"
    xmlSpace="preserve"
    className="play-video__svg"
  >
    <g>
      <path
        fill="none"
        stroke="#000"
        d="M49.9 2.5C23.6 2.8 2.1 24.4 2.5 50.4 2.9 76.5 24.7 98 50.3 97.5c26.4-.6 47.4-21.8 47.2-47.7-.2-26.1-21.8-47.5-47.6-47.3"
        className="stroke-solid"
      />
      <rect className="icon" stroke="null" fill="null" x="35.036084" y="31.920626" width="6.357562" height="38.481739" />
      <rect className="icon" stroke="null" fill="null" x="57.945279" y="31.920626" width="6.357562" height="38.481739" />
    </g>
  </svg>
);

export const BackButtonSvg = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="100"
    height="100"
    x="0"
    y="0"
    version="1.1"
    viewBox="0 0 100 100"
    xmlSpace="preserve"
    className="back-video__svg"
  >
    <path
      fill="none"
      stroke="#000"
      d="M49.9 2.5C23.6 2.8 2.1 24.4 2.5 50.4 2.9 76.5 24.7 98 50.3 97.5c26.4-.6 47.4-21.8 47.2-47.7-.2-26.1-21.8-47.5-47.6-47.3"
      className="stroke-solid"
    />
    <path
      className="icon"
      stroke="null"
      id="svg_2"
      d="m69.10866,32.72917a1.41963,1.58521 0 0 0 -1.41963,0.12682l-14.84938,11.79394l0,-10.50992a1.41963,1.58521 0 0 0 -2.24302,-1.28402l-19.87489,15.85206a1.41963,1.58521 0 0 0 0,2.58389l19.87489,15.85206a1.41963,1.58521 0 0 0 2.24302,-1.29987l0,-10.50992l14.79259,11.79394a1.41963,1.58521 0 0 0 2.24302,-1.28402l0,-31.70413a1.41963,1.58521 0 0 0 -0.7666,-1.41083z"
    />
  </svg>
);

export const ForwardButtonSvg = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="100"
    height="100"
    x="0"
    y="0"
    version="1.1"
    viewBox="0 0 100 100"
    xmlSpace="preserve"
    className="forward-video__svg"
  >
    <path
      fill="none"
      stroke="#000"
      d="M49.9 2.5C23.6 2.8 2.1 24.4 2.5 50.4 2.9 76.5 24.7 98 50.3 97.5c26.4-.6 47.4-21.8 47.2-47.7-.2-26.1-21.8-47.5-47.6-47.3"
      className="stroke-solid"
    />
    <path
      className="icon"
      stroke="null"
      id="svg_2"
      d="m68.55679,48.90426l-19.12618,-13.48922a1.36616,1.34892 0 0 0 -2.15853,1.09263l0,8.94335l-14.23534,-10.03598a1.36616,1.34892 0 0 0 -2.15853,1.09263l0,26.97844a1.36616,1.34892 0 0 0 0.73773,1.20054a1.46179,1.44335 0 0 0 0.62843,0.14838a1.36616,1.34892 0 0 0 0.79237,-0.2563l14.23534,-10.03598l0,8.94335a1.36616,1.34892 0 0 0 0.73773,1.20054a1.46179,1.44335 0 0 0 0.62843,0.14838a1.36616,1.34892 0 0 0 0.79237,-0.2563l19.12618,-13.48922a1.36616,1.34892 0 0 0 0,-2.18525z"
    />
  </svg>
);
