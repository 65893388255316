import STEPS from '@jsv2/config/SignUp/steps';
import ROLES from '@jsv2/config/userRoles';
import BASE_STEP_WELCOME from '@jsv2/templates/vipTraveler/SignUp/Steps/BaseStepWelcome';

const welcomeFlowConfig = {
  [STEPS.BASE_STEP_WELCOME]: {
    name: STEPS.BASE_STEP_WELCOME,
    component: BASE_STEP_WELCOME,
    onComplete: () => {
      window.location.reload();
    },
    isClosable: true,
    roles: {
      [ROLES.ANONYMOUS]: true,
    },
  },
};

export default welcomeFlowConfig;
