import { isAbsoluteUrl, storageURL } from '@jsv3/utils/urlUtils';
import { getOptimalImageSize, isInlineImage, nameHasExtension } from '@utils/imageUtils';

class AbstractImageResolver {
  data = {
    name: '',
    path: '',
    type: '',
    resolutions: [],
  };

  // not all resolvers use all of the params listed below
  config = {
    size: null,
    density: null, // number
    blurred: false,
    squared: false,
  };

  src = '';
  format = 'jpeg'; // jpeg, webp

  /**
   * @param {Object|string} imageData
   * @param {Object} config
   */
  constructor(imageData, config = {}) {
    Object.assign(this.config, config);

    if (imageData) {
      if (typeof imageData === 'string') {
        this.data.path = imageData;
      } else {
        this.data = imageData;
      }
    }
  }

  getSrc() {
    if (!this.src) {
      this.src = this.resolve();
    }

    return this.src;
  }

  resolve() {
    if (isAbsoluteUrl(this.data.path) || isInlineImage(this.data.path)) {
      return this.data.path;
    }

    if (nameHasExtension(this.data.path)) {
      return storageURL(`/${this.data.path}`);
    }

    if (nameHasExtension(this.data.name)) {
      return storageURL(`/${this.data.path}/${this.data.name}`);
    }

    this.resolveFormat();
    this.resolveDensity();
    this.resolveSize();

    return '';
  }

  resolveFormat() {
    if (browserFeatures.hasWebP) {
      this.format = 'webp';
    } else {
      this.format = 'jpeg';
    }
  }

  resolveDensity() {
    if (!this.config.density && window.devicePixelRatio >= 1.6) {
      this.config.density = window.devicePixelRatio;
    }
  }

  resolveSize() {
    if (!this.config.size) {
      this.config.size = getOptimalImageSize(window.screen.width);
    }
  }
}

export default AbstractImageResolver;
