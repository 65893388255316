import React, { useContext } from 'react';
import PropTypes from 'prop-types';

import BaseMessageTemplate from '@jsv2/templates/vipTraveler/ModalMessages/BaseMessageTemplate';
import AuthMessage from '@jsv2/components/Authorization/AuthMessage';
import UserContext from '@jsv2/context/UserContext';
import { hasTravelFinds } from '@jsv2/utils/UserUtils/userStatuses';

/**
 * Post Q Pop A (Free).
 */

const MESSAGE_CONTENT = {
  image: '/images/lp/promo2/vip_illustration-ok.svg',
  heading: 'YOU’RE ALL SET!',
  tagline:
    'We’re now working on your unique VIP Traveler experience and will email you Travel Finds™ as soon as we’re ready to share. In the meantime, check out our featured VIP Exclusives™',
};

const FreeThanksMessage = ({ step, getNextStep }) => {
  const { customer } = useContext(UserContext);

  const isTravelFindsAllowed = hasTravelFinds(customer.roles);

  const handleClick = () => {
    if (isTravelFindsAllowed) {
      window.location.href = '/travel-finds';
    }

    getNextStep(step.onComplete());
  };

  const buttonText = isTravelFindsAllowed ? 'VIEW MY TRAVEL FINDS™' : 'VIEW VIP EXCLUSIVES™';

  return (
    <AuthMessage
      step={step}
      getNextStep={getNextStep}
      render={() => (
        <BaseMessageTemplate
          handleClick={handleClick}
          buttonText={buttonText}
          {...MESSAGE_CONTENT}
        />
      )}
    />
  );
};

FreeThanksMessage.propTypes = {
  step: PropTypes.shape({
    component: PropTypes.func.isRequired,
    onComplete: PropTypes.func.isRequired,
  }).isRequired,
  getNextStep: PropTypes.func.isRequired,
};

export default FreeThanksMessage;
