// eslint-disable-next-line import/no-cycle
import { FORM_EVENTS } from './registrationForm';

export const EMAIL_IS_ALREADY_REGISTERED = 'EMAIL_IS_ALREADY_REGISTERED';
export const MAGIC_LINK_EXPIRED = 'MAGIC_LINK_EXPIRED';
export const RESTORE_SIGN_UP_LINK = 'RESTORE_SIGN_UP_LINK';
export const FORM_ERROR = 'FORM_ERROR';
export const SET_FORM_EVENT = 'registrationsForm/SET_FORM_EVENT';
export const INVALID_PASSWORD = 'INVALID_PASSWORD';

// The reducer is quite stupid.
// Mostly all the time we need to figure out what to do with `event` at place
export default function reducer(action) {
  return action;
}

export function setFormEvent(event) {
  return {
    type: SET_FORM_EVENT,
    payload: event,
  };
}

export const setFormError = (message) =>
  setFormEvent({
    type: FORM_EVENTS.FORM_ERROR,
    payload: message,
  });

export const clearFormEvent = () => setFormEvent();

export const setGenericNetworkError = () =>
  setFormError(
    t(
      'An error occurred and your request could not be processed. Please refresh the page and try again in a few moments.',
    ),
  );

export const restoreSignUpLink = (payload) => ({
  type: FORM_EVENTS.RESTORE_SIGN_UP_LINK,
  payload,
});

export const passwordIsInvalid = (payload) => ({
  type: FORM_EVENTS.INVALID_PASSWORD,
  payload,
});

export const emailIsAlreadyRegistered = (email) => ({
  type: EMAIL_IS_ALREADY_REGISTERED,
  payload: { email },
});

export const magicLinkExpired = (email) => ({
  type: MAGIC_LINK_EXPIRED,
  payload: { email },
});
