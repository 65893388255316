import React, { useContext } from 'react';

import ErrorBoundaryDecorator from '@components/decorators/ErrorBoundaryDecorator';
import EditFieldContext, { Editable } from '@jsv2/LandingPage/context/EditFieldContext';
import OffersSliderWide from './OffersSliderWide';

const ExclusiveOffersSectionWide = React.forwardRef(({ id }, ref) => {
  const { editableFields } = useContext(EditFieldContext);

  return (
    <>
      {editableFields && (
        <section className="exclusive-offers" ref={ref} id={id}>
          <div className="main-container exclusive-offers__container">
            <div className="section-title section-title--white section-title--center section-title--with-underline">
              <Editable fieldValue={editableFields.exclusive_offers_heading} />
            </div>

            <div className="section-description section-description--white section-description--center">
              <Editable fieldValue={editableFields.exclusive_offers_testimonial} />
            </div>
          </div>
        </section>
      )}

      <OffersSliderWide />
    </>
  );
});

export default ErrorBoundaryDecorator()(ExclusiveOffersSectionWide);
