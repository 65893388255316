/**
 * List of urls, available during the sign-up process.
 *
 * @type {Object|(string)[]}
 */
const URLS = {
  /**
   * List of urls where user enters his email (signs-up).
   */
  BASE_STEP_DEFAULT: '/signup/entry',
  BASE_STEP_UPGRADE: '/signup/entry',
  BASE_STEP_PROFILER: '/signup/entry',

  ADDRESS_STEP_DEFAULT: '/signup/info',
  ADDRESS_STEP_PROFILER: '/signup/info',

  CHOOSE_PLAN_STEP: '/signup/membership',

  PAYMENT_STEP_DEFAULT: '/signup/payment',
  PAYMENT_STEP_UPGRADE: '/signup/payment',

  INCOMPLETE_SIGNUP_MESSAGE: '/signup/success',
  FREE_WELCOME_MESSAGE: '/signup/success',
  PAID_WELCOME_MESSAGE: '/signup/welcome',

  /**
   * List of urls with hardcoded questions in Travel Profiler (questionnaire).
   */
  TRAVEL_PROFILER: '/signup/profiler',
  TRAVEL_AIRLINE: '/signup/profiler',
  TRAVEL_HISTORY: '/signup/profiler',
  TRAVEL_PREFERENCES: '/signup/profiler',

  FREE_THANKS_MESSAGE: '/signup/welcome',
  PAID_THANKS_MESSAGE: '/signup/success',
  REGISTRATION_MESSAGE: '/signup/success',
  SUCCESS_ABANDONED_MESSAGE: '/signup/welcome',
  SUCCESS_FREE_MESSAGE: '/signup/success',
  SUCCESS_PAID_MESSAGE: '/signup/welcome',
  DISABLED_SIGNUP_MESSAGE: '/signup/success',

  SUCCESS_ONE_OF_BOOKING_PAID_MESSAGE: '/signup/welcome',
  SUCCESS_ONE_OF_BOOKING_ABANDONED_MESSAGE: '/signup/welcome',

  SUCCESS_DEPOSIT_MESSAGE: '/signup/welcome',
};

export default URLS;
