import React, { useContext } from 'react';
import PropTypes from 'prop-types';

import Slider from '@jsv2/components/Slider/Slider';
import Image from '@jsv2/components/Image';
import { IMAGE_SIZES } from '@js/constants';
import ErrorBoundaryDecorator from '@components/decorators/ErrorBoundaryDecorator';
import ScreenTypeContext, { isMobile } from '@js/context/ScreenTypeContext';
import { EditableImg } from '@jsv2/LandingPage/context/EditFieldContext';

const settings = {
  pagination: {
    clickable: true,
  },
  navigation: true,
  className: 'slider-wBorder__slider',
};

const SliderWithBorder = ({ items }) => {
  const screenTypeContext = useContext(ScreenTypeContext);

  return (
    <div className="slider-wBorder">
      <Slider
        sliderProps={{
          ...settings,
        }}
      >
        {items
          .filter((item) => item.image)
          .map((item) => (
            <div key={item.id} className="slider-wBorder__slide">
              <EditableImg image={item.image} width={547} height={406}>
                <Image
                  data={item.image && item.image.content}
                  type="PageContent"
                  config={{
                    size: isMobile(screenTypeContext) ? IMAGE_SIZES.IMAGE_W350 : IMAGE_SIZES.SMALL,
                  }}
                  background
                />
              </EditableImg>
            </div>
          ))}
      </Slider>
    </div>
  );
};

SliderWithBorder.propTypes = {
  items: PropTypes.arrayOf(PropTypes.object).isRequired,
};

SliderWithBorder.displayName = 'SliderWithBorder';

export default ErrorBoundaryDecorator()(SliderWithBorder);
