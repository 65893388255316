import React from 'react';
import PropTypes from 'prop-types';

import ErrorBoundaryDecorator from '@components/decorators/ErrorBoundaryDecorator';

/**
 * Render footer social links.
 *
 * @param   {Array} socialLinks
 *
 * @returns {JSX.Element}
 *
 * @constructor
 */
const FooterSocialLinks = ({ socialLinks }) => (
  <div className="main-footer__social">
    {socialLinks.map(
      (item) =>
        item.value !== null && (
          <a
            key={item.name}
            href={item.value}
            className={`social-item social-item--${item.name}`}
            target="__blank"
          >
            <img src={`/images/social/white-icon/${item.name}-icon.svg`} alt={item.name} />
          </a>
        ),
    )}
  </div>
);

FooterSocialLinks.propTypes = {
  socialLinks: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default ErrorBoundaryDecorator()(FooterSocialLinks);
