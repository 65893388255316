import React, { useContext } from 'react';

import { finishFillTravelHistory } from '@jsv2/utils/UserUtils/APIRequest';
import TravelHistoryWrapper from '@jsv2/templates/vipTraveler/TravelHistory/TravelHistoryWrapper';
import WizardContext from '@jsv2/context/WizardContext';
import UserContext from '@jsv2/context/UserContext';

const TravelHistory = ({ getNextStep }) => {
  const { onComplete } = useContext(WizardContext).stepConfig;
  const { customer, updateUserRoles } = useContext(UserContext);

  const handleClick = () => {
    getNextStep(onComplete(customer.roles));
    finishFillTravelHistory()
      .then(() => {
        updateUserRoles();
      });
  };

  return <TravelHistoryWrapper finishFillWishList={handleClick} />;
};

export default TravelHistory;
