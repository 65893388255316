import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

const FreeMembershipChoice = ({ handleFreeChoice, isTopPosition }) => (
  <div
    className={classNames(
      'premium-membership__footer',
      { 'reverse': isTopPosition },
    )}
  >
    <div className="premium-membership__separator">
      {t('- or -')}
    </div>

    <button
      type="button"
      className="premium-membership__close-btn button-pink"
      onClick={() => handleFreeChoice()}
      data-qa-id="qa_premium_membership"
    >
      {t('continue with free membership')}
    </button>
  </div>
);

FreeMembershipChoice.propTypes = {
  handleFreeChoice: PropTypes.func.isRequired,
  isTopPosition: PropTypes.bool,
};

FreeMembershipChoice.defaultProps = {
  isTopPosition: false,
};

export default FreeMembershipChoice;
