import React from 'react';
import PropTypes from 'prop-types';

import ErrorBoundaryDecorator from '@components/decorators/ErrorBoundaryDecorator';
import { FieldArray } from 'formik';
import Image from '@jsv2/components/Image';
import { IMAGE_SIZES } from '@js/constants';

/**
 * @param {object} question
 * @param {object} values
 * @param {number} questionIndex
 * @param {string} error
 * @param {bool} isSingleSubmit
 * @return {JSX.Element}
 * @constructor
 */
const SelectQuestion = ({ question, values, questionIndex, error, isSingleSubmit }) => {
  const questionName = isSingleSubmit ? 'answers' : `answers[${questionIndex}].answers`;
  const currentValues = isSingleSubmit ? values.answers : values.answers[questionIndex].answers;

  return (
    <>
      {question.hint && <div className="question-hint mb-20">{question.hint}</div>}

      {error && <div className="error-message">{error}</div>}

      <FieldArray
        name={questionName}
        render={(arrayHelpers) => (
          <div className="options-block">
            {question.options.map((answer, index) => (
              <label
                key={answer.id}
                htmlFor={answer.id}
                className={`options-item ${currentValues.includes(answer.text) ? 'active' : ''}`}
                data-qa-id="qa_options_item"
              >
                <input
                  id={answer.id}
                  name={`values.${questionName}[${index}]`}
                  type="checkbox"
                  value={currentValues[index] || ''}
                  checked={currentValues.includes(answer.text)}
                  onChange={(e) => {
                    if (e.target.checked && question.answer_limit === 1) {
                      arrayHelpers.remove();
                      arrayHelpers.push(answer.text);

                      return;
                    }

                    const limit = isSingleSubmit
                      ? arrayHelpers.form.values.answers.length < question.answer_limit
                      : arrayHelpers.form.values.answers[questionIndex].answers.length <
                        question.answer_limit;

                    if (e.target.checked && limit) {
                      arrayHelpers.push(answer.text);
                    }

                    if (!e.target.checked) {
                      const idx = currentValues.indexOf(answer.text);
                      arrayHelpers.remove(idx);
                    }
                  }}
                />

                {answer.image ? (
                  <Image
                    data={answer.image}
                    config={{
                      size: IMAGE_SIZES.SMALL,
                    }}
                    alt={answer.text}
                  />
                ) : (
                  <>
                    <div className="options-item-title">{answer.text}</div>
                    <div className="options-item-hint">{answer.hint}</div>
                  </>
                )}
              </label>
            ))}
          </div>
        )}
      />
    </>
  );
};

SelectQuestion.propTypes = {
  question: PropTypes.object.isRequired,
  values: PropTypes.object.isRequired,
  questionIndex: PropTypes.number.isRequired,
  error: PropTypes.string,
  isSingleSubmit: PropTypes.bool,
};

SelectQuestion.defaultProps = {
  error: '',
  isSingleSubmit: false,
};

export default ErrorBoundaryDecorator()(SelectQuestion);
