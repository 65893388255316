import apiClient from '@js/apiClient';
import { withResolutionsFilter } from '@jsv2/utils/imageUtils';

// region TravelPreferences
/**
 * Get Destinations List.
 *
 * @return {Promise<AxiosResponse<any>>}
 */
export function getDestinationsList() {
  const resolutionsConfig = withResolutionsFilter(['w600']);

  return apiClient.get('user/travel-preference/undecided?' + resolutionsConfig);
}

/**
 * Add WishList Item.
 *
 * @param {number} id
 * @param {string} type
 *
 * @return {Promise<AxiosResponse<any>>}
 */
export function addWishListItem(id, type) {
  return apiClient.post('user/travel-preference/white-list', {
    entity_id: id,
    entity_type: type,
  });
}

/**
 * Add Wish List Item By Name.
 *
 * @param {string} name
 * @param {string} entityType
 *
 * @return {Promise<AxiosResponse<any>>}
 */
export function addWishListItemByName(name, entityType) {
  return apiClient.post('user/travel-preference', {
    name,
    entity_type: entityType,
  });
}

/**
 * Remove Wish List Item By Name.
 *
 * @param {string} name
 *
 * @return {Promise<AxiosResponse<any>>}
 */
export function removeWishListItemByName(name) {
  return apiClient.delete('user/travel-preference', {
    data: {
      name,
    },
  });
}

/**
 * Add Wish List Item to black list.
 *
 * @param {object} item
 *
 * @return {Promise<AxiosResponse<any>>}
 */
export function blacklisted(item) {
  return apiClient.post('user/travel-preference/black-list', {
    entity_id: item.id,
    entity_type: item.entity_type,
  });
}

/**
 * Send request to order travel preferences by priority.
 *
 * @param {array<string>} preferenceNames
 *
 * Using "names" instead of "ids" here because of "handing destinations" which do not have attached entity.
 * So the only way to tie them to stored travel preference is "name".
 *
 * @return {Promise<AxiosResponse<any>>}
 */
export function reorderWishListItems(preferenceNames) {
  return apiClient.put('user/travel-preference/reorder', {
    order: preferenceNames,
  });
}

// endregion

// region TravelPreferences
/**
 * Get Destinations List.
 *
 * @return {Promise<AxiosResponse<any>>}
 */
export function getTravelHistoryDestinationsList() {
  const resolutionsConfig = withResolutionsFilter(['w600']);

  return apiClient.get('user/travel-history/undecided?' + resolutionsConfig);
}

/**
 * Add TravelHistory Item.
 *
 * @param {number} id
 * @param {string} type
 *
 * @return {Promise<AxiosResponse<any>>}
 */
export function addTravelHistoryItem(id, type) {
  return apiClient.post('user/travel-history/white-list', {
    entity_id: id,
    entity_type: type,
  });
}

/**
 * Add TravelHistory Item By Name.
 *
 * @param {string} name
 *
 * @return {Promise<AxiosResponse<any>>}
 */
export function addTravelHistoryItemByName(name) {
  return apiClient.post('user/travel-history', {
    name,
  });
}

/**
 * Remove TravelHistory Item By Name.
 *
 * @param {string} name
 *
 * @return {Promise<AxiosResponse<any>>}
 */
export function removeTravelHistoryItemByName(name) {
  return apiClient.delete('user/travel-history', {
    data: {
      name,
    },
  });
}

// endregion

// region TravelAirlines
/**
 * Get Airlines List.
 *
 * @return {Promise<AxiosResponse<any>>}
 */
export function getTravelAirlinesList() {
  return apiClient.get('airlines');
}

// endregion
