import React, { useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import Cookies from 'browser-cookies';
import { connect } from 'react-redux';

import { EXPIRED_SESSION_RELOADED_KEY } from '@jsv2/Enums/ExpiredSessionEnums';
import { AD_TYPES } from './constants';
import LayoverComponent from './components/LayoverComponent';

const processLanding = (config) => {
  const { redirect_url: redirectUrl } = config.settings;

  if (!redirectUrl) {
    Logger.error('redirect_url is not specified for landing-page add');

    return;
  }

  window.location.replace(redirectUrl);
};

const markAdAsShown = (config) => {
  Cookies.set('content-control-items-' + config.id, 'true', { expires: config.interval });
};

const increaseTagCounter = (config) => {
  const { tag_id: tagId } = config;
  let shownTags = {};

  const shownTagsCookie = Cookies.get('content-control-tags');

  if (shownTagsCookie) {
    shownTags = JSON.parse(atob(shownTagsCookie));
  }

  if (!shownTags[tagId]) {
    shownTags[tagId] = 1;
  } else {
    shownTags[tagId]++;
  }

  Cookies.set('content-control-tags', btoa(JSON.stringify(shownTags)));
};

const AdProcessor = ({ adConfig, isLayoverOpen }) => {
  const [Component, setComponent] = useState(null);
  const layoverComponentRef = useRef(null);

  const { settings } = adConfig || {};

  const modalSetting = {
    shouldCloseOnOverlayClick: settings?.click_outside_out,
    shouldCloseOnEsc: settings?.esc_out,
    data: { 'qa-id': 'qa_popup_layover' },
    className: settings?.class_name || 'modal-window',
  };

  const processLayover = async () => {
    const templatePath = settings.template_path.replace('.', '/');

    const { default: Layover } = await import(`@js/layovers/${templatePath}`);

    setComponent(Layover);
  };

  const performAdAction = async () => {
    if (isLayoverOpen) {
      Logger.info('Ad action is canceled.');
    } else {
      switch (adConfig.type) {
        case AD_TYPES.BANNER:
          break;
        case AD_TYPES.LAYOVER:
          await processLayover();
          break;
        case AD_TYPES.LANDING_PAGE:
          processLanding(adConfig);
          break;
        default:
          Logger.error(`Wrong ad type: ${adConfig.type}`);
      }

      markAdAsShown(adConfig);
      increaseTagCounter(adConfig);
    }
  };

  useEffect(() => {
    if (adConfig) {
      performAdAction().then();

      setTimeout(() => {
        layoverComponentRef.current.openModal();
      }, 100);
    } else {
      setComponent(null);
    }
  }, [adConfig]);

  useEffect(() => {
    const isPageReloaded = localStorage.getItem(EXPIRED_SESSION_RELOADED_KEY);

    if (isPageReloaded === 'true') {
      localStorage.setItem(EXPIRED_SESSION_RELOADED_KEY, 'done');
    } else if (isPageReloaded === 'done') {
      localStorage.removeItem(EXPIRED_SESSION_RELOADED_KEY);
    }

    let adTimeout = null;

    if (!adConfig || !settings) {
      Logger.info('AD config is not specified');
    } else if (settings.time_delay) {
      adTimeout = setTimeout(() => {
        performAdAction().then();
      }, settings.time_delay * 1000);
    } else {
      performAdAction().then();
    }

    return () => {
      if (adTimeout !== null) {
        clearTimeout(adTimeout);
      }
    };
  }, []);

  if (!Component) {
    return null;
  }

  return (
    <LayoverComponent
      ref={layoverComponentRef}
      modalSettings={modalSetting}
      enableCloseButton={settings.x_out}
      LayoverContentComponent={Component}
      layoverContentProps={settings.template_config}
      openByDefault
    />
  );
};

AdProcessor.propTypes = {
  adConfig: PropTypes.object,
  isLayoverOpen: PropTypes.bool.isRequired,
};

AdProcessor.defaultProps = {
  adConfig: null,
};

const mapStateToProps = ({ adConfig, layover }) => ({
  adConfig,
  isLayoverOpen: layover.isOpen,
});

export default connect(mapStateToProps)(AdProcessor);
