import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

const imageSrc = (color) => `/images/spinner-${color}.svg`;

// preload spinner image
const image = new Image();
image.src = imageSrc('pink');

const Spinner = ({ color, fullHeight, className }) => (
  <div
    className={classNames('spinner', { 'spinner--full-height': fullHeight }, className)}
    data-qa-id="spinner"
  >
    <img src={imageSrc(color)} alt="Spinner" />
  </div>
);

Spinner.propTypes = {
  color: PropTypes.string,
  className: PropTypes.string,
  fullHeight: PropTypes.bool,
};

Spinner.defaultProps = {
  color: 'pink',
  className: '',
  fullHeight: false,
};

export default Spinner;
