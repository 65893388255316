import React, { useEffect, useState } from 'react';

import apiClient from '@js/apiClient';
import Modal from '@components/common/Modal';
import Spinner from '@components/Spinner';
import ErrorBoundaryDecorator from '@components/decorators/ErrorBoundaryDecorator';

const termsConditionsPageURL = window.__SERVER_DATA__.shared_data.staticPages.terms_and_conditions;

const TermsConditionsModal = ({ isOpen, closeModal }) => {
  const [modalContent, setModalContent] = useState(null);
  const [isLoading, setLoading] = useState(false);

  const loadModalContent = () => apiClient.get(`page-content?url=${termsConditionsPageURL}`);

  useEffect(() => {
    if (isOpen) {
      setLoading(true);
      loadModalContent()
        .then(({ data }) => {
          // eslint-disable-next-line camelcase
          setModalContent(data?.content_areas?.main);
          setLoading(false);
        })
        .catch(() => {
          setTimeout(() => {
            setLoading(false);
            closeModal();
          }, 2000);
        });
    }
  }, [isOpen, closeModal]);

  return (
    <Modal
      isOpen={isOpen}
      withCloseButton={!isLoading}
      onRequestClose={closeModal}
      shouldCloseOnOverlayClick
      data={{
        'qa-id': 'qa_popup_terms_conditions',
      }}
    >
      <div>
        {isLoading ? (
          <Spinner />
        ) : (
          <div className="html-content" dangerouslySetInnerHTML={{ __html: modalContent }} />
        )}
      </div>
    </Modal>
  );
};

TermsConditionsModal.propTypes = {};

export default ErrorBoundaryDecorator()(TermsConditionsModal);
