import React, { PureComponent } from 'react';
import { connect, Field } from 'formik';
import PropTypes from 'prop-types';

class PasswordField extends PureComponent {
  static propTypes = {
    name: PropTypes.string,
    placeholder: PropTypes.string,
  };

  static defaultProps = {
    name: 'password',
    placeholder: 'Password',
  };

  state = {
    showPassword: false,
  };

  toggleShowPassword = () => {
    this.setState((prevState) => ({ showPassword: !prevState.showPassword }));
  };

  render() {
    const { name, ...props } = this.props;
    const { showPassword } = this.state;

    return (
      <div className="input password">
        <div className="input-wrapper">
          <Field type={showPassword ? 'text' : 'password'} name={name} {...props} />

          {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events */}
          <span
            role="button"
            tabIndex="0"
            className={`show-password ${showPassword ? 'active' : ''}`}
            onClick={this.toggleShowPassword}
          />
        </div>
      </div>
    );
  }
}

export default connect(PasswordField);
