import React, { useContext } from 'react';
import PropTypes from 'prop-types';

import SignUpContext from '@jsv2/context/SignUpContext';
import AddressStep from '@jsv2/components/Authorization/AddressStep';
import BenefitsDefault from '@jsv2/templates/vipTraveler/SignUp/StepSidebars/BenefitsDefault';
import ProgressBar from '@jsv2/components/Authorization/ProgressBar';
import Spinner from '@components/Spinner';
import StepForm from './StepForm';

const AddressStepProfiler = ({ step, getNextStep }) => {
  const { membershipSummary } = useContext(SignUpContext).signUpProcess;
  const { membershipPlanId } = membershipSummary;

  return (
    <AddressStep
      step={step}
      getNextStep={getNextStep}
      render={({ isLoading, serverError, handleSubmit }) => (
        <div className="viptraveler-modal">
          <BenefitsDefault />

          <div className="signup signup--step2">
            <ProgressBar activeStep={2} />

            {isLoading && <Spinner />}

            {serverError && <div className="label-content--error mt-20"> {serverError} </div>}

            <div
              hidden={isLoading}
              className="summary"
              data-plan-id={membershipPlanId}
            >
              <StepForm handleSubmit={handleSubmit} />
            </div>
          </div>
        </div>
      )}
    />
  );
};

AddressStepProfiler.propTypes = {
  step: PropTypes.shape({
    component: PropTypes.func.isRequired,
    onComplete: PropTypes.func.isRequired,
  }).isRequired,
  getNextStep: PropTypes.func.isRequired,
};

export default AddressStepProfiler;
