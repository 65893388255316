import React, { useContext } from 'react';
import PropTypes from 'prop-types';

import ErrorBoundaryDecorator from '@components/decorators/ErrorBoundaryDecorator';
import EditFieldContext, {
  Editable,
  EditableImg,
} from '@jsv2/LandingPage/context/EditFieldContext';
import LPContext from '@jsv2/LandingPage/context/LPContext';
import Image from '@jsv2/components/Image';
import { IMAGE_SIZES } from '@js/constants';
import { preparedAvailableItemsForRender } from '@jsv2/LandingPage/utils';

const TeamSection = ({ className, id }) => {
  const { editableFields, editableImageFields } = useContext(EditFieldContext);
  const { isEditSectionMode } = useContext(LPContext);

  const team = [
    {
      number: 1,
      image: editableImageFields.team_member_1,
      heading: editableFields.team_member_1_heading,
      position: editableFields.team_member_1_position,
      short_content: editableFields.team_member_1_content,
      full_content: editableFields.team_member_1_content_full,
    },
    {
      number: 2,
      image: editableImageFields.team_member_2,
      heading: editableFields.team_member_2_heading,
      position: editableFields.team_member_2_position,
      short_content: editableFields.team_member_2_content,
      full_content: editableFields.team_member_2_content_full,
    },
    {
      number: 3,
      image: editableImageFields.team_member_3,
      heading: editableFields.team_member_3_heading,
      position: editableFields.team_member_3_position,
      short_content: editableFields.team_member_3_content,
      full_content: editableFields.team_member_3_content_full,
    },
    {
      number: 4,
      image: editableImageFields.team_member_4,
      heading: editableFields.team_member_4_heading,
      position: editableFields.team_member_4_position,
      short_content: editableFields.team_member_4_content,
      full_content: editableFields.team_member_4_content_full,
    },
    {
      number: 5,
      image: editableImageFields.team_member_5,
      heading: editableFields.team_member_5_heading,
      position: editableFields.team_member_5_position,
      short_content: editableFields.team_member_5_content,
      full_content: editableFields.team_member_5_content_full,
    },
    {
      number: 6,
      image: editableImageFields.team_member_6,
      heading: editableFields.team_member_6_heading,
      position: editableFields.team_member_6_position,
      short_content: editableFields.team_member_6_content,
      full_content: editableFields.team_member_6_content_full,
    },
    {
      number: 7,
      image: editableImageFields.team_member_7,
      heading: editableFields.team_member_7_heading,
      position: editableFields.team_member_7_position,
      short_content: editableFields.team_member_7_content,
      full_content: editableFields.team_member_7_content_full,
    },
    {
      number: 8,
      image: editableImageFields.team_member_8,
      heading: editableFields.team_member_8_heading,
      position: editableFields.team_member_8_position,
      short_content: editableFields.team_member_8_content,
      full_content: editableFields.team_member_8_content_full,
    },
  ];

  const handleShowMore = (e) => e.target.parentElement.classList.toggle('show');

  return (
    <section className={`team-section ${className}`} id={id}>
      <div className="main-container">
        <div className="section-title section-title--center">
          <Editable fieldValue={editableFields.team_heading} />
        </div>

        <div className="team-blocks">
          {preparedAvailableItemsForRender(team, isEditSectionMode).map((item) => (
            <div key={item.number} className="block">
              <div className="block-image">
                {item.image && (
                  <EditableImg image={item.image} width={402} height={379}>
                    <Image
                      className="feature__img"
                      data={item.image.content}
                      type="PageContent"
                      config={{
                        size: IMAGE_SIZES.SMALL,
                      }}
                      background
                    />
                  </EditableImg>
                )}
              </div>

              <div className="block-content">
                <div className="block-content__inner-wrapper">
                  <div className="title">
                    <Editable fieldValue={item.heading} />
                  </div>

                  <div className="sub-title">
                    <Editable fieldValue={item.position} />
                  </div>

                  <div className="desc__wrapper">
                    <div className="desc desc__short">
                      <Editable fieldValue={item.short_content} />
                    </div>

                    <div className="desc desc__full">
                      <Editable fieldValue={item.full_content} />
                    </div>
                  </div>

                  {item.full_content && (
                    <button type="button" className="link" onClick={(e) => handleShowMore(e)}>
                      More
                    </button>
                  )}
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

TeamSection.propTypes = {
  id: PropTypes.string.isRequired,
};

export default ErrorBoundaryDecorator()(TeamSection);
