import React, { useState } from 'react';

const ErrorLogContext = React.createContext({});

export const ErrorLogContextProvider = ({ children }) => {
  const [errorInfo, setErrorInfo] = useState({});

  const appendErrorInfo = (data) => {
    setErrorInfo((state) => ({
      ...state,
      ...data,
    }));
  };

  return (
    <ErrorLogContext.Provider
      value={{
        errorInfo,
        appendErrorInfo,
      }}
    >
      {children}
    </ErrorLogContext.Provider>
  );
};

export default ErrorLogContext;
