import React from 'react';
import PropTypes from 'prop-types';

import Image from '@jsv2/components/Image';
import { IMAGE_SIZES } from '@js/constants';
import Spinner from '@components/Spinner';
import ErrorBoundaryDecorator from '@components/decorators/ErrorBoundaryDecorator';

const QuestionnaireTemplate = ({
  children,
  index,
  question,
  loading,
  style,
  questions,
  hasBackgroundImage,
}) => (
  <Image
    className={`quiz-wrapper ${
      question.type === 'text' ? 'quiz-wrapper--text' : 'quiz-wrapper--select'
    } ${hasBackgroundImage && question.image ? 'quiz-wrapper--with-img' : ''}`}
    data={question.image}
    config={{
      size: IMAGE_SIZES.LARGE,
    }}
    background={hasBackgroundImage}
  >
    <div className="layover-content">
      {loading && <Spinner />}
      <div className="quiz-content" style={style}>
        {children}
        <div className="progress-count">
          <div className="progress-count-decoration progress-count-decoration--left" />
          {questions.map((item, i) => (
            <div key={item.id} className={`progress-item ${index === i ? 'active' : ''}`} />
          ))}
          <div className="progress-count-decoration progress-count-decoration--right" />
        </div>
      </div>
    </div>
  </Image>
);

QuestionnaireTemplate.propTypes = {
  questions: PropTypes.arrayOf(PropTypes.object).isRequired,
  question: PropTypes.object.isRequired,
  style: PropTypes.object,
  hasBackgroundImage: PropTypes.bool,
};

QuestionnaireTemplate.defaultProps = {
  style: {},
  hasBackgroundImage: true,
};

export default ErrorBoundaryDecorator()(QuestionnaireTemplate);
