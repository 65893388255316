import _ from 'lodash';
import * as Dialogs from '@utils/Dialogs';

import locationReload from '@utils/locationReload';

class StartupNotifications {
  notifications = [];
  currentNotificationIndex = 0;

  defaultDialogProps = {
    closeOnOverlayClick: false,
  };

  constructor() {
    this.notifications = window.__SERVER_DATA__.startupNotifications;
  }

  run() {
    if (_.isEmpty(this.notifications)) {
      return;
    }

    this.processNotification(this.getCurrentNotification());
  }

  /**
   * @param {string} type
   * @param {string} message
   * @param {Object} actions
   */
  processNotification({ type, message, actions }) {
    const parsedActions = this.parseNotificationActions(actions);
    const dialogProps = this.mapActionsToDialogProps(parsedActions);

    this.modifyDialogPropsToOpenNextDialog(dialogProps);

    switch (type) {
      case 'alert':
      case 'confirm':
        Dialogs[type](message, dialogProps);
        break;

      default:
        break;
    }
  }

  /**
   * Returns parsed actions in format:
   * [
   *   { eventName: 'onClose', action: 'redirect', params: ['/'] },
   *   ...
   * ]
   *
   * @param {Object} actions
   *
   * @return {Array}
   */
  parseNotificationActions(actions) {
    const parsedActions = [];

    _.each(actions, (actionData, eventName) => {
      if (_.isEmpty(actionData)) {
        return;
      }

      const action = _.head(actionData);
      const params = actionData.slice(1);

      parsedActions.push({
        eventName,
        action,
        params,
      });
    });

    return parsedActions;
  }

  /**
   * @param {Array} actions
   */
  mapActionsToDialogProps(actions) {
    const props = { ...this.defaultDialogProps };

    actions.forEach((action) => {
      props[action.eventName] = this.createActionCallback(action);
    });

    return props;
  }

  /**
   * @param {Object} props
   */
  modifyDialogPropsToOpenNextDialog(props) {
    if (!this.getNextNotification()) {
      return;
    }

    const defaultOnClose = props.onClose || (() => {});

    props.onClose = () => {
      this.currentNotificationIndex += 1;
      this.processNotification(this.getCurrentNotification());
      defaultOnClose();
    };
  }

  /**
   * @param {string} action
   * @param {Array} params
   *
   * @return {Function}
   */
  createActionCallback({ action, params }) {
    switch (action) {
      case 'refresh':
        return () => locationReload();
      case 'redirect':
        return () => (window.location.href = _.head(params));

      default:
        return () => {};
    }
  }

  /**
   * @return {Object|null}
   */
  getCurrentNotification() {
    return this.notifications[this.currentNotificationIndex] || null;
  }

  /**
   * @return {Object|null}
   */
  getNextNotification() {
    return this.notifications[this.currentNotificationIndex + 1] || null;
  }
}

export default StartupNotifications;
