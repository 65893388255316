import { IMAGE_SIZES } from '@js/constants';
import _ from 'lodash';

export const getOptimalImageSize = (containerWidth) => {
  if (containerWidth <= IMAGE_SIZES.SMALL) {
    return IMAGE_SIZES.SMALL;
  }

  if (containerWidth <= IMAGE_SIZES.MEDIUM) {
    return IMAGE_SIZES.MEDIUM;
  }

  if (containerWidth <= IMAGE_SIZES.LARGE) {
    return IMAGE_SIZES.LARGE;
  }

  return IMAGE_SIZES.EXTRA_LARGE;
};

export const nameHasExtension = name => name.match(/\.(webp|jp[e]?g|png)$/);

export const isInlineImage = path => _.startsWith(path, 'data:image/');
