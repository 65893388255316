import React from 'react';

import PropTypes from 'prop-types';
import LinkItem from '../../components/LinkItem';

const Payments = ({ isSubItem, onMenuItemClick }) => (
  <LinkItem
    href="/profile#/payments"
    id="payments-button"
    title="Payments"
    isSubItem={isSubItem}
    onMenuItemClick={onMenuItemClick}
  />
);

Payments.propTypes = {
  isSubItem: PropTypes.bool,
  onMenuItemClick: PropTypes.func,
};

Payments.defaultProps = {
  isSubItem: false,
  onMenuItemClick: () => {},
};

export default Payments;
