import React from 'react';
import PropTypes from 'prop-types';

import LinkItem from '../../components/LinkItem';

const EmailFrequency = ({ isSubItem, onMenuItemClick }) => (
  <LinkItem
    href="/profile#/email-preferences"
    id="email-preferences-button"
    title="Notifications"
    isSubItem={isSubItem}
    onMenuItemClick={onMenuItemClick}
  />
);

EmailFrequency.propTypes = {
  isSubItem: PropTypes.bool,
  onMenuItemClick: PropTypes.func,
};

EmailFrequency.defaultProps = {
  isSubItem: false,
  onMenuItemClick: () => {},
};

export default EmailFrequency;
