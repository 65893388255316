import STEPS from '@jsv2/config/SignUp/steps';
import ROLES from '@jsv2/config/userRoles';
import URLS from '@jsv2/config/SignUp/urls';
import { makeUserFreeMember } from '@jsv2/utils/UserUtils/APIRequest';
import BASE_STEP_PROFILER from '@jsv2/templates/vipTraveler/SignUp/Steps/BaseStepProfiler';
import TRAVEL_PROFILER from '@jsv2/templates/vipTraveler/SignUp/Steps/TravelProfiler';
import TRAVEL_HISTORY from '@jsv2/templates/vipTraveler/SignUp/Steps/TravelHistory';
import TRAVEL_PREFERENCES from '@jsv2/templates/vipTraveler/SignUp/Steps/TravelPreferences';
import TRAVEL_AIRLINE from '@jsv2/templates/vipTraveler/SignUp/Steps/TravelAirline';
import REGISTRATION_MESSAGE from '@jsv2/templates/vipTraveler/SignUp/Messages/RegistrationMessage';
import ADDRESS_STEP_PROFILER from '@jsv2/templates/vipTraveler/SignUp/Steps/AddressStepProfiler';
import PAYMENT_STEP_UPGRADE from '@jsv2/templates/vipTraveler/SignUp/Steps/PaymentStepUpgrade';
import SUCCESS_PAID_MESSAGE from '@jsv2/templates/vipTraveler/SignUp/Messages/SuccessPaidMessage';
import SUCCESS_FREE_MESSAGE from '@jsv2/templates/vipTraveler/SignUp/Messages/SuccessFreeMessage';
import SUCCESS_ABANDONED_MESSAGE from '@jsv2/templates/vipTraveler/SignUp/Messages/SuccessAbandonedMessage';

const {
  shared_data: { defaultHomePageUrl },
} = window.__SERVER_DATA__;

const profilerUpgradeFlowConfig = {
  [STEPS.BASE_STEP_PROFILER]: {
    name: STEPS.BASE_STEP_PROFILER,
    component: BASE_STEP_PROFILER,
    onComplete: () => STEPS.TRAVEL_PROFILER,
    isClosable: true,
    style: 'base-step-profiler',
    url: URLS[STEPS.BASE_STEP_PROFILER],
    roles: {
      [ROLES.ANONYMOUS]: true,
    },
  },
  [STEPS.TRAVEL_PROFILER]: {
    name: STEPS.TRAVEL_PROFILER,
    component: TRAVEL_PROFILER,
    onComplete: () => STEPS.TRAVEL_HISTORY,
    isClosable: false,
    style: 'travel-profiler',
    url: URLS[STEPS.TRAVEL_PROFILER],
    roles: {
      [ROLES.INCOMPLETE_PROFILER]: true,
    },
  },
  [STEPS.TRAVEL_HISTORY]: {
    name: STEPS.TRAVEL_HISTORY,
    component: TRAVEL_HISTORY,
    onComplete: () => STEPS.TRAVEL_PREFERENCES,
    isClosable: false,
    style: 'travel-preferences',
    background: '/images/layover/viptraveler/travel-preferences-1.jpg',
    url: URLS[STEPS.TRAVEL_HISTORY],
    roles: {
      [ROLES.INCOMPLETE_SIGN_UP]: true,
      [ROLES.INCOMPLETE_PROFILER]: false,
      [ROLES.INCOMPLETE_TRAVEL_HISTORY]: true,
      [ROLES.INCOMPLETE_TRAVEL_PREFERENCES]: true,
    },
  },
  [STEPS.TRAVEL_PREFERENCES]: {
    name: STEPS.TRAVEL_PREFERENCES,
    component: TRAVEL_PREFERENCES,
    onComplete: () => STEPS.TRAVEL_AIRLINE,
    isClosable: false,
    style: 'travel-preferences',
    background: '/images/layover/viptraveler/travel-preferences-2.jpg',
    url: URLS[STEPS.TRAVEL_PREFERENCES],
    roles: {
      [ROLES.INCOMPLETE_SIGN_UP]: true,
      [ROLES.INCOMPLETE_PROFILER]: false,
      [ROLES.INCOMPLETE_TRAVEL_HISTORY]: false,
      [ROLES.INCOMPLETE_TRAVEL_PREFERENCES]: true,
    },
  },
  [STEPS.TRAVEL_AIRLINE]: {
    name: STEPS.TRAVEL_AIRLINE,
    component: TRAVEL_AIRLINE,
    onComplete: () => STEPS.REGISTRATION_MESSAGE,
    isClosable: false,
    style: 'travel-airline',
    url: URLS[STEPS.TRAVEL_AIRLINE],
    roles: {
      [ROLES.INCOMPLETE_PROFILER]: false,
      [ROLES.INCOMPLETE_TRAVEL_HISTORY]: false,
      [ROLES.INCOMPLETE_TRAVEL_PREFERENCES]: false,
    },
  },
  [STEPS.REGISTRATION_MESSAGE]: {
    name: STEPS.REGISTRATION_MESSAGE,
    component: REGISTRATION_MESSAGE,
    onComplete: () => STEPS.ADDRESS_STEP_PROFILER,
    isClosable: false,
    url: URLS[STEPS.REGISTRATION_MESSAGE],
  },
  [STEPS.ADDRESS_STEP_PROFILER]: {
    name: STEPS.ADDRESS_STEP_PROFILER,
    component: ADDRESS_STEP_PROFILER,
    onComplete: () => STEPS.PAYMENT_STEP_UPGRADE,
    isClosable: true,
    style: 'closable-sign-up-step',
    url: URLS[STEPS.ADDRESS_STEP_PROFILER],
    roles: {
      [ROLES.INCOMPLETE_SIGN_UP]: true,
      [ROLES.INCOMPLETE_PROFILER]: false,
      [ROLES.INCOMPLETE_TRAVEL_PREFERENCES]: false,
      [ROLES.INCOMPLETE_TRAVEL_HISTORY]: false,
    },
  },
  [STEPS.PAYMENT_STEP_UPGRADE]: {
    name: STEPS.PAYMENT_STEP_UPGRADE,
    component: PAYMENT_STEP_UPGRADE,
    onComplete: () => STEPS.SUCCESS_PAID_MESSAGE,
    // TODO make this better
    onUpgrade: () => {
      makeUserFreeMember();
      return STEPS.SUCCESS_FREE_MESSAGE;
    },
    // TODO and this
    onClose: () => {
      makeUserFreeMember('step_interrupted');
      return STEPS.SUCCESS_ABANDONED_MESSAGE;
    },
    isClosable: true,
    style: 'closable-sign-up-step',
    url: URLS[STEPS.PAYMENT_STEP_UPGRADE],
    roles: {
      [ROLES.INCOMPLETE_SIGN_UP]: false,
      [ROLES.INCOMPLETE_PROFILER]: false,
      [ROLES.INCOMPLETE_TRAVEL_PREFERENCES]: false,
      [ROLES.INCOMPLETE_TRAVEL_HISTORY]: false,
    },
  },
  [STEPS.SUCCESS_PAID_MESSAGE]: {
    name: STEPS.SUCCESS_PAID_MESSAGE,
    component: SUCCESS_PAID_MESSAGE,
    onComplete: () => (window.location.href = defaultHomePageUrl),
    isClosable: false,
    url: URLS[STEPS.SUCCESS_PAID_MESSAGE],
  },
  [STEPS.SUCCESS_FREE_MESSAGE]: {
    name: STEPS.SUCCESS_FREE_MESSAGE,
    component: SUCCESS_FREE_MESSAGE,
    onComplete: () => (window.location.href = defaultHomePageUrl),
    isClosable: false,
    url: URLS[STEPS.SUCCESS_FREE_MESSAGE],
  },
  [STEPS.SUCCESS_ABANDONED_MESSAGE]: {
    name: STEPS.SUCCESS_ABANDONED_MESSAGE,
    component: SUCCESS_ABANDONED_MESSAGE,
    onComplete: () => (window.location.href = defaultHomePageUrl),
    isClosable: false,
    url: URLS[STEPS.SUCCESS_ABANDONED_MESSAGE],
  },
};

export default profilerUpgradeFlowConfig;
