export const facebookSocialButton = [
  {
    className: 'fb',
    title: 'Continue with Facebook',
    name: 'facebook',
  },
];

export const allSocialButtons = [
  {
    className: 'fb',
    title: 'Facebook',
    name: 'facebook',
  },
  {
    className: 'google',
    title: 'Google',
    name: 'google',
  },
  // {
  //   className: 'amazon',
  //   title: 'Amazon',
  //   name: 'amazon',
  // },
  // {
  //   className: 'paypal',
  //   title: 'PayPal',
  //   name: 'paypal_oauth',
  // },
];

export const socialButtonsWithoutFb = [
  {
    className: 'google',
    title: 'Google',
    name: 'google',
  },
  // {
  //   className: 'amazon',
  //   title: 'Amazon',
  //   name: 'amazon',
  // },
  // {
  //   className: 'paypal',
  //   title: 'PayPal',
  //   name: 'paypal_oauth',
  // },
];
