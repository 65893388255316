export const NOT_SET_VALUE = '(not set)';
export const INITIAL_VALUE_KEY = 'initialValue';
export const FINAL_VALUE_KEY = 'finalValue';
export const WAS_RELOADED_KEY = 'reloaded';
export const VARIABLES_TYPE = 'variables';
export const IMAGES_TYPE = 'images';
export const DEFAULT_VARIABLE_FIELD_VALUE = {
  type: 'variables',
  content: '',
};
export const DEFAULT_IMAGE_FIELD_VALUE = {
  type: 'images',
  content: {
    name: '',
    path: '',
    link: '/media/',
    type: 'image',
    display_name: null,
    resolutions: [],
  },
};
