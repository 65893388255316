import Logger from 'js-logger';
import _ from 'lodash';

import STATUS_CODES from '@jsv2/config/httpStatusCodes';
import apiClient from '../apiClient';

window.Logger = Logger;

const logLevelsMap = {
  TRACE: 'debug',
  DEBUG: 'debug',
  INFO: 'info',
  WARN: 'warning',
  ERROR: 'error',
};

function safeStringify(obj) {
  const cache = new Set();

  return JSON.stringify(
    obj,
    (key, value) => {
      if (typeof value === 'object' && value !== null) {
        if (cache.has(value)) {
          return;
        }
        cache.add(value);
      }
      return value;
    },
    2,
  );
}

if (window.IS_PRODUCTION) {
  Logger.setLevel(Logger.WARN);
  Logger.setHandler(([...messages], context) => {
    const level = logLevelsMap[context.level.name] || 'info';
    let message = messages[0];

    if (message.response && message.response.status === STATUS_CODES.UNPROCESSABLE_ENTITY) {
      return;
    }

    if (!_.isString(message)) {
      if (message instanceof Error) {
        message = `${message.message} (stack: ${message.stack})`;
      } else if (_.isObject(message)) {
        message = safeStringify(message);
      } else {
        message = `Level: ${level}, Message: ${message}`;
      }
    } else {
      messages.shift();
    }

    if (message.response) {
      const { data, status, headers } = message.response;

      message += `\nResponse Status: ${status}`;
      message += `\nResponse Headers: ${safeStringify(headers)}`;
      message += `\nResponse Data: ${safeStringify(data)}`;
    }

    messages.forEach((value, index) => {
      if (value.error) {
        messages[index].error = {
          message: value.error.message,
          stack: value.error.stack,
        };
      } else if (_.isObject(value)) {
        messages[index] = safeStringify(value);
      } else {
        messages[index] = String(value);
      }
    });

    apiClient.post('/logs', {
      message,
      additionalData: messages,
      level,
    });
  });
} else if (process.env.NODE_ENV === 'test') {
  Logger.setLevel(Logger.error);
  // eslint-disable-next-line no-console
  console.warn('Be aware that window.Logger messages are disabled in test env');
  Logger.setHandler(() => {});
} else {
  Logger.useDefaults();
}
