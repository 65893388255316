export const calcVideoSize = (clientW, clientH, videoW, videoH) => {
  if (videoH / videoW > clientH / clientW) {
    return {
      width: clientH * clientW / (clientW * videoH / videoW),
      height: clientH,
    };
  }

  return {
    width: clientW,
    height: clientW * videoH / videoW,
  };
};
