import React, { useContext, useEffect } from 'react';

import Spinner from '@components/Spinner';
import WizardContext from '@jsv2/context/WizardContext';
import ErrorBoundaryDecorator from '@components/decorators/ErrorBoundaryDecorator';

const { origin, pathname, search, hash } = window.location;

const Step = ({ setStepUrl }) => {
  const { getNextStep, stepConfig } = useContext(WizardContext);
  const StepComponent = stepConfig?.component;

  useEffect(() => {
    if (stepConfig.url) {
      if (setStepUrl) {
        window.history.pushState({}, null, setStepUrl(stepConfig.url));
      } else {
        window.history.pushState({}, null, origin + pathname + stepConfig.url + search + hash);
      }
    }
  }, [stepConfig]);

  return StepComponent ? (
    <StepComponent getNextStep={getNextStep} step={stepConfig} />
  ) : (
    <Spinner />
  );
};

export default ErrorBoundaryDecorator()(Step);
