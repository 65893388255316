import React, { useContext } from 'react';

import SignUpContext from '@jsv2/context/SignUpContext';
import {
  membershipGuarantee,
  membershipBonus,
  membershipCreditIssuance,
  membershipTotal,
  membershipFeeRows,
  initialFee,
  footnote,
} from '@jsv2/templates/vipTraveler/SignUp/StepSidebars/MembershipSummary/Rows';

const SummaryDefault = () => {
  const { membershipSummary } = useContext(SignUpContext).signUpProcess;

  const {
    signupBonus,
    recurringBonus,
    moneybackPeriod,
    promoNote,
    membershipPlanId,
    promoPeriod,
  } = membershipSummary;

  return (
    <div className="summary" data-plan-id={membershipPlanId} data-qa-id="summary_block">
      <ul className="calc-list">
        {initialFee(membershipSummary)}
        {membershipFeeRows(membershipSummary)}

        {recurringBonus.current && membershipCreditIssuance(membershipSummary)}
        {moneybackPeriod.current && membershipGuarantee(membershipSummary)}
        {signupBonus.current && membershipBonus(membershipSummary)}
      </ul>

      {membershipTotal(membershipSummary)}

      {!!promoNote && !promoPeriod.hasNoExpiration && footnote(membershipSummary)}
    </div>
  );
};

export default SummaryDefault;
