import STEPS from '@jsv2/config/SignUp/steps';
import ROLES from '@jsv2/config/userRoles';
import BASE_STEP_WELCOME_UPDATED from '@jsv2/templates/vipTraveler/SignUp/Steps/BaseStepWelcomeUpdated';

const welcomeFlowUpdatedConfig = {
  [STEPS.BASE_STEP_WELCOME_UPDATED]: {
    name: STEPS.BASE_STEP_WELCOME_UPDATED,
    component: BASE_STEP_WELCOME_UPDATED,
    onComplete: () => {
      window.location.reload();
    },
    isClosable: true,
    roles: {
      [ROLES.ANONYMOUS]: true,
    },
  },
};

export default welcomeFlowUpdatedConfig;
