import React from 'react';
import { Textfit } from 'react-textfit';

import ErrorBoundaryDecorator from '@components/decorators/ErrorBoundaryDecorator';

/**
 * Original Textfit, for some reasons, doesn't fit :)
 * Wrapping it in the div with width 90% and overflow: visible fixes the problem
 * @type {{$$typeof, render}}
 */
const TextFit = React.forwardRef(({ children, title, ...rest }, ref) => (
  <div className="text-fit-wrapper" title={title}>
    <Textfit {...rest} ref={ref}>
      {children}
    </Textfit>
  </div>
));

TextFit.displayName = 'TextFit';

export default ErrorBoundaryDecorator()(TextFit);
