import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

/**
 * Spinner
 */
const Spinner = ({ color, enableFullHeight, inline, width }) => {
  const imageStyle = {};

  if (width) {
    imageStyle.width = width;
  }

  return (
    <div
      className={classNames(
        'spinner',
        { 'spinner--full-height': enableFullHeight },
        { 'spinner--inline': inline },
      )}
      data-qa-id="spinner"
    >
      <img style={imageStyle} src={`/images/spinner-${color}.svg`} alt="Spinner" />
    </div>
  );
};

Spinner.propTypes = {
  color: PropTypes.string,
  enableFullHeight: PropTypes.bool,
  inline: PropTypes.bool,
  width: PropTypes.string,
};

Spinner.defaultProps = {
  color: 'pink',
  enableFullHeight: false,
  inline: false,
  width: '',
};

export default Spinner;
