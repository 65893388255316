import React from 'react';
import PropTypes from 'prop-types';
import { Field, Form, Formik } from 'formik';

import { validateEmail } from '@utils/form/validators';
import OverlaySpinner from '@jsv3/components/molecules/OverlaySpinner';
import LabelComponent from '@js/landing/vipTravelerPromo2/auth/LabelComponent';
import ErrorBoundaryDecorator from '@components/decorators/ErrorBoundaryDecorator';

const SimpleEmailForm = ({ className, submitButtonText, onSubmit, serverError, children }) => {
  const initialValues = {
    email: '',
  };

  /**
   * Email form submit handler.
   *
   * @param {Object} values
   * @param {Object} actions
   */
  const handleSubmit = (values, actions) => {
    actions.setErrors({ email: null });
    actions.setSubmitting(true);
    onSubmit(values, actions);
  };

  /**
   * Email form change handler.
   *
   * @event e
   * @callback setFieldValue
   * @callback setFieldError
   */
  const handleChange = (e, setFieldValue, setFieldError) => {
    setFieldError(e.currentTarget.name, null);
    setFieldValue(e.currentTarget.name, e.target.value);
  };

  return (
    <div className={`form-wrapper ${className}`}>
      {serverError && <div className="label-content--error mt-10 mb-10"> {serverError} </div>}

      <Formik initialValues={initialValues} onSubmit={handleSubmit} validateOnChange={false}>
        {({ setFieldValue, setFieldError, isSubmitting, errors }) => (
          <Form className="form">
            {isSubmitting && <OverlaySpinner isAbsolute />}

            <div className="input form-field">
              <LabelComponent name="email" errors={errors} labelText={t('Your Email:')} />

              <div className="input-container">
                <Field
                  type="email"
                  inputMode="email"
                  name="email"
                  autoComplete="email"
                  placeholder="john@example.com"
                  validate={validateEmail}
                  onBlur={() => {}}
                  onChange={(e) => handleChange(e, setFieldValue, setFieldError)}
                  data-qa-id="email_field"
                />

                {children}
              </div>
            </div>

            <button
              className="btn-email"
              type="submit"
              disabled={isSubmitting}
              data-qa-id="welcome_offer_button"
            >
              {submitButtonText}
            </button>
          </Form>
        )}
      </Formik>
    </div>
  );
};

SimpleEmailForm.propTypes = {
  className: PropTypes.string,
  submitButtonText: PropTypes.string,
  onSubmit: PropTypes.func.isRequired,
  serverError: PropTypes.string,
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
};

SimpleEmailForm.defaultProps = {
  className: '',
  submitButtonText: '',
  serverError: null,
  children: null,
};

export default ErrorBoundaryDecorator()(SimpleEmailForm);
