import React from 'react';

import { renderPage } from '@jsv2/utils/PageUtils/RenderPageUtils';
import MainLandingPage from '@jsv2/LandingPage/MainLandingPage';

import App from './App';

const { isPreviewPage } = window;

const Main = () => (
  <App className={`app landing-page landing-page-test${isPreviewPage ? ' preview-mode' : ''}`}>
    <MainLandingPage />
  </App>
);

renderPage(Main);
