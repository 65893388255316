import apiClient from '@jsv3/services/apiClient';

/**
 * Get a list of articles.
 *
 * @return {Promise<AxiosResponse<any>>}
 */
export function getArticleListAPI() {
  return apiClient.get('page/blog-pages');
}
