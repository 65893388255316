import React from 'react';

import ErrorBoundaryDecorator from '@components/decorators/ErrorBoundaryDecorator';

/**
 * Render Trust Badges in Footer.
 *
 * @return {*}
 *
 * @constructor
 */
const TrustBadges = () => (
  <div className="main-footer__badges">
    <div className="main-footer__badges-col main-footer__badges-col--left">
      <p>We prefer to work with hotels from:</p>

      <img
        src="/images/icons/trust-badges/trust-badges-logos.svg"
        alt="trust-badge"
        className="main-footer__badge main-footer__badge--lines"
      />
    </div>

    <div className="main-footer__badges-col main-footer__badges-col--right">
      <div className="main-footer__badges-col main-footer__badges-col--grid">
        <img
          src="/images/icons/trust-badges/wta-nominee-shield.png"
          alt="trust-badge"
          className="main-footer__badge main-footer__badge--single"
        />
        <img
          src="/images/icons/trust-badges/trust-badges-circle-new.svg"
          alt="trust-badge"
          className="main-footer__badge main-footer__badge--circle"
        />
      </div>

      <img
        src="/images/icons/trust-badges/trust-badge-2024.png"
        alt="trust-badge-2024"
        className="main-footer__badge main-footer__badge--full-height"
      />
    </div>
  </div>
);

export default ErrorBoundaryDecorator()(TrustBadges);
