/**
 * Default setting for slider
 */
export default {
  slidesPerView: 2,
  slidesPerGroup: 2,
  noSwiping: true,
  allowTouchMove: false,
  grid: {
    rows: 2,
    fill: 'row',
  },
  breakpoints: {
    767: {
      slidesPerView: 3,
      slidesPerGroup: 3,
    },
    1024: {
      slidesPerView: 4,
      slidesPerGroup: 4,
    },
  },
};
