import React, { useEffect, useState } from 'react';
import { ErrorMessage, Field, Form, Formik } from 'formik';
import Cookies from 'browser-cookies';

import BaseStepForm from '@jsv2/components/Authorization/BaseStepForm';
import Modal from '@jsv3/components/organisms/Modal';

const StepForm = ({ handleSubmit, serverError }) => {
  const [isOpen, setOpen] = useState(false);

  useEffect(() => {
    if (serverError) {
      setOpen(true);
    }
  }, [serverError]);

  const closeModal = () => {
    setOpen(false);
  };

  const initialValues = {
    email: Cookies.get('remember-logged-out-user-email') || '',
    social_reg: 1,
  };

  return (
    <BaseStepForm
      render={({ handleChange }) => (
        <Formik initialValues={initialValues} onSubmit={handleSubmit} validateOnChange={false}>
          {({ setFieldValue, setFieldError, isSubmitting }) => (
            // TODO change this classNames
            <Form className="form questionnaire">
              <div className="text-title">
                {t('In order to get started, please enter your email address')}
              </div>

              <Modal isOpen={isOpen} withCloseButton onRequestClose={closeModal} size="md">
                <div className="error-popup">
                  <p className="error-popup__titile">
                    An account with this e-mail address already exists. Please click "Log In" below
                    or sign up with a different e-mail address.
                  </p>
                  <a href="/sign-in" className="error-popup__btn" data-qa-id="step_login_btn">
                    {t('Login')}
                  </a>
                </div>
              </Modal>

              <ErrorMessage name="email" component="div" className="label-content--error mt-20" />

              <div className="input form-field">
                <Field
                  type="email"
                  inputMode="email"
                  name="email"
                  autoComplete="email"
                  placeholder="john@example.com"
                  onBlur={() => {}}
                  onChange={(e) => handleChange(e, setFieldValue, setFieldError)}
                  data-qa-id="email_field"
                />
              </div>

              <div className="btn-wrapper-center pt-40">
                <button
                  className="btn-survey"
                  type="submit"
                  disabled={isSubmitting}
                  data-qa-id="sign_up_button"
                >
                  {t('NEXT')}
                </button>
              </div>
            </Form>
          )}
        </Formik>
      )}
    />
  );
};

export default StepForm;
