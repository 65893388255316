const ROLES = {
  ANONYMOUS: 'Anonymous',
  PARTIALLY_IDENTIFIED: 'PartiallyIdentified',
  FULLY_VERIFIED: 'FullyVerified',
  CANCELLED_MEMBER: 'Cancelled Member',
  ADMIN_CANCELLED: 'Canceled member by admin',
  SUBSCRIBER: 'Subscriber',
  VIEWED_OFFERS: 'ViewedOffers',
  INCOMPLETE_SIGN_UP: 'Incomplete sign up',
  FREE_MEMBER: 'Free Member',
  PAID_MEMBER: 'Paid Member',
  PREVIOUSLY_PAID_MEMBER: 'Previously Paid Member',
  WAIT_LIST_MEMBER: 'Wait list Member',
  MISSING_PAYMENT_METHOD: 'Missing payment method',
  INCOMPLETE_PROFILER: 'Incomplete profiler',
  INCOMPLETE_TRAVEL_PREFERENCES: 'Incomplete travel preferences',
  INCOMPLETE_TRAVEL_HISTORY: 'Incomplete travel history',
  PAYMENT_STEP_INTERRUPTED: 'Payment step was interrupted',
  EMAIL_ONLY: 'Email only',
  HAS_TRAVEL_FINDS: 'Has travel finds',
  IS_IMPERSONATED: 'Impersonated',
};

export default ROLES;
