import React from 'react';
import PropTypes from 'prop-types';

import BaseMessageTemplate from '@jsv2/templates/vipTraveler/ModalMessages/BaseMessageTemplate';
import AuthMessage from '@jsv2/components/Authorization/AuthMessage';

/**
 * Pre Q Pop A (Abandoned).
 */

const MESSAGE_CONTENT = {
  image: '/images/lp/promo2/glasses.svg',
  heading: 'No worries! We get it. You want to keep things casual.',
  tagline: 'Instead, please enjoy our free membership plan -- where you can access exclusive '
    + 'content and receive personalized travel opportunities we know you’ll love. In order to bring '
    + 'you luxury travel finds that match your preferences, please complete our Travel Profiler.',
  buttonText: 'get started',
};

const IncompleteSignUpMessage = ({ step, getNextStep }) => (
  <AuthMessage
    step={step}
    getNextStep={getNextStep}
    render={({ handleClick }) => (
      <BaseMessageTemplate
        handleClick={(handleClick)}
        {...MESSAGE_CONTENT}
      />
    )}
  />
);

IncompleteSignUpMessage.propTypes = {
  step: PropTypes.shape({
    component: PropTypes.func.isRequired,
    onComplete: PropTypes.func.isRequired,
  }).isRequired,
  getNextStep: PropTypes.func.isRequired,
};

export default IncompleteSignUpMessage;
