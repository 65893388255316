import classNames from 'classnames';

const defaultModalSettings = {
  className: 'modal-window',
  bodyOpenClassName: 'noScroll',
};

export const generateModalSettings = (className) => ({
  ...defaultModalSettings,
  overlayClassName: classNames(className, 'modal-overlay'),
  className: classNames(defaultModalSettings.className, className),
});
