import { useState } from 'react';
import parseFormErrors from '@utils/form/parseFormErrors';
import { ERRORS } from '@js/messages';

/**
 * Hook wrapper over submit functionality.
 * @return { object } - set of hooks: error, loading and handleSubmit
 */
const useSubmit = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const performRequest = (submitFunction, setValidationErrors, preventLoading) => {
    setLoading(true);
    setError(null);

    return submitFunction()
      .catch((e) => {
        if (e && e.response && e.response.status < 500 && e.response.data) {
          setError(e.response.data.message);

          if (setValidationErrors && e.response.data.data) {
            const validationErrors = parseFormErrors(e.response.data.data);
            setValidationErrors(validationErrors);
          }
        } else {
          Logger.error('5xx error has been caught in useSubmit', e);
          setError(ERRORS.REQUEST_ERROR);
        }
      })
      .finally(() => {
        if (!preventLoading) {
          setLoading(false);
        }
      });
  };

  return { performRequest, loading, error, setError };
};

export default useSubmit;
