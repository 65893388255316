const { pathname, hash } = window.location;

const SET_ACTIVE_MENU_ITEM = 'SET_ACTIVE_MENU_ITEM';

const defaultState = {
  activeMenuItem: pathname + hash,
};

export default function reducer(state = defaultState, action) {
  switch (action.type) {
    case SET_ACTIVE_MENU_ITEM: {
      return {
        ...state,
        activeMenuItem: action.payload,
      };
    }

    default:
      return state;
  }
}

export const setActiveMenuItemAction = (activeMenuItem) => ({
  type: SET_ACTIVE_MENU_ITEM,
  payload: activeMenuItem,
});
