/**
 This is the implementation of https://github.com/erikras/ducks-modular-redux
 */
import { combineReducers } from 'redux';

import registrationForm from './registrationForm';
import ads from './ads';
import layover from './layover';
import header from './header';
import footer from './footer';
import fixedBuyButton from './fixedBuyButton';
import liveChat from './liveChat';
import communications from './communications';
import menuItems from './menuItems';

export default combineReducers({
  registrationForm,
  adConfig: ads,
  layover,
  header,
  footer,
  fixedBuyButton,
  liveChat,
  communications,
  menuItems,
});
