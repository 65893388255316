import React, { useContext } from 'react';

import ErrorBoundaryDecorator from '@components/decorators/ErrorBoundaryDecorator';
import EditFieldContext, { Editable } from '@jsv2/LandingPage/context/EditFieldContext';
import PublicationsSlider from './PublicationsSlider';

const PubsSection = ({ id, ...rest }) => {
  const { editableFields } = useContext(EditFieldContext);

  return (
    <>
      {editableFields && (
        <section className="pubs" id={id}>
          <div className="wrapper">
            <h4 className="section-title-small">
              <Editable fieldValue={editableFields.publishers_heading} />
            </h4>

            <PublicationsSlider {...rest} />
          </div>
        </section>
      )}
    </>
  );
};

export default ErrorBoundaryDecorator()(PubsSection);
