/* eslint-disable no-underscore-dangle,camelcase */
export default class User {
  constructor(user, userRoles) {
    this._email = user?.email;
    this._phone = user?.phone;
    this._zip = user?.zip;
    this._countryId = user?.country_id;
    this._fullName = user?.full_name;
    this._roles = userRoles;
  }

  get countryId() { return this._countryId; }

  set countryId(value) { this._countryId = value; }

  get roles() { return this._roles; }

  set roles(value) { this._roles = value; }

  get zip() { return this._zip; }

  set zip(value) { this._zip = value; }

  get phone() { return this._phone; }

  set phone(value) { this._phone = value; }

  get email() { return this._email; }

  set email(value) { this._email = value; }

  get fullName() { return this._fullName; }

  set fullName(value) { this._fullName = value; }
}
