import React, { useContext } from 'react';

import ScreenTypeContext, { isDesktop } from '@js/context/ScreenTypeContext';
import { UPGRADE_PLAN_URL } from '@jsv3/enums/urlEnums';
import UpgradePlan from '../../components/Header/Items/Profile/UpgradePlan';
import EmailFrequency from '../../components/Header/Items/Profile/EmailFrequency';
import Settings from '../../components/Header/Items/Profile/Settings';
import Payments from '../../components/Header/Items/Profile/Payments';
import Balance from '../../components/Header/Items/Profile/Balance';
import LogOut from '../../components/Header/Items/Profile/LogOut';
import Trips from '../../components/Header/Items/Profile/Trips';
import { isPaidMember, isWaitListMember } from '../../utils/UserUtils/userStatuses';

const { userRoles } = window;

const RenderProfileMenuItems = () => {
  const screenTypeContext = useContext(ScreenTypeContext);

  const { pathname } = window.location;
  const isUpgradePlanShow = isPaidMember(userRoles) || isWaitListMember(userRoles);

  return (
    <>
      {isDesktop(screenTypeContext) && !isUpgradePlanShow && pathname !== UPGRADE_PLAN_URL && (
        <UpgradePlan />
      )}
      <Settings />
      <Trips />
      <Balance />
      <Payments />
      <EmailFrequency />
      <LogOut />
    </>
  );
};

const profileMenuItems = () => (
  <>
    <RenderProfileMenuItems />
  </>
);

export default profileMenuItems();
