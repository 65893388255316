import PROFILER_STEPS from '@jsv2/config/Profiler/steps';
import ROLES from '@jsv2/config/userRoles';
import TRAVEL_PROFILER from '@jsv2/templates/vipTraveler/SignUp/Steps/TravelProfiler';
import TRAVEL_HISTORY from '@jsv2/templates/vipTraveler/SignUp/Steps/TravelHistory';
import TRAVEL_PREFERENCES from '@jsv2/templates/vipTraveler/SignUp/Steps/TravelPreferences';
import TRAVEL_AIRLINE from '@jsv2/templates/vipTraveler/SignUp/Steps/TravelAirline';
import SUCCESS_COMPLETE_MESSAGE from '@jsv2/templates/vipTraveler/Profiler/Messages/SuccessCompleteProfilerMessage';
import ErrorBoundaryDecorator from '@components/decorators/ErrorBoundaryDecorator';

const {
  shared_data: { defaultHomePageUrl },
} = window.__SERVER_DATA__;

const defaultFlow = {
  [PROFILER_STEPS.TRAVEL_PROFILER]: {
    name: PROFILER_STEPS.TRAVEL_PROFILER,
    component: TRAVEL_PROFILER,
    onComplete: () => PROFILER_STEPS.TRAVEL_HISTORY,
    isClosable: false,
    style: 'travel-profiler',
    roles: {
      [ROLES.INCOMPLETE_PROFILER]: true,
    },
  },
  [PROFILER_STEPS.TRAVEL_HISTORY]: {
    name: PROFILER_STEPS.TRAVEL_HISTORY,
    component: TRAVEL_HISTORY,
    onComplete: () => PROFILER_STEPS.TRAVEL_PREFERENCES,
    isClosable: false,
    style: 'travel-preferences',
    background: '/images/layover/viptraveler/travel-preferences-1.jpg',
    roles: {
      [ROLES.INCOMPLETE_PROFILER]: false,
      [ROLES.INCOMPLETE_TRAVEL_HISTORY]: true,
      [ROLES.INCOMPLETE_TRAVEL_PREFERENCES]: true,
    },
  },
  [PROFILER_STEPS.TRAVEL_PREFERENCES]: {
    name: PROFILER_STEPS.TRAVEL_PREFERENCES,
    component: TRAVEL_PREFERENCES,
    onComplete: () => PROFILER_STEPS.TRAVEL_AIRLINE,
    isClosable: false,
    style: 'travel-preferences',
    background: '/images/layover/viptraveler/travel-preferences-2.jpg',
    roles: {
      [ROLES.INCOMPLETE_PROFILER]: false,
      [ROLES.INCOMPLETE_TRAVEL_HISTORY]: false,
      [ROLES.INCOMPLETE_TRAVEL_PREFERENCES]: true,
    },
  },
  [PROFILER_STEPS.TRAVEL_AIRLINE]: {
    name: PROFILER_STEPS.TRAVEL_AIRLINE,
    component: TRAVEL_AIRLINE,
    onComplete: () => PROFILER_STEPS.SUCCESS_COMPLETE_MESSAGE,
    isClosable: false,
    style: 'travel-airline',
    roles: {
      [ROLES.INCOMPLETE_PROFILER]: false,
      [ROLES.INCOMPLETE_TRAVEL_HISTORY]: false,
      [ROLES.INCOMPLETE_TRAVEL_PREFERENCES]: false,
    },
  },
  [PROFILER_STEPS.SUCCESS_COMPLETE_MESSAGE]: {
    name: PROFILER_STEPS.SUCCESS_COMPLETE_MESSAGE,
    component: SUCCESS_COMPLETE_MESSAGE,
    onComplete: () => (window.location.href = defaultHomePageUrl),
    isClosable: false,
  },
};

export default ErrorBoundaryDecorator()(defaultFlow);
