import AbstractImageResolver from '@utils/images/image-resolvers/AbstractImageResolver';
import { storageURL } from '@jsv3/utils/urlUtils';

/**
 * CMS Page image resolver.
 */
class PageContentImageResolver extends AbstractImageResolver {
  resolve() {
    const src = super.resolve();

    if (src) {
      return src;
    }

    const { path, name } = this.data;
    const { density } = this.config;

    return storageURL(`/${path}/${density ? 'images-2x/' : ''}${name}.${this.format}`);
  }
}

export default PageContentImageResolver;
