import axios from 'axios';
import { getApiClientInstance } from './apiClient';

const { CancelToken } = axios;
const REQUEST_CANCELED = 'REQUEST_CANCELED';

class ApiThrottleClient {
  static isRequestCanceled = e => e === REQUEST_CANCELED;

  prevRequestToken = null;
  apiClient = null;

  constructor() {
    this.apiClient = getApiClientInstance();
    this.apiClient.interceptors.request.use((config) => {
      config.cancelToken = this.getPrevRequestToken();
      return config;
    });
  }

  makeRequest = (params) => {
    this.clearLoadTimeout();
    this.cancelPrevRequestToken();
    this.setPrevRequestToken();

    const debounce = params.debounce || 0;
    return new Promise((resolve, reject) => {
      const requestCallback = () => this.apiClient(params)
        .then((resp) => {
          this.clearPrevRequestToken();
          resolve(resp);
        })
        .catch((error) => {
          if (axios.isCancel(error)) {
            reject(REQUEST_CANCELED);
            return;
          }
          this.clearPrevRequestToken();
          reject(error);
        });

      if (debounce) {
        const timeout = setTimeout(requestCallback, debounce);
        this.clearLoadTimeout = () => {
          reject(REQUEST_CANCELED);
          clearTimeout(timeout);
        };
      } else {
        requestCallback();
      }
    });
  };

  clearPrevRequestToken = () => this.prevRequestToken = null;

  cancelPrevRequestToken = () => this.prevRequestToken && this.prevRequestToken.cancel();

  setPrevRequestToken = () => this.prevRequestToken = CancelToken.source();

  getPrevRequestToken = () => this.prevRequestToken.token;

  clearLoadTimeout = () => {};
}

export default ApiThrottleClient;
