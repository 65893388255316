import React from 'react';

import HOC from '@jsv2/utils/BucketListUtils/ConnectComponentToWishListContextHOC';
import SearchBox from '@jsv2/components/BucketList/searchBox/SearchBox';
import WishListComponent from '@jsv2/components/BucketList/BucketListComponent';
import WishListSlider from '@jsv2/components/BucketList/preferenceList/WishListSlider';
import * as APIRequest from '@jsv2/utils/BucketListUtils/APIRequests';
import ErrorBoundaryDecorator from '@components/decorators/ErrorBoundaryDecorator';

const settings = {
  slidesPerView: 2,
  slidesPerGroup: 2,
  noSwiping: true,
  allowTouchMove: false,
  grid: {
    rows: 1,
    fill: 'row',
  },
  breakpoints: {
    767: {
      slidesPerView: 3,
      slidesPerGroup: 3,
    },
    1024: {
      slidesPerView: 4,
      slidesPerGroup: 4,
    },
  },
};

const TravelHistoryWrapper = ({ finishFillWishList, bodyClassName = '', background = '' }) => (
  <div
    className={`wish-list-popup ${bodyClassName || ''}`}
    style={{ backgroundImage: `url(${background})` }}
    data-qa-id="travel-history-modal"
  >
    <div className="wish-list-wrapper">
      <div className="wish-list-header">
        <div className="title">
          {t('Tell us about some of the places ')}
          <br />
          {t('you have most enjoyed traveling to:')}
        </div>

        <div className="sub-title">
          {t('Just enter a destination below, select from dropdown, and click "Add".')}
        </div>
      </div>

      <div className="wish-list-content">
        <WishListComponent
          entityType="Destination"
          getDestinationsList={APIRequest.getTravelHistoryDestinationsList}
          addWishListItem={APIRequest.addTravelHistoryItem}
          addWishListItemByName={APIRequest.addTravelHistoryItemByName}
          removeWishListItemByName={APIRequest.removeTravelHistoryItemByName}
        >
          <div className="destinations-search-box-wrapper destination-list">
            <HOC
              WrappedComponent={SearchBox}
              placeholder="Enter a destination"
              apiUrl="user/travel-history/undecided"
              maxInputLength={40}
            />
          </div>

          <HOC WrappedComponent={WishListSlider} settings={settings} placeholder="destination" />
        </WishListComponent>
      </div>

      <div className="button-wrapper">
        <button
          className="btn-wish-list"
          type="submit"
          data-qa-id="next"
          onClick={finishFillWishList}
        >
          {t('NEXT')}
        </button>
      </div>
    </div>
  </div>
);

export default ErrorBoundaryDecorator()(TravelHistoryWrapper);
