import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

const DropDownLinkItem = ({ id, title, href, className, children }) => (
  <div className={classNames('navlist__item navlist__item--dropdown', className)}>
    <a className="navlist__btn left-border" href={href} data-qa-id={id} key={id}>
      {title}
    </a>

    <div className="main-header__dropdown-menu" data-qa-id="qa_main_header_dd">{children}</div>
  </div>
);

DropDownLinkItem.propTypes = {
  title: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  href: PropTypes.string.isRequired,
  className: PropTypes.string,
};

DropDownLinkItem.defaultProps = {
  className: '',
};

export default DropDownLinkItem;
