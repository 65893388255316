import React, { useContext } from 'react';
import PropTypes from 'prop-types';

import ScreenTypeContext, { isMobile } from '@js/context/ScreenTypeContext';
import ProgressBar from '@jsv2/components/Authorization/ProgressBar';
import SummaryUpgrade from './MembershipSummary/SummaryUpgrade';
import FreeMembershipChoice from '../Steps/PaymentStepUpgrade/FreeMembershipChoice';

const MembershipUpgrade = ({ handleFreeChoice }) => {
  const screenTypeContext = useContext(ScreenTypeContext);

  return (
    <>
      {isMobile(screenTypeContext) && (
        <>
          <div className="sidebar sidebar--step3 membership--premium">
            <div className="wrapper membership">
              <ProgressBar activeStep={3} />
            </div>
          </div>

          <FreeMembershipChoice handleFreeChoice={handleFreeChoice} isTopPosition />
        </>
      )}

      <div className="sidebar sidebar--step3 membership--premium">
        <div className="wrapper membership">
          <div className="summary__heading">
            <h3 className="heading">{t('try premium membership')}</h3>
            <p className="tagline">
              {t(
                'Enjoy full access to VIP traveler including a dedicated travel stylist, personalized travel finds and 24/7 service',
              )}
            </p>
          </div>

          <SummaryUpgrade />
        </div>
      </div>
    </>
  );
};

MembershipUpgrade.propTypes = {
  handleFreeChoice: PropTypes.func.isRequired,
};

export default MembershipUpgrade;
