import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import Spinner from '@components/Spinner';
import BucketListContext from '@jsv2/context/BucketListContext';
import ScreenTypeContext, { isMobile } from '@js/context/ScreenTypeContext';
import Logo from '@jsv2/components/Header/Items/Logo';
import ErrorBoundaryDecorator from '@components/decorators/ErrorBoundaryDecorator';
import PopupHeader from '../explorePopup/PopupHeader';
import PopupButtons from '../explorePopup/PopupButtons';
import DestinationsSlider from '../explorePopup/DestinationsSlider';

// TODO: need full refactor, strange component.. chaos here (when Atomic js)
/**
 * Render Discover Destination Modal
 *
 * @type {React.ForwardRefExoticComponent<React.PropsWithoutRef<{}> & React.RefAttributes<unknown>>}
 */
const DiscoverDestinationModal = React.forwardRef((props, ref) => {
  const {
    activeDestination,
    activeTag,
    activeGroup,
    groupedDestinations,
    updateActiveTag,
    hideUserReactions,
  } = props;

  const screenTypeContext = useContext(ScreenTypeContext);
  const { randomTag } = useContext(BucketListContext);

  const [destinationsSlider, setDestinationsSlider] = useState(null);
  const [groups, setGroups] = useState(groupedDestinations);
  const [isLoading, setLoading] = useState(true);
  const [areAllGroupsEmpty, setAllGroupsEmpty] = useState(false);
  const [animateFilter, setAnimateFilter] = useState(false);
  const [slideItem, setSlideItem] = useState({});
  const [destinationsInActiveGroup, setDestinationsInActiveGroup] = useState({});
  const [activeFilterValue, setActiveFilterValue] = useState({});
  const [destinationsGroups, setDestinationsGroups] = useState({});
  const [currentSlide, setCurrentSlide] = useState(0);
  const [displayRightArrow, setDisplayRightArrow] = useState(false);
  const [displayLeftArrow, setDisplayLeftArrow] = useState(false);
  const [filterOptions, setFilterOptions] = useState({});

  /**
   * Update tag list
   */
  const updateFilterOptions = () => {
    setFilterOptions(Object.keys(groups).map((group) => ({ value: group, label: group })));
  };

  /**
   * Set active slide
   */
  const handlerSliderChange = (newIndex) => {
    setSlideItem(destinationsInActiveGroup[newIndex]);

    setDisplayLeftArrow(newIndex !== 0);
    setDisplayRightArrow(newIndex !== destinationsInActiveGroup.length - 1);
  };

  /**
   * Updating state based on selected tag
   *
   * @param selectedOption {Object}
   */
  const handleChangeFilterValue = (selectedOption) => {
    const selectedGroup = destinationsGroups[selectedOption.value];

    setSlideItem(selectedGroup[0] || null);
    destinationsSlider.slideTo(0);
    setAnimateFilter(false);
    setActiveFilterValue(selectedOption);
    setDisplayRightArrow(selectedGroup.length > 1);

    setTimeout(() => {
      setDestinationsInActiveGroup(selectedGroup);
    });
  };

  /**
   * Delete empty group
   */
  const removeEmptyGroupName = () => {
    setFilterOptions((prevState) =>
      prevState.filter(({ value }) => destinationsGroups[value].length > 1),
    );
  };

  /**
   * Check if the surprise group has directions
   */
  const checkSurpriseGroupSize = () => {
    if (destinationsGroups[randomTag].length > 0) {
      handleChangeFilterValue({ value: randomTag, label: randomTag });
      updateActiveTag(randomTag);
    } else {
      setAllGroupsEmpty(true);
    }
  };

  /**
   * Set new active slide for destination slider
   *
   * @param currentSliderIndex {Number}
   * @param destinations {Array}
   */
  const updateCurrentSlide = (currentSliderIndex, destinations) => {
    if (currentSliderIndex < destinations.length) {
      destinationsSlider.slideTo(currentSliderIndex);
      setSlideItem(destinations[currentSliderIndex]);
    } else {
      destinationsSlider.slideTo(currentSliderIndex - 1);
      setSlideItem(destinations[currentSliderIndex - 1]);
    }
  };

  /**
   * Remove destination from destination list
   *
   */
  const removeClickedDestination = () => {
    setDestinationsInActiveGroup((prevState) => {
      const filteredDestinations = prevState.filter(({ id }) => slideItem.id !== id);

      const currentSliderIndex = prevState.findIndex(({ id }) => slideItem.id === id);

      if (filteredDestinations.length < 1) {
        checkSurpriseGroupSize();
      } else {
        updateCurrentSlide(currentSliderIndex, filteredDestinations);

        setDisplayRightArrow(currentSliderIndex < filteredDestinations.length - 1);
        setDisplayLeftArrow(currentSliderIndex !== 0);
        setAnimateFilter(false);
      }

      return filteredDestinations;
    });

    setDestinationsGroups((prevState) => {
      const res = {};

      Object.keys(prevState).forEach((group) => {
        res[group] = prevState[group].filter(({ id }) => slideItem.id !== id);
      });

      return res;
    });
  };

  /**
   * Set active group index
   *
   */
  const updateDestinations = () => {
    removeClickedDestination();
    removeEmptyGroupName();
  };

  /**
   * Go to the previous slide.
   */
  const handleSliderPrev = () => destinationsSlider.slidePrev();

  /**
   * Go to the next slide
   */
  const handleSliderNext = () => destinationsSlider.slideNext();

  /**
   * Render Popup Content
   *
   * @return {*}
   */
  const renderPopupContent = () => {
    if (isLoading) {
      return (
        <div className="loading">
          <Spinner />
        </div>
      );
    }

    if (areAllGroupsEmpty) {
      return (
        <p className="warning-msg">
          {t(
            "Sorry, but we can't offer to you other destinations right now. Return back later to see new destinations.",
          )}
        </p>
      );
    }

    return (
      <>
        {isMobile(screenTypeContext) && (
          <header className="main-header" id="main-header" ref={ref}>
            <div className="main-header__wrapper">
              <div className="explore-popup__title">{t('Explore')}</div>
              <Logo />
            </div>
          </header>
        )}
        <PopupHeader
          filterOptions={filterOptions}
          activeFilterValue={activeFilterValue}
          animateFilter={animateFilter}
          handleChangeFilterValue={handleChangeFilterValue}
        />
        <div
          className={`explore-popup__slider ${
            animateFilter ? 'explore-popup__slider--brightness' : ''
          } ${hideUserReactions ? 'hide-like-dislike' : ''}`}
        >
          <DestinationsSlider
            setDestinationsSlider={setDestinationsSlider}
            destinationsInActiveGroup={destinationsInActiveGroup}
            currentSlide={currentSlide}
            handlerSliderChange={handlerSliderChange}
            activeFilterValue={activeFilterValue}
          />

          <PopupButtons
            slideItem={slideItem}
            updateDestinations={updateDestinations}
            hideUserReactions={hideUserReactions}
            displayLeftArrow={displayLeftArrow}
            displayRightArrow={displayRightArrow}
            handlerPrevSlide={handleSliderPrev}
            handlerNexSlide={handleSliderNext}
          />
        </div>
      </>
    );
  };

  /**
   * Initial state
   */
  useEffect(() => {
    setGroups(groupedDestinations);

    updateFilterOptions();

    const currentSliderIndex = activeGroup.indexOf(activeDestination);

    setDestinationsInActiveGroup(activeGroup);
    setSlideItem(activeGroup[currentSliderIndex] || null);
    setCurrentSlide(currentSliderIndex);
    setDisplayRightArrow(currentSliderIndex < activeGroup.length - 1);
    setDisplayLeftArrow(currentSliderIndex !== 0);
    setActiveFilterValue({ value: activeTag, label: activeTag });
    setDestinationsGroups(groups);
    setLoading(false);
  }, []);

  return <div className="explore-popup__content">{renderPopupContent()}</div>;
});

DiscoverDestinationModal.displayName = 'DiscoverDestinationModal';

DiscoverDestinationModal.propTypes = {
  activeDestination: PropTypes.object.isRequired,
  activeTag: PropTypes.string,
  activeGroup: PropTypes.arrayOf(PropTypes.object).isRequired,
  groupedDestinations: PropTypes.arrayOf(PropTypes.object),
  updateActiveTag: PropTypes.func,
  hideUserReactions: PropTypes.bool,
};

DiscoverDestinationModal.defaultProps = {
  activeTag: '',
  groupedDestinations: [],
  updateActiveTag: () => {},
  hideUserReactions: false,
};

export default ErrorBoundaryDecorator()(DiscoverDestinationModal);
