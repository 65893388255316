import apiClient from '@js/apiClient';

/**
 * Update pageSections of specified page via API.
 *
 * "field" param structure {
 *  id: {Number},
 *  key: {String},
 *  value: {String},
 *  entityType: {String},
 * }
 *
 * @param   {Object} config
 * @param   {String} previewHash
 *
 * @returns {*|boolean|Promise<AxiosResponse<any>>|Observable<AjaxResponse>|Promise<void>}
 */
export const updatePageSections = (config, previewHash) =>
  apiClient.put(`/page-sections/${window.pageId}?preview_hash=${previewHash}`, {
    data: config,
  });

/**
 * Update content of specified field via API.
 *
 * @param {string} content
 * @param {string} type
 * @param {string} name
 * @param {string} previewHash
 * @param {string} entityType
 * @param {number} entityId
 *
 * @returns {*|boolean|Promise<AxiosResponse<any>>|Observable<AjaxResponse>|Promise<void>}
 */
export const updateFieldContent = ({ content, type, name }, previewHash, entityType, entityId) =>
  apiClient.put(`/page-content/?preview_hash=${previewHash}`, {
    content,
    type,
    name,
    entity_type: entityType,
    entity_id: entityId,
  });

/**
 * Update image related to specified field via API.
 *
 * "field" param structure {
 *  id: {Number},
 *  key: {String},
 *  value: {String},
 *  entityType: {String},
 * }
 *
 * "imageOptions" param structure {
 *  width: {Number},
 *  height: {Number},
 * }
 *
 * @param {object} field
 * @param {blob}   image
 * @param {object} imageOptions
 * @param {string} previewHash
 * @param {string} entityType
 * @param {number} entityId
 *
 * @returns {*|boolean|Promise<AxiosResponse<any>>|Observable<AjaxResponse>|Promise<void>}
 */
export const updateFieldImage = (field, image, imageOptions, previewHash, entityType, entityId) => {
  const formData = new FormData();

  formData.append('file', image);
  formData.append('type', 'image');
  formData.append('field_type', 'images');
  formData.append('file_type', 'page_content');

  // TODO: obsolete
  formData.append('entity_type', entityType); // TODO: field.entityType
  formData.append('entity_id', entityId);

  formData.append('width', imageOptions.width);
  formData.append('height', imageOptions.height);

  formData.append('name', field.name);

  return apiClient({
    url: `/page-content/upload?preview_hash=${previewHash}`,
    method: 'POST',
    data: formData,
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
};

/**
 * Delete image.
 *
 * @param {number} entityId
 * @param {string} entityType
 * @param {object} field
 * @param {string} previewHash
 *
 * @return {*}
 */
export const deleteImageContent = (entityId, entityType, field, previewHash) =>
  apiClient.delete(`/page-content?preview_hash=${previewHash}`, {
    data: {
      entity_id: entityId,
      entity_type: entityType,
      name: field.name,
      type: field.type,
    },
  });
