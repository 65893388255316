import React, { useContext } from 'react';

import TravelAirlineWrapper from '@jsv2/templates/vipTraveler/TravelHistory/TravelAirlineWrapper';
import WizardContext from '@jsv2/context/WizardContext';
import UserContext from '@jsv2/context/UserContext';

const TravelAirline = ({ getNextStep }) => {
  const { onComplete } = useContext(WizardContext).stepConfig;
  const { customer } = useContext(UserContext);

  const handleClick = () => {
    getNextStep(onComplete(customer.roles));
  };

  return <TravelAirlineWrapper finishFillWishList={handleClick} />;
};

export default TravelAirline;
