import React from 'react';
import { getCurrentScreenType } from '@js/utils/deviceWidthDetector';
import { SCREEN_TYPES } from '@js/constants';

export const isDesktop = (currentScreenType) => currentScreenType === SCREEN_TYPES.DESKTOP;

export const isTablet = (currentScreenType) => currentScreenType === SCREEN_TYPES.TABLET;

export const isMobile = (currentScreenType) => currentScreenType === SCREEN_TYPES.MOBILE;

export const isMiniScreen = (currentScreenType) => currentScreenType === SCREEN_TYPES.MINI;

export const selectTheAppropriateSize = (
  currentScreenType,
  desktop = 1920,
  tablet = 900,
  mobile = 600,
) => {
  const sizes = {
    desktop,
    tablet,
    mobile,
  };

  return sizes[currentScreenType];
};

const ScreenTypeContext = React.createContext(getCurrentScreenType());

export default ScreenTypeContext;
