import React, { useCallback, useContext, useRef, useState } from 'react';

import Slider from '@jsv2/components/Slider/Slider';
import SignUpContext from '@jsv2/context/SignUpContext';
import Image from '@jsv2/components/Image';
import { IMAGE_SIZES } from '@js/constants';
import ErrorBoundaryDecorator from '@components/decorators/ErrorBoundaryDecorator';
import { NextArrow, PrevArrow } from '@jsv2/components/Slider/SliderArrows';
import EditFieldContext, {
  Editable,
  EditableImg,
} from '@jsv2/LandingPage/context/EditFieldContext';

const { isPreviewPage } = window;

const settings = {
  // className: 'center',
  centeredSlides: true,
  slidesPerView: 'auto',
  loop: true,
  spaceBetween: 0,
  breakpoints: {
    1024: {
      spaceBetween: 100,
    },
    1100: {
      spaceBetween: 150,
    },
    1300: {
      spaceBetween: 170,
    },
    1400: {
      spaceBetween: 200,
    },
  },
};

const OffersSliderWide = React.forwardRef(({ renderBecomeButton, ...rest }, ref) => {
  const { editableFields, editableImageFields } = useContext(EditFieldContext);
  const { openModal } = useContext(SignUpContext);

  const initSignUpProcess = useCallback(() => {
    if (!isPreviewPage) {
      openModal(true);
    }
  }, [openModal]);

  const offers = [
    {
      id: 1,
      image: editableImageFields.exclusive_offers_1,
      destination: editableFields.offer_option_1_destination,
      title: editableFields.offer_option_1_title,
      price: editableFields.offer_option_1_price,
      discount: editableFields.offer_option_1_discount,
      benefits_title: editableFields.offer_option_1_benefits_title,
      benefits_option_1_title: editableFields.offer_option_1_benefits_option_1_title,
      benefits_option_2_title: editableFields.offer_option_1_benefits_option_2_title,
      benefits_option_3_title: editableFields.offer_option_1_benefits_option_3_title,
      benefits_option_4_title: editableFields.offer_option_1_benefits_option_4_title,
    },
    {
      id: 2,
      image: editableImageFields.exclusive_offers_2,
      destination: editableFields.offer_option_2_destination,
      title: editableFields.offer_option_2_title,
      price: editableFields.offer_option_2_price,
      discount: editableFields.offer_option_2_discount,
      benefits_title: editableFields.offer_option_2_benefits_title,
      benefits_option_1_title: editableFields.offer_option_2_benefits_option_1_title,
      benefits_option_2_title: editableFields.offer_option_2_benefits_option_2_title,
      benefits_option_3_title: editableFields.offer_option_2_benefits_option_3_title,
      benefits_option_4_title: editableFields.offer_option_2_benefits_option_4_title,
    },
    {
      id: 3,
      image: editableImageFields.exclusive_offers_3,
      destination: editableFields.offer_option_3_destination,
      title: editableFields.offer_option_3_title,
      price: editableFields.offer_option_3_price,
      discount: editableFields.offer_option_3_discount, // TODO: change "discount" for "travel period"
      benefits_title: editableFields.offer_option_3_benefits_title,
      benefits_option_1_title: editableFields.offer_option_3_benefits_option_1_title,
      benefits_option_2_title: editableFields.offer_option_3_benefits_option_2_title,
      benefits_option_3_title: editableFields.offer_option_3_benefits_option_3_title,
      benefits_option_4_title: editableFields.offer_option_3_benefits_option_4_title,
    },
    {
      id: 4,
      image: editableImageFields.exclusive_offers_4,
      destination: editableFields.offer_option_4_destination,
      title: editableFields.offer_option_4_title,
      price: editableFields.offer_option_4_price,
      discount: editableFields.offer_option_4_discount, // TODO: change "discount" for "travel period"
      benefits_title: editableFields.offer_option_4_benefits_title,
      benefits_option_1_title: editableFields.offer_option_4_benefits_option_1_title,
      benefits_option_2_title: editableFields.offer_option_4_benefits_option_2_title,
      benefits_option_3_title: editableFields.offer_option_4_benefits_option_3_title,
      benefits_option_4_title: editableFields.offer_option_4_benefits_option_4_title,
    },
    {
      id: 5,
      image: editableImageFields.exclusive_offers_5,
      destination: editableFields.offer_option_5_destination,
      title: editableFields.offer_option_5_title,
      price: editableFields.offer_option_5_price,
      discount: editableFields.offer_option_5_discount, // TODO: change "discount" for "travel period"
      benefits_title: editableFields.offer_option_5_benefits_title,
      benefits_option_1_title: editableFields.offer_option_5_benefits_option_1_title,
      benefits_option_2_title: editableFields.offer_option_5_benefits_option_2_title,
      benefits_option_3_title: editableFields.offer_option_5_benefits_option_3_title,
      benefits_option_4_title: editableFields.offer_option_5_benefits_option_4_title,
    },
    {
      id: 6,
      image: editableImageFields.exclusive_offers_6,
      destination: editableFields.offer_option_6_destination,
      title: editableFields.offer_option_6_title,
      price: editableFields.offer_option_6_price,
      discount: editableFields.offer_option_6_discount, // TODO: change "discount" for "travel period"
      benefits_title: editableFields.offer_option_6_benefits_title,
      benefits_option_1_title: editableFields.offer_option_6_benefits_option_1_title,
      benefits_option_2_title: editableFields.offer_option_6_benefits_option_2_title,
      benefits_option_3_title: editableFields.offer_option_6_benefits_option_3_title,
      benefits_option_4_title: editableFields.offer_option_6_benefits_option_4_title,
    },
  ];

  const [slider, setSlider] = useState(null);
  const navigationPrevRef = useRef(null);
  const navigationNextRef = useRef(null);

  const slideNext = () => {
    slider.slideNext();
  };

  const slidePreview = () => {
    slider.slidePrev();
  };

  return (
    <section className="offers" {...rest} ref={ref}>
      <div className="main-container">
        <button
          type="button"
          onClick={slidePreview}
          className="offers__sliderBtn offers__sliderBtn--prev"
        />

        <button
          type="button"
          onClick={slideNext}
          className="offers__sliderBtn offers__sliderBtn--next"
        />

        <Slider
          sliderProps={{
            className: 'offers__wide-slider',
            onSwiper: setSlider,
            navigation: {
              prevEl: navigationPrevRef.current,
              nextEl: navigationNextRef.current,
            },
            onBeforeInit: (swiper) => {
              swiper.params.navigation.prevEl = navigationPrevRef.current;
              swiper.params.navigation.nextEl = navigationNextRef.current;
            },
            ...settings,
          }}
        >
          {offers.map((item) => (
            <div key={item.id} className="offers__item">
              <div className="offers__item-wrapper">
                <div className="offers__item-inner">
                  <div className="offers__image-wrapper">
                    {item.image && (
                      <EditableImg image={item.image} width={450} height={315}>
                        <Image
                          data={item.image && item.image.content}
                          type="PageContent"
                          config={{
                            size: IMAGE_SIZES.IMAGE_W350,
                          }}
                          background
                        />
                      </EditableImg>
                    )}
                  </div>

                  {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/interactive-supports-focus */}
                  <div
                    className="offers__item-labels clickable-block"
                    role="button"
                    onClick={() => initSignUpProcess()}
                  >
                    <div className="offers__item-label offers__item-label--black">
                      <Editable fieldValue={item.price} />
                    </div>
                    <div className="offers__item-label offers__item-label--red">
                      <Editable fieldValue={item.discount} />
                    </div>
                  </div>

                  {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/interactive-supports-focus */}
                  <div
                    className="offers__item-location clickable-block"
                    role="button"
                    onClick={() => initSignUpProcess()}
                  >
                    <Editable fieldValue={item.destination} />
                  </div>

                  {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-noninteractive-element-interactions */}
                  <h4
                    className="offers__item-title clickable-block"
                    onClick={() => initSignUpProcess()}
                  >
                    <Editable fieldValue={item.title} />
                  </h4>
                </div>

                {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/interactive-supports-focus */}
                <div
                  className="offers__item-benefits offer-benefits clickable-block"
                  role="button"
                  onClick={() => initSignUpProcess()}
                >
                  <div className="offer-benefits__item offer-benefits__item--title">
                    <Editable fieldValue={item.benefits_title} />
                  </div>
                  <div className="offer-benefits__item">
                    <img src="/images/lp/promo2/icons/sun.svg" alt="Early Check-In" />
                    <Editable fieldValue={item.benefits_option_1_title} />
                  </div>

                  <div className="offer-benefits__item">
                    <img src="/images/lp/promo2/icons/key.svg" alt="Full Club Access" />
                    <Editable fieldValue={item.benefits_option_2_title} />
                  </div>

                  <div className="offer-benefits__item">
                    <img src="/images/lp/promo2/icons/private.svg" alt="Private Balconies" />
                    <Editable fieldValue={item.benefits_option_3_title} />
                  </div>

                  <div className="offer-benefits__item">
                    <img src="/images/lp/promo2/icons/spa.svg" alt="Spa Day Pass" />
                    <Editable fieldValue={item.benefits_option_4_title} />
                  </div>
                </div>
              </div>
            </div>
          ))}
        </Slider>

        <div className="offers__custom-arrows">
          <PrevArrow ref={navigationPrevRef} />

          <NextArrow ref={navigationNextRef} />
        </div>
      </div>
    </section>
  );
});

OffersSliderWide.displayName = 'OffersSlider';

export default ErrorBoundaryDecorator()(OffersSliderWide);
