import React, { useContext } from 'react';

import ScreenTypeContext, { isDesktop } from '@js/context/ScreenTypeContext';
import NativeVideoComponent from './NativeVideoComponent';
import CustomVideoComponent from './CustomVideoComponent';

const VideoBlock = (props) => {
  const screenTypeContext = useContext(ScreenTypeContext);

  return (
    <div className="video-section" style={{ height: '100%' }}>
      {!isDesktop(screenTypeContext) ? (
        <NativeVideoComponent {...props} />
      ) : (
        <CustomVideoComponent {...props} />
      )}
    </div>
  );
};

export default VideoBlock;
