import React from 'react';

import { UPGRADE_PLAN_URL } from '@jsv3/enums/urlEnums';
import LinkItem from '../../components/LinkItem';

const UpgradePlan = () => (
  <LinkItem
    href={UPGRADE_PLAN_URL}
    id="upgrade-to-vip-plus"
    title="Upgrade to VIP Plus"
    className="upgrade-link"
  />
);

export default UpgradePlan;
