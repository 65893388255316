import ErrorBoundaryDecorator from '@components/decorators/ErrorBoundaryDecorator';
import PROFILER_STEPS from '../Profiler/steps';

/**
 * List of steps, available during the sign-up process.
 *
 * @type {Object|(string)[]}
 */
const STEPS = {
  /**
   * List of steps where user enters his email (signs-up).
   */
  BASE_STEP_DEFAULT: 'BASE_STEP_DEFAULT',
  BASE_STEP_UPGRADE: 'BASE_STEP_UPGRADE',
  BASE_STEP_WELCOME: 'BASE_STEP_WELCOME',
  BASE_STEP_WELCOME_MULTIPLE: 'BASE_STEP_WELCOME_MULTIPLE',
  BASE_STEP_WELCOME_UPDATED: 'BASE_STEP_WELCOME_UPDATED',
  BASE_STEP_PROFILER: 'BASE_STEP_PROFILER',

  ADDRESS_STEP_DEFAULT: 'ADDRESS_STEP_DEFAULT',
  ADDRESS_STEP_PROFILER: 'ADDRESS_STEP_PROFILER',

  CHOOSE_PLAN_STEP: 'CHOOSE_PLAN_STEP',

  PAYMENT_STEP_DEFAULT: 'PAYMENT_STEP_DEFAULT',
  PAYMENT_STEP_UPGRADE: 'PAYMENT_STEP_UPGRADE',

  INCOMPLETE_SIGNUP_MESSAGE: 'INCOMPLETE_SIGNUP_MESSAGE',
  FREE_WELCOME_MESSAGE: 'FREE_WELCOME_MESSAGE',
  PAID_WELCOME_MESSAGE: 'PAID_WELCOME_MESSAGE',

  FREE_THANKS_MESSAGE: 'FREE_THANKS_MESSAGE',
  PAID_THANKS_MESSAGE: 'PAID_THANKS_MESSAGE',
  REGISTRATION_MESSAGE: 'REGISTRATION_MESSAGE',
  DEPOSIT_REGISTRATION_MESSAGE: 'DEPOSIT_REGISTRATION_MESSAGE',
  SUCCESS_ABANDONED_MESSAGE: 'SUCCESS_ABANDONED_MESSAGE',
  SUCCESS_FREE_MESSAGE: 'SUCCESS_FREE_MESSAGE',
  SUCCESS_PAID_MESSAGE: 'SUCCESS_PAID_MESSAGE',
  DISABLED_SIGNUP_MESSAGE: 'DISABLED_SIGNUP_MESSAGE',

  SUCCESS_ONE_OF_BOOKING_PAID_MESSAGE: 'SUCCESS_ONE_OF_BOOKING_PAID_MESSAGE',
  SUCCESS_ONE_OF_BOOKING_ABANDONED_MESSAGE: 'SUCCESS_ONE_OF_BOOKING_ABANDONED_MESSAGE',

  SUCCESS_DEPOSIT_MESSAGE: 'SUCCESS_DEPOSIT_MESSAGE',

  ...PROFILER_STEPS,
};

export default ErrorBoundaryDecorator()(STEPS);
