/**
 * @param pageId
 * @return {string}
 */
const createGeneralValueKey = (pageId) => {
  if (typeof window.pageId === 'number') {
    return `Editable_UnsavedChanges__cms-page-${window.pageId}`;
  }

  return `Editable_UnsavedChanges__page-${pageId}`;
};

export default createGeneralValueKey;
