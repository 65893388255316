import React from 'react';
import PropTypes from 'prop-types';

import AuthMessage from '@jsv2/components/Authorization/AuthMessage';
import BaseMessageTemplate from '@jsv2/templates/vipTraveler/ModalMessages/BaseMessageTemplate';

/**
 * Pre Q Success Pop 2.
 */

const MESSAGE_CONTENT = {
  image: '/images/lp/promo2/vip_illustration-ok.svg',
  heading: 'Thanks! You’re en route.',
  tagline: 'You’ve signed up for a Free Membership plan! Soon, we’ll reach out to share travel finds we know you’ll love'
    + ' and to update you on our progress as we continue to craft your personalized VIP Traveler experience. In the'
    + ' meantime, check out our featured offers to the destinations we\'re most excited about this month.',
  buttonText: 'VIEW VIP EXCLUSIVES™',
};

const SuccessFreeMessage = ({ step, getNextStep }) => (
  <AuthMessage
    step={step}
    getNextStep={getNextStep}
    render={({ handleClick }) => (
      <BaseMessageTemplate
        handleClick={(handleClick)}
        {...MESSAGE_CONTENT}
      />
    )}
  />
);

SuccessFreeMessage.propTypes = {
  step: PropTypes.shape({
    component: PropTypes.func.isRequired,
    onComplete: PropTypes.func.isRequired,
  }).isRequired,
  getNextStep: PropTypes.func.isRequired,
};

export default SuccessFreeMessage;
