import React from 'react';

/**
 * @param Section {JSXElement}
 * @param AdditionalContent {JSXElement}
 */
const SectionWithAdditionalContentHOC = (Section, AdditionalContent) => (props) => (
  <Section {...props}>
    <AdditionalContent />
  </Section>
);

export default SectionWithAdditionalContentHOC;
