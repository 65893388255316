import React, { useContext, useCallback } from 'react';

import Slider from '@jsv2/components/Slider/Slider';
import ErrorBoundaryDecorator from '@components/decorators/ErrorBoundaryDecorator';
import SignUpContext from '@jsv2/context/SignUpContext';
import Image from '@jsv2/components/Image';
import { IMAGE_SIZES } from '@js/constants';
import EditFieldContext, {
  Editable,
  EditableImg,
} from '@jsv2/LandingPage/context/EditFieldContext';
import LPContext from '@jsv2/LandingPage/context/LPContext';
import { preparedAvailableItemsForRender } from '@jsv2/LandingPage/utils';

const { isPreviewPage } = window;

const settings = {
  pagination: {
    clickable: true,
  },
  navigation: true,
  className: 'offers__slider',
};

const OffersSlider = React.forwardRef(({ renderBecomeButton, ...rest }, ref) => {
  const { editableFields, editableImageFields } = useContext(EditFieldContext);
  const { openModal } = useContext(SignUpContext);
  const { isEditSectionMode } = useContext(LPContext);

  const offers = [
    {
      id: 1,
      image: editableImageFields.exclusive_offers_1,
      destination: editableFields.offer_option_1_destination,
      title: editableFields.offer_option_1_title,
      price: editableFields.offer_option_1_price,
      discount: editableFields.offer_option_1_discount,
      benefits_title: editableFields.offer_option_1_benefits_title,
      benefits_option_1_title: editableFields.offer_option_1_benefits_option_1_title,
      benefits_option_2_title: editableFields.offer_option_1_benefits_option_2_title,
      benefits_option_3_title: editableFields.offer_option_1_benefits_option_3_title,
      benefits_option_4_title: editableFields.offer_option_1_benefits_option_4_title,
    },
    {
      id: 2,
      image: editableImageFields.exclusive_offers_2,
      destination: editableFields.offer_option_2_destination,
      title: editableFields.offer_option_2_title,
      price: editableFields.offer_option_2_price,
      discount: editableFields.offer_option_2_discount,
      benefits_title: editableFields.offer_option_2_benefits_title,
      benefits_option_1_title: editableFields.offer_option_2_benefits_option_1_title,
      benefits_option_2_title: editableFields.offer_option_2_benefits_option_2_title,
      benefits_option_3_title: editableFields.offer_option_2_benefits_option_3_title,
      benefits_option_4_title: editableFields.offer_option_2_benefits_option_4_title,
    },
    {
      id: 3,
      image: editableImageFields.exclusive_offers_3,
      destination: editableFields.offer_option_3_destination,
      title: editableFields.offer_option_3_title,
      price: editableFields.offer_option_3_price,
      discount: editableFields.offer_option_3_discount, // TODO: change "discount" for "travel period"
      benefits_title: editableFields.offer_option_3_benefits_title,
      benefits_option_1_title: editableFields.offer_option_3_benefits_option_1_title,
      benefits_option_2_title: editableFields.offer_option_3_benefits_option_2_title,
      benefits_option_3_title: editableFields.offer_option_3_benefits_option_3_title,
      benefits_option_4_title: editableFields.offer_option_3_benefits_option_4_title,
    },
    {
      id: 4,
      image: editableImageFields.exclusive_offers_4,
      destination: editableFields.offer_option_4_destination,
      title: editableFields.offer_option_4_title,
      price: editableFields.offer_option_4_price,
      discount: editableFields.offer_option_4_discount, // TODO: change "discount" for "travel period"
      benefits_title: editableFields.offer_option_4_benefits_title,
      benefits_option_1_title: editableFields.offer_option_4_benefits_option_1_title,
      benefits_option_2_title: editableFields.offer_option_4_benefits_option_2_title,
      benefits_option_3_title: editableFields.offer_option_4_benefits_option_3_title,
      benefits_option_4_title: editableFields.offer_option_4_benefits_option_4_title,
    },
    {
      id: 5,
      image: editableImageFields.exclusive_offers_5,
      destination: editableFields.offer_option_5_destination,
      title: editableFields.offer_option_5_title,
      price: editableFields.offer_option_5_price,
      discount: editableFields.offer_option_5_discount, // TODO: change "discount" for "travel period"
      benefits_title: editableFields.offer_option_5_benefits_title,
      benefits_option_1_title: editableFields.offer_option_5_benefits_option_1_title,
      benefits_option_2_title: editableFields.offer_option_5_benefits_option_2_title,
      benefits_option_3_title: editableFields.offer_option_5_benefits_option_3_title,
      benefits_option_4_title: editableFields.offer_option_5_benefits_option_4_title,
    },
    {
      id: 6,
      image: editableImageFields.exclusive_offers_6,
      destination: editableFields.offer_option_6_destination,
      title: editableFields.offer_option_6_title,
      price: editableFields.offer_option_6_price,
      discount: editableFields.offer_option_6_discount, // TODO: change "discount" for "travel period"
      benefits_title: editableFields.offer_option_6_benefits_title,
      benefits_option_1_title: editableFields.offer_option_6_benefits_option_1_title,
      benefits_option_2_title: editableFields.offer_option_6_benefits_option_2_title,
      benefits_option_3_title: editableFields.offer_option_6_benefits_option_3_title,
      benefits_option_4_title: editableFields.offer_option_6_benefits_option_4_title,
    },
  ];

  const preparedOffersData = preparedAvailableItemsForRender(offers, isEditSectionMode, [
    'destination',
    'benefits_option_1_title',
    'benefits_option_2_title',
    'benefits_option_3_title',
    'benefits_option_4_title',
  ]);

  const initSignUpProcess = useCallback(() => {
    if (!isPreviewPage) {
      openModal(true);
    }
  }, [openModal]);

  return (
    editableFields && (
      <div className="offers" {...rest} ref={ref}>
        <Slider sliderProps={{ ...settings }}>
          {preparedOffersData.map((item) => (
            <div key={item.id} className="offers__item">
              <div className="offers__item-wrapper">
                <div className="offers__item-inner">
                  <div className="offers__image-wrapper">
                    {item.image && (
                      <EditableImg image={item.image} width={472} height={372}>
                        <Image
                          data={item.image && item.image.content}
                          type="PageContent"
                          config={{
                            size: IMAGE_SIZES.SMALL,
                          }}
                          background
                        />
                      </EditableImg>
                    )}
                  </div>

                  {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/interactive-supports-focus */}
                  <div
                    className="offers__item-labels clickable-block"
                    role="button"
                    onClick={() => initSignUpProcess()}
                  >
                    <div className="offers__item-label offers__item-label--black">
                      <Editable fieldValue={item.price} />
                    </div>
                    <div className="offers__item-label offers__item-label--red">
                      <Editable fieldValue={item.discount} />
                    </div>
                  </div>

                  {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/interactive-supports-focus */}
                  <div
                    className="offers__item-location clickable-block"
                    role="button"
                    onClick={() => initSignUpProcess()}
                  >
                    <Editable fieldValue={item.destination} isOptionalField />
                  </div>

                  {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-noninteractive-element-interactions */}
                  <h4
                    className="offers__item-title clickable-block"
                    onClick={() => initSignUpProcess()}
                  >
                    <Editable fieldValue={item.title} />
                  </h4>
                </div>

                {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/interactive-supports-focus */}
                <div
                  className="offers__item-benefits offer-benefits clickable-block"
                  role="button"
                  onClick={() => initSignUpProcess()}
                >
                  <div className="offer-benefits__title">
                    <Editable fieldValue={item.benefits_title} />
                  </div>
                  <div className="offer-benefits__items">
                    <div className="offer-benefits__item">
                      <Editable fieldValue={item.benefits_option_1_title} isOptionalField />
                    </div>

                    <div className="offer-benefits__item">
                      <Editable fieldValue={item.benefits_option_2_title} isOptionalField />
                    </div>

                    <div className="offer-benefits__item">
                      <Editable fieldValue={item.benefits_option_3_title} isOptionalField />
                    </div>

                    <div className="offer-benefits__item">
                      <Editable fieldValue={item.benefits_option_4_title} isOptionalField />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </Slider>
      </div>
    )
  );
});

OffersSlider.displayName = 'OffersSlider';

export default ErrorBoundaryDecorator()(OffersSlider);
