import apiClient from '@jsv3/services/apiClient';

/**
 * Get a list of promo campaigns.
 * @param pageId
 * @return {Promise<AxiosResponse<any>>}
 */
export function promoCampaignListAPI(pageId) {
  return apiClient.get('/promo-campaigns', {
    params: { entity_id: pageId, entity_type: 'Common\\Models\\BrandPage' },
  });
}
