import React from 'react';

import HOC from '@jsv2/utils/BucketListUtils/ConnectComponentToWishListContextHOC';
import SearchBox from '@jsv2/components/BucketList/searchBox/SearchBox';
import SliderItemTemplate from '@jsv2/templates/vipTraveler/TravelHistory/SliderItemTemplate';
import WishListComponent from '@jsv2/components/BucketList/BucketListComponent';
import WishListSlider from '@jsv2/components/BucketList/preferenceList/WishListSlider';
import * as APIRequest from '@jsv2/utils/BucketListUtils/APIRequests';
import ErrorBoundaryDecorator from '@components/decorators/ErrorBoundaryDecorator';

const settings = {
  slidesPerView: 2,
  slidesPerGroup: 2,
  noSwiping: true,
  allowTouchMove: false,
  grid: {
    rows: 1,
    fill: 'row',
  },
  breakpoints: {
    767: {
      slidesPerView: 3,
      slidesPerGroup: 3,
    },
    1024: {
      slidesPerView: 4,
      slidesPerGroup: 4,
    },
  },
};

const TravelHistoryWrapper = ({ finishFillWishList, bodyClassName = '' }) => (
  <div className={`wish-list-popup ${bodyClassName || ''}`} data-qa-id="travel-airline-modal">
    <div className="wish-list-wrapper">
      <div className="wish-list-header">
        <div className="title">{t('What are your preferred airlines?')}</div>

        <div className="sub-title">
          {t('Just enter an airline below, select from dropdown, and click "Add".')}
        </div>
      </div>

      <div className="wish-list-content">
        <WishListComponent
          entityType="Airline"
          getDestinationsList={APIRequest.getTravelAirlinesList}
          addWishListItem={APIRequest.addWishListItem}
          addWishListItemByName={APIRequest.addWishListItemByName}
          removeWishListItemByName={APIRequest.removeWishListItemByName}
          reorderWishListItems={APIRequest.reorderWishListItems}
        >
          <div className="destinations-search-box-wrapper destination-list">
            <HOC
              WrappedComponent={SearchBox}
              placeholder="Enter an airline"
              apiUrl="airlines"
              maxInputLength={40}
            />
          </div>

          <HOC
            WrappedComponent={WishListSlider}
            SliderItemTemplate={SliderItemTemplate}
            settings={settings}
            placeholder="airline"
          />
        </WishListComponent>
      </div>

      <div className="button-wrapper">
        <button
          className="btn-wish-list"
          type="submit"
          data-qa-id="next"
          onClick={finishFillWishList}
        >
          {t('NEXT')}
        </button>
      </div>
    </div>
  </div>
);

export default ErrorBoundaryDecorator()(TravelHistoryWrapper);
