import ErrorBoundaryDecorator from '@components/decorators/ErrorBoundaryDecorator';

/**
 * List of steps, available during the filling profiler.
 *
 * @type {Object|(string)[]}
 */
const PROFILER_STEPS = {
  /**
   * List of steps with hardcoded questions in Travel Profiler (questionnaire).
   */
  TRAVEL_PROFILER: 'TRAVEL_PROFILER',
  TRAVEL_AIRLINE: 'TRAVEL_AIRLINE',
  TRAVEL_HISTORY: 'TRAVEL_HISTORY',
  TRAVEL_PREFERENCES: 'TRAVEL_PREFERENCES',
  COMPLETE_TRAVEL_PROFILER: 'COMPLETE_TRAVEL_PROFILER',

  SUCCESS_COMPLETE_MESSAGE: 'SUCCESS_COMPLETE_MESSAGE',
};

export default ErrorBoundaryDecorator()(PROFILER_STEPS);
