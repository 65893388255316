import React from 'react';
import PropTypes from 'prop-types';

import { resourceURL } from '@jsv3/utils/urlUtils';
import ErrorBoundaryDecorator from '@components/decorators/ErrorBoundaryDecorator';

const CardIcon = ({ imageName }) => (
  <span
    className={`card-type-icon ${imageName}`}
    style={
      imageName
        ? {
          backgroundImage: `url(${resourceURL(`/images/icons/card-types/${imageName}.svg`)})`,
        }
        : {}
    }
    data-qa-id="saved_method_card"
  />
);

CardIcon.propTypes = {
  imageName: PropTypes.string,
};

CardIcon.defaultProps = {
  imageName: '',
};

export default ErrorBoundaryDecorator()(CardIcon);
