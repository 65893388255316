import apiClient from '@js/apiClient';
import { withResolutionsFilter } from '@jsv2/utils/imageUtils';

export const saveUserEmail = (data) => apiClient.post('/user/email', data);

export const registerUser = (data) =>
  apiClient.post('/register', {
    ...data,
    step_name: 'registration',
  });

export const updateUserInfo = (data) =>
  apiClient.post('/update-info', {
    ...data,
    step_name: 'location',
  });

export const subscribeUser = (data) => apiClient.post('/user/membership/default', data);

export const makeUserFreeMember = (param = '') => apiClient.post(`user/membership/free?${param}`);

export const finishFillTravelPreferences = () => apiClient.post('user/travel-preference/skip');

export const finishFillTravelHistory = () => apiClient.post('user/travel-history/skip');

export const getProfilerAvailability = () => apiClient.get('user/profiler-availability');

export const getUserRoles = () => apiClient.get('/user/roles');

export const getUserSuggestions = (params = {}) => {
  const resolutionsConfig = withResolutionsFilter(['w600']);

  return apiClient.get('/suggestions?' + resolutionsConfig, { params });
};

export const getPopularUserSuggestions = (params = {}) => {
  const resolutionsConfig = withResolutionsFilter(['w600']);

  return apiClient.get('/suggestions/popular?' + resolutionsConfig, { params });
};

export const postUserSuggestionsView = (id = {}) =>
  apiClient.post('/products/views', { entity_id: id, entity_type: 'Suggestions\\Suggestion' });

/**
 * @param {string} context
 * @param {string} action
 * @param {object} data
 * @return {*}
 */
export const postUserAction = (context, action, data = null) =>
  apiClient.post('/user-actions', { context, action, data });
