import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ReactModal from 'react-modal';
import classNames from 'classnames';

import ErrorBoundaryDecorator from '@components/decorators/ErrorBoundaryDecorator';

class Modal extends Component {
  className = classNames(this.props.className, {
    'modal-overlay': true,
  });

  defaultModalSettings = {
    overlayClassName: this.className,
    className: 'modal-window',
    bodyOpenClassName: 'noScroll',
  };

  static propTypes = {
    className: PropTypes.string,
    withCloseButton: PropTypes.bool,
    size: PropTypes.string,
    onRequestClose: PropTypes.func,
    dataQaForCloseButton: PropTypes.string,
    dataQaModalType: PropTypes.string,
    dataQaModalId: PropTypes.string,
  };

  static defaultProps = {
    className: '',
    withCloseButton: false,
    size: 'lg',
    onRequestClose: () => {},
    dataQaForCloseButton: 'qa_close_btn',
    dataQaModalType: '',
    dataQaModalId: 'qa_popup_alert',
  };

  /**
   * @return {Object}
   */
  getModalProps() {
    const props = { ...this.defaultModalSettings, ...this.props };

    props.className = [this.defaultModalSettings.className, this.props.className].join(' ').trim();

    return props;
  }

  render() {
    const { withCloseButton, size, dataQaForCloseButton, dataQaModalType, dataQaModalId } = this.props;

    return (
      <ReactModal {...this.getModalProps()}>
        <div className="layover-wrapper" data-modal-type={dataQaModalType} data-qa-id={dataQaModalId}>
          {withCloseButton && (
            // eslint-disable-next-line jsx-a11y/click-events-have-key-events
            <div
              role="button"
              tabIndex="0"
              className="btn-close btn-close--black btn-close--absolute size-20"
              onClick={this.props.onRequestClose}
              data-qa-id={dataQaForCloseButton}
            />
          )}

          <div className={'modal-popup modal-' + size}>
            <div className="modal-content">{this.props.children}</div>
          </div>
        </div>
      </ReactModal>
    );
  }
}

export default ErrorBoundaryDecorator()(Modal);
