import React, { useMemo, useRef, useState } from 'react';
import PropTypes from 'prop-types';

import { storageURL } from '@jsv3/utils/urlUtils';
import ErrorBoundaryDecorator from '@components/decorators/ErrorBoundaryDecorator';

const NativeVideoComponent = ({ posterSrc, videoSrc, toggleAudioPlayback, playButton }) => {
  const video = useRef(null);
  const videoSourceSrc = useMemo(() => storageURL(videoSrc), [videoSrc]);

  const [isPlay, setIsPlay] = useState(false);

  const afterPlay = () => {
    if (toggleAudioPlayback) {
      toggleAudioPlayback(false);
    }
  };

  const togglePlayPauseVideo = () => {
    if (isPlay) {
      video.current.pause();
      setIsPlay(false);
    } else {
      afterPlay();
      video.current.play();
      setIsPlay(true);
    }
    return null;
  };

  return (
    <div className="video-container-native">
      {/* eslint-disable-next-line jsx-a11y/media-has-caption */}
      <video
        ref={video}
        width="100%"
        height="100%"
        preload="none"
        poster={posterSrc}
        controls={isPlay}
        onPause={togglePlayPauseVideo}
      >
        <source data-src={videoSourceSrc} src={videoSourceSrc} type="video/mp4" />
      </video>

      {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
      <div
        className="btn-video play-video"
        onClick={togglePlayPauseVideo}
        style={{ display: isPlay ? 'none' : 'block' }}
      >
        {playButton?.()}
      </div>
    </div>
  );
};

NativeVideoComponent.propTypes = {
  posterSrc: PropTypes.string.isRequired,
  videoSrc: PropTypes.string.isRequired,
  toggleAudioPlayback: PropTypes.func,
  playButton: PropTypes.func,
};

NativeVideoComponent.defaultProps = {
  playButton: null,
  toggleAudioPlayback: null,
};

export default ErrorBoundaryDecorator()(NativeVideoComponent);
