import React, { useContext } from 'react';
import PropTypes from 'prop-types';

import BucketListContext from '@jsv2/context/BucketListContext';
import ErrorBoundaryDecorator from '@components/decorators/ErrorBoundaryDecorator';

/**
 * Render user reaction buttons in the Explore popup
 *
 * @param slideItem
 * @param updateDestinations
 * @param hideUserReactions
 * @param displayLeftArrow
 * @param displayRightArrow
 * @param handlerPrevSlide
 * @param handlerNexSlide
 * @return {*}
 *
 * @constructor
 */
const PopupButtons = ({
  slideItem,
  updateDestinations,
  hideUserReactions,
  displayLeftArrow,
  displayRightArrow,
  handlerPrevSlide,
  handlerNexSlide,
}) => {
  const { addWishListItem, blackListed } = useContext(BucketListContext);

  /**
   * Add destination to blacklist and update destination list in popup
   */
  const handleAddToBlacklist = () => {
    blackListed(slideItem);

    updateDestinations();
  };

  /**
   * Add destination to bucket list and update destination list in popup
   */
  const handleAddToWishlist = () => {
    addWishListItem(slideItem.id, slideItem.type); // TODO: pass as one object

    updateDestinations();
  };

  return (
    <>
      <div className="explore-popup__actions">
        <button
          type="button"
          data-qa-id="rewind_btn"
          onClick={handlerPrevSlide}
          className={`explore-popup__arrow-btn explore-popup__arrow-btn--rewind ${
            !displayLeftArrow ? 'explore-popup__arrow-btn--disable' : ''
          }`}
        >
          {t('REWIND')}
        </button>

        {!hideUserReactions && (
          <div className="explore-popup__reaction-btns">
            <button
              type="button"
              data-qa-id="dislike_btn"
              className="explore-popup__btn bucket-btn--dislike explore-popup__btn--dislike"
              onClick={handleAddToBlacklist}
            />

            <button
              type="button"
              data-qa-id="like_btn"
              className="explore-popup__btn bucket-btn--like explore-popup__btn--like"
              onClick={handleAddToWishlist}
            />
          </div>
        )}

        <button
          type="button"
          data-qa-id="arrow_next_btn"
          onClick={handlerNexSlide}
          className={`explore-popup__arrow-btn explore-popup__arrow-btn--next ${
            !displayRightArrow ? 'explore-popup__arrow-btn--disable' : ''
          }`}
        >
          {t('NEXT')}
        </button>
      </div>
    </>
  );
};

PopupButtons.propTypes = {
  slideItem: PropTypes.object.isRequired,
  updateDestinations: PropTypes.func.isRequired,
  hideUserReactions: PropTypes.bool,
  displayLeftArrow: PropTypes.bool.isRequired,
  displayRightArrow: PropTypes.bool.isRequired,
  handlerPrevSlide: PropTypes.func.isRequired,
  handlerNexSlide: PropTypes.func.isRequired,
};

PopupButtons.defaultProps = {
  hideUserReactions: false,
};

export default ErrorBoundaryDecorator()(PopupButtons);
