import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import SocialNetworks from '@components/Auth/SocialNetworks';
import { facebookSocialButton } from '@components/Auth/SocialButtons/Buttons';
import { isFacebookUrl } from '@jsv3/utils/urlUtils';

const ProgressBar = ({ activeStep, handleSocialSignUp, signup }) => {
  const renderFirstStep = () => {
    if (isFacebookUrl() && signup) {
      return (
        <div className="first-step__wrapper">
          <SocialNetworks
            onSuccess={handleSocialSignUp}
            buttons={facebookSocialButton}
            className="single-button"
          />

          <div className="first-step__or">or</div>

          <div className="first-step__title">Create an account using an email</div>
        </div>
      );
    }

    return <div className="first-step__title">Get Started</div>;
  };

  return (
    <div className={classNames('steps', `activeStep-${activeStep}`, { show: isFacebookUrl() })}>
      {activeStep === 1 ? (
        renderFirstStep()
      ) : (
        <>
          <div className="step-item item-1">{t('Signup')}</div>
          <div className="line line-1" />
          <div className="step-item item-2">{t('Details')}</div>
          <div className="line line-2" />
          <div className="step-item item-3">{t('Complete')}</div>
        </>
      )}
    </div>
  );
};

ProgressBar.propTypes = {
  activeStep: PropTypes.number.isRequired,
  handleSocialSignUp: PropTypes.func,
  signup: PropTypes.bool,
};

ProgressBar.defaultProps = {
  handleSocialSignUp: () => {},
  signup: false,
};

export default ProgressBar;
