import React from 'react';
import PropTypes from 'prop-types';

import AuthMessage from '@jsv2/components/Authorization/AuthMessage';
import BaseMessageTemplate from '@jsv2/templates/vipTraveler/ModalMessages/BaseMessageTemplate';

/**
 * Pre Q Success Pop 3.
 */

const MESSAGE_CONTENT = {
  image: '/images/lp/promo2/vip_illustration-ok.svg',
  heading: 'Welcome to the Departure Lounge',
  tagline:
    'Thank you for joining VIP Traveler!  Due to the high volume of new Premium Members, there will be a slight ' +
    'delay in pairing you with your personal travel stylist. We’re working hard to get them online, and you won’t be ' +
    'charged until we do; plus, your Happiness Guarantee won’t begin until we have. We’ll update you on our progress ' +
    'as we continue to craft your personalized VIP Traveler experience. In the meantime, check out our featured ' +
    "offers to the destinations we're most excited about this month.",
  buttonText: 'VIEW VIP EXCLUSIVES™',
};

const SuccessPaidMessage = ({ step, getNextStep }) => (
  <AuthMessage
    step={step}
    getNextStep={getNextStep}
    render={({ handleClick }) => (
      <BaseMessageTemplate handleClick={handleClick} {...MESSAGE_CONTENT} />
    )}
  />
);

SuccessPaidMessage.propTypes = {
  step: PropTypes.shape({
    component: PropTypes.func.isRequired,
    onComplete: PropTypes.func.isRequired,
  }).isRequired,
  getNextStep: PropTypes.func.isRequired,
};

export default SuccessPaidMessage;
