import React from 'react';
import PropTypes from 'prop-types';

import ErrorBoundaryDecorator from '@components/decorators/ErrorBoundaryDecorator';
import Image from '@jsv2/components/Image';
import { IMAGE_SIZES } from '@js/constants';

const ArticleGrid = ({ articles }) => {
  const renderArticle = (item) => {
    const Component = item.image ? Image : 'div';
    const params = item.image
      ? {
        data: item.image,
        background: true,
        config: {
          size: IMAGE_SIZES.SMALL,
        },
      }
      : null;

    return (
      <a key={item.id} href={`/${item.url}`} className="articles__item">
        <Component className="articles__item-img" {...params}>
          <div className="articles__item-title-wrap">
            <div className="articles__item-title">
              <div className="articles__item-title-inner">{item.title}</div>
            </div>
          </div>
        </Component>
      </a>
    );
  };

  return <div className="articles">{articles.map((item) => renderArticle(item))}</div>;
};

ArticleGrid.propTypes = {
  articles: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default ErrorBoundaryDecorator()(ArticleGrid);
