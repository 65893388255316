import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import ErrorBoundaryDecorator from '@components/decorators/ErrorBoundaryDecorator';
import Slider from '@jsv2/components/Slider/Slider';
import DestinationTags from './DestinationTags';
import ImageGallery from './ImageGallery';

/**
 * Settings for slider.
 */
const destinationSliderSettings = {
  slidesPerView: 1,
  navigation: false,
  allowTouchMove: false,
};

/**
 * The number of tags that are displayed
 *
 * @type {number}
 */
const NUMBER_OF_TAGS = 3;

/**
 * The number of loaded slides.
 *
 * @type {number}
 */
const NUMBER_OF_LOADED_SLIDES = 15;

/**
 * Destinations slider.
 *
 * @param destinationsSlider
 * @param destinationsInActiveGroup
 * @param currentSlide
 * @param handlerSliderChange
 * @param setDestinationsSlider
 * @param activeFilterValue
 * @returns {JSX.Element}
 * @constructor
 */
const DestinationsSlider = ({
  destinationsInActiveGroup,
  currentSlide,
  handlerSliderChange,
  setDestinationsSlider,
  activeFilterValue,
}) => {
  const [numberOfVisibleSlides, setNumberOfVisibleSlides] = useState(currentSlide + 2);
  const [activeSlide, setActiveSlide] = useState(currentSlide);

  const onSlideChange = (activeIndex) => {
    setActiveSlide(activeIndex);
    handlerSliderChange(activeIndex);
  };

  // Set the default number of loaded slides after changing the filter.
  useEffect(() => {
    if (activeSlide !== currentSlide) {
      setNumberOfVisibleSlides(NUMBER_OF_LOADED_SLIDES);
    }
  }, [activeFilterValue]);

  useEffect(() => {
    // Load slides if the active slide is the last of the already loaded slides.
    if (activeSlide + 2 >= numberOfVisibleSlides) {
      setNumberOfVisibleSlides((prevState) => prevState + NUMBER_OF_LOADED_SLIDES);
    }
  }, [activeSlide, numberOfVisibleSlides]);

  return (
    <div className="main-slider__wrapper">
      <Slider
        sliderProps={{
          onSwiper: setDestinationsSlider,
          onSlideChange: ({ activeIndex }) => onSlideChange(activeIndex),
          initialSlide: currentSlide,
          ...destinationSliderSettings,
        }}
      >
        {destinationsInActiveGroup.slice(0, numberOfVisibleSlides).map((item) => (
          <div key={item.id} className="explore-popup__item item">
            <div className="item__heading">
              {item.images && <ImageGallery images={item.images} />}
            </div>

            <div className="item__wrapper">
              <div className="item__main-info">
                <div className="item__title" data-qa-id="item_title">
                  <div className="item__name" data-qa-id="item_name">{item.name}</div>

                  <div className="item__location" data-qa-id="item_location">{item.location && t(', ') + item.location}</div>
                </div>

                {item.blog_entry && (
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    className="item__link"
                    href={item.blog_entry.url}
                  >
                    {t('Learn more')}
                  </a>
                )}
              </div>

              {!!item.tags.length && (
                <DestinationTags items={item.tags} numberOfTags={NUMBER_OF_TAGS} />
              )}

              <div className="item__description" data-qa-id="item_description">
                <div
                  className="html-content"
                  dangerouslySetInnerHTML={{ __html: item.description }}
                />
              </div>
            </div>
          </div>
        ))}
      </Slider>
    </div>
  );
};

DestinationsSlider.propTypes = {
  destinationsInActiveGroup: PropTypes.arrayOf(PropTypes.object).isRequired,
  currentSlide: PropTypes.number,
  handlerSliderChange: PropTypes.func.isRequired,
  setDestinationsSlider: PropTypes.func.isRequired,
  activeFilterValue: PropTypes.object.isRequired,
};

DestinationsSlider.defaultProps = {
  currentSlide: 0,
};

export default ErrorBoundaryDecorator()(DestinationsSlider);
