import _ from 'lodash';

export const validateEmail = (value) => {
  let error;
  if (!value) {
    error = window.lang.get('form_messages.email.required');
  } else if (!value.match(/[^@]+@[^@]+\.[^@]+/)) {
    error = window.lang.get('form_messages.email.email');
  }
  return error;
};

export const validateConfirmEmail = (value, values) => {
  if (!value) {
    return window.lang.get('This field is required');
  }

  if (value !== values.email) {
    return window.lang.get('Emails do not match');
  }

  return '';
};

export const validateConfirmPassword = (value, values) => {
  if (!value) {
    return window.lang.get('This field is required');
  }

  if (value !== values.password) {
    return window.lang.get('Passwords do not match');
  }

  return '';
};

export const required = value => (
  (!value || (_.isArray(value) && !value.length)) ? t('This field is required') : ''
);

export const validateName = (name, fieldLabel) => {
  if (!name) {
    return t(`${fieldLabel} is required`);
  }

  /**
   * same regexp is used for frontend validation
   *
   * front/app/Rules/UserNameRule.php
   */
  if (!/^([^\W\d]+[-'. ]*)+$/u.test(name)) {
    return t(`${fieldLabel} is invalid`);
  }

  return '';
};
