import { createRoot } from 'react-dom/client';

import { WithErrorBoundaryDecorator } from '@components/decorators/ErrorBoundaryDecorator';

// react 18 version
export const renderPage = (Page) => {
  const root = createRoot(document.getElementById('root'));

  root.render(WithErrorBoundaryDecorator(Page));
};
