const BaseStepForm = ({ render }) => {
  const handleChange = (e, setFieldValue, setFieldError) => {
    setFieldError(e.currentTarget.name, null);
    setFieldValue(e.currentTarget.name, e.target.value);
  };

  return render({ handleChange });
};

export default BaseStepForm;
