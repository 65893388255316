import React, { Component } from 'react';

import forwardHoc from '@jsv3/HOC/ForwardHOC';
import { LOGGER_ERRORS } from '@jsv3/enums/errorMessagesEnums';
import ErrorLogContext from '@jsv3/context/ErrorLogContext';
import ErrorBoundariesComponent from './UI/ErrorBoundariesComponent';

/**
 * Component to catch an error
 * enableShowErrorMessage === true only for pages or templates when error occurred for whole page
 *
 * @param enableShowErrorMessage {boolean}
 *
 * @return {JSX.Element}
 */
const ErrorBoundaryDecorator = (enableShowErrorMessage = false) => (WrappedComponent) => {
  class DecoratedComponent extends Component {
    static contextType = ErrorLogContext;

    displayName = 'ErrorBoundaryDecorator';

    state = {
      hasError: null,
    };

    componentDidCatch(error, info) {
      const { errorInfo } = this.context;
      this.setState({ hasError: error });

      Logger.error(LOGGER_ERRORS.BOUNDARIES, { error, info, errorInfo });
    }

    render() {
      const { forwardedRef, ...props } = this.props;

      if (this.state.hasError) {
        return enableShowErrorMessage ? <ErrorBoundariesComponent /> : null;
      }

      return <WrappedComponent {...props} ref={forwardedRef} />;
    }
  }

  // Coz refs are not passed down with {...props},
  // we need to write some additional code to not brake refs with our decorator
  // see https://reactjs.org/docs/forwarding-refs.html
  return forwardHoc(WrappedComponent, DecoratedComponent, 'ErrorBoundaryDecorator');
};

export default ErrorBoundaryDecorator;
