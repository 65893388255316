import ScreenTypeContext, { isMobile } from '@js/context/ScreenTypeContext';
import DefaultImageResolver from '@utils/images/image-resolvers/DefaultImageResolver';
import { useContext } from 'react';

/**
 * This resolver is supposed to be used for Offer and Package images.
 */
class OfferImageResolver extends DefaultImageResolver {
  resolveSize() {
    const screenTypeContext = useContext(ScreenTypeContext);

    // for offer and package images on mobile devices
    if (isMobile(screenTypeContext)) {
      // this.config.size = '375';
    } else {
      super.resolveSize();
    }
  }
}

export default OfferImageResolver;
