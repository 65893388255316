import React from 'react';
import PropTypes from 'prop-types';
import { Swiper, SwiperSlide } from 'swiper/react/swiper-react';
import { Lazy, Pagination, Navigation, Grid, Autoplay } from 'swiper';

import { getProcessedSwiperSliderProps } from '@jsv3/utils/swiperSliderUtils';
import ErrorBoundaryDecorator from '@components/decorators/ErrorBoundaryDecorator';

const defaultSliderSettings = {
  // install Swiper modules
  modules: [Lazy, Pagination, Navigation, Grid, Autoplay],
  slidesPerView: 1,
  pagination: false,
  navigation: false,
  loop: false,
  noSwiping: false,
  allowTouchMove: true,
  observer: true,
  observeParents: true,
};

const Slider = ({ sliderProps, slideProps, isPreloader, children }) => {
  const swiperSliderProps = getProcessedSwiperSliderProps(
    {
      ...defaultSliderSettings,
      ...sliderProps,
    },
    children.length,
  );

  return (
    <Swiper {...swiperSliderProps}>
      {children.map((slide) => (
        <SwiperSlide key={`slide_${slide.key}`} {...slideProps}>
          {slide}

          {isPreloader && <div className="swiper-lazy-preloader swiper-lazy-preloader-black" />}
        </SwiperSlide>
      ))}
    </Swiper>
  );
};

Slider.propTypes = {
  sliderProps: PropTypes.object,
  slideProps: PropTypes.object,
  isPreloader: PropTypes.bool,
  children: PropTypes.node.isRequired,
};

Slider.defaultProps = {
  sliderProps: {},
  slideProps: {},
  isPreloader: false,
};

export default ErrorBoundaryDecorator()(Slider);
