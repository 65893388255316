import _ from 'lodash';
// eslint-disable-next-line no-unused-vars
import AbstractImageResolver from '@utils/images/image-resolvers/AbstractImageResolver';
import DefaultImageResolver from '@utils/images/image-resolvers/DefaultImageResolver';
import PageContentImageResolver from '@utils/images/image-resolvers/PageContentImageResolver';
import OfferImageResolver from '@utils/images/image-resolvers/OfferImageResolver';

/**
 * Resolver name (image type) => Resolver class
 */
const classMap = {
  Default: DefaultImageResolver,
  PageContent: PageContentImageResolver,
  Offer: OfferImageResolver,
};

class ImageResolverFactory {
  /**
   * @param {string} resolver
   * @param {Object|string|null} imageData
   * @param {Object} config
   *
   * @return {AbstractImageResolver}
   */
  static create(resolver, imageData, config = {}) {
    // resolver in pascal case
    const resolverName = _.upperFirst(_.camelCase(resolver));

    if (classMap[resolverName]) {
      return new classMap[resolverName](imageData, config);
    }

    throw new Error(
      `Invalid image resolver: ${resolver}.`
      + ' If you\'ve created a new image resolver class, '
      + 'don\'t forget to register it in the ImageResolverFactory.js file.'
    );
  }
}

export default ImageResolverFactory;
