import React, { useContext } from 'react';

import BlogSlider from '@js/components/carousels/BlogSlider';
import ErrorBoundaryDecorator from '@components/decorators/ErrorBoundaryDecorator';
import EditFieldContext, { Editable } from '@jsv2/LandingPage/context/EditFieldContext';

const { articles } = window;

const BlogSection = ({ id }) => {
  const { editableFields } = useContext(EditFieldContext);

  return (
    editableFields &&
    !!articles.length && (
      <section className="trending" id={id}>
        <div className="main-container trending__container">
          <div className="trending__title">
            <div className="section-title section-title--center section-title--with-underline">
              <Editable fieldValue={editableFields.blog_heading} />
            </div>
          </div>

          <BlogSlider className="promo3" articles={articles} />
        </div>
      </section>
    )
  );
};

export default ErrorBoundaryDecorator()(BlogSection);
