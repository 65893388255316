import React, { useContext } from 'react';
import PropTypes from 'prop-types';

import SignUpContext from '@jsv2/context/SignUpContext';
import OverlaySpinner from '@jsv3/components/molecules/OverlaySpinner';
import BaseStep from '@jsv2/components/Authorization/BaseStep';
import ProgressBar from '@jsv2/components/Authorization/ProgressBar';
import StepForm from '@jsv2/templates/vipTraveler/SignUp/Steps/BaseStepDefault/StepForm';
import BenefitsUpgrade from '../../StepSidebars/BenefitsUpgrade';

const BaseStepUpgrade = ({ step, getNextStep }) => {
  const { membershipSummary } = useContext(SignUpContext).signUpProcess;
  const { membershipPlanId } = membershipSummary;

  return (
    <BaseStep
      step={step}
      getNextStep={getNextStep}
      render={({ isLoading, serverError, handleSubmit, handleSocialSignUp }) => (
        <div className="viptraveler-modal">
          <BenefitsUpgrade />

          <div className="signup signup--step1">
            {isLoading && <OverlaySpinner isAbsolute />}

            <ProgressBar activeStep={1} handleSocialSignUp={handleSocialSignUp} signup />

            {serverError && <div className="label-content--error mt-20"> {serverError} </div>}

            <div className="summary" data-plan-id={membershipPlanId}>
              <StepForm handleSocialSignUp={handleSocialSignUp} handleSubmit={handleSubmit} />
            </div>
          </div>
        </div>
      )}
    />
  );
};

BaseStepUpgrade.propTypes = {
  step: PropTypes.shape({
    component: PropTypes.func.isRequired,
    onComplete: PropTypes.func.isRequired,
  }).isRequired,
  getNextStep: PropTypes.func.isRequired,
};

export default BaseStepUpgrade;
