import React, { useContext } from 'react';
import PropTypes from 'prop-types';

import BaseMessageTemplate from '@jsv2/templates/vipTraveler/ModalMessages/BaseMessageTemplate';
import AuthMessage from '@jsv2/components/Authorization/AuthMessage';
import UserContext from '@jsv2/context/UserContext';
import { hasTravelFinds } from '@jsv2/utils/UserUtils/userStatuses';

/**
 * Post Q Pop B (Paid).
 */

const MESSAGE_CONTENT = {
  image: '/images/lp/promo2/vip_illustration-ok.svg',
  heading: 'WELCOME TO THE DEPARTURE LOUNGE',
  tagline:
    'Thanks for completing our Travel Quiz! Due to the high volume of new VIP+ Members, there will ' +
    'be a slight delay in pairing you with your personal stylist. We’re working hard to get you matched up, but' +
    ' you won’t be charged until we do; plus, your Happiness Guarantee won’t begin until we have.' +
    ' We’ll update you on our progress as we continue to craft your personalized VIP Traveler experience.' +
    ' In the meantime, check out our Top Picks to the destinations we’re most excited about this month.',
};

const PaidThanksMessage = ({ step, getNextStep }) => {
  const { customer } = useContext(UserContext);

  const isTravelFindsAllowed = hasTravelFinds(customer.roles);

  const handleClick = () => {
    if (isTravelFindsAllowed) {
      window.location.href = '/travel-finds';
    }

    getNextStep(step.onComplete());
  };

  const buttonText = isTravelFindsAllowed ? 'VIEW MY TRAVEL FINDS™' : 'VIEW VIP EXCLUSIVES™';

  return (
    <AuthMessage
      step={step}
      getNextStep={getNextStep}
      render={() => (
        <BaseMessageTemplate
          handleClick={handleClick}
          buttonText={buttonText}
          {...MESSAGE_CONTENT}
        />
      )}
    />
  );
};

PaidThanksMessage.propTypes = {
  step: PropTypes.shape({
    component: PropTypes.func.isRequired,
    onComplete: PropTypes.func.isRequired,
  }).isRequired,
  getNextStep: PropTypes.func.isRequired,
};

export default PaidThanksMessage;
