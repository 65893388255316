import React, { useContext, useEffect, useState } from 'react';

import ROLES from '@jsv2/config/userRoles';
import TravelProfiler from '@jsv2/components/TravelProfiler/index';
import WizardContext from '@jsv2/context/WizardContext';
import UserContext from '@jsv2/context/UserContext';

const TravelProfilerAnonymous = ({ getNextStep }) => {
  const { onComplete } = useContext(WizardContext).stepConfig;
  const { customer, updateUserRoles, setQuizAnswers } = useContext(UserContext);
  const [saveAnswers, setSaveAnswers] = useState(false);

  useEffect(() => {
    if (customer.roles.includes(ROLES.ANONYMOUS)) {
      setSaveAnswers(true);
    }
  }, []);

  const handleClick = () => {
    getNextStep(onComplete(customer.roles));
    updateUserRoles();
  };

  const onNextQuestion = (data) => {
    setQuizAnswers((prevItems) => [...prevItems, ...data.answers]);
  };

  return (
    <TravelProfiler
      getNextStep={handleClick}
      onNextQuestion={onNextQuestion}
      saveAnswers={saveAnswers}
    />
  );
};

export default TravelProfilerAnonymous;
