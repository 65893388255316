import React from 'react';

import Price from '@components/Price';

const asteriskCode = 42;

const isRecurringChargeFree = (recurringCharge) => recurringCharge.current.amount === 0;

const showOriginalRecurringChargeFree = (recurringCharge) =>
  recurringCharge.initial &&
  recurringCharge.initial.amount &&
  recurringCharge.initial.amount !== recurringCharge.current.amount;

const membershipGuarantee = ({ moneybackPeriod, language = {} }) => (
  <li className="calc-item">
    <div className="calc-item__name">
      <div className="calc-item__control">
        <span className="calc-item__type">
          {language.moneyBackGuarantee || t('Happiness guarantee')}
        </span>
      </div>

      <span className="calc-item__desc">
        {t('Cancel for any reason during your first ')}
        <span data-qa-id="moneyback_guarantee_days">{moneybackPeriod.current}</span>
        {t(' days of membership and receive a full refund')}
      </span>
    </div>
  </li>
);

const membershipBonus = ({ signupBonus, language = {} }) => (
  <li className="calc-item mt-5">
    <div className="calc-item__name">
      <div className="calc-item__control">
        <span className="calc-item__type">{language.signupBonus || t('Signup Bonus')}</span>
      </div>

      <span className="calc-item__desc">
        {t('Get')}{' '}
        <Price
          inline
          price={signupBonus.current.amount}
          currency_iso3={signupBonus.current.currencyIso3}
          hideDecimalsForIntegers
          dataQaId="signup_bonus"
        />
        {' ' + t('in VIP Traveler Dollars once your membership is activated')}
      </span>
    </div>
  </li>
);

const membershipCreditIssuance = ({ recurringBonus, recurringInterval, language }) => (
  <li className="calc-item" data-qa-id="recurring_credit_issuance">
    <div className="calc-item__name">
      <div className="calc-item__title">
        {language.recurringCreditIssuance || (
          <>
            <Price
              inline
              price={recurringBonus.current.amount}
              currency_iso3={recurringBonus.current.currencyIso3}
              hideDecimalsForIntegers
              dataQaId="recurring_credit_issuance_value"
            />{' '}
            {t('VIP Traveler Dollars')}{' '}
            <span data-qa-id="recurring_credit_issuance_interval">{recurringInterval.current}</span>
          </>
        )}
      </div>
    </div>

    <div className="calc-item__price">
      <span className="calc-item__included">{t('INCLUDED')}</span>
    </div>
  </li>
);

const membershipTotal = ({ recurringCharge, isPromo, totalCharge }) => (
  <div className="total">
    <div className="total__title">
      {isRecurringChargeFree(recurringCharge) && isPromo ? "Today's Total" : 'Total'}
    </div>

    <div
      className={`
      total__price 
      ${isRecurringChargeFree(recurringCharge) && isPromo ? 'total__price--with-star' : ''}
      `}
    >
      <Price
        inline
        price={totalCharge.amount}
        currency_iso3={totalCharge.currencyIso3}
        hideDecimalsForIntegers
      />
    </div>
  </div>
);

/**
 * It is possible to render 2 rows that describe membership fee.
 * The first row is always exist. The second depends on presented data.
 */
const membershipFeeRows = ({
  isPromo,
  recurringInterval,
  recurringCharge,
  promoNote,
  language = {},
}) => (
  <li
    className={`
    calc-item 
    ${isRecurringChargeFree(recurringCharge) && isPromo ? 'calc-item--free' : ''}
    `}
  >
    <div className="calc-item__name">
      <div className="calc-item__title">{language.recurringCharge || t('Membership fee:')}</div>
    </div>

    {!isRecurringChargeFree(recurringCharge) &&
      recurringCharge.initial &&
      recurringCharge.initial.amount &&
      recurringCharge.initial.amount !== 0 && (
      <div
        className="calc-item__underline calc-item__italic calc-item__free-interval"
        data-qa-id="billing_interval"
      >
        {recurringInterval.current}
      </div>
    )}

    {showOriginalRecurringChargeFree(recurringCharge) && (
      <div className="calc-item__crossed-out">
        <span className="price__value" data-qa-id="original_recurring_charge">
          <Price
            inline
            price={recurringCharge.initial.amount}
            currency_iso3={recurringCharge.initial.currencyIso3}
            hideDecimalsForIntegers
          />
        </span>
      </div>
    )}

    <div className="calc-item__price">
      <span className="price__value" data-qa-id="recurring_charge">
        {isRecurringChargeFree(recurringCharge) ? (
          t('FREE')
        ) : (
          <>
            <Price
              inline
              price={recurringCharge.current.amount}
              currency_iso3={recurringCharge.current.currencyIso3}
              hideDecimalsForIntegers
            />

            {!!promoNote && (
              <span className="promo-footnote">{String.fromCharCode(asteriskCode)}</span>
            )}
          </>
        )}
      </span>
    </div>
  </li>
);

const initialFee = ({ signupFee, language = {} }) => (
  <li className="calc-item">
    <div className="calc-item__name">
      <div className="calc-item__title">{language.signupFee || t('Initial registration fee:')}</div>
    </div>

    {signupFee.initial && (
      <div className="calc-item__crossed-out" data-qa-id="signup_fee_crossed_out">
        <Price
          inline
          price={signupFee.initial.amount}
          currency_iso3={signupFee.initial.currencyIso3}
          hideDecimalsForIntegers
        />
      </div>
    )}

    <div className="calc-item__price">
      <div className="price__value">
        {!signupFee.current || signupFee.current.amount === 0 ? (
          <span data-qa-id="signup_fee">{t('FREE')}</span>
        ) : (
          <div data-qa-id="signup_fee">
            <Price
              inline
              price={signupFee.current.amount}
              currency_iso3={signupFee.current.currencyIso3}
              hideDecimalsForIntegers
            />
          </div>
        )}
      </div>
    </div>
  </li>
);

const footnote = ({ promoNote }) => (
  <div className="calc-item__desc footnote" data-qa-id="flow_footnote">
    <span className="promo-footnote">{String.fromCharCode(asteriskCode)}</span>

    {promoNote}
  </div>
);

export {
  membershipGuarantee,
  membershipBonus,
  membershipCreditIssuance,
  membershipTotal,
  membershipFeeRows,
  initialFee,
  footnote,
};
