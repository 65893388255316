import React from 'react';
import PropTypes from 'prop-types';

import ErrorBoundaryDecorator from '@components/decorators/ErrorBoundaryDecorator';

/**
 * Dynamic rating with render of decimal values.
 *
 * @param {number} rating
 * @param {string} color // 'pink' | 'grey'
 * @returns {JSX.Element}
 * @constructor
 */
const DynamicStarRating = ({ rating, color }) => {
  const width = rating * 20 + '%';

  return (
    <div className={`stars-rating stars-rating--${color}`}>
      <div className="stars-rating__star-value" style={{ width }} />
    </div>
  );
};

DynamicStarRating.propTypes = {
  rating: PropTypes.number,
  color: PropTypes.string,
};

DynamicStarRating.defaultProps = {
  rating: 0,
  color: 'grey',
};

export default ErrorBoundaryDecorator()(DynamicStarRating);
