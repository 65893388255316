/**
 * Get data-qa-id format.
 *
 * @param partClassName {string}
 * @param title {string}
 *
 * @return {string}
 */
const getDataQaId = (partClassName= '', title= '') => `${partClassName}_${title.toLowerCase().split(' ').join('_')}`;

export default getDataQaId;
