import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { setActiveMenuItemAction } from '@js/ducks/menuItems';

const linksData = {
  'vip exclusives': '/home',
  'top picks': '/home',
  'my travel finds': '/travel-finds',
  'my stylist': '/stylist',
  // 'luxury listings': '/hotel-search',
  'my bucket list': '/bucket-list',
  account: '/profile#/account-settings',
  'my bookings': '/profile#/my-trips',
  notifications: '/profile#/email-preferences',
  'credit balance': '/profile#/balance',
  payments: '/profile#/payments',
  'payment methods': '/profile#/payment-methods',
  dashboard: '/profile#/dashboard',
};

const arrowSVG = (
  <svg width="18" height="10" viewBox="0 0 18 10" xmlns="http://www.w3.org/2000/svg">
    <g fill="none" stroke="white" strokeWidth="2">
      <polyline points="1,0 6,5 1,10" />
      <polyline points="6,0 11,5 6,10" />
    </g>
  </svg>
);

const LinkItem = ({
  id,
  title,
  href,
  className,
  activeMenuItem,
  setActiveMenuItem,
  isSubItem,
  onMenuItemClick,
}) => {
  const currentItem = linksData[title.toLowerCase()];

  const onClickHandler = () => {
    setActiveMenuItem(currentItem);

    onMenuItemClick();
  };

  return (
    <a
      className={classNames('navlist__btn', className, {
        active: activeMenuItem === currentItem,
      })}
      href={href}
      data-qa-id={id}
      key={id}
      onClick={onClickHandler}
    >
      {isSubItem && arrowSVG}
      {title}
    </a>
  );
};

LinkItem.propTypes = {
  title: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  href: PropTypes.string.isRequired,
  className: PropTypes.string,
  isSubItem: PropTypes.bool,
  onMenuItemClick: PropTypes.func,
};

LinkItem.defaultProps = {
  isSubItem: false,
  onMenuItemClick: () => {},
};

const mapStateToProps = ({ menuItems: { activeMenuItem } }) => ({
  activeMenuItem,
});

const mapDispatchToProps = {
  setActiveMenuItem: setActiveMenuItemAction,
};

export default connect(mapStateToProps, mapDispatchToProps)(LinkItem);
