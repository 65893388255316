import * as cookies from 'browser-cookies';

const { userRoles } = window;

// to be filled
export const ROLES = {
  ANONYMOUS: 'Anonymous',
  PARTIALLY_IDENTIFIED: 'PartiallyIdentified',
  CANCELLED_MEMBER: 'Cancelled Member',
  ADMIN_CANCELLED: 'Canceled member by admin',
  SUBSCRIBER: 'Subscriber',
  VIEWED_OFFERS: 'ViewedOffers',
  INCOMPLETE_SIGN_UP: 'Incomplete sign up',
  FREE_MEMBER: 'Free Member',
  PAID_MEMBER: 'Paid Member',
  MISSING_PAYMENT_METHOD: 'Missing payment method',
  INCOMPLETE_PROFILER: 'Incomplete profiler',
};

export const eraseSessionCookie = () => cookies.erase('getaway_session');

export const isRegistered = () => !userRoles.includes(ROLES.ANONYMOUS);

export const isPartiallyIdentified = () => userRoles.includes(ROLES.PARTIALLY_IDENTIFIED);

export const isFreeMember = () => userRoles.includes(ROLES.FREE_MEMBER);

export const isPaidMember = () => userRoles.includes(ROLES.PAID_MEMBER);

export const isIncompleteSignUp = () => userRoles.includes(ROLES.INCOMPLETE_SIGN_UP);

export const isMissingPaymentMethod = () => userRoles.includes(ROLES.MISSING_PAYMENT_METHOD);

export const isIncompleteProfiler = () => userRoles.includes(ROLES.INCOMPLETE_PROFILER);
