const LAYOVER_IS_OPEN = 'LAYOVER/IS_OPEN';
const LAYOVER_IS_CLOSED = 'LAYOVER/IS_CLOSED';
const TOGGLE_LAYOVER = 'LAYOVER/TOGGLE'

const defaultState = {
  isOpen: false,
}

export default function reducer(state = defaultState, action) {
  switch(action.type) {
    case LAYOVER_IS_OPEN:
      return { isOpen: true };
    case LAYOVER_IS_CLOSED:
      return { isOpen: false };
    case TOGGLE_LAYOVER: {
      let isOpen = action.payload;
      if (typeof isOpen === 'undefined') {
        isOpen = !state.isOpen;
      }
      return { isOpen };
    }
    default:
      return state;
  }
}

export function toggleLayoverAsOpen() {
  return { type: LAYOVER_IS_OPEN };
}

export function toggleLayoverAsClosed() {
  return { type: LAYOVER_IS_CLOSED };
}

export function toggleLayover(isOpen) {
  return {
    type: TOGGLE_LAYOVER,
    payload: isOpen,
  };
}
