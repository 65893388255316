import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

const ButtonItem = ({ id, title, onClick, className, type }) => (
  <div className={classNames('navlist__item', className)}>
    <button
      className="navlist__btn"
      onClick={() => onClick(true)}
      data-qa-id={id}
      key={id}
      /* eslint-disable-next-line react/button-has-type */
      type={type}
    >
      {title}
    </button>
  </div>
);

ButtonItem.propTypes = {
  title: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  onClick: PropTypes.func,
  className: PropTypes.string,
  type: PropTypes.string,
};

ButtonItem.defaultProps = {
  className: '',
  onClick: () => {},
  type: 'button',
};

export default ButtonItem;
