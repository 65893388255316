import React from 'react';
import { connect } from 'react-redux';

import FooterLinks from './FooterLinks';
import FooterLogo from './FooterLogo';
import FooterCopyright from './FooterCopyright';
import FooterSocialLinks from './FooterSocialLinks';
import FooterPhone from './FooterPhone';
import TrustBadges from './TrustBadges';

/**
 * Get social links
 */
const { socialLinks } = window.brand;

const Footer = ({ links, show, phoneNumber }) => {
  if (!show) {
    return null;
  }

  return (
    <footer className="main-footer" id="footer" data-qa-id="main-footer">
      <div className="wrapper">
        <FooterLogo />

        <FooterLinks links={links} />

        <div className="main-footer__row">
          <FooterPhone phoneNumber={phoneNumber} />

          <FooterSocialLinks socialLinks={socialLinks} />
        </div>

        <TrustBadges />

        <FooterCopyright />
      </div>
    </footer>
  );
};

const mapStateToProps = (state) => ({
  show: state.footer.show,
  phoneNumber: state.footer.phoneNumber,
});

export default connect(mapStateToProps)(Footer);
