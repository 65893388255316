import React, { useContext } from 'react';

import Image from '@jsv2/components/Image';
import { IMAGE_SIZES } from '@js/constants';
import ErrorBoundaryDecorator from '@components/decorators/ErrorBoundaryDecorator';
import LPContext from '@jsv2/LandingPage/context/LPContext';
import EditFieldContext, {
  Editable,
  EditableImg,
} from '@jsv2/LandingPage/context/EditFieldContext';

const HowItWorks = React.forwardRef(({ id }, ref) => {
  const { editableFields, editableImageFields } = useContext(EditFieldContext);
  const { renderBecomeButton } = useContext(LPContext);

  const steps = [
    {
      id: 1,
      title: editableFields.step_1,
      image: editableImageFields.step_1_image,
    },
    {
      id: 2,
      title: editableFields.step_2,
      image: editableImageFields.step_2_image,
    },
    {
      id: 3,
      title: editableFields.step_3,
      image: editableImageFields.step_3_image,
    },
  ];

  return (
    <>
      {editableFields && (
        <section className="stages" ref={ref} style={{ backgroundColor: '#fff' }} id={id}>
          <div className="main-container">
            <h2 className="section-title section-title--center section-title--with-underline">
              <Editable fieldValue={editableFields.how_it_works_heading} />
            </h2>

            <div className="section-testimonial">
              <Editable fieldValue={editableFields.how_it_works_sub_heading} />
            </div>

            <div className="stages__wrapper">
              {steps.map((item) => (
                <div key={item.id} className="stages__item">
                  <div className="stages__heading">
                    <div className="stages__num">{item.id}</div>

                    <div className="stages__description">
                      <Editable fieldValue={item.title} />
                    </div>
                  </div>

                  <div className="stages__substrate">
                    {item.image && (
                      <EditableImg image={item.image} width={278} height={132}>
                        <Image
                          data={item.image && item.image.content}
                          type="PageContent"
                          config={{
                            size: IMAGE_SIZES.IMAGE_W350,
                          }}
                          background
                        />
                      </EditableImg>
                    )}
                  </div>
                </div>
              ))}
            </div>

            {renderBecomeButton(
              'become-btn become-btn--with-border',
              window.templateVariables.body_cta_text.content,
              'become-join-btn-4',
            )}
          </div>
        </section>
      )}
    </>
  );
});

export default ErrorBoundaryDecorator()(HowItWorks);
