import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

/**
 * Atom Skeleton Loading Item
 */
const SkeletonLoadingItem = ({ className, width, height, ...props }) => (
  <div
    className={classNames('skeleton-loading-item', className)}
    style={{ width: `${width}`, height: `${height}` }}
    {...props}
  />
);

SkeletonLoadingItem.propTypes = {
  className: PropTypes.string,
  width: PropTypes.string,
  height: PropTypes.string,
};

SkeletonLoadingItem.defaultProps = {
  className: '',
  width: '',
  height: '',
};

export default SkeletonLoadingItem;
