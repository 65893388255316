import React from 'react';
import { useGoogleOneTapLogin } from '@react-oauth/google';
import apiClient from '@jsv3/services/apiClient';
import { isAnonymous } from '@jsv2/utils/UserUtils/userStatuses';

const { userRoles } = window;

export const GoogleOneTap = () => {
  useGoogleOneTapLogin({
    onSuccess: async (credentialResponse) => {
      const { credential } = credentialResponse;

      const params = {
        'id-token': credential,
      };
      apiClient.post(`/login/google/token`, params).then(({ data }) => {
        if (data && data.logged_in) {
          window.location.href = data.redirect_uri;
        }
      });
    },
    onError: () => {},
    disabled: !isAnonymous(userRoles),
  });

  return null;
};
