window.browserFeatures = window.browserFeatures || {};
const { browserFeatures } = window;

export const hasWebP = new Promise((resolve) => {
  (() => {
    // If the browser doesn't has the method createImageBitmap, you can't display webp format
    if (!window.createImageBitmap) {
      browserFeatures.hasWebP = false;
      return;
    }

    const img = new Image();
    img.onload = () => {
      browserFeatures.hasWebP = img.width > 0 && img.height > 0;
      resolve(browserFeatures.hasWebP);
    };
    img.onerror = () => {
      browserFeatures.hasWebP = false;
      resolve(browserFeatures.hasWebP);
    };
    // lossless webp check
    img.src = 'data:image/webp;base64,UklGRhoAAABXRUJQVlA4TA0AAAAvAAAAEAcQERGIiP4HAA';
  })();
});

export const isTouchDevice = () =>
  'ontouchstart' in window || navigator.maxTouchPoints > 0 || navigator.msMaxTouchPoints > 0;
