import React, { useContext } from 'react';

import ErrorBoundaryDecorator from '@components/decorators/ErrorBoundaryDecorator';
import EditFieldContext, { Editable } from '@jsv2/LandingPage/context/EditFieldContext';
import ChoosePlan from '../components/ChoosePlan';

const ChoosePlanSection = ({ id }) => {
  const { editableFields } = useContext(EditFieldContext);

  return (
    <>
      {editableFields && (
        <section className="choose-plan" id={id} data-qa-id="become-section">
          <div className="main-container">
            <div className="section-title section-title--center">
              <Editable fieldValue={editableFields.choose_plan_heading} />
            </div>

            {editableFields.choose_plan_sub_testimonial && (
              <div className="section-description section-description--center">
                <Editable fieldValue={editableFields.choose_plan_sub_testimonial} />
              </div>
            )}

            <ChoosePlan />
          </div>
        </section>
      )}
    </>
  );
};

export default ErrorBoundaryDecorator()(ChoosePlanSection);
