import React, { useContext } from 'react';
import PropTypes from 'prop-types';

import AuthMessage from '@jsv2/components/Authorization/AuthMessage';
import BaseMessageTemplate from '@jsv2/templates/vipTraveler/ModalMessages/BaseMessageTemplate';
import SignUpContext from '@jsv2/context/SignUpContext';

const MESSAGE_CONTENT_1 = {
  image: '/images/lp/promo2/vip_illustration-ok.svg',
  heading: 'Welcome!',
  tagline:
    'FIRST PLAN. If you’re ready to move forward with a custom booking, click below to submit a deposit and give us some more information about your travel goals. If you’re still just exploring, feel free to take a look at some of the exclusive travel packages we currently offer.',
  buttonText: 'VIEW VIP EXCLUSIVES™',
};

const MESSAGE_CONTENT_2 = {
  image: '/images/lp/promo2/vip_illustration-ok.svg',
  heading: 'Welcome!',
  tagline:
    'SECOND PLAN. If you’re ready to move forward with a custom booking, click below to submit a deposit and give us some more information about your travel goals. If you’re still just exploring, feel free to take a look at some of the exclusive travel packages we currently offer.',
  buttonText: 'VIEW VIP EXCLUSIVES™',
};

const DepositSuccessMessage = ({ step, getNextStep }) => {
  const { signUpProcess } = useContext(SignUpContext);
  const content =
    signUpProcess.membershipSummary.name === 'Deposit' ? MESSAGE_CONTENT_1 : MESSAGE_CONTENT_2;

  return (
    <AuthMessage
      step={step}
      getNextStep={getNextStep}
      render={({ handleClick }) => <BaseMessageTemplate handleClick={handleClick} {...content} />}
    />
  );
};

DepositSuccessMessage.propTypes = {
  step: PropTypes.shape({
    component: PropTypes.func.isRequired,
    onComplete: PropTypes.func.isRequired,
  }).isRequired,
  getNextStep: PropTypes.func.isRequired,
};

export default DepositSuccessMessage;
