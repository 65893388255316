import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { ErrorMessage } from 'formik';

import ErrorBoundaryDecorator from '@components/decorators/ErrorBoundaryDecorator';

class LabelComponent extends Component {
  static propTypes = {
    name: PropTypes.string.isRequired,
    labelText: PropTypes.string.isRequired,
    errors: PropTypes.object,
    dataQaId: PropTypes.string,
  };

  static defaultProps = {
    errors: {},
    dataQaId: 'error',
  };

  renderLabel = () => <div className="label-content">{this.props.labelText}</div>;

  renderError = () => (
    <ErrorMessage
      name={this.props.name}
      component="div"
      className="label-content label-content--error"
      data-qa-id={this.props.dataQaId}
    />
  );

  render() {
    const { errors, name } = this.props;

    return <div className="label">{errors[name] ? this.renderError() : this.renderLabel()}</div>;
  }
}

export default ErrorBoundaryDecorator()(LabelComponent);
