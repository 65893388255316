import React from 'react';

const PlayButton = () => (
  <img
    src="/images/icons/vip-play-button.png"
    className="vip-play-button"
    alt="play"
  />
);

export default PlayButton;
