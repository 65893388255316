import React, { useContext } from 'react';
import PropTypes from 'prop-types';

import ErrorBoundaryDecorator from '@components/decorators/ErrorBoundaryDecorator';
import EditFieldContext, {
  Editable,
  EditableImg,
} from '@jsv2/LandingPage/context/EditFieldContext';
import Image from '@jsv2/components/Image';
import { IMAGE_SIZES } from '@js/constants';
import LPContext from '@jsv2/LandingPage/context/LPContext';
import { preparedAvailableItemsForRender } from '@jsv2/LandingPage/utils';

const TrustFactors = ({ id, showCTAButton }) => {
  const { editableFields, editableImageFields } = useContext(EditFieldContext);
  const { renderBecomeButton, isEditSectionMode } = useContext(LPContext);

  const items = [
    {
      id: 1,
      title: editableFields.trust_factors_title_1,
      content: editableFields.trust_factors_content_1,
      image: editableImageFields.trust_factors_image_1,
    },
    {
      id: 2,
      title: editableFields.trust_factors_title_2,
      content: editableFields.trust_factors_content_2,
      image: editableImageFields.trust_factors_image_2,
    },
    {
      id: 3,
      title: editableFields.trust_factors_title_3,
      content: editableFields.trust_factors_content_3,
      image: editableImageFields.trust_factors_image_3,
    },
  ];

  return (
    <section className="trust-factors" id={id}>
      <div className="main-container">
        <div className="section-title section-title--with-underline section-title--center">
          <Editable fieldValue={editableFields.trust_factors_heading} />
        </div>

        <div className="trust-factors__wrapper">
          {preparedAvailableItemsForRender(items, isEditSectionMode).map((item) => (
            <div key={item.id} className="trust-factors__item">
              <div className="trust-factors__image">
                {item.image && (
                  <EditableImg image={item.image} width={100} height={80}>
                    <Image
                      data={item.image && item.image.content}
                      config={{
                        size: IMAGE_SIZES.IMAGE_W100,
                      }}
                      type="PageContent"
                      background
                    />
                  </EditableImg>
                )}
              </div>

              <div className="trust-factors__title">
                <Editable fieldValue={item.title} />
              </div>

              <div className="common-text common-text--center">
                <Editable fieldValue={item.content} />
              </div>
            </div>
          ))}
        </div>

        {showCTAButton &&
          renderBecomeButton(
            'become-btn become-btn--with-border',
            window.templateVariables.body_cta_text.content,
            'become-join-btn',
          )}
      </div>
    </section>
  );
};

TrustFactors.propTypes = {
  id: PropTypes.string.isRequired,
  showCTAButton: PropTypes.bool,
};

TrustFactors.defaultProps = {
  showCTAButton: false,
};

export default ErrorBoundaryDecorator()(TrustFactors);
