const { urlsMap: locationToTitleMap } = window.__SERVER_DATA__;

/**
 * Listens for hash changes and updates title accordingly
 */
export default {
  init() {
    if (!locationToTitleMap || !Object.keys(locationToTitleMap).length) {
      // there is no dynamic routes on this page
      return;
    }

    this.onHashChange();
    window.addEventListener('hashchange', this.onHashChange);
  },

  onHashChange() {
    const newTitle = locationToTitleMap[window.location.hash] || locationToTitleMap.default;

    if (!newTitle) {
      Logger.error('There is no default title for location: ', window.location.href);
      return;
    }

    document.title = newTitle;
  },

  deatachListener() {
    window.removeEventListener('hashchange', this.onHashChange);
  },
};
