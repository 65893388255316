import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import PropTypes from 'prop-types';

import apiClient from '@js/apiClient';
import ErrorBoundaryDecorator from '@components/decorators/ErrorBoundaryDecorator';
import Button from '@jsv3/components/atoms/Button';
import _ from 'lodash';
import DropDownBlock from './DropDownBlock';

const SelectComponent = ({
  placeholder,
  addWishListItemByName,
  clearSearchInput,
  apiUrl,
  error,
  hideDefaultError,
  maxInputLength,
  buttonTitle,
  userDestinationsAllowed,
  showClearInputBtn,
  isSearching,
  allowedCustomOptions,
}) => {
  hideDefaultError();

  const searchInput = useRef(null);

  const [isLoading, setIsLoading] = useState(false);
  const [options, setOptions] = useState([]);
  const [isDropDownOpen, setIsDropDownOpen] = useState(false);
  const [inputValue, setInputValue] = useState('');
  const [addedOption, setAddedOption] = useState(false);

  const trimmedInput = inputValue.trim();

  /**
   * Send destination to wish list
   */
  const sendDestinationHandler = () => {
    if (!inputValue.trim().match(/[0-9a-zA-Z]{3,}/)) {
      return;
    }

    addWishListItemByName(addedOption.name, addedOption.ids || addedOption.id);

    if (!showClearInputBtn) {
      setInputValue('');
      setAddedOption(null);
    }
  };

  /**
   * Clear Input Value
   */
  const clearInput = () => {
    setInputValue('');
    setAddedOption(null);

    clearSearchInput(null);
  };

  /**
   * Drop Down Handler
   */
  const dropDownHandler = (val) => {
    if (val !== isDropDownOpen) {
      setIsDropDownOpen(val);
    }
  };

  /**
   * Create options if there is no complete match of destinations.
   *
   * @param mockedOptionId
   * @param filteredOptions
   *
   * @returns {[{name: string, id},...*]|*[]|*}
   */
  const createDefaultCombinedOptions = (mockedOptionId, filteredOptions) => {
    if (userDestinationsAllowed) {
      return [{ id: mockedOptionId, name: inputValue }, ...filteredOptions];
    }

    if (filteredOptions.length > 0) {
      return filteredOptions;
    }

    return [];
  };

  /**
   * Put mocked value from input to options if it's different from any option
   *
   * @param data {array}
   */
  const setCombinedOptions = (data) => {
    const mockedOptionId = -1;
    let combinedOptions = [];

    let filteredOptions = data.filter((option) => option.id !== mockedOptionId);

    const filteredCountries = _.flow([
      (list) => _.filter(list, (item) => {
        if (_.isUndefined(item.country)) {
          return false;
        }

        const country = item.country.toLowerCase();
        const value = inputValue.toLowerCase();

        return country.includes(value);
      }),
      (list) => _.groupBy(list, (item) => item.country),
      (list) => _.map(list, (group, name) => {
        const ids = _.map(group, (item) => item.id);

        return {
          id: name,
          name,
          ids
        }
      })
    ])(filteredOptions);

    filteredOptions = _.concat(filteredCountries, filteredOptions);

    const isResponseContainsInput = filteredOptions.some(
      (option) => option.name.toLowerCase() === inputValue.toLowerCase(),
    );

    if (allowedCustomOptions) {
      combinedOptions = isResponseContainsInput
        ? filteredOptions
        : createDefaultCombinedOptions(mockedOptionId, filteredOptions);
    } else {
      combinedOptions =
        filteredOptions.length > 0
          ? filteredOptions
          : createDefaultCombinedOptions(mockedOptionId, filteredOptions);
    }

    setOptions(combinedOptions);
  };

  /**
   * Watcher of input value + AJAX
   */
  useEffect(() => {
    if (!trimmedInput) {
      setOptions([]);
    }

    if (inputValue.length > 2) {
      const ourRequest = axios.CancelToken.source();

      setIsLoading(true);

      const fetchPost = async () => {
        try {
          const response = await apiClient.get(`${apiUrl}?query=${inputValue}`, {
            cancelToken: ourRequest.token,
          });

          setCombinedOptions(response.data.data);

          setIsLoading(false);
        } catch (errorData) {
          // eslint-disable-next-line no-console
          console.log(errorData);
          setIsLoading(false);
        }
      };

      fetchPost();

      return () => ourRequest.cancel();
    }

    return () => {};
  }, [inputValue]);

  /**
   * Option and input value watcher to control drop down
   */
  useEffect(() => {
    if (!addedOption && inputValue && options) {
      dropDownHandler(true);
    } else {
      dropDownHandler(false);
    }
  }, [inputValue, options]);

  /**
   * Change Input Handler
   *
   * @param e
   */
  const changeInputHandler = (e) => {
    e.preventDefault();

    setInputValue(e.target.value);
    setAddedOption(null);
  };

  /**
   * Adding option name in the input value
   *
   * @param option
   */
  const addOptionInInput = (option) => {
    const optionTemplate = `${option.name}${option.location ? `, ${option.location}` : ''}`;
    setInputValue(optionTemplate);
    setAddedOption(option);

    dropDownHandler(false);
  };

  return (
    <>
      <div className="destinations-search-box">
        <div className="input-drop-down-container">
          <div className="input-component">
            <input
              ref={searchInput}
              type="text"
              placeholder={placeholder}
              onChange={(e) => changeInputHandler(e)}
              value={inputValue}
              maxLength={maxInputLength}
              data-qa-id="search_input"
            />

            {showClearInputBtn && inputValue && (
              <Button className="clear-btn btn-close btn-close--black" onClick={clearInput} />
            )}

            {isLoading && <div className="loader" />}
          </div>

          <DropDownBlock
            options={options}
            addOptionInInput={addOptionInInput}
            isDropDownOpen={isDropDownOpen}
          />
        </div>

        <div className="button-component">
          <button
            type="button"
            className="btn-add"
            onClick={sendDestinationHandler}
            disabled={!addedOption || isSearching || isLoading}
            data-qa-id="add_btn"
          >
            {buttonTitle}
          </button>
        </div>
      </div>

      {error && <div className="error-message mt-25">{error}</div>}
    </>
  );
};

SelectComponent.propTypes = {
  placeholder: PropTypes.string,
  addWishListItemByName: PropTypes.func.isRequired,
  clearSearchInput: PropTypes.func.isRequired,
  apiUrl: PropTypes.string,
  error: PropTypes.string,
  hideDefaultError: PropTypes.func,
  maxInputLength: PropTypes.number,
  buttonTitle: PropTypes.string,
  userDestinationsAllowed: PropTypes.bool,
  showClearInputBtn: PropTypes.bool,
  isSearching: PropTypes.bool,
  allowedCustomOptions: PropTypes.bool,
};

SelectComponent.defaultProps = {
  placeholder: 'Search a destination',
  apiUrl: 'user/travel-preference/undecided',
  error: null,
  hideDefaultError: () => {},
  maxInputLength: -1,
  buttonTitle: 'Add',
  userDestinationsAllowed: true,
  showClearInputBtn: false,
  isSearching: false,
  allowedCustomOptions: true,
};

export default ErrorBoundaryDecorator()(SelectComponent);
