import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import ErrorBoundaryDecorator from '@components/decorators/ErrorBoundaryDecorator';
import apiClient from '@js/apiClient';
import Questionnaire from '@js/components/Questionnaire';
import template from '@js/landing/vipTravelerPromo2/auth/QuestionnaireTemplate';
import Spinner from '@components/Spinner';

/**
 * @param className
 * @param questions
 * @param getNextStep
 * @param onNextQuestion
 * @param saveAnswers
 * @param props
 * @return {JSX.Element}
 * @constructor
 */
const TravelProfiler = ({
  className = 'quiz-promo-2',
  questions,
  getNextStep,
  onNextQuestion,
  saveAnswers,
  ...props
}) => {
  const [quizQuestions, getQuestions] = useState(questions);
  const { pageId, questionnaireFilters } = window;

  useEffect(() => {
    if (!quizQuestions.length) {
      apiClient
        .get('/user/survey-question-list', { params: { pageId, ...questionnaireFilters } })
        .then((response) => {
          const { surveyQuestions } = response.data;

          if (!surveyQuestions.length) {
            getNextStep();
          }

          getQuestions(surveyQuestions);
        });
    }
  }, [quizQuestions, getNextStep]);

  return (
    <div className={className}>
      {!quizQuestions.length ? (
        <Spinner />
      ) : (
        <Questionnaire
          template={template}
          questions={quizQuestions}
          handleClickFinish={getNextStep}
          onNextQuestion={onNextQuestion}
          saveAnswers={saveAnswers}
          successMessageTitle="Thank you!"
          successMessageContent="As a VIP service, we strive to provide individual, personalized experience to each
           client. Your answers will help us fulfill your travel dreams in the best way possible."
          {...props}
        />
      )}
    </div>
  );
};

TravelProfiler.propTypes = {
  questions: PropTypes.arrayOf(PropTypes.object),
  saveAnswers: PropTypes.bool,
};

TravelProfiler.defaultProps = {
  questions: [],
  saveAnswers: false,
};

export default ErrorBoundaryDecorator()(TravelProfiler);
