/**
 * Checking if the element is renderable.
 * If the content isn't filled and the "life mode" is turned on - element cannot be displayed.
 *
 * @param {object} data
 * @param {boolean} isEditMode
 * @param {array} optionalFields
 *
 * @return {boolean}
 */
export const isRenderContentAvailable = (data, isEditMode, optionalFields) => {
  // checking for empty content
  const isValueContentEmpty = (content) => {
    let isHtmlStringEmpty = false;

    if (typeof content === 'string') {
      // checking if the content is empty without tags
      const plainText = content.replace(/(<([^>]+)>)/gi, '');

      isHtmlStringEmpty = plainText.trim().length === 0;
    }

    return isHtmlStringEmpty || content === null || content === undefined || Number.isNaN(content);
  };

  const isEmptyValues = (element) => {
    let hasFilledValues = true;

    Object.keys(element).forEach((valueKey) => {
      const isFieldOptional = optionalFields.findIndex((field) => field === valueKey);

      // if the field is not optional check if this field is filled with content
      if (isFieldOptional === -1) {
        // if the value is an array, then we check all nested elements
        if (Array.isArray(element[valueKey])) {
          let isEmpty = true;

          element[valueKey].forEach((item) => {
            isEmpty = isEmpty && isEmptyValues(item);
          });

          hasFilledValues = hasFilledValues && !isEmpty;
        } else if (typeof element[valueKey] === 'object') {
          hasFilledValues = hasFilledValues && !isValueContentEmpty(element[valueKey].content);
        }
      }
    });

    return !hasFilledValues;
  };

  return !(isEmptyValues(data) && !isEditMode);
};

/**
 * Prepared available elements for render.
 *
 * @param {array} items
 * @param {boolean} isEditMode
 * @param {array} optionalFields
 * @return {*}
 */
export const preparedAvailableItemsForRender = (items, isEditMode, optionalFields = []) =>
  items.filter((item) => isRenderContentAvailable(item, isEditMode, optionalFields));
