import React from 'react';
import PropTypes from 'prop-types';

import ErrorBoundaryDecorator from '@components/decorators/ErrorBoundaryDecorator';

/**
 * Render phone in footer.
 *
 * @param phoneNumber
 * @returns {*}
 * @constructor
 */
const FooterPhone = ({ phoneNumber }) => (
  <div className="main-footer__phone">
    {phoneNumber && (
      <a className="navlist__btn" href={'tel:' + phoneNumber.replace(/\s/g, '')}>
        <img src="/images/icons/phone-white.svg" alt="" />

        {phoneNumber}
      </a>
    )}
  </div>
);

FooterPhone.propTypes = {
  phoneNumber: PropTypes.string,
};

FooterPhone.defaultProps = {
  phoneNumber: null,
};

export default ErrorBoundaryDecorator()(FooterPhone);
