import React, { Component } from 'react';

import { ERRORS, LOGGER_ERRORS } from '@js/messages';
import forwardHoc from '@utils/forwardHoc';

const ErrorBoundariesComponent = () => (
  <div className="error-boundaries">{ERRORS.INFORMATION_NOT_DISPLAYED}</div>
);

// withMessage = true only for pages
const ErrorBoundaryDecorator = (withMessage = false) => (WrappedComponent) => {
  class DecoratedComponent extends Component {
    displayName = 'ErrorBoundaryDecorator';

    state = {
      hasError: null,
    };

    componentDidCatch(error, info) {
      this.setState({ hasError: error });

      Logger.error(LOGGER_ERRORS.BOUNDARIES, { error, info });
    }

    render() {
      const { forwardedRef, ...props } = this.props;

      if (this.state.hasError) {
        return withMessage ? <ErrorBoundariesComponent /> : null;
      }

      return <WrappedComponent {...props} ref={forwardedRef} />;
    }
  }

  // Coz refs are not passed down with {...props},
  // we need to write some additional code to not brake refs with our decorator
  // see https://reactjs.org/docs/forwarding-refs.html
  return forwardHoc(WrappedComponent, DecoratedComponent, 'ErrorBoundaryDecorator');
};

export const WithErrorBoundaryDecorator = (WrappedComponent) => {
  const DecoratedComponent = ErrorBoundaryDecorator(true)(WrappedComponent);

  return <DecoratedComponent />;
};

export default ErrorBoundaryDecorator;
