import React from 'react';
import ReactModal from 'react-modal';

import { ModalPropTypes, ModalDefaultProps } from '@jsv3/propTypes/ModalProps';
import processEmptyProp from '@jsv3/utils/helpers/processEmptyPropHelper';
import ErrorBoundaryDecorator from '@jsv3/components/organisms/ErrorBoundaryDecorator';
import Button from '@jsv3/components/atoms/Button';
import { generateModalSettings } from './config';

ReactModal.setAppElement('body');

/**
 * Main Modal
 */
const Modal = ({
  className,
  bodyClassName,
  withCloseButton,
  size,
  onRequestClose,
  dataQaModalType,
  dataQaCloseButton,
  dataQaModalId,
  children,
  ...props
}) => (
  // TODO: copy styles
  <ReactModal {...props} {...generateModalSettings(className)}>
    <div
      className="layover-wrapper"
      {...processEmptyProp('data-modal-type', dataQaModalType)}
      {...processEmptyProp('data-qa-id', dataQaModalId)}
    >
      {withCloseButton && (
        <Button
          className="btn-close btn-close--black"
          onClick={onRequestClose}
          dataQaId={dataQaCloseButton}
        />
      )}

      <div className={`modal-popup modal-${size}`}>
        <div className={`modal-content ${bodyClassName}`}>{children}</div>
      </div>
    </div>
  </ReactModal>
);

Modal.propTypes = ModalPropTypes;
Modal.defaultProps = ModalDefaultProps;

export default ErrorBoundaryDecorator()(Modal);
