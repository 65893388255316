import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect, Field } from 'formik';

import ErrorBoundaryDecorator from '@components/decorators/ErrorBoundaryDecorator';

/**
 * Enhanced component for formik/Field
 */
class CustomField extends PureComponent {
  static propTypes = {
    name: PropTypes.string.isRequired,
    dataQaId: PropTypes.string,
  };
  static defaultProps = {
    dataQaId: 'input_field',
  }

  render() {
    const { name, dataQaId, ...rest } = this.props;

    return <Field name={name} data-qa-id={dataQaId} {...rest} />;
  }
}

export default ErrorBoundaryDecorator()(connect(CustomField));
