import React, { useContext } from 'react';

import ErrorBoundaryDecorator from '@components/decorators/ErrorBoundaryDecorator';
import EditFieldContext, { Editable } from '@jsv2/LandingPage/context/EditFieldContext';
import RedesignChoosePlan from '../components/RedesignChoosePlan';

const ChoosePlanSection = ({ className }) => {
  const { editableFields } = useContext(EditFieldContext);

  return (
    <>
      {editableFields && (
        <section
          className={`choose-plan choose-plan--redesign gray-section ${className}`}
          id="choosePlanSection"
          data-qa-id="become-section"
        >
          <div className="main-container">
            <div className="section-title section-title--with-underline section-title--center">
              <Editable fieldValue={editableFields.choose_plan_heading} />
            </div>

            {editableFields.choose_plan_sub_testimonial && (
              <div className="section-description section-description--font-weigth-400 section-description--center">
                <Editable fieldValue={editableFields.choose_plan_sub_testimonial} />
              </div>
            )}

            <RedesignChoosePlan />
          </div>
        </section>
      )}
    </>
  );
};

export default ErrorBoundaryDecorator()(ChoosePlanSection);
