import STEPS from '@jsv2/config/SignUp/steps';
import ROLES from '@jsv2/config/userRoles';
import URLS from '@jsv2/config/SignUp/urls';
import TRAVEL_PROFILER from '@jsv2/templates/vipTraveler/SignUp/Steps/TravelProfilerAnonymous';
import BASE_STEP_DEFAULT from '@jsv2/templates/vipTraveler/SignUp/Steps/BaseStepDefault';
import CHOOSE_PLAN_STEP from '@jsv2/templates/vipTraveler/SignUp/Steps/DepositChoosePlanStep';
import WELCOME_MESSAGE from '@jsv2/templates/vipTraveler/SignUp/Messages/DepositFlowSuccessMessage';
import PAYMENT_STEP_DEFAULT from '@jsv2/templates/vipTraveler/SignUp/Steps/PaymentStepDefault';

const {
  shared_data: { defaultHomePageUrl },
} = window.__SERVER_DATA__;

const newDepositFlowConfig = {
  [STEPS.TRAVEL_PROFILER]: {
    name: STEPS.TRAVEL_PROFILER,
    component: TRAVEL_PROFILER,
    onComplete: (roles) => {
      if (roles.includes(ROLES.ANONYMOUS)) {
        return STEPS.BASE_STEP_DEFAULT;
      }

      return STEPS.CHOOSE_PLAN_STEP;
    },
    isClosable: false,
    style: 'travel-profiler',
    url: URLS[STEPS.TRAVEL_PROFILER],
    roles: {},
  },
  [STEPS.BASE_STEP_DEFAULT]: {
    name: STEPS.BASE_STEP_DEFAULT,
    component: BASE_STEP_DEFAULT,
    onComplete: () => STEPS.CHOOSE_PLAN_STEP,
    isClosable: false,
    hideBenefits: true,
    style: 'closable-sign-up-step',
    url: URLS[STEPS.BASE_STEP_DEFAULT],
    roles: {
      [ROLES.ANONYMOUS]: true,
    },
  },
  [STEPS.CHOOSE_PLAN_STEP]: {
    name: STEPS.CHOOSE_PLAN_STEP,
    component: CHOOSE_PLAN_STEP,
    onComplete: () => STEPS.PAYMENT_STEP_DEFAULT,
    isClosable: false,
    style: 'closable-sign-up-step',
    url: URLS[STEPS.CHOOSE_PLAN_STEP],
    roles: {
      [ROLES.INCOMPLETE_SIGN_UP]: true,
      [ROLES.MISSING_PAYMENT_METHOD]: true,
    },
  },
  [STEPS.PAYMENT_STEP_DEFAULT]: {
    name: STEPS.PAYMENT_STEP_DEFAULT,
    component: PAYMENT_STEP_DEFAULT,
    onComplete: () => STEPS.PAID_WELCOME_MESSAGE,
    isClosable: false,
    prevStep: STEPS.CHOOSE_PLAN_STEP,
    style: 'closable-sign-up-step',
    url: URLS[STEPS.PAYMENT_STEP_DEFAULT],
  },
  [STEPS.PAID_WELCOME_MESSAGE]: {
    name: STEPS.PAID_WELCOME_MESSAGE,
    component: WELCOME_MESSAGE,
    onComplete: () => (window.location.href = defaultHomePageUrl),
    isClosable: false,
    url: URLS[STEPS.PAID_WELCOME_MESSAGE],
    roles: {
      [ROLES.WAIT_LIST_MEMBER]: true,
    },
  },
};

export default newDepositFlowConfig;
