import React from 'react';

import ErrorBoundaryDecorator from '@components/decorators/ErrorBoundaryDecorator';

/**
 * Render footer copyright.
 *
 * @return {*}
 *
 * @constructor
 */
const FooterCopyright = () => {
  /**
   * Get the current year
   */
  const getYear = () => new Date().getFullYear();

  return (
    <div className="main-footer__copyright">
      Copyright &copy; {getYear()} Better Travel Co dba VIP Traveler.{' '}
      <div>All rights reserved.</div>
    </div>
  );
};

export default ErrorBoundaryDecorator()(FooterCopyright);
