import React, { useState, useEffect } from 'react';
import ReactQuill, { Quill } from 'react-quill';
import 'react-quill/dist/quill.snow.css';

import ErrorBoundaryDecorator from '@components/decorators/ErrorBoundaryDecorator';

const Size = Quill.import('formats/size');
const Font = Quill.import('formats/font');

const fontSizes = ['15', '16', '20', '24', '28', '35', '50', '55', '60', '65'];
const fontFamilies = ['FuturaPT', 'Calluna'];

Size.whitelist = fontSizes;
Font.whitelist = fontFamilies;

Quill.register(Size, true);
Quill.register(Font, true);

const StyleTextEditor = ({
  fieldValue,
  onHandleFieldChange,
  needToCreateEditorState,
  setNeedToCreateEditorState,
}) => {
  const [value, setValue] = useState(fieldValue.content);

  const onEditorStateChange = (content) => {
    setValue(content);
    onHandleFieldChange(content);
  };

  useEffect(() => {
    setValue(fieldValue.content);
  }, [fieldValue.id]);

  useEffect(() => {
    if (needToCreateEditorState) {
      setNeedToCreateEditorState(false);
      setValue(fieldValue.content);
    }
  }, [needToCreateEditorState]);

  return (
    <ReactQuill
      theme="snow"
      className="style-text-editor-wrapper"
      modules={{
        toolbar: {
          container: [
            ['bold', 'italic', 'underline'],
            [{ size: fontSizes }],
            [{ font: fontFamilies }],
            [{ align: [] }],
            ['link'],
          ],
        },
        clipboard: { matchVisual: false },
      }}
      formats={['bold', 'italic', 'underline', 'size', 'font', 'align', 'link']}
      value={value}
      placeholder="(not set)"
      onChange={onEditorStateChange}
    />
  );
};

export default ErrorBoundaryDecorator()(StyleTextEditor);
