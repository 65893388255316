import React from 'react';

import BaseStep from '@jsv2/components/Authorization/BaseStep';
import Spinner from '@components/Spinner';
import StepForm from './StepForm';

const BaseStepProfiler = ({ step, getNextStep }) => (
  <BaseStep
    step={step}
    getNextStep={getNextStep}
    render={({ isLoading, serverError, handleSubmit }) => (
      <div className="quiz-promo-2">
        <div className="quiz-wrapper quiz-wrapper--text">
          <div className="layover-content">

            {isLoading && <Spinner />}

            <div
              hidden={isLoading}
              className="quiz-content"
            >
              <StepForm
                handleSubmit={handleSubmit}
                serverError={serverError}
              />
            </div>
          </div>
        </div>
      </div>
    )}
  />
);

export default BaseStepProfiler;
