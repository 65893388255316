import _ from 'lodash';
import axios from 'axios';

import apiClient from '@js/apiClient';
import { alert } from '@utils/Dialogs';
import locationReload from '@utils/locationReload';

/**
 * This class detects user activity and
 * updates timestamps in the `carts` and `users` tables (`last_activity_date` column)
 */
class ActivityDaemon {
  pingingEnabled = true;
  inactivityTimeThresholdMinutes = null;
  pingIntervalSeconds = null;

  activityEvents = ['mousedown', 'mousemove', 'keypress', 'scroll', 'touchstart'];

  requestsInProgress = {
    ping: false,
    restoreAbandonedCart: false,
  };

  lastPingRequestTime = Date.now();
  inactivityTimer = null;
  isCartAbandoned = false;

  constructor() {
    this.inactivityTimeThresholdMinutes =
      parseInt(window.__SERVER_DATA__.inactivityTimeThresholdMinutes, 10) || 0;
    this.pingIntervalSeconds = parseInt(window.__SERVER_DATA__.pingIntervalSeconds, 10) || 0;
  }

  /**
   * Run all timer and activity checking processes.
   */
  run() {
    if (!this.inactivityTimeThresholdMinutes || !this.pingIntervalSeconds) {
      return;
    }

    const callback = _.throttle(() => this.onActivity(), 3000);

    this.activityEvents.forEach((eventName) => {
      document.addEventListener(eventName, callback, true);
    });
  }

  /**
   * Called each time user does some activity on the page.
   */
  onActivity() {
    this.resetInactivityTimer();

    if (this.isCartAbandoned) {
      // restore abandoned cart
      this.sendRestoreAbandonedCartRequest();
    } else if (this.shouldSendPingRequest()) {
      // ping the server, update last action time for the current cart
      this.sendPingRequest();
    }
  }

  /**
   * Run inactivity timer again.
   */
  resetInactivityTimer() {
    clearTimeout(this.inactivityTimer);

    this.inactivityTimer = setTimeout(() => {
      this.isCartAbandoned = true;
    }, this.inactivityTimeThresholdMinutes * 60 * 1000);
  }

  /**
   * @return {boolean}
   */
  shouldSendPingRequest() {
    return (
      this.pingingEnabled &&
      !this.isCartAbandoned &&
      !this.requestsInProgress.ping &&
      this.getSecondsFromLastPingRequest() >= this.pingIntervalSeconds
    );
  }

  /**
   * @return {number}
   */
  getSecondsFromLastPingRequest() {
    return (Date.now() - this.lastPingRequestTime) / 1000;
  }

  /**
   * Refresh last_action_date on the current cart.
   */
  sendPingRequest() {
    this.requestsInProgress.ping = true;

    apiClient
      .put('/cart/ping')
      .then(({ data }) => {
        this.lastPingRequestTime = data.timestamp * 1000;
        this.isCartAbandoned = !!data.abandoned;

        if (this.isCartAbandoned) {
          // restore abandoned cart
          this.sendRestoreAbandonedCartRequest();
        }
      })
      .catch((error) => {
        // unauthorized or CSRF token expired
        if (error.response.status === 401 || error.response.status === 419) {
          this.pingingEnabled = false;
          window.location.reload();
        } else {
          // send request to refresh cart last action date
          axios.get('/');
        }
      })
      .then(() => (this.requestsInProgress.ping = false));
  }

  /**
   * Try to restore abandoned cart.
   */
  sendRestoreAbandonedCartRequest() {
    if (window.requestsInProgress && window.requestsInProgress.restoreAbandonedCart) {
      return;
    }

    window.requestsInProgress = window.requestsInProgress || {};
    window.requestsInProgress.restoreAbandonedCart = true;

    apiClient
      .put('/cart/restore')
      .then(({ data }) => {
        if (data.message) {
          alert(data.message, {
            closeOnOverlayClick: false,
            onClose: () => {
              if (!data.cart) {
                locationReload();
              }
            },
          });
        }

        if (data.success) {
          this.isCartAbandoned = false;
        }
      })
      .catch(() => {})
      .then(() => (window.requestsInProgress.restoreAbandonedCart = false));
  }
}

export default ActivityDaemon;
