import React, { useContext } from 'react';

import ProgressBar from '@jsv2/components/Authorization/ProgressBar';
import SignUpContext from '@jsv2/context/SignUpContext';
import Price from '@components/Price';

const BenefitsUpgrade = () => {
  const { baseStepSidebarTagline, membershipSummary } = useContext(SignUpContext).signUpProcess;

  const { signupBonus, benefits } = membershipSummary;

  return (
    <div className="sidebar sidebar--step1 membership--premium sidebar--benefits">
      <ProgressBar activeStep={1} />

      <div className="wrapper benefits-wrapper">
        <img src="/images/lp/promo6/1.png" alt="" className="benefits-wrapper__image" />

        <h2 className="heading">{t('join us!')}</h2>

        <p className="tagline">{baseStepSidebarTagline}</p>

        {benefits.current && benefits.current.length > 0 && (
          <div className="benefits">
            <p className="benefits__heading">{t('You get:')}</p>

            <ul className="benefits__list inclusions__list list" data-qa-id="benefit_list">
              {benefits.current.map(({ id, description, name }) => (
                <li key={id} className="list__item" data-qa-id="benefit_list_item">
                  {name && <strong>{name}</strong>} {description}
                </li>
              ))}

              {signupBonus.current && (
                <li className="list__item card" data-qa-id="membership-credit-issuance">
                  Plus{' '}
                  <Price
                    inline
                    price={signupBonus.current.amount}
                    currency_iso3={signupBonus.current.currencyIso3}
                    hideDecimalsForIntegers
                    dataQaId="credit_value"
                  />{' '}
                  VIP Traveler Dollars to get you started
                </li>
              )}
            </ul>
          </div>
        )}
      </div>
    </div>
  );
};

export default BenefitsUpgrade;
