import React, { useContext } from 'react';
import PropTypes from 'prop-types';

import OverlaySpinner from '@jsv3/components/molecules/OverlaySpinner';
import AddressStep from '@jsv2/components/Authorization/AddressStep';
import ProgressBar from '@jsv2/components/Authorization/ProgressBar';
import SignUpContext from '@jsv2/context/SignUpContext';
import StepForm from './StepForm';
import AddressSideBar from '../../StepSidebars/AddressSideBar';

const AddressStepDefault = ({ step, getNextStep }) => {
  const { membershipSummary } = useContext(SignUpContext).signUpProcess;
  const { membershipPlanId } = membershipSummary;

  return (
    <AddressStep
      step={step}
      getNextStep={getNextStep}
      render={({ isLoading, serverError, handleSubmit }) => (
        <div className="viptraveler-modal">
          <AddressSideBar />

          <div className="signup signup--step2">
            {isLoading && <OverlaySpinner isAbsolute />}

            <ProgressBar activeStep={2} />

            {serverError && <div className="label-content--error mt-20"> {serverError} </div>}

            <div className="summary" data-plan-id={membershipPlanId}>
              <StepForm handleSubmit={handleSubmit} />
            </div>
          </div>
        </div>
      )}
    />
  );
};

AddressStepDefault.propTypes = {
  step: PropTypes.shape({
    component: PropTypes.func.isRequired,
    onComplete: PropTypes.func.isRequired,
  }).isRequired,
  getNextStep: PropTypes.func.isRequired,
};

export default AddressStepDefault;
