import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import SavedPaymentMethodShape from '@js/propTypes/SavedPaymentMethod';
import CardIcon from '@js/components/checkout/SecureCheckout/CardIcon';
import ErrorBoundaryDecorator from '@components/decorators/ErrorBoundaryDecorator';

const SavedPaymentMethods = ({ savedMethods, selectedMethodId, onPaymentMethodChange }) => {
  if (!savedMethods || !savedMethods.length) {
    return null;
  }

  return (
    <>
      <div className="saved-payment-methods">
        <div className="saved-methods-container" data-qa-id="saved_payment_container">
          {savedMethods.map((method) => (
            // eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions
            <div
              key={method.payment_method_id}
              className="method"
              onClick={() => onPaymentMethodChange({ ...method })}
              data-qa-id="saved_method"
            >
              <div
                className={classNames('radio', {
                  active: method.payment_method_id === selectedMethodId,
                })}
              />

              <CardIcon imageName={method.card_brand_code} />

              <span className="card-name" data-qa-id="saved_method_data">
                {method.card_brand_name}
                {method.card_brand_name && ' '}
                {t('card ending in')} {method.card_last4}
              </span>
            </div>
          ))}
        </div>
      </div>

      <div className="divider" />
    </>
  );
};

SavedPaymentMethods.propTypes = {
  savedMethods: PropTypes.arrayOf(SavedPaymentMethodShape).isRequired,
  onPaymentMethodChange: PropTypes.func.isRequired,
  selectedMethodId: PropTypes.number,
};

SavedPaymentMethods.defaultProps = {
  selectedMethodId: undefined,
};

export default ErrorBoundaryDecorator()(SavedPaymentMethods);
