import PropTypes from 'prop-types';

export const ModalPropTypes = {
  isOpen: PropTypes.bool.isRequired,
  className: PropTypes.string,
  size: PropTypes.string,
  dataQaCloseButton: PropTypes.string,
  dataQaModalType: PropTypes.string,
  dataQaModalId: PropTypes.string,
  withCloseButton: PropTypes.bool,
  shouldCloseOnEsc: PropTypes.bool,
  shouldCloseOnOverlayClick: PropTypes.bool,
  onRequestClose: PropTypes.func,
  bodyClassName: PropTypes.string,
};

export const ModalDefaultProps = {
  className: '',
  size: 'lg',
  dataQaCloseButton: 'qa_close_btn',
  dataQaModalType: null,
  dataQaModalId: 'qa_popup_alert',
  withCloseButton: false,
  shouldCloseOnEsc: false,
  shouldCloseOnOverlayClick: false,
  onRequestClose: () => {},
  bodyClassName: '',
};
