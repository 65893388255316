import React, { useState, useEffect, useRef } from 'react';
import throttle from 'lodash/throttle';

import { calcVideoSize } from '@jsv2/components/Video/utils';
import ErrorBoundaryDecorator from '@components/decorators/ErrorBoundaryDecorator';
import VideoPlayer from './VideoPlayer';

const CustomVideoComponent = (props) => {
  const [videoProps, setVideoProps] = useState([]);

  const videoPropsRef = useRef(videoProps);

  const setVideoPropsHooks = (data) => {
    videoPropsRef.current = data;
    setVideoProps(data);
  };

  const setPropsHandler = () => {
    const documentWidth = window.innerWidth;
    const documentHeight = window.innerHeight;
    const headerHeight = window.document.getElementsByTagName('header')[0].clientHeight;
    const actualHeight = documentHeight - headerHeight;
    const videoSize = calcVideoSize(
      documentWidth,
      actualHeight,
      videoPropsRef.current.width,
      videoPropsRef.current.height,
    );

    return {
      documentWidth,
      documentHeight,
      headerHeight,
      actualHeight,
      videoWidth: videoSize.width || '100%',
      videoHeight: videoSize.height || '100%',
    };
  };
  const [componentProps, setComponentProps] = useState({});

  const updatePropsHandler = throttle(() => setComponentProps(setPropsHandler()), 1000);

  useEffect(() => {
    window.addEventListener('resize', updatePropsHandler);

    return () => {
      window.removeEventListener('resize', updatePropsHandler);
    };
  }, []);

  useEffect(() => {
    if (Object.values(videoProps).length) {
      setComponentProps(setPropsHandler());
    }
  }, [videoProps]);

  return (
    <>
      <VideoPlayer
        videoWrapperHeight={(window.innerWidth * 450) / 1200}
        checkDocumentParameters={setPropsHandler}
        documentParameters={componentProps}
        setVideoProps={setVideoPropsHooks}
        {...props}
      />
    </>
  );
};

export default ErrorBoundaryDecorator()(CustomVideoComponent);
