export const STORAGE_ITEMS = {
  FIRST_NAME: 'firstName',
  LAST_NAME: 'lastName',
  CHECKOUT_PHONE: 'checkoutPhone',
  CHECK_AVAILABILITY_DATA: 'checkAvailabilityData',
};

Object.freeze(STORAGE_ITEMS);

const sessionStorageUtils = {
  init() {
    if (!window.user) {
      this.clearDataAfterLogout();
    }
  },
  clearDataAfterLogout: () => {
    sessionStorage.removeItem(STORAGE_ITEMS.FIRST_NAME);
    sessionStorage.removeItem(STORAGE_ITEMS.LAST_NAME);
    sessionStorage.removeItem(STORAGE_ITEMS.CHECKOUT_PHONE);
  },
};

export default sessionStorageUtils;
