import React, { useContext } from 'react';
import PropTypes from 'prop-types';

import SignUpContext from '@jsv2/context/SignUpContext';
import ScreenTypeContext, { isMobile } from '@js/context/ScreenTypeContext';
import OverlaySpinner from '@jsv3/components/molecules/OverlaySpinner';
import PaymentStep from '@jsv2/components/Authorization/PaymentStepUpgrade';
import ProgressBar from '@jsv2/components/Authorization/ProgressBar';
import StepForm from '../PaymentStepDefault/StepForm';
import MembershipUpgrade from '../../StepSidebars/MembershipUpgrade';
import FreeMembershipChoice from './FreeMembershipChoice';

const PaymentStepUpgrade = ({ step, getNextStep }) => {
  const screenTypeContext = useContext(ScreenTypeContext);

  const { membershipSummary } = useContext(SignUpContext).signUpProcess;
  const { membershipPlanId } = membershipSummary;

  return (
    // TODO resolve component names
    <PaymentStep
      step={step}
      getNextStep={getNextStep}
      render={({ isLoading, error, handleSubmit, handleFreeChoice }) => (
        <div className="viptraveler-modal">
          <MembershipUpgrade handleFreeChoice={handleFreeChoice} />

          <div className="signup signup--step3">
            {isLoading && <OverlaySpinner isAbsolute />}

            <ProgressBar activeStep={3} />

            {error?.message && <div className="label-content--error mt-20"> {error?.message} </div>}

            <div className="summary" data-plan-id={membershipPlanId}>
              <StepForm handleSubmit={handleSubmit} errors={error?.data} />
            </div>
          </div>

          {!isMobile(screenTypeContext) && (
            <FreeMembershipChoice handleFreeChoice={handleFreeChoice} />
          )}
        </div>
      )}
    />
  );
};

PaymentStepUpgrade.propTypes = {
  step: PropTypes.shape({
    component: PropTypes.func.isRequired,
    onComplete: PropTypes.func.isRequired,
  }).isRequired,
  getNextStep: PropTypes.func.isRequired,
};

export default PaymentStepUpgrade;
