import React, { useContext, useEffect, useState, useRef } from 'react';

import Spinner from '@components/Spinner';
import EditFieldContext from '@jsv2/LandingPage/context/EditFieldContext';
import ErrorBoundaryDecorator from '@components/decorators/ErrorBoundaryDecorator';

import { FINAL_VALUE_KEY, WAS_RELOADED_KEY } from '@jsv2/Enums/EditableEnums';
import createGeneralValueKey from '../helpers';

const EditImagePopup = () => {
  const {
    pageId,
    isEditable,
    error,
    setError,
    currentImageEditField,
    saveImageField,
    resetEditImageField,
    isEditLoading,
    updateImageEditState,
    getUnsavedChanges,
    getUnsavedChangesItem,
    setUnsavedChanges,
    clearUnsavedChangesItem,
  } = useContext(EditFieldContext);

  const [fieldValue, setFieldValue] = useState(null);
  const [fieldId, setFieldId] = useState(null);
  const [isFileSelected, setIsFileSelected] = useState(false);
  const [hasUnsavedChanges, setHasUnsavedChanges] = useState(false);

  const inputFileRef = useRef(null);

  const composeKey = () => `${fieldValue.name}--${fieldValue.type}`;

  const onSubmit = () => {
    saveImageField(fieldValue.content);

    clearUnsavedChangesItem(composeKey());
  };

  const onCancel = () => {
    clearUnsavedChangesItem(composeKey());

    resetEditImageField();
    setHasUnsavedChanges(false);
  };

  const HandleFieldChange = (content) => {
    updateImageEditState(content);
    setFieldValue({ ...currentImageEditField, content });

    setHasUnsavedChanges(false);
    setError(null);
  };

  const onChange = (e) => {
    const file = e.target.files[0];

    setIsFileSelected(true);
    HandleFieldChange(file);
  };

  const dataURItoBlob = (dataURI) => {
    const byteString = atob(dataURI.split(',')[1]);
    const mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];

    const arrayBuffer = new ArrayBuffer(byteString.length);
    const _ia = new Uint8Array(arrayBuffer);

    for (let i = 0; i < byteString.length; i++) {
      _ia[i] = byteString.charCodeAt(i);
    }

    const dataView = new DataView(arrayBuffer);

    return new Blob([dataView], { type: mimeString });
  };

  const onRestoreUnsavedChanges = () => {
    const fileItem = getUnsavedChangesItem(composeKey());
    const file = dataURItoBlob(fileItem[FINAL_VALUE_KEY]);

    const updatedItems = getUnsavedChanges().map((item) => {
      if (item.key === fileItem.key) {
        return { ...item, [WAS_RELOADED_KEY]: false };
      }

      return item;
    });

    setIsFileSelected(true);
    setHasUnsavedChanges(false);
    setUnsavedChanges(updatedItems);

    HandleFieldChange(file);
  };

  const onHandleSelectFile = () => {
    inputFileRef.current.click();
  };

  const renderCustomFileInput = () => (
    <>
      <button className="editable-btn" type="button">
        Choose a picture
      </button>

      <span>{isFileSelected ? 'Picture selected' : 'No picture selected'}</span>
    </>
  );

  const renderImageEditForm = () => (
    <div className="editable-popup">
      {isEditLoading ? (
        <Spinner />
      ) : (
        <div className="editable-form image-popup">
          <div className="editable-form__top-container">
            {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
            <div className="editable-form__file-input-box" onClick={onHandleSelectFile}>
              <input
                ref={inputFileRef}
                type="file"
                accept="image/*"
                value=""
                onChange={(e) => onChange(e)}
              />

              {renderCustomFileInput()}
            </div>

            <div className="editable-form__btns">
              <button type="button" className="editable-btn editable-btn--save" onClick={onSubmit}>
                Save
              </button>

              <button type="button" className="editable-btn editable-btn--reset" onClick={onCancel}>
                Cancel
              </button>
            </div>
          </div>
          <div className="editable-form__bottom-container">
            {hasUnsavedChanges && !error && (
              <div className="editable-form__unsaved-changes">
                you have unsaved changes,
                <button type="button" onClick={onRestoreUnsavedChanges}>
                  restore?
                </button>
              </div>
            )}

            {error && <p className="editable-form__error">{error}</p>}
          </div>
        </div>
      )}
    </div>
  );

  useEffect(() => {
    setError(null);
    setFieldValue(currentImageEditField);

    if (currentImageEditField !== null) {
      onHandleSelectFile();
    }
  }, [currentImageEditField]);

  useEffect(() => {
    setFieldId(fieldValue?.id);
  }, [fieldValue]);

  useEffect(() => {
    if (fieldId) {
      setIsFileSelected(false);

      const currentItem = getUnsavedChangesItem(composeKey());

      if (currentItem) {
        setHasUnsavedChanges(currentItem[WAS_RELOADED_KEY]);
      } else {
        setHasUnsavedChanges(false);
      }
    }

    return () => {
      if (fieldId) {
        const updatedItems = getUnsavedChanges().map((item) => {
          if (item.key === composeKey()) {
            return {
              ...item,
              [WAS_RELOADED_KEY]: true,
            };
          }

          return item;
        });

        if (updatedItems.length > 0) {
          setUnsavedChanges(updatedItems);
        } else {
          localStorage.removeItem(createGeneralValueKey(pageId));
        }
      }

      if (currentImageEditField) {
        resetEditImageField(false);
      }
    };
  }, [fieldId]);

  return <>{currentImageEditField && isEditable && renderImageEditForm()}</>;
};

export default ErrorBoundaryDecorator()(EditImagePopup);
