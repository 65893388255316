import React from 'react';

import DropDownLinkItem from '../components/DropDownLinkItem';

const Account = ({ children }) => (
  <DropDownLinkItem
    id="my-account"
    title="My Account"
    href="/profile#/my-trips"
  >
    {children}
  </DropDownLinkItem>
);

export default Account;
