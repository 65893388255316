import React, { useState, useEffect } from 'react';
import Cookies from 'browser-cookies';
import PropTypes from 'prop-types';

import echoClient from '@js/echoClient';
import BucketListContext from '../../context/BucketListContext';
import { bucketListModel } from '../../models/BucketListModels/BucketListModel';

import * as utils from '../../utils/BucketListUtils/Utils';

const randomTag = 'surprise me';

const BucketList = (props) => {
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [showDefaultError, setShowDefaultError] = useState(true);
  const [allDestinations, setAllDestinations] = useState([]);
  const [wishList, setWishList] = useState([]);
  const [destinationsList, setDestinationsList] = useState([]);
  const [tags, setTags] = useState([]);
  const [hideGreetingPopup, setHideGreetingPopup] = useState(
    Cookies.get('hideGreetingPopup') || null,
  );
  const [isDestinationAdding, setIsDestinationAdding] = useState(false);

  /**
   * Handler to move destination item to black list
   *
   * @param item {object}
   * @return {void}
   */
  const moveDestinationItemToBlackListHandler = (item) => {
    const newDestinations = destinationsList.filter(({ id }) => id !== item.id);

    setDestinationsList([...newDestinations]);

    props.blacklisted(item).catch((e) => {
      Logger.error(e);
    });
  };

  /**
   * Create wish list
   *
   * @param preferences {array}
   * @param allDestinationItems {array}
   * @return {array}
   */
  const createWishList = (preferences, allDestinationItems) =>
    preferences.map(
      (item) =>
        allDestinationItems.find(
          (destination) => destination.id === item.entity_id && destination.name === item.name,
        ) || item,
    );

  /**
   * Load data for Bucket list page from API. This call is responsible to get all necessary data
   * to make this page operational.
   *
   * @return {void}
   */
  const loadTravelPreferenceDataFromApi = () => {
    props
      .getDestinationsList()
      .then((response) => {
        const { data, preferences, tags: newTags } = bucketListModel(response.data);

        setAllDestinations(data);
        setDestinationsList(utils.getNotPreferred(data, preferences));
        setWishList(createWishList(preferences, data));
        setTags(newTags);
      })
      .catch((e) => {
        Logger.error('Could not load destinations list!', e);
      })
      .finally(() => setIsLoading(false));
  };

  /**
   * Save current order of items in travel preference list. Working w/ names here
   * since we might not have ids once reorder call is requested.
   *
   * @param preferenceNames <String[]>
   * @return {void}
   */
  const reorderItems = (preferenceNames) => {
    if (props.reorderWishListItems) {
      props.reorderWishListItems(preferenceNames).catch((e) => {
        Logger.error(e);
      });
    }
  };

  /**
   * Move to top by index handler
   * Default
   *
   * @param id {string}
   * @param index {number|null}
   * @return {void}
   */
  const moveToTopHandler = (id, index = null) => {
    const neededElement = wishList.filter((el) => el.id === id);
    const withoutNeededElement = wishList.filter((el) => el.id !== id);

    let orderedWishList;

    if (typeof index === 'number') {
      const before = withoutNeededElement.slice(0, index - 3);
      const after = withoutNeededElement.slice(index - 3);

      orderedWishList = [...before, ...neededElement, ...after];
    } else {
      orderedWishList = [...neededElement, ...withoutNeededElement];
    }

    const preferenceNames = orderedWishList.reduce((acc, item) => {
      acc.push(item.name);
      return acc;
    }, []);

    setWishList(orderedWishList);

    reorderItems(preferenceNames);
  };

  /**
   * Handler to add wish list item
   * @param id {string}
   * @return {void}
   */
  const addWishListItemHandler = (id) => {
    const newWishItem = destinationsList.filter((item) => item.id === id)[0] || null;
    const newDestinations = destinationsList
      .filter((item) => item.id !== id)
      .sort((a, b) => a.id - b.id);

    if (newWishItem === null) {
      Logger.error('An error...');
      return;
    }

    setIsDestinationAdding(true);

    setWishList((prevState) => [newWishItem && newWishItem, ...prevState]);
    setDestinationsList([...newDestinations]);

    props
      .addWishListItem(id, newWishItem.entity_type)
      .then(() => {
        const preferenceNames = [newWishItem, ...wishList].reduce((acc, item) => {
          acc.push(item.name);
          return acc;
        }, []);

        reorderItems(preferenceNames);
      })
      .catch((e) => {
        Logger.error(e);

        loadTravelPreferenceDataFromApi();
      })
      .finally(() => setIsDestinationAdding(false));
  };

  /**
   * Handle close greeting popup
   *
   * @return {void}
   */
  const handleCloseGreetingPopup = () => {
    Cookies.set('hideGreetingPopup', 'true');

    setHideGreetingPopup(true);
  };

  /**
   * Handler to hide default error
   *
   * @return {void}
   */
  const hideDefaultErrorHandler = () => {
    if (showDefaultError) {
      setShowDefaultError(false);
    }
  };

  /**
   * Add wish list item by name handler
   *
   * @param destination {string}
   * @return {void}
   */
  const addWishListItemByNameHandler = (destination) => {
    setError(null);

    if (!destination) return;

    if (
      wishList.filter((wishItem) => wishItem.name.toLowerCase() === destination.toLowerCase())
        .length
    ) {
      setError('This destination has already added');
      return;
    }

    const hasDestination = utils.getDestinationByName(destinationsList, destination);

    if (hasDestination) {
      addWishListItemHandler(hasDestination);
      return;
    }

    const newWishList = [{ name: destination }, ...wishList];

    setWishList(newWishList);

    props
      .addWishListItemByName(destination, props.entityType)
      .then((response) => {
        const preferenceNames = [{ name: destination }, ...newWishList].reduce((acc, item) => {
          acc.push(item.name);
          return acc;
        }, []);

        const wishListItems = newWishList.map((item) => {
          if (item.name === destination) {
            item.primary_image = response.data.file;
          }
          return item;
        });

        setWishList(wishListItems);

        reorderItems(preferenceNames);
      })
      .catch((e) => {
        Logger.error(e);

        loadTravelPreferenceDataFromApi();
      });
  };

  /**
   * Handler to remove destination list item by name
   *
   * @param name {string}
   * @return {void}
   */
  const removeDestinationListItemByNameHandler = (name) => {
    const newDestination = allDestinations.filter((item) => item.name === name);

    setWishList([...wishList.filter((el) => el.name !== name)]);
    setDestinationsList([...destinationsList, ...newDestination]);

    props
      .removeWishListItemByName(name)
      .then(() => {
        const preferenceNames = [...wishList.filter((el) => el.name !== name)].reduce(
          (acc, item) => {
            acc.push(item.name);
            return acc;
          },
          [],
        );

        if (preferenceNames.length) {
          reorderItems(preferenceNames);
        }
      })
      .catch((e) => {
        Logger.error(e);

        loadTravelPreferenceDataFromApi();
      });
  };

  useEffect(() => {
    const { user } = window;

    loadTravelPreferenceDataFromApi();

    if (props.reloadListIfImageReady) {
      echoClient
        .channel(`private-App.User.${user.id}`)
        .listen('Media\\FileUploaded', () => {
          loadTravelPreferenceDataFromApi();
        });
    }

  }, []);

  return (
    <BucketListContext.Provider
      value={{
        isLoading,
        error,
        wishList,
        destinationsList,
        tags,
        randomTag,
        hideGreetingPopup,
        isDestinationAdding,
        hideDefaultError: hideDefaultErrorHandler,
        closeGreetingPopup: handleCloseGreetingPopup,
        addWishListItemByName: addWishListItemByNameHandler,
        getDestinationsListData: loadTravelPreferenceDataFromApi,
        removeDestinationListItemByName: removeDestinationListItemByNameHandler,
        addWishListItem: addWishListItemHandler,
        blackListed: moveDestinationItemToBlackListHandler,
        moveToTop: moveToTopHandler,
        moveUp3Stops: moveToTopHandler,
      }}
    >
      <>
        {showDefaultError && error && <div className="error-message mt-25" data-qa-id="error_message">{error}</div>}

        {props.children}
      </>
    </BucketListContext.Provider>
  );
};

BucketList.propTypes = {
  entityType: PropTypes.string,
  reloadListIfImageReady: PropTypes.bool
};

BucketList.defaultProps = {
  entityType: 'Destination',
  reloadListIfImageReady: false,
};

export default BucketList;
