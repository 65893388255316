import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { ErrorMessage, FieldArray } from 'formik';

import ErrorBoundaryDecorator from '@components/decorators/ErrorBoundaryDecorator';
import { ALLOWED_UPLOAD_QUESTION_FILE_TYPES, IMAGE_TYPES } from '../config';

const FILE_ICON_SRC = '/images/file-icons/file.png';

/**
 * @param {object} question
 * @param {string} error
 * @param {number} questionIndex
 * @param {function} uploadFile
 * @param {boolean} isSingleSubmit
 * @return {JSX.Element}
 * @constructor
 */
const UploadQuestion = ({ question, error, questionIndex, uploadFile, isSingleSubmit }) => {
  const [selectedFile, setSelectedFile] = useState('');
  const [preview, setPreview] = useState('');

  const questionName = isSingleSubmit ? 'answers' : `answers[${questionIndex}].answers`;

  /**
   * @param {string} type
   * @return {boolean}
   */
  const isImage = (type) => IMAGE_TYPES.includes(type);

  /**
   * @param {object} arrayHelpers
   * @param {object} e
   */
  const onUploadFile = (arrayHelpers, e) => {
    const file = Array.from(e.target.files)[0];
    const formData = new FormData();

    setSelectedFile(file);

    formData.append('file', file);
    formData.append('entity_type', 'Common\\Models\\Questionnaire\\Question');
    formData.append('entity_id', question.id);
    formData.append('file_type', 'question_uploaded_file');
    formData.append('type', isImage(question.type) ? 'image' : 'file');

    uploadFile(formData, question.id).then((response) => {
      arrayHelpers.form.setFieldValue(questionName, [response.data.id]);
    });
  };

  // create a preview
  useEffect(() => {
    if (!selectedFile) {
      setPreview(undefined);
      return;
    }

    if (isImage(selectedFile.type)) {
      const URL = window.URL || window.webkitURL;

      if (URL) {
        const objectUrl = URL.createObjectURL(selectedFile);

        setPreview(objectUrl);
      }
    } else {
      setPreview(FILE_ICON_SRC);
    }
  }, [selectedFile]);

  return (
    <div className="input form-field upload-question-type mt-20" key={question.question}>
      {question.hint && <div className="question-hint">{question.hint}</div>}
      {error && <div className="error-message">{error}</div>}

      <FieldArray
        name={questionName}
        render={(arrayHelpers) => (
          <>
            <label htmlFor={`id_${question.id}`} className="upload-question-type__upload-label">
              {selectedFile ? (
                <>
                  <img src={preview} className="upload-question-type__preview" alt="file" />
                  <span>{selectedFile.name}</span>
                </>
              ) : (
                <>
                  <span className="upload-question-type__upload-icon" />
                  Browse file
                </>
              )}
            </label>

            <input
              id={`id_${question.id}`}
              className="upload-question-type__upload-input"
              name={`values.${questionName}[0]`}
              type="file"
              accept={ALLOWED_UPLOAD_QUESTION_FILE_TYPES}
              data-qa-id="upload_field"
              onChange={(event) => onUploadFile(arrayHelpers, event)}
            />
          </>
        )}
      />

      <ErrorMessage name={question.type} />
    </div>
  );
};

UploadQuestion.propTypes = {
  question: PropTypes.object.isRequired,
  error: PropTypes.string,
  questionIndex: PropTypes.number.isRequired,
  uploadFile: PropTypes.func.isRequired,
  isSingleSubmit: PropTypes.bool,
};

UploadQuestion.defaultProps = {
  error: '',
  isSingleSubmit: false,
};

export default ErrorBoundaryDecorator()(UploadQuestion);
