import React from 'react';
import { createRoot } from 'react-dom/client';

import { DIALOG_TYPES } from '@js/constants';
import Container from './Container';

/**
 * Usage example:
 *
 * import Dialogs from '@utils/Dialogs';
 *
 * ...
 *
 * Dialogs.alert('Hello world!');
 */

const divContainer = document.createElement('div');
divContainer.id = 'quick-modals';

document.body.appendChild(divContainer);

/**
 * @param {string} content
 * @param {Object} props
 */
const renderModal = (content, props) => {
  const isStringContent = typeof content === 'string';

  const root = createRoot(divContainer);

  const onUnmountComponent = (handler) => {
    handler?.();

    root.unmount();
  };

  root.render(
    <Container
      {...props}
      onClose={() => onUnmountComponent(props.onClose)}
      onCancel={() => onUnmountComponent(props.onCancel)}
      onOk={() => onUnmountComponent(props.onOk)}
    >
      {isStringContent ? (
        <div
          className="layover-popup__title fsz-22"
          dangerouslySetInnerHTML={{ __html: content }}
        />
      ) : (
        <div className="layover-popup__title fsz-22">{content}</div>
      )}
    </Container>,
  );
};

/**
 * Show modal with some HTML content and close button.
 *
 * @param {string} content Html content.
 * @param {Object} props Available props:
 *                       onClose (function) - modal close callback.
 */
export const alert = (content, props = {}) =>
  renderModal(content, {
    ...props,
    type: DIALOG_TYPES.ALERT,
    data: { 'qa-id': props?.alertDataQa || 'qa_popup_alert' },
  });

/**
 * Show modal with some HTML content and close button.
 *
 * @param {string} content Html content.
 * @param {Object} props Available props:
 *                       onClose (function) - modal close callback.
 */
export const confirm = (content, props = {}) =>
  renderModal(content, {
    ...props,
    type: DIALOG_TYPES.CONFIRM,
    data: { 'qa-id': 'qa_popup_confirm' },
  });
