export default class Cost {
  constructor(params) {
    this._amount = params?.amount || null;
    this._currencyIso3 = params?.currencyIso3 || null;
  }

  get amount() {
    return this._amount;
  }

  get currencyIso3() {
    return this._currencyIso3;
  }
}
