import React, { useContext, useState } from 'react';
import PropTypes from 'prop-types';

import Image from '@jsv2/components/Image';
import ScreenTypeContext, { isMobile } from '@js/context/ScreenTypeContext';
import BaseStepWelcome from '@jsv2/components/Authorization/BaseStepWelcome';
import SimpleEmailForm from '@jsv2/components/Forms/SimpleEmailForm';
import SignUpContext from '@jsv2/context/SignUpContext';
import SocialNetworks from '@components/Auth/SocialNetworks';
import { isFacebookUrl } from '@jsv3/utils/urlUtils';
import { allSocialButtons, facebookSocialButton } from '@components/Auth/SocialButtons/Buttons';
import OAuthUserEmail from '@components/Auth/OAuthUserEmail';
import { eraseSessionCookie } from '@utils/user';

const images = [
  {
    source: '/images/lp/promo2/publication-image/new-york-times.svg',
    title: 'new-york-times',
  },
  {
    source: '/images/lp/promo2/publication-image/nbc-news.svg',
    title: 'nbc-news',
  },
];

const BaseStepWelcomeTemplate = ({ step, getNextStep }) => {
  const screenContext = useContext(ScreenTypeContext);
  const { isEmailMissing } = useContext(SignUpContext).signUpProcess;

  const [isEmailModalOpen, setEmailModalOpen] = useState(isEmailMissing);

  return (
    <BaseStepWelcome
      step={step}
      getNextStep={getNextStep}
      render={({ serverError, handleSocialSignUp, handleSubmit }) => (
        <div className="layover-popup welcome-popup">
          <div className="welcome-popup__image-wrapper">
            <Image
              className="welcome-popup__image"
              data={{ path: 'templates/layovers/welcome-popup-3.jpg' }}
              background
            />

            {!isMobile(screenContext) && (
              <>
                <Image
                  className="welcome-popup__image hidden-on-mobile"
                  data={{ path: 'templates/layovers/welcome-popup-2.jpg' }}
                  background
                />

                <Image
                  className="welcome-popup__image hidden-on-mobile"
                  data={{ path: 'templates/layovers/welcome-popup-1.jpg' }}
                  background
                />
              </>
            )}
          </div>

          <div className="welcome-popup__content">
            <div className="welcome-popup__logotype" />

            <h2 className="welcome-popup__title">You&apos;re seconds away from better travel</h2>

            <p className="welcome-popup__text">
              We leverage our industry expertise and connections to secure partnerships with luxury
              properties around the world and provide our members exclusive offers for special
              limited-time-only pricing. Enter your email below to join!
            </p>

            <SimpleEmailForm
              className="welcome-popup__form"
              submitButtonText="view welcome offer"
              serverError={serverError}
              onSubmit={handleSubmit}
            >
              <div className="input-or">- or -</div>

              <SocialNetworks
                buttons={isFacebookUrl() ? facebookSocialButton : allSocialButtons}
                onlyIcon
                className="social-login--only-icon"
              />
            </SimpleEmailForm>

            <OAuthUserEmail
              isOpen={isEmailModalOpen}
              onSuccess={handleSocialSignUp}
              onCancel={() => {
                setEmailModalOpen(false);
                eraseSessionCookie();
              }}
            />
          </div>

          <div className="welcome-popup__publications">
            <p className="pubs__header"> As Seen On: </p>

            <div className="pubs__list">
              {images.map(({ source, title }) => (
                <img key={title} className={`pubs__item ${title}`} src={source} alt={title} />
              ))}
            </div>
          </div>
        </div>
      )}
    />
  );
};

BaseStepWelcomeTemplate.propTypes = {
  step: PropTypes.shape({
    component: PropTypes.func.isRequired,
    onComplete: PropTypes.func.isRequired,
  }).isRequired,
  getNextStep: PropTypes.func.isRequired,
};

export default BaseStepWelcomeTemplate;
