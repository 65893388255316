import React from 'react';
import hoistStatics from 'hoist-non-react-statics';

/**
 * Updates HOC and prevents it from braking decorated components.
 *  1. Forwards ref
 *  2. Updates display name
 *  3. Hoist statics
 * @param Origin - The original component
 * @param HOC - newly created HOC
 * @param hocName - name of the hoc
 */
function forwardHoc(Origin, HOC, hocName) {
  const forwarded = React.forwardRef((props, ref) => <HOC {...props} forwardedRef={ref} />);

  const name = Origin.displayName || Origin.name;
  forwarded.displayName = `${hocName}(${name})`;
  forwarded.WrappedComponent = Origin;
  forwarded.propTypes = Origin.propTypes;
  forwarded.defaultProps = Origin.defaultProps;

  return hoistStatics(forwarded, Origin);
}

export default forwardHoc;
