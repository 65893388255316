import React, { useEffect, useState } from 'react';

import Image from '@jsv2/components/Image';
import { IMAGE_SIZES } from '@js/constants';
import ErrorBoundaryDecorator from '@components/decorators/ErrorBoundaryDecorator';

/**
 * The number of thumbnail images that are displayed
 *
 * @type {number}
 */
const NUMBER_OF_THUMBNAIL_IMAGES = 3;

/**
 * Image gallery
 *
 * @param images
 *
 * @return {*}
 *
 * @constructor
 */
const ImageGallery = ({ images }) => {
  const [mainImage, setMainImage] = useState(null);

  const changeMainImage = (image) => {
    setMainImage(image);
  };

  const renderThumbnailImage = (image) => (
    <Image
      key={image.name}
      className="item__thumbnail-image"
      data={image}
      config={{
        size: IMAGE_SIZES.SMALL,
      }}
      background
      onClick={() => changeMainImage(image)}
    />
  );

  const renderThumbnailImages = () =>
    images.slice(0, NUMBER_OF_THUMBNAIL_IMAGES).map((image) => renderThumbnailImage(image));

  useEffect(() => {
    changeMainImage(images[0]);
  }, [images]);

  return (
    <>
      {mainImage && (
        <Image
          key={mainImage.link}
          className="item__main-image swiper-lazy"
          data={mainImage}
          config={{
            size: IMAGE_SIZES.SMALL,
          }}
          background
        />
      )}

      {renderThumbnailImages(images)}
    </>
  );
};

export default ErrorBoundaryDecorator()(ImageGallery);
