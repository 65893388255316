import ErrorBoundaryDecorator from '@components/decorators/ErrorBoundaryDecorator';

/**
 * List of available profiler flows. Should be identical to codes stored in the database.
 *
 * @type {Object|(string)[]}
 */
const PROFILER_FLOW = {
  DEFAULT: 'default_flow',
  one_of_user_booking_flow: 'one_of_user_booking_flow',
};

export default ErrorBoundaryDecorator()(PROFILER_FLOW);
