import React, { useContext, useEffect, useState } from 'react';

import ErrorBoundaryDecorator from '@components/decorators/ErrorBoundaryDecorator';
import EditFieldContext, { Editable } from '@jsv2/LandingPage/context/EditFieldContext';
import LPContext from '@jsv2/LandingPage/context/LPContext';

import { getReviewListApi } from '@jsv3/utils/api/reviewListAPI';
import ReviewsGallery from '@jsv3/components/molecules/ReviewsGallery';
import PropTypes from 'prop-types';

const sliderSettings = {
  navigation: false,
  slidesPerView: 1.1,
  centeredSlides: true,
  className: 'reviews-gallery__slider',
  breakpoints: {
    767: {
      slidesPerView: 2,
      centeredSlides: false,
    },
    992: {
      slidesPerView: 3,
      centeredSlides: false,
    },
  },
};

/**
 * Render reviews in a slider
 *
 * @return {*}
 *
 * @constructor
 */
const ReviewsWithBorder = ({ showBecomeButton }) => {
  const { editableFields } = useContext(EditFieldContext);
  const { renderBecomeButton } = useContext(LPContext);

  const [reviews, setReviews] = useState([]);

  useEffect(() => {
    getReviewListApi({ internal: 1 }).then(({ data }) => {
      setReviews(data || []);
    });
  }, []);

  return (
    reviews.length > 0 && (
      <div className="reviews-with-border">
        <div className="main-container">
          <div className="section-title section-title--with-underline section-title--center">
            <Editable fieldValue={editableFields.reviews_title} />
          </div>

          {editableFields.reviews_testimonial && (
            <div className="section-testimonial">
              <Editable fieldValue={editableFields.reviews_testimonial} />
            </div>
          )}

          <ReviewsGallery reviews={reviews} settings={sliderSettings} />

          {showBecomeButton &&
            renderBecomeButton(
              'become-btn become-btn--border-radius become-btn--red',
              window.templateVariables.body_cta_text.content,
              'become-join-btn-1',
            )}
        </div>
      </div>
    )
  );
};

ReviewsWithBorder.propTypes = {
  showBecomeButton: PropTypes.bool,
};

ReviewsWithBorder.defaultProps = {
  showBecomeButton: true,
};

export default ErrorBoundaryDecorator()(ReviewsWithBorder);
