import React, { Fragment, useState } from 'react';
import { Form, Formik } from 'formik';
import PropTypes from 'prop-types';

import apiClient from '@js/apiClient';
import useSubmit from '@js/hooks/useSubmit';
import CustomField from '@components/formComponents/CustomField';
import { uploadFileDataRequest } from '@jsv3/utils/api/fileUploaderAPI';
import { QUESTION_TYPES } from '@jsv3/components/organisms/QuestionnaireWrapper/config';
import UploadQuestion from '@jsv3/components/organisms/QuestionnaireWrapper/UI/UploadQuestion';
import InputQuestion from '@jsv3/components/organisms/QuestionnaireWrapper/UI/InputQuestion';
import SelectQuestion from '@jsv3/components/organisms/QuestionnaireWrapper/UI/SelectQuestion';
import DummyQuestion from '@jsv3/components/organisms/QuestionnaireWrapper/UI/DummyQuestion';
import Spinner from '@jsv3/components/molecules/Spinner';
import ErrorBoundaryDecorator from '@components/decorators/ErrorBoundaryDecorator';

const Questionnaire = ({
  questions: initQuestions,
  handleClickFinish,
  template,
  isMocked,
  isRegistrationCompleted,
  successMessageTitle,
  successMessageContent,
  onNextQuestion,
  saveAnswers,
  ...templateProps
}) => {
  const QuestionnaireTemplate = template || Fragment;
  const { loading, error, performRequest } = useSubmit();

  const [questions, setQuestions] = useState({ ...initQuestions });
  const [isQuestions, setIsQuestions] = useState(true);
  const [index, setIndex] = useState(0);
  const [fieldError, setFieldError] = useState('');
  const [isFileLoading, setIsFileLoading] = useState(false);

  const question = questions[index];

  /**
   * @param {object} values
   * @return {null|boolean}
   */
  const isAnswerChanged = (values) => {
    if (question.answer) {
      return values.text === question.answer;
    }

    if (question.comment) {
      return values.comment === question.comment;
    }

    if (question.answers) {
      return values.answers === question.answers;
    }

    return null;
  };

  /**
   * @return {boolean}
   */
  const isTextQuestion = () =>
    question.type === QUESTION_TYPES.TEXT || question.type === QUESTION_TYPES.TEXT_MULTIPLE;

  /**
   * @return {false|boolean|*}
   */
  const isCommentsAllowed = () => !isTextQuestion() && question.comment_allowed;

  /**
   * @param {object} values
   * @param {function} setErrors
   * @return {void}
   */
  const handleSubmit = (values, { setErrors }) => {
    setFieldError('');

    if (
      question.is_required &&
      !values.text &&
      values.answers.length === 0 &&
      question.type !== QUESTION_TYPES.DUMMY
    ) {
      setFieldError('Question is required');

      return;
    }

    if (isMocked) {
      return;
    }

    const filteredAnswers = values.answers.filter((answer) => answer);

    const data = {
      answers: [
        {
          question_id: question.id,
          answers: filteredAnswers,
          comment: values.comment,
          type: question.type,
        },
      ],
    };

    if (values.text) {
      data.answers[0].answers.push(values.text);
    }

    setQuestions({
      ...questions,
      [index]: {
        ...question,
        answer: values.text,
        answers: values.answers,
        comment: values.comment,
      },
    });

    if (isAnswerChanged(values)) {
      setIndex(index + 1);
      return;
    }

    const isLastQuestion = () => index === Object.keys(questions).length - 1;

    const onProceed = () => {
      if (!isLastQuestion()) {
        setIndex(index + 1);
        return;
      }

      if (handleClickFinish && typeof handleClickFinish === 'function') {
        handleClickFinish();
      } else {
        setIsQuestions(false);
      }
    };

    const submitFunc = () => apiClient.put('/user/survey-question-answer', data);

    if (saveAnswers) {
      onNextQuestion(data, isLastQuestion());
      onProceed();
    } else {
      performRequest(submitFunc, setErrors, isLastQuestion()).then(() => {
        onProceed();
      });
    }
  };

  /**
   * @return {void}
   */
  const handlePreviousQuestion = () => {
    setIndex(index - 1);
  };

  /**
   * @param {formData} formData
   */
  const onUploadFile = (formData) => {
    setIsFileLoading(true);

    return uploadFileDataRequest(formData)
      .catch((e) => {
        setFieldError(e.response.data.message);
      })
      .finally(() => setIsFileLoading(false));
  };

  /**
   * @param {object} props
   * @return {JSX.Element|*}
   */
  const renderQuestion = (props) => {
    switch (question.type) {
      case QUESTION_TYPES.UPLOAD:
        return (
          <UploadQuestion
            question={question}
            error={error || fieldError}
            questionIndex={index}
            uploadFile={onUploadFile}
            isSingleSubmit
          />
        );

      case QUESTION_TYPES.SELECT:
      case QUESTION_TYPES.SELECT_MULTIPLE:
        return (
          <SelectQuestion
            question={question}
            values={props.values}
            questionIndex={index}
            error={error || fieldError}
            isSingleSubmit
          />
        );

      case QUESTION_TYPES.TEXT:
      case QUESTION_TYPES.TEXT_MULTIPLE:
        return (
          <InputQuestion
            question={question}
            values={props.values}
            error={error || fieldError}
            questionIndex={index}
            isSingleSubmit
          />
        );

      default:
        return <DummyQuestion question={question} />;
    }
  };

  const style = {};

  if (loading) {
    style.display = 'none';
  }

  return (
    <>
      {isQuestions ? (
        <QuestionnaireTemplate
          error={error}
          questions={initQuestions}
          question={question}
          index={index}
          loading={loading}
          style={style}
          {...templateProps}
        >
          {index > 0 && (
            <button
              className="btn-survey-back"
              type="submit"
              disabled={false}
              data-qa-id="btn-survey-back"
              onClick={handlePreviousQuestion}
            />
          )}
          <Formik
            key={question.id}
            initialValues={{
              text: (question.answer && question.answer) || '',
              comment: (question.comment && question.comment) || '',
              answers: (question.answers && question.answers) || [],
            }}
            onSubmit={handleSubmit}
          >
            {(props) => (
              <Form className="form questionnaire" data-qa-required={question.is_required}>
                {question.type !== QUESTION_TYPES.DUMMY && (
                  <div
                    className={question.type === 'text' ? 'text-title' : 'select-title'}
                    data-qa-id="title_text"
                  >
                    {`${question.question}${question.is_required ? '*' : ''}`}
                  </div>
                )}

                {renderQuestion(props)}

                {isCommentsAllowed() && (
                  <div className="questionnaire-comment form-field input pt-25 mb-0">
                    <CustomField
                      name="comment"
                      component="input"
                      placeholder={question.comment_placeholder}
                      autoComplete="off"
                    />
                  </div>
                )}

                <div className="btn-wrapper-center pt-40">
                  <button
                    className="btn-survey"
                    type="submit"
                    disabled={isMocked || isFileLoading}
                    data-qa-id="next"
                  >
                    {question.button || 'NEXT'}

                    {isFileLoading && <Spinner color="white" inline width="20px" />}
                  </button>
                </div>
              </Form>
            )}
          </Formik>
        </QuestionnaireTemplate>
      ) : (
        <div className="quiz-thank-message" data-qa-id="quiz-message">
          <div className="thank-title" data-qa-id="title-message">
            {t(successMessageTitle)}
          </div>

          <div className="thank-content" data-qa-id="content-message">
            {t(successMessageContent)}
          </div>
        </div>
      )}
    </>
  );
};

Questionnaire.propTypes = {
  handleClickFinish: PropTypes.func,
  isRegistrationCompleted: PropTypes.bool,
  isMocked: PropTypes.bool,
  questions: PropTypes.arrayOf(PropTypes.object).isRequired,
  template: PropTypes.oneOfType([PropTypes.func, PropTypes.node]).isRequired,
  successMessageTitle: PropTypes.string.isRequired,
  successMessageContent: PropTypes.string.isRequired,
  onNextQuestion: PropTypes.func,
  saveAnswers: PropTypes.bool,
};

Questionnaire.defaultProps = {
  isRegistrationCompleted: false,
  handleClickFinish: null,
  isMocked: false,
  onNextQuestion: null,
  saveAnswers: false,
};

export default ErrorBoundaryDecorator()(Questionnaire);
