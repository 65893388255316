import React from 'react';
import PropTypes from 'prop-types';

import ErrorBoundaryDecorator from '@components/decorators/ErrorBoundaryDecorator';
import { getBlackIconUrl } from '@utils/icons';

/**
 * Tags for destination.
 *
 * @param items
 * @param numberOfTags
 * @returns {JSX.Element}
 * @constructor
 */
const DestinationTags = ({ items, numberOfTags }) => {
  const renderTagIcon = (tag) => {
    if (tag.icon && tag.icon.link) {
      return <img src={getBlackIconUrl(tag.icon)} alt={tag.name} />;
    }

    return null;
  };

  const renderTag = (tag) => (
    <div key={tag.name} className="item__tag">
      {renderTagIcon(tag)}

      {tag.name}
    </div>
  );

  const renderTags = () => items.slice(0, numberOfTags).map((tag) => renderTag(tag));

  return <div className="item__tags">{renderTags()}</div>;
};

DestinationTags.propTypes = {
  items: PropTypes.arrayOf(PropTypes.object).isRequired,
  numberOfTags: PropTypes.number.isRequired,
};

export default ErrorBoundaryDecorator()(DestinationTags);
