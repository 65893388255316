import React, { useContext } from 'react';

import ErrorBoundaryDecorator from '@components/decorators/ErrorBoundaryDecorator';
import EditFieldContext, { Editable } from '@jsv2/LandingPage/context/EditFieldContext';

const VipBenefitsSection = React.forwardRef(({ id }, ref) => {
  const { editableFields } = useContext(EditFieldContext);

  return (
    <>
      {editableFields && (
        <section className="vip-benefits" ref={ref} id={id}>
          <div className="main-container vip-benefits__container">
            <h2 className="section-title section-title--center section-title--with-underline">
              <Editable fieldValue={editableFields.stackup_heading} />
            </h2>

            <div className="vip-benefits__description">
              <Editable fieldValue={editableFields.stackup_testimonial} />
            </div>

            <div className="vip-benefits__table">
              <div className="vip-benefits__row">
                <div className="vip-benefits__col">
                  <div className="vip-benefits__title vip-benefits__title--red">
                    <Editable fieldValue={editableFields.column_1_title} />
                  </div>
                </div>

                <div className="vip-benefits__col">
                  <div className="vip-benefits__title">
                    <Editable fieldValue={editableFields.column_2_title} />
                  </div>
                </div>

                <div className="vip-benefits__col">
                  <div className="vip-benefits__title">
                    <Editable fieldValue={editableFields.column_3_title} />
                  </div>
                </div>

                <div className="vip-benefits__col">
                  <div className="vip-benefits__title">
                    <Editable fieldValue={editableFields.column_4_title} />
                  </div>
                </div>
              </div>

              <div className="vip-benefits__row">
                <div className="vip-benefits__col">
                  <div className="vip-benefits__title vip-benefits__title--big">
                    <Editable fieldValue={editableFields.row_1_title} />
                  </div>
                </div>

                <div className="vip-benefits__col">
                  <span className="vip-benefits__check vip-benefits__check--red" />
                </div>

                <div className="vip-benefits__col">
                  <span className="vip-benefits__check" />
                </div>

                <div className="vip-benefits__col">
                  <span className="vip-benefits__check" />
                </div>

                <div className="vip-benefits__col">
                  <span className="vip-benefits__check" />
                </div>
              </div>

              <div className="vip-benefits__row">
                <div className="vip-benefits__col">
                  <div className="vip-benefits__title vip-benefits__title--big">
                    <Editable fieldValue={editableFields.row_3_title} />
                  </div>
                </div>

                <div className="vip-benefits__col">
                  <span className="vip-benefits__check vip-benefits__check--red" />
                </div>

                <div className="vip-benefits__col">
                  <span className="vip-benefits__check" />
                </div>
              </div>

              <div className="vip-benefits__row">
                <div className="vip-benefits__col">
                  <div className="vip-benefits__title vip-benefits__title--big">
                    <Editable fieldValue={editableFields.row_2_title} />
                  </div>
                </div>

                <div className="vip-benefits__col">
                  <span className="vip-benefits__check vip-benefits__check--red" />
                </div>
              </div>

              <div className="vip-benefits__row">
                <div className="vip-benefits__col">
                  <div className="vip-benefits__title vip-benefits__title--big">
                    <Editable fieldValue={editableFields.row_4_title} />
                  </div>
                </div>

                <div className="vip-benefits__col">
                  <span className="vip-benefits__check vip-benefits__check--red" />
                </div>
              </div>

              <div className="vip-benefits__row">
                <div className="vip-benefits__col">
                  <div className="vip-benefits__title vip-benefits__title--big">
                    <Editable fieldValue={editableFields.row_5_title} />
                  </div>
                </div>

                <div className="vip-benefits__col">
                  <span className="vip-benefits__check vip-benefits__check--red" />
                </div>
              </div>

              <div className="vip-benefits__row">
                <div className="vip-benefits__col">
                  <div className="vip-benefits__title vip-benefits__title--big">
                    <Editable fieldValue={editableFields.row_6_title} />
                  </div>
                </div>

                <div className="vip-benefits__col">
                  <span className="vip-benefits__check vip-benefits__check--red" />
                </div>
              </div>

              <div className="vip-benefits__row">
                <div className="vip-benefits__col">
                  <div className="vip-benefits__title vip-benefits__title--big">
                    <Editable fieldValue={editableFields.row_7_title} />
                  </div>
                </div>

                <div className="vip-benefits__col">
                  <span className="vip-benefits__check vip-benefits__check--red" />
                </div>
              </div>

              <div className="vip-benefits__row">
                <div className="vip-benefits__col">
                  <div className="vip-benefits__title vip-benefits__title--big">
                    <Editable fieldValue={editableFields.row_8_title} />
                  </div>
                </div>

                <div className="vip-benefits__col">
                  <span className="vip-benefits__check vip-benefits__check--red" />
                </div>
              </div>
            </div>
          </div>
        </section>
      )}
    </>
  );
});

export default ErrorBoundaryDecorator()(VipBenefitsSection);
