const SET_UNREAD_MESSAGES_COUNT = 'COMMUNICATIONS/SET_UNREAD_MESSAGES_COUNT';
const SET_COMMUNICATION_DATA = 'COMMUNICATIONS/SET_COMMUNICATION_DATA';

const defaultState = {
  inputMessage: '',
  unreadMessagesCount: 0,
  communicationMessages: [],
  communicationHistory: [],
  userStylist: {
    email: '',
    phone: '',
    whatsappPhone: '',
    stylistName: '',
  },
};

export default function reducer(state = defaultState, action) {
  switch (action.type) {
    case SET_UNREAD_MESSAGES_COUNT: {
      if (typeof action.payload === 'object') {
        return {
          ...state,
          unreadMessagesCount: action.payload
            .filter((message) => message.source_type === 'chat')
            .filter((message) => message.opened_at === null)
            .filter((message) => !message.is_customer_author).length,
        };
      }

      if (typeof action.payload === 'number') {
        return {
          ...state,
          unreadMessagesCount: action.payload,
        };
      }

      return {
        ...state,
        unreadMessagesCount: state.communicationMessages
          .filter((message) => message.source_type === 'chat')
          .filter((message) => message.opened_at === null)
          .filter((message) => !message.is_customer_author).length,
      };
    }
    // keyValue: "input", "messages", "history", "stylist"
    case SET_COMMUNICATION_DATA: {
      const { keyValue, content } = action.payload;

      let stateKeyValue = '';

      switch (keyValue) {
        case 'input':
          stateKeyValue = 'inputMessage';
          break;
        case 'messages':
          stateKeyValue = 'communicationMessages';
          break;
        case 'history':
          stateKeyValue = 'communicationHistory';
          break;
        case 'stylist':
          stateKeyValue = 'userStylist';
          break;

        default:
          return state;
      }

      return {
        ...state,
        [stateKeyValue]: content,
      };
    }

    default:
      return state;
  }
}

export const setUnreadMessagesCountAction = (count) => ({
  type: SET_UNREAD_MESSAGES_COUNT,
  payload: count,
});

// keyValue: "input", "messages", "history", "stylist"
export const setCommunicationDataAction = (keyValue, content) => ({
  type: SET_COMMUNICATION_DATA,
  payload: {
    keyValue,
    content,
  },
});
