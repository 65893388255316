import STEPS from '@jsv2/config/SignUp/steps';
import ROLES from '@jsv2/config/userRoles';
import URLS from '@jsv2/config/SignUp/urls';
import BASE_STEP_DEFAULT from '@jsv2/templates/vipTraveler/SignUp/Steps/BaseStepDefault';
import ADDRESS_STEP_DEFAULT from '@jsv2/templates/vipTraveler/SignUp/Steps/AddressStepDefault';
import SUCCESS_DEPOSIT_MESSAGE from '@jsv2/templates/vipTraveler/SignUp/Messages/DepositSuccessMessage';

const {
  shared_data: { defaultHomePageUrl },
} = window.__SERVER_DATA__;

const profilerPaidFlowConfig = {
  [STEPS.BASE_STEP_DEFAULT]: {
    name: STEPS.BASE_STEP_DEFAULT,
    component: BASE_STEP_DEFAULT,
    onComplete: () => STEPS.ADDRESS_STEP_DEFAULT,
    isClosable: true,
    style: 'closable-sign-up-step',
    url: URLS[STEPS.BASE_STEP_DEFAULT],
    roles: {
      [ROLES.ANONYMOUS]: true,
    },
  },
  [STEPS.ADDRESS_STEP_DEFAULT]: {
    name: STEPS.ADDRESS_STEP_DEFAULT,
    component: ADDRESS_STEP_DEFAULT,
    onComplete: () => STEPS.SUCCESS_DEPOSIT_MESSAGE,
    isClosable: true,
    style: 'closable-sign-up-step',
    url: URLS[STEPS.ADDRESS_STEP_DEFAULT],
    roles: {
      [ROLES.INCOMPLETE_SIGN_UP]: true,
    },
  },
  [STEPS.SUCCESS_DEPOSIT_MESSAGE]: {
    name: STEPS.SUCCESS_DEPOSIT_MESSAGE,
    component: SUCCESS_DEPOSIT_MESSAGE,
    onComplete: () => (window.location.href = '/deposit'),
    onSecondComplete: () => (window.location.href = defaultHomePageUrl),
    isProfilerStep: true,
    isClosable: false,
    url: URLS[STEPS.SUCCESS_DEPOSIT_MESSAGE],
    roles: {
      [ROLES.FULLY_VERIFIED]: true,
    },
  },
};

export default profilerPaidFlowConfig;
