import React from 'react';
import ReactModal from 'react-modal';
import classNames from 'classnames';

import ErrorBoundaryDecorator from '@components/decorators/ErrorBoundaryDecorator';

const Modal = ({
  closeModal,
  withCloseButton,
  isModalOpen,
  overlayClassName,
  className,
  children,
  background,
  ...props
}) => (
  <ReactModal
    overlayClassName={classNames('landing-modal-window-overlay modal-overlay', overlayClassName)}
    className={classNames('landing-modal-window', className)}
    isOpen={isModalOpen}
    {...props}
  >
    {withCloseButton && (
      // eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions
      <div
        onClick={() => closeModal()}
        className="button-close btn-close button-close--mobile-black"
        data-qa-id="qa_close_btn"
      />
    )}
    {background && (
      <div className="inner-layover" style={{ backgroundImage: `url(${background})` }} />
    )}

    {children}
  </ReactModal>
);

export default ErrorBoundaryDecorator()(Modal);
