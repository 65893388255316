import STEPS from '@jsv2/config/SignUp/steps';
import ROLES from '@jsv2/config/userRoles';
import URLS from '@jsv2/config/SignUp/urls';
import { makeUserFreeMember } from '@jsv2/utils/UserUtils/APIRequest';
import BASE_STEP_PROFILER from '@jsv2/templates/vipTraveler/SignUp/Steps/BaseStepProfiler';
import TRAVEL_PROFILER from '@jsv2/templates/vipTraveler/SignUp/Steps/TravelProfiler';
import DEPOSIT_REGISTRATION_MESSAGE from '@jsv2/templates/vipTraveler/SignUp/Messages/DepositRegistrationMessage';
import ADDRESS_STEP_PROFILER from '@jsv2/templates/vipTraveler/SignUp/Steps/AddressStepProfiler';
import PAYMENT_STEP_DEFAULT from '@jsv2/templates/vipTraveler/SignUp/Steps/PaymentStepDefault';
import SUCCESS_ONE_OF_BOOKING_PAID_MESSAGE from '@jsv2/templates/vipTraveler/SignUp/Messages/OneOfBookingSuccessPaidMessage';
import SUCCESS_ONE_OF_BOOKING_ABANDONED_MESSAGE from '@jsv2/templates/vipTraveler/SignUp/Messages/OneOfBookingSuccessAbandonedMessage';

const {
  shared_data: { defaultHomePageUrl },
} = window.__SERVER_DATA__;

const profilerPaidFlowConfig = {
  [STEPS.BASE_STEP_PROFILER]: {
    name: STEPS.BASE_STEP_PROFILER,
    component: BASE_STEP_PROFILER,
    onComplete: () => {
      window.questionnaireFilters = { type: 'deposit_survey' };

      return STEPS.COMPLETE_TRAVEL_PROFILER;
    },
    isClosable: true,
    style: 'base-step-profiler',
    url: URLS[STEPS.BASE_STEP_PROFILER],
    roles: {
      [ROLES.ANONYMOUS]: true,
    },
  },
  [STEPS.COMPLETE_TRAVEL_PROFILER]: {
    name: STEPS.COMPLETE_TRAVEL_PROFILER,
    component: TRAVEL_PROFILER,
    onComplete: () => STEPS.DEPOSIT_REGISTRATION_MESSAGE,
    isClosable: false,
    style: 'travel-profiler',
    url: URLS[STEPS.TRAVEL_PROFILER],
    roles: {
      [ROLES.INCOMPLETE_PROFILER]: true,
    },
  },
  [STEPS.DEPOSIT_REGISTRATION_MESSAGE]: {
    name: STEPS.DEPOSIT_REGISTRATION_MESSAGE,
    component: DEPOSIT_REGISTRATION_MESSAGE,
    onComplete: () => STEPS.ADDRESS_STEP_PROFILER,
    isClosable: false,
    url: URLS[STEPS.DEPOSIT_REGISTRATION_MESSAGE],
  },
  [STEPS.ADDRESS_STEP_PROFILER]: {
    name: STEPS.ADDRESS_STEP_PROFILER,
    component: ADDRESS_STEP_PROFILER,
    onComplete: () => STEPS.PAYMENT_STEP_DEFAULT,
    isClosable: true,
    style: 'closable-sign-up-step',
    url: URLS[STEPS.ADDRESS_STEP_PROFILER],
  },
  [STEPS.PAYMENT_STEP_DEFAULT]: {
    name: STEPS.PAYMENT_STEP_DEFAULT,
    component: PAYMENT_STEP_DEFAULT,
    onComplete: () => STEPS.SUCCESS_ONE_OF_BOOKING_PAID_MESSAGE,
    onClose: () => {
      makeUserFreeMember('step_interrupted');
      return STEPS.SUCCESS_ONE_OF_BOOKING_ABANDONED_MESSAGE;
    },
    isClosable: true,
    style: 'closable-sign-up-step',
    url: URLS[STEPS.PAYMENT_STEP_DEFAULT],
  },
  [STEPS.SUCCESS_ONE_OF_BOOKING_PAID_MESSAGE]: {
    name: STEPS.SUCCESS_ONE_OF_BOOKING_PAID_MESSAGE,
    component: SUCCESS_ONE_OF_BOOKING_PAID_MESSAGE,
    onComplete: () => (window.location.href = defaultHomePageUrl),
    isProfilerStep: true,
    isClosable: false,
    url: URLS[STEPS.SUCCESS_ONE_OF_BOOKING_PAID_MESSAGE],
  },
  [STEPS.SUCCESS_ONE_OF_BOOKING_ABANDONED_MESSAGE]: {
    name: STEPS.SUCCESS_ONE_OF_BOOKING_ABANDONED_MESSAGE,
    component: SUCCESS_ONE_OF_BOOKING_ABANDONED_MESSAGE,
    onComplete: () => (window.location.href = defaultHomePageUrl),
    isProfilerStep: true,
    isClosable: false,
    url: URLS[STEPS.SUCCESS_ONE_OF_BOOKING_ABANDONED_MESSAGE],
  },
};

export default profilerPaidFlowConfig;
