const SHOW = 'FIXED_BUY_BUTTON/SHOW';
const HIDE = 'FIXED_BUY_BUTTON/HIDE';
const TOGGLE = 'FIXED_BUY_BUTTON/TOGGLE';

const defaultState = {
  show: true,
};

export default function reducer(state = defaultState, action) {
  switch (action.type) {
    case SHOW: return { show: true };
    case HIDE: return { show: false };
    case TOGGLE: {
      if (typeof action.payload !== 'undefined') {
        return { show: action.payload };
      }
      return { show: !state.show };
    }
    default: return state;
  }
}

export const showFixedBuyButton = () => ({
  type: SHOW,
});

export const hideFixedBuyButton = () => ({
  type: HIDE,
});

export const toggleFixedBuyButton = show => ({
  type: TOGGLE,
  payload: show,
});
