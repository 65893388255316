import React, { useContext, useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { storageURL } from '@jsv3/utils/urlUtils';
import ErrorBoundaryDecorator from '@components/decorators/ErrorBoundaryDecorator';
import LPContext from '@jsv2/LandingPage/context/LPContext';
import EditFieldContext, { Editable } from '@jsv2/LandingPage/context/EditFieldContext';
import Image from '@jsv2/components/Image';

const BannerSection = React.forwardRef(
  ({ nextSection, id, backgroundImage, videoSrc, showHeroImage, ...rest }, ref) => {
    const { editableFields } = useContext(EditFieldContext);
    const { scrollToNextSection } = useContext(LPContext);

    const [isVideoPlaying, setStateVideo] = useState(false);

    return (
      <>
        {editableFields && (
          <section
            className={classNames('banner', { 'video-loaded': isVideoPlaying })}
            ref={ref}
            id={id}
            {...rest}
          >
            <Image
              className="banner__video-wrapper"
              data={{
                link: storageURL(backgroundImage),
              }}
              background
            >
              <video
                loop="loop"
                muted
                playsInline="playsinline"
                autoPlay="autoplay"
                className="banner__video"
                poster="/images/lp/transparent-icon.png"
                onPlay={() => setStateVideo(true)}
              >
                <source src={storageURL(videoSrc)} type="video/mp4" />
              </video>
            </Image>

            <div className={`main-container${!showHeroImage ? ' mt-0' : ''}`}>
              {showHeroImage && <div className="banner__logotype" />}

              <div className="banner__title">
                <Editable fieldValue={editableFields.heading} />
              </div>

              <div className="banner__sub-title">
                <Editable fieldValue={editableFields.tagline} />
              </div>
            </div>

            <button
              type="button"
              className="btn-scroll btn-scroll--white"
              onClick={() => scrollToNextSection(nextSection)}
              data-qa-id="btn-scroll"
            />
          </section>
        )}
      </>
    );
  },
);

BannerSection.propTypes = {
  backgroundImage: PropTypes.string,
  videoSrc: PropTypes.string,
  nextSection: PropTypes.string.isRequired,
  showHeroImage: PropTypes.bool,
};

BannerSection.defaultProps = {
  backgroundImage: '/cms-page/banner-preview-low.jpg',
  videoSrc: '/lp-videos/VIPBanner.mp4',
  showHeroImage: true,
};

export default ErrorBoundaryDecorator()(BannerSection);
