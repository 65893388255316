import React from 'react';
import PropTypes from 'prop-types';

import LinkItem from '../../components/LinkItem';

const Balance = ({ isSubItem, onMenuItemClick }) => (
  <LinkItem
    href="/profile#/balance"
    id="balance-button"
    title="Credit Balance"
    isSubItem={isSubItem}
    onMenuItemClick={onMenuItemClick}
  />
);

Balance.propTypes = {
  isSubItem: PropTypes.bool,
  onMenuItemClick: PropTypes.func,
};

Balance.defaultProps = {
  isSubItem: false,
  onMenuItemClick: () => {},
};

export default Balance;
