import React, { useContext, useState } from 'react';

import ScreenTypeContext, { isMobile } from '@js/context/ScreenTypeContext';
import Slider from '@jsv2/components/Slider/Slider';
import ErrorBoundaryDecorator from '@components/decorators/ErrorBoundaryDecorator';
import EditFieldContext, {
  Editable,
  EditableImg,
} from '@jsv2/LandingPage/context/EditFieldContext';
import LPContext from '@jsv2/LandingPage/context/LPContext';
import Image from '@jsv2/components/Image';
import { IMAGE_SIZES } from '@js/constants';
import { preparedAvailableItemsForRender } from '@jsv2/LandingPage/utils';

const DestinationExpertsSection = ({ id }) => {
  const screenTypeContext = useContext(ScreenTypeContext);
  const { editableFields, editableImageFields } = useContext(EditFieldContext);
  const { isEditSectionMode } = useContext(LPContext);

  const [slider, setSlider] = useState(null);
  const [currentSlide, setCurrentSlide] = useState(0);
  const [isLastSlide, setLastSlide] = useState(false);
  const [isFirstSlide, setFirstSlide] = useState(true);

  const destinationExperts = [
    {
      key: 1,
      image: editableImageFields.stylist_1,
      name: editableFields.stylist_1_name,
      description: editableFields.stylist_1_description,
      label: editableFields.stylist_1_label,
      long_description: editableFields.stylist_1_long_description,
    },
    {
      key: 2,
      image: editableImageFields.stylist_2,
      name: editableFields.stylist_2_name,
      description: editableFields.stylist_2_description,
      label: editableFields.stylist_2_label,
      long_description: editableFields.stylist_2_long_description,
    },
    {
      key: 3,
      image: editableImageFields.stylist_3,
      name: editableFields.stylist_3_name,
      description: editableFields.stylist_3_description,
      label: editableFields.stylist_3_label,
      long_description: editableFields.stylist_3_long_description,
    },
    {
      key: 4,
      image: editableImageFields.stylist_4,
      name: editableFields.stylist_4_name,
      description: editableFields.stylist_4_description,
      label: editableFields.stylist_4_label,
      long_description: editableFields.stylist_4_long_description,
    },
    {
      key: 5,
      image: editableImageFields.stylist_5,
      name: editableFields.stylist_5_name,
      description: editableFields.stylist_5_description,
      label: editableFields.stylist_5_label,
      long_description: editableFields.stylist_5_long_description,
    },
    {
      key: 6,
      image: editableImageFields.stylist_6,
      name: editableFields.stylist_6_name,
      description: editableFields.stylist_6_description,
      label: editableFields.stylist_6_label,
      long_description: editableFields.stylist_6_long_description,
    },
    {
      key: 7,
      image: editableImageFields.stylist_7,
      name: editableFields.stylist_7_name,
      description: editableFields.stylist_7_description,
      label: editableFields.stylist_7_label,
      long_description: editableFields.stylist_7_long_description,
    },
    {
      key: 8,
      image: editableImageFields.stylist_8,
      name: editableFields.stylist_8_name,
      description: editableFields.stylist_8_description,
      label: editableFields.stylist_8_label,
      long_description: editableFields.stylist_8_long_description,
    },
  ];

  const numberOfSliders = destinationExperts.length;

  const preparedDestinationExpertsData = preparedAvailableItemsForRender(
    destinationExperts,
    isEditSectionMode,
    ['description'],
  );

  /**
   * Display slider arrows based on the current slide (mobile template).
   * @param newIndex
   */
  const beforeChange = (newIndex) => {
    setCurrentSlide(newIndex);
    setLastSlide(newIndex === numberOfSliders - 1);
    setFirstSlide(newIndex === 0);
  };

  /**
   * Custom handler for next slide transition (mobile template).
   */
  const slideNext = () => {
    slider.slideNext();
  };

  /**
   * Custom handler for previous slide transition (mobile template).
   */
  const slidePrev = () => {
    slider.slidePrev();
  };

  /**
   * @returns {*[]}
   */
  const renderItemTemplate = (stylist) => (
    <>
      <div className="destinationExpertsSection__item-card">
        <div className="destinationExpertsSection__item-image">
          <EditableImg image={stylist.image} width={233} height={233}>
            <Image
              data={stylist.image.content}
              type="PageContent"
              config={{
                size: IMAGE_SIZES.IMAGE_W350,
                squared: true,
              }}
              background
            />
          </EditableImg>
        </div>

        <div className="destinationExpertsSection__item-label">
          <div className="item-label item-label--front">
            <Editable fieldValue={stylist.label} />
          </div>
          <div className="item-label item-label--back">
            <Editable fieldValue={stylist.long_description} />
          </div>
        </div>
      </div>

      <div className="destinationExpertsSection__item-name">
        <Editable fieldValue={stylist.name} />
      </div>

      <div className="destinationExpertsSection__item-description">
        <Editable fieldValue={stylist.description} isOptionalField />
      </div>
    </>
  );

  /**
   * Render template for desktop.
   * @returns {*[]}
   */
  const renderDesktopTemplate = () =>
    preparedDestinationExpertsData.map((stylist) => (
      <div key={stylist.key} className="destinationExpertsSection__item">
        {renderItemTemplate(stylist)}
      </div>
    ));

  /**
   * Render template for mobile.
   * @returns {*}
   */
  const renderMobileTemplate = () => (
    <>
      <Slider
        sliderProps={{
          onSwiper: setSlider,
          onSlideChange: ({ activeIndex }) => beforeChange(activeIndex),
          className: 'destinationExpertsSection__slider',
        }}
      >
        {preparedDestinationExpertsData.map((stylist) => (
          <div key={stylist.key} className="destinationExpertsSection__item">
            {renderItemTemplate(stylist)}

            <div className="item-long-description">
              <Editable fieldValue={stylist.long_description} />
            </div>
          </div>
        ))}
      </Slider>

      <div className="destinationExpertsSection__slider-nav">
        <button
          type="button"
          className={`destinationExpertsSection__slider-arrow destinationExpertsSection__slider-arrow--prev ${
            isFirstSlide ? 'disabled' : ''
          }`}
          onClick={() => slidePrev()}
        />

        <span>
          {currentSlide + 1} / {numberOfSliders}
        </span>

        <button
          type="button"
          className={`destinationExpertsSection__slider-arrow destinationExpertsSection__slider-arrow--next ${
            isLastSlide ? 'disabled' : ''
          }`}
          onClick={() => slideNext()}
        />
      </div>
    </>
  );

  return (
    <section className="destinationExpertsSection" id={id}>
      <div className="wrapper">
        <div className="section-title section-title--center">
          <Editable fieldValue={editableFields.stylists_heading} />
        </div>

        <div className="destinationExpertsSection__list">
          {isMobile(screenTypeContext) ? renderMobileTemplate() : renderDesktopTemplate()}
        </div>
      </div>
    </section>
  );
};

export default ErrorBoundaryDecorator()(DestinationExpertsSection);
