import { useContext, useState } from 'react';
import PropTypes from 'prop-types';

import UserContext from '@jsv2/context/UserContext';
import { updateUserInfo } from '@jsv2/utils/UserUtils/APIRequest';

const AddressStep = ({ step, getNextStep, render }) => {
  const { updateUserRoles } = useContext(UserContext);

  const [isLoading, setIsLoading] = useState(false);
  const [serverError, setError] = useState(null);

  const handleSubmit = (values, actions) => {
    actions.setSubmitting(true);
    setError(null);
    setIsLoading(true);

    updateUserInfo(values)
      .then(() => {
        setIsLoading(false);
        actions.setSubmitting(false);
        getNextStep(step.onComplete());
        updateUserRoles();
      })
      .catch(({ response }) => {
        setIsLoading(false);
        actions.setSubmitting(false);

        if (response.status !== 422) {
          setError(response.data.message);
        }

        if (response.data?.data) {
          actions.setErrors(response.data.data);
        }
      });
  };

  return render({ isLoading, serverError, handleSubmit });
};

AddressStep.propTypes = {
  render: PropTypes.func.isRequired,
  step: PropTypes.shape({
    component: PropTypes.func.isRequired,
    onComplete: PropTypes.func.isRequired,
  }).isRequired,
  getNextStep: PropTypes.func.isRequired,
};

export default AddressStep;
