import React, { useRef, useState, useEffect, useLayoutEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import throttle from 'lodash/throttle';
import classNames from 'classnames';

import ErrorBoundaryDecorator from '@components/decorators/ErrorBoundaryDecorator';
import { storageURL } from '@jsv3/utils/urlUtils';
import * as SvgBtn from '@components/PlayPauseButtonSvg';

const config = {
  seconds: 15,
};

const VideoPlayer = ({
  videoWrapperHeight,
  posterSrc,
  videoSrc,
  playButton,
  toggleAudioPlayback,
  isAudioPlay,
  documentParameters,
  checkDocumentParameters,
  setVideoProps,
  isFreezeFrame,
  className,
  videoShowPropsHandler,
}) => {
  const videoWrapper = useRef(null);
  const videoPopup = useRef(null);
  const video = useRef(null);
  const barSize = useRef(null);
  const bar = useRef(null);
  const progressBar = useRef(null);
  const videoTimer = useRef(null);

  const videoSourceSrc = useMemo(() => storageURL(videoSrc), [videoSrc]);

  const replayHandler = (seconds) => {
    video.current.currentTime -= seconds;
  };

  const forwardHandler = (seconds) => {
    video.current.currentTime += seconds;
  };

  const play = () => video.current.play();
  const pause = () => video.current.pause();
  const replay = () => replayHandler(config.seconds);
  const forward = () => forwardHandler(config.seconds);

  const [isPlay, setIsPlay] = useState(false);
  const [isVideoShow, setIsVideoShow] = useState(false);
  const [isOverlayShow, setIsOverlayShow] = useState(false);
  const [isMouseMove, setIsMouseMove] = useState(false);
  const [isFreeze, setIsFreeze] = useState(false);

  const afterPlay = () => {
    if (toggleAudioPlayback) {
      toggleAudioPlayback(false);
    }
  };

  const playVideo = () => {
    setIsFreeze(false);
    setIsVideoShow(true);
    setIsPlay(true);
    setIsOverlayShow(true);
    play();
    afterPlay();
    checkDocumentParameters();
    videoShowPropsHandler(true);
    return null;
  };

  const pauseVideo = () => {
    pause();
    return null;
  };

  const closeVideo = () => {
    setIsVideoShow(false);
    setIsPlay(false);
    setIsOverlayShow(false);
    pauseVideo();
    videoShowPropsHandler(false);
    return null;
  };

  const togglePlayPauseVideo = () => {
    if (isPlay) {
      pause();
      setIsPlay(false);
    } else {
      play();
      if (isFreezeFrame) {
        setIsFreeze(false);
        setIsOverlayShow(true);
      }
      setIsPlay(true);
      afterPlay();
    }
    return null;
  };

  const freezeVideo = () => {
    pause();
    setIsPlay(false);
    setIsOverlayShow(false);
    setIsFreeze(true);
    return null;
  };

  const timeout = () =>
    setTimeout(() => {
      setIsMouseMove(true);
    }, 1000);

  const handleMouseMove = () => {
    setIsMouseMove(false);
    clearTimeout(timeout);
    timeout();
  };

  const onClickBar = (e) => {
    if (!video.current.ended) {
      const mouseX = e.pageX - bar.current.offsetLeft - video.current.offsetLeft;
      const newtime = (mouseX * video.current.duration) / barSize.current.clientWidth;
      video.current.currentTime = newtime;
      progressBar.current.style.width = mouseX + 'px';
    }
  };

  const getCurTime = () => {
    if (!video.current.ended) {
      const timer = new Date(video.current.currentTime * 1000).toISOString().substr(11, 8);
      videoTimer.current.innerHTML = timer;

      const size = Number(
        (video.current.currentTime * barSize.current.clientWidth) / video.current.duration,
      );

      progressBar.current.style.width = size + 'px';
    } else {
      progressBar.current.style.width = '0px';
    }
  };

  const handleVideoShowing = throttle(() => {
    const videoWrapperProps = videoWrapper.current.getBoundingClientRect();
    const videoPopupProps = videoPopup.current.getBoundingClientRect();

    if (
      isVideoShow &&
      (videoWrapperProps.top > videoPopupProps.height || videoWrapperProps.top < -200)
    ) {
      if (isFreezeFrame) {
        freezeVideo();
        return;
      }
      closeVideo();
    }
  }, 500);

  useEffect(() => {
    window.addEventListener('scroll', handleVideoShowing, { passive: true });
    return () => {
      window.removeEventListener('scroll', handleVideoShowing);
    };
  }, [isPlay]);

  useEffect(() => {
    video.current.addEventListener('timeupdate', getCurTime);
    videoPopup.current.addEventListener('mousemove', (e) => handleMouseMove(e));
    bar.current.addEventListener('click', onClickBar, false);

    video.current.addEventListener('loadeddata', (e) =>
      setVideoProps({
        width: e.target.videoWidth,
        height: e.target.videoHeight,
      }),
    );
  }, []);

  useLayoutEffect(
    () => () => {
      video.current.removeEventListener('timeupdate', getCurTime);
      videoPopup.current.removeEventListener('mousemove', (e) => handleMouseMove(e));
      bar.current.removeEventListener('click', onClickBar, false);
    },
    [],
  );

  useEffect(() => {
    if (isAudioPlay) {
      closeVideo();
    }
  }, [isAudioPlay]);

  const classes = classNames('video-popup', {
    show: isVideoShow,
    hidden: !isVideoShow,
    freeze: isFreeze,
  });

  return (
    <>
      {isOverlayShow && <div className="video-popup-overlay" />}
      <div
        ref={videoWrapper}
        className={`video-wrapper ${className}`}
        style={{ height: isFreezeFrame ? videoWrapperHeight : '100%' }}
      >
        <div
          ref={videoPopup}
          className={classes}
          style={{
            top: !isFreeze && `${documentParameters.headerHeight}px`,
            height: !isFreeze && `${documentParameters.actualHeight}px`,
          }}
        >
          <button type="button" className="btn-close button-close" onClick={closeVideo} />

          <div
            className={`video-popup__layover ${isPlay ? 'show' : 'hidden'}`}
            style={{
              width: '100%',
              margin: '0 auto',
              height: isFreeze ? videoWrapperHeight : `${documentParameters.videoHeight}px`,
            }}
          >
            {/* eslint-disable-next-line jsx-a11y/media-has-caption */}
            <video
              ref={video}
              onEnded={closeVideo}
              preload="none"
              width={documentParameters.videoWidth || '100%'}
              height={documentParameters.videoHeight || '100%'}
              onClick={togglePlayPauseVideo}
            >
              <source data-src={videoSourceSrc} src={videoSourceSrc} type="video/mp4" />
            </video>
            <div
              id="video-controls-wrapper"
              className={`video-controls-wrapper ${isMouseMove ? 'hiddenControls' : ''}`}
              style={{
                width: `${documentParameters.videoWidth}px`,
              }}
            >
              <div className="video-controls-wrapper__left-side">
                <div ref={barSize} className="progress-bar">
                  <div
                    ref={bar}
                    className="defaultBar"
                    style={{ width: (barSize.current && barSize.current.clientWidth) || 0 }}
                  >
                    <div ref={progressBar} className="progressBar " />
                  </div>
                </div>
                <div ref={videoTimer} className="video-timer" />
              </div>
              <div className="video-controls-wrapper__right-side">
                <div className="video-controls">
                  {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
                  <div className="btn-video back-video" onClick={replay}>
                    <SvgBtn.BackButtonSvg />
                  </div>

                  {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
                  <div className="btn-video pause-video" onClick={togglePlayPauseVideo}>
                    {isPlay ? <SvgBtn.PauseButtonSvg /> : <SvgBtn.PlayButtonSvg />}
                  </div>

                  {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
                  <div className="btn-video forward-video" onClick={forward}>
                    <SvgBtn.ForwardButtonSvg />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="video-poster" style={posterSrc && { backgroundImage: `url(${posterSrc})` }}>
          {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
          <div
            className="btn-video play-video"
            onClick={playVideo}
            style={{ display: isPlay ? 'none' : 'block' }}
          >
            {playButton?.() || <SvgBtn.PlayButtonSvg />}
          </div>
        </div>
      </div>
    </>
  );
};

VideoPlayer.propTypes = {
  videoWrapperHeight: PropTypes.number.isRequired,
  posterSrc: PropTypes.string,
  documentParameters: PropTypes.object.isRequired,
  checkDocumentParameters: PropTypes.func.isRequired,
  setVideoProps: PropTypes.func.isRequired,
  toggleAudioPlayback: PropTypes.func,
  videoSrc: PropTypes.string.isRequired,
  playButton: PropTypes.func,
  isAudioPlay: PropTypes.bool,
  isFreezeFrame: PropTypes.bool,
  className: PropTypes.string,
  videoShowPropsHandler: PropTypes.func,
};

VideoPlayer.defaultProps = {
  playButton: null,
  isFreezeFrame: false,
  toggleAudioPlayback: null,
  isAudioPlay: false,
  className: '',
  posterSrc: null,
  videoShowPropsHandler: () => {},
};

export default ErrorBoundaryDecorator()(VideoPlayer);
