import React, { useContext } from 'react';

import ErrorBoundaryDecorator from '@components/decorators/ErrorBoundaryDecorator';
import EditFieldContext, { EditableImg } from '@jsv2/LandingPage/context/EditFieldContext';
import Image from '@jsv2/components/Image';
import { IMAGE_SIZES } from '@js/constants';

const SingleImage = React.forwardRef(({ id, imageVariable = 'single_image' }, ref) => {
  const { editableImageFields } = useContext(EditFieldContext);
  const image = editableImageFields[imageVariable];

  return (
    <>
      {editableImageFields && (
        <section className="single-image-section" id={id} ref={ref}>
          <div className="main-container">
            <div className="single-image-section__image">
              {image && (
                <EditableImg image={image} width={1020} height={705}>
                  <Image
                    data={image && image.content}
                    type="PageContent"
                    config={{
                      size: IMAGE_SIZES.LARGE,
                    }}
                    background
                  />
                </EditableImg>
              )}
            </div>
          </div>
        </section>
      )}
    </>
  );
});

SingleImage.displayName = 'SingleImage';

export default ErrorBoundaryDecorator()(SingleImage);
