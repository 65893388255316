import React from 'react';
import classNames from 'classnames';

export const PrevArrow = React.forwardRef(({ smallArrow = false, isAbsolute = false }, ref) => {
  const className = classNames({
    'swiper-black-arrow swiper-black-arrow-prev': true,
    'swiper-black-arrow--small': smallArrow,
    'swiper-black-arrow--absolute': isAbsolute,
  });

  return <button ref={ref} type="button" className={className} data-qa-id="arrow_prev_btn" />;
});

export const NextArrow = React.forwardRef(({ smallArrow = false, isAbsolute = false }, ref) => {
  const className = classNames({
    'swiper-black-arrow swiper-black-arrow-next': true,
    'swiper-black-arrow--small': smallArrow,
    'swiper-black-arrow--absolute': isAbsolute,
  });

  return <button ref={ref} type="button" className={className} data-qa-id="arrow_next_btn" />;
});
