import React, { useContext, useState } from 'react';
import pluralize from 'pluralize';
import PropTypes from 'prop-types';

import Slider from '@jsv2/components/Slider/Slider';
import ErrorBoundaryDecorator from '@components/decorators/ErrorBoundaryDecorator';
import SIGNUP_FLOW from '@jsv2/config/SignUp/flows';
import Image from '@jsv2/components/Image';
import Price from '@components/Price';
import { isIncompleteProfiler, isIncompleteSignUp } from '@jsv2/utils/UserUtils/userStatuses';
import ScreenTypeContext, { isMobile } from '@js/context/ScreenTypeContext';
import SignUpContext from '@jsv2/context/SignUpContext';
import UserContext from '@jsv2/context/UserContext';

// Slider settings
const settingsMobile = {
  className: 'choose-plan__wrapper',
  slidesPerView: 'auto',
  spaceBetween: 10,
  pagination: {
    clickable: true,
  },
};

const settingsDesktop = {
  className: 'plans_slider',
  slidesPerView: 2,
  spaceBetween: 10,
  navigation: true,
};

const MAX_NUMBER_OF_VISIBLE_PLANS_DESKTOP = 2;

const MAX_NUMBER_OF_VISIBLE_PLANS_MOBILE = 1;

const ChoosePlan = ({ onSelectPlan, isSignUpStep }) => {
  const screenTypeContext = useContext(ScreenTypeContext);

  const { openModal, updateSignUpData, signUpProcess, membershipOptions } = useContext(
    SignUpContext,
  );
  const { customer } = useContext(UserContext);
  const [indexOfActivePlan, setIndexOfActivePlan] = useState(0);
  const [sliderRef, setSliderRef] = useState(null);

  const initSignUpProcess = (plan) => {
    if (isSignUpStep) {
      updateSignUpData({ flow: SIGNUP_FLOW.DEPOSIT_FLOW, membershipSummary: plan });

      onSelectPlan();
    } else if (
      !isIncompleteProfiler(customer.roles) ||
      (isIncompleteProfiler(customer.roles) && signUpProcess.flow === plan.flow)
    ) {
      updateSignUpData({ flow: plan.flow, membershipSummary: plan });

      setTimeout(openModal);
    }
  };

  const tabClickHandler = (index) => sliderRef.slideTo(index);

  const renderFlowPlanPrice = (plan) => {
    const isOneOfBookingFlow = plan.flow === SIGNUP_FLOW.ONE_OF_BOOKING;

    return isOneOfBookingFlow ? (
      <div className="value choose-plan__item-price">
        <Price
          inline
          price={plan.signupFee.current.amount}
          currency_iso3={plan.signupFee.current.currencyIso3}
          hideDecimalsForIntegers
          dataQaId="membership-deposit-value"
        />

        <span className="deposit-label">Deposit</span>

        <div className="deposit-notes">
          We guarantee the best rates & VIP Benefits or you get your deposit back.
        </div>
      </div>
    ) : (
      <div className="value">
        {isSignUpStep && plan.signupFee.current.amount > 0 ? (
          <Price
            inline
            price={plan.signupFee.current.amount}
            currency_iso3={plan.signupFee.current.currencyIso3}
            hideDecimalsForIntegers
            dataQaId="membership-price-value"
          />
        ) : (
          'FREE'
        )}
      </div>
    );
  };

  const renderPlans = () =>
    membershipOptions.map((plan, index) => {
      const isDisabled =
        (isIncompleteSignUp(customer.roles) || isIncompleteProfiler(customer.roles)) &&
        signUpProcess.flow !== plan.flow &&
        !isSignUpStep;
      const isDisabledClassName = isDisabled ? ' choose-plan__item--disabled-plan' : '';
      const freePlanClassName =
        plan.flow === SIGNUP_FLOW.FREE ? ' choose-plan__item--free-plan' : '';

      return (
        // eslint-disable-next-line jsx-a11y/no-static-element-interactions,jsx-a11y/click-events-have-key-events
        <div
          key={plan.membershipPlanId}
          className={`choose-plan__item${isDisabledClassName}${freePlanClassName}`}
          onClick={() => initSignUpProcess(plan, index)}
          data-qa-id={`membership-join-${index + 1}`}
        >
          <span className="choose-plan__stroke" />

          <div className="choose-plan__item-info">
            {plan.image && (
              <Image
                className="choose-plan__item-icon"
                data={plan.image}
                config={{
                  size: 300,
                }}
                background
              />
            )}

            <div className="choose-plan__item-title">
              {plan.name}
              {plan.is_beta && <span className="choose-plan__item-label">BETA</span>}
            </div>

            {plan.caption && <div className="choose-plan__sub">{plan.caption}</div>}

            <ul className="choose-plan__item-list check-mark-style">
              {plan.benefits.current.map((benefit) => (
                <li key={benefit.id}>{benefit.name}</li>
              ))}
            </ul>
          </div>

          <div className="choose-plan__item-price">
            {plan.recurringCharge.current.amount > 0 ? (
              <>
                <div className="value">
                  <Price
                    inline
                    price={plan.recurringCharge.current.amount}
                    currency_iso3={plan.recurringCharge.current.currencyIso3}
                    hideDecimalsForIntegers
                    dataQaId="membership-price-value"
                  />
                </div>
                <div className="period" data-qa-id="price_interval">
                  {plan.recurringInterval.current}*
                </div>
              </>
            ) : (
              renderFlowPlanPrice(plan)
            )}
          </div>
        </div>
      );
    });

  const renderHappinessGuarantee = () => {
    const membership = membershipOptions.find((item) => item.moneybackPeriod.current !== null);

    if (!membership) {
      return null;
    }

    return (
      <div className="choose-plan__note">
        <p data-qa-id="inclusions_note">
          *Our Happiness Guarantee: If for any reason you’re not loving your {membership.name}{' '}
          membership, let us know within
          {t(' ')}
          <span data-qa-id="moneyback">
            {pluralize(t('day'), membership.moneybackPeriod.current, true)}
          </span>
          {t(' and we’ll refund you in full, no questions asked.')}
        </p>
      </div>
    );
  };

  const renderMobileTabs = () => (
    <div className="choose-plan__mobile-labels">
      {membershipOptions.map((plan, index) => (
        // eslint-disable-next-line jsx-a11y/no-static-element-interactions,jsx-a11y/click-events-have-key-events
        <div
          key={plan.membershipPlanId}
          className={`choose-plan__mobile-label ${indexOfActivePlan === index ? 'active' : ''}`}
          onClick={() => tabClickHandler(index)}
        >
          {plan.name}
          {plan.is_beta && <span className="choose-plan__item-label">BETA</span>}
        </div>
      ))}
    </div>
  );

  const renderMobilePlanBlock = () => (
    <>
      {renderMobileTabs()}

      {membershipOptions.length <= MAX_NUMBER_OF_VISIBLE_PLANS_MOBILE ? (
        <div className="choose-plan__wrapper">{renderPlans()}</div>
      ) : (
        <Slider
          sliderProps={{
            onSwiper: setSliderRef,
            onSlideChange: ({ activeIndex }) => setIndexOfActivePlan(activeIndex),
            ...settingsMobile,
          }}
        >
          {renderPlans()}
        </Slider>
      )}
    </>
  );

  const renderDesktopPlanBlock = () => (
    <>
      {membershipOptions.length <= MAX_NUMBER_OF_VISIBLE_PLANS_DESKTOP ? (
        <div
          className={`choose-plan__wrapper 
                  ${membershipOptions.length === 1 ? 'choose-plan__wrapper--center' : ''}`}
        >
          {renderPlans()}
        </div>
      ) : (
        <Slider
          sliderProps={{
            onSwiper: setSliderRef,
            onSlideChange: ({ activeIndex }) => setIndexOfActivePlan(activeIndex),
            ...settingsDesktop,
          }}
        >
          {renderPlans()}
        </Slider>
      )}
    </>
  );

  return (
    <>
      {isMobile(screenTypeContext) ? renderMobilePlanBlock() : renderDesktopPlanBlock()}

      {renderHappinessGuarantee()}
    </>
  );
};

ChoosePlan.propTypes = {
  onSelectPlan: PropTypes.func,
  isSignUpStep: PropTypes.bool,
};

ChoosePlan.defaultProps = {
  onSelectPlan: null,
  isSignUpStep: false,
};

export default ErrorBoundaryDecorator()(ChoosePlan);
