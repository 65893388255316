import { createStore, applyMiddleware } from 'redux';
// eslint-disable-next-line import/no-extraneous-dependencies
import { composeWithDevTools } from 'redux-devtools-extension';
import thunk from 'redux-thunk';
import rootReducer from './ducks';

const configureStore = () =>
  createStore(
    rootReducer,
    {
      adConfig: window.AD_CONFIG,
    },
    composeWithDevTools(applyMiddleware(thunk)),
  );

export default configureStore;
