import React, { useContext } from 'react';

import TravelProfiler from '@jsv2/components/TravelProfiler/index';
import WizardContext from '@jsv2/context/WizardContext';
import UserContext from '@jsv2/context/UserContext';

const TravelProfilerStep = ({ getNextStep }) => {
  const { onComplete } = useContext(WizardContext).stepConfig;
  const { customer, updateUserRoles } = useContext(UserContext);

  const handleClick = () => {
    getNextStep(onComplete(customer.roles));
    updateUserRoles();
  };

  return <TravelProfiler getNextStep={handleClick} />;
};

export default TravelProfilerStep;
