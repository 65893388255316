import isArray from 'lodash/isArray';

/**
 * Takes default errors object from server and extracts
 * only first error for each field
 *
 * @param {Object} errorsObj - object with server errors
 *
 * @returns {Object} errors object whit only one error for each field
 */
const parseFormErrors = (errorsObj) =>
  Object.entries(errorsObj).reduce((result, [key, val]) => {
    result[key] = isArray(val) ? val[0] : val;

    return result;
  }, {});

export default parseFormErrors;
