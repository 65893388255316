import STEPS from '@jsv2/config/SignUp/steps';
import ROLES from '@jsv2/config/userRoles';
import URLS from '@jsv2/config/SignUp/urls';
import DISABLED_SIGNUP_MESSAGE from '@jsv2/templates/vipTraveler/SignUp/Messages/DisabledSignupMessage';

const disabledAuthFlowConfig = {
  [STEPS.DISABLED_SIGNUP_MESSAGE]: {
    name: STEPS.DISABLED_SIGNUP_MESSAGE,
    component: DISABLED_SIGNUP_MESSAGE,
    onComplete: () => window.location.reload(),
    isClosable: false,
    url: URLS[STEPS.DISABLED_SIGNUP_MESSAGE],
    roles: {
      [ROLES.ANONYMOUS]: true,
    },
  },
};

export default disabledAuthFlowConfig;
