import React from 'react';
import ReactPhoneInput from 'react-phone-input-2';

import ErrorBoundaryDecorator from '@components/decorators/ErrorBoundaryDecorator';

const PhoneInputAdapter = ({
  field,
  form: { setFieldValue, setFieldError },
  ...props
}) => (
  <ReactPhoneInput
    inputStyle={{
      paddingLeft: '60px',
    }}
    buttonStyle={{
      outline: 'none',
      background: 'none',
      border: 'none',
    }}
    {...field}
    onChange={(val) => {
      setFieldError(field.name, null);
      setFieldValue(field.name, `+${val}`);
    }}
    {...props}
  />
);

export default ErrorBoundaryDecorator()(PhoneInputAdapter);
