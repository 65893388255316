import React, { useContext } from 'react';
import PropTypes from 'prop-types';

import ChoosePlanStepDefault from '@jsv2/components/Authorization/ChoosePlanStepDefault';
import ProgressBar from '@jsv2/components/Authorization/ProgressBar';
import Price from '@components/Price';
import SignUpContext from '@jsv2/context/SignUpContext';
import pluralize from 'pluralize';

const ChoosePlanStep = ({ step, getNextStep }) => {
  const { membershipSummary } = useContext(SignUpContext).signUpProcess;
  const {
    recurringInterval,
    moneybackPeriod,
    recurringBonus,
    signupBonus,
    recurringCharge,
    membershipPlanId,
  } = membershipSummary;

  return (
    <ChoosePlanStepDefault
      step={step}
      getNextStep={getNextStep}
      render={({ handleFreeChoice, handlePaidChoice }) => (
        <div className="viptraveler-modal">
          <div className="signup signup--step3 signup--plans">
            <ProgressBar activeStep={3} />

            <div className="plans-block">
              <div className="plans-block__heading">
                <h2 className="heading">Choose your plan</h2>

                <div className="tagline">
                  Try our premium membership risk-free to gain on-demand access to a personal
                  travel stylist
                </div>
              </div>

              <div className="plans-block__table">
                <div className="plans-block__table-row plans-block__table-row--first">
                  <div className="plans-block__table-col">
                    VIP MEMBERSHIP
                  </div>


                  <div className="plans-block__table-col">
                    VIP+ MEMBERSHIP
                  </div>
                </div>

                <div className="plans-block__table-row plans-block__table-row--gray">
                  <div className="plans-block__table-col plans-block__table-col--title">
                    <p>Personalized Travel Finds™</p>
                  </div>

                  <div className="plans-block__table-col">
                    <span className="check check--black" />
                  </div>

                  <div className="plans-block__table-col">
                    <span className="check check--pink" />
                  </div>
                </div>

                <div className="plans-block__table-row">
                  <div className="plans-block__table-col plans-block__table-col--title">
                    <p>VIP Benefits</p>
                  </div>

                  <div className="plans-block__table-col">
                    <span className="check check--black" />
                  </div>

                  <div className="plans-block__table-col">
                    <span className="check check--pink" />
                  </div>
                </div>

                <div className="plans-block__table-row plans-block__table-row--gray">
                  <div className="plans-block__table-col plans-block__table-col--title">
                    <p>Members Only Savings</p>
                  </div>

                  <div className="plans-block__table-col">
                    <span className="check check--black" />
                  </div>

                  <div className="plans-block__table-col">
                    <span className="check check--pink" />
                  </div>
                </div>

                <div className="plans-block__table-row">
                  <div className="plans-block__table-col plans-block__table-col--title">
                    <p>A Dedicated Travel Stylist</p>
                  </div>

                  <div className="plans-block__table-col">
                    <span className="dash" />
                  </div>

                  <div className="plans-block__table-col">
                    <span className="check check--pink" />
                  </div>
                </div>

                <div className="plans-block__table-row plans-block__table-row--gray">
                  <div className="plans-block__table-col plans-block__table-col--title">
                    <p>Call, Text,<br /> or Chat 24/7</p>
                  </div>

                  <div className="plans-block__table-col">
                    <span className="dash" />
                  </div>

                  <div className="plans-block__table-col">
                    <span className="check check--pink" />
                  </div>
                </div>

                {
                  membershipSummary.membershipPlanId === 9 && (
                    <div className="plans-block__table-row">
                      <div className="plans-block__table-col plans-block__table-col--title">
                        {signupBonus.current
                        && (
                          <Price
                            inline
                            price={signupBonus.current.amount}
                            currency_iso3={signupBonus.current.currencyIso3}
                            hideDecimalsForIntegers
                          />
                        )}
                        <span>{t(' VIP Traveler Dollars ')} to get you started</span>
                      </div>

                      <div className="plans-block__table-col">
                        <span className="check check--black" />
                      </div>

                      <div className="plans-block__table-col">
                        <span className="check check--pink" />
                      </div>
                    </div>
                  )
                }

                {
                  membershipSummary.membershipPlanId !== 9 && (
                    <div className="plans-block__table-row">
                      <div className="plans-block__table-col plans-block__table-col--title">
                        {recurringBonus.current
                        && (
                          <Price
                            inline
                            price={recurringBonus.current.amount}
                            currency_iso3={recurringBonus.current.currencyIso3}
                            hideDecimalsForIntegers
                          />
                        )}
                        <span>{t(' VIP Traveler Dollars ')}{recurringInterval.current}</span>
                      </div>

                      <div className="plans-block__table-col">
                        <span className="dash" />
                      </div>

                      <div className="plans-block__table-col">
                        <span className="check check--pink" />
                      </div>
                    </div>
                  )
                }


                <div className="plans-block__table-row plans-block__table-row--pink">
                  <div className="plans-block__table-col">
                    Membership Fee
                  </div>

                  <div className="plans-block__table-col">
                    FREE
                  </div>

                  <div className="plans-block__table-col">
                    {recurringCharge.current && (
                      <>
                        <Price
                          inline
                          price={recurringCharge.current.amount}
                          currency_iso3={recurringCharge.current.currencyIso3}
                          hideDecimalsForIntegers
                          dataQaId="credit_value"
                        />
                        <span>{recurringInterval.current}*</span>
                      </>
                    )}
                  </div>
                </div>

                <div className="plans-block__table-row plans-block__table-row--buttons">
                  <div className="plans-block__table-col">
                    <img
                      className="plans-block__logo"
                      src="/images/lp/promo2/logotype-black.svg"
                      alt="Vip Traveler"
                    />
                  </div>

                  <div className="plans-block__table-col">
                    <button
                      type="button"
                      className="plans-block__button"
                      data-qa-id="select-free"
                      data-plan-id={membershipPlanId}
                      onClick={() => handleFreeChoice()}
                    >
                      select
                    </button>
                  </div>

                  <div className="plans-block__table-col">
                    <button
                      type="button"
                      className="plans-block__button"
                      data-qa-id="select-paid"
                      data-plan-id={membershipPlanId}
                      onClick={() => handlePaidChoice()}
                    >
                      select
                    </button>
                  </div>
                </div>
              </div>

              <div className="plans-block__footnote">
                *HAPPINESS GUARANTEED! If you are unhappy with our premium membership for any
                reason, let us know within {pluralize(t('day'), moneybackPeriod.current, true)},
                and we will refund you - no questions asked!
              </div>
            </div>
          </div>
        </div>
      )}
    />
  );
};

ChoosePlanStep.propTypes = {
  step: PropTypes.shape({
    component: PropTypes.func.isRequired,
    onComplete: PropTypes.func.isRequired,
  }).isRequired,
  getNextStep: PropTypes.func.isRequired,
};

export default ChoosePlanStep;
