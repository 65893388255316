import React from 'react';
import PropTypes from 'prop-types';

const GenericFormError = ({ message, className }) => (
  <div
    className={`error ${className}`}
    data-qa-id="error_message"
  >
    {message}
  </div>
);

GenericFormError.propTypes = {
  message: PropTypes.string.isRequired,
  className: PropTypes.string,
};


GenericFormError.defaultProps = {
  className: '',
};

export default GenericFormError;
