import React, { forwardRef } from 'react';
import classNames from 'classnames';

import { buttonPropTypes, buttonDefaultProps } from '@jsv3/propTypes/buttonProps';
import processEmptyProp from '@jsv3/utils/helpers/processEmptyPropHelper';

/**
 * Atom <button>
 */
const Button = forwardRef(
  ({ className, dataQaId, disableDefaultClassName, href, children, ...props }, ref) => {
    if (href) {
      return (
        // eslint-disable-next-line react/button-has-type
        <a
          ref={ref}
          className={classNames({ 'link-atom': !disableDefaultClassName }, className)}
          href={href}
          {...props}
          {...processEmptyProp('data-qa-id', dataQaId)}
          type={null}
        >
          {children}
        </a>
      );
    }

    return (
      // eslint-disable-next-line react/button-has-type
      <button
        ref={ref}
        className={classNames({ btn: !disableDefaultClassName }, className)}
        {...props}
        {...processEmptyProp('data-qa-id', dataQaId)}
      >
        {children}
      </button>
    );
  },
);

Button.propTypes = buttonPropTypes;
Button.defaultProps = buttonDefaultProps;

export default Button;
