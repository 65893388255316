import React, { useContext } from 'react';
import PropTypes from 'prop-types';

import PaymentStepDefault from '@jsv2/components/Authorization/PaymentStepDefault';
import UserContext from '@jsv2/context/UserContext';

const PaymentStepUpgrade = ({ step, getNextStep, render }) => {
  const { updateUserRoles } = useContext(UserContext);

  const handleFreeChoice = () => {
    if (typeof step.onUpgrade === 'function') {
      getNextStep(step.onUpgrade());
      updateUserRoles();
    }
  };

  return (
    <PaymentStepDefault
      getNextStep={getNextStep}
      step={step}
      render={props => render({ handleFreeChoice, ...props })}
    />
  );
};

PaymentStepUpgrade.propTypes = {
  render: PropTypes.func.isRequired,
  step: PropTypes.shape({
    component: PropTypes.func.isRequired,
    onComplete: PropTypes.func.isRequired,
  }).isRequired,
  getNextStep: PropTypes.func.isRequired,
};

export default PaymentStepUpgrade;
