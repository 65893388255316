import React, { useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';

import Spinner from '@components/Spinner';
import ErrorBoundaryDecorator from '@components/decorators/ErrorBoundaryDecorator';

/**
 * DropDown Block
 *
 * @param options
 * @param isDropDownOpen
 * @param addOptionInInput
 * @param isLoading
 * @param showCountry
 *
 * @return {*}
 *
 * @constructor
 */
const DropDownBlock = ({ options, isDropDownOpen, addOptionInInput, isLoading, showCountry }) => {
  const dropDownComponent = useRef(false);

  const [selected, setSelected] = useState(false);
  const [currentOption, setCurrentOption] = useState(false);
  const [cursor, setCursor] = useState(0);

  function downHandler(e) {
    if (e.keyCode === 38) {
      e.preventDefault();
      setCursor((prevState) => (prevState > 0 ? prevState - 1 : prevState));
    }
    if (e.keyCode === 40) {
      e.preventDefault();
      setCursor((prevState) => (prevState < options.length - 1 ? prevState + 1 : prevState));
    }
    if (e.keyCode === 13) {
      e.preventDefault();
      setSelected(true);
    }
  }

  useEffect(() => {
    window.addEventListener('keydown', downHandler);

    return () => {
      window.removeEventListener('keydown', downHandler);
    };
  }, [options]);

  const optionHeight = 37;

  const maxVisibleItems = 4;

  /**
   * Render Option Item
   *
   * @param item
   * @param active
   *
   * @return {*}
   */
  const OptionItem = ({ item, active }) => {
    let preparedName = item.name;

    if (showCountry && item.name !== item.country) {
      preparedName = item.name + ', ' + item.country;
    } else if (item.location) {
      preparedName = item.name + ', ' + item.location;
    }

    return (
      <button
        type="button"
        data-qa-id="search_option"
        className={`search-box__option ${active ? 'active' : ''}`}
        onClick={() => setCurrentOption(item)}
      >
        <div className="search-box__option-label">{preparedName}</div>
      </button>
    );
  };

  /**
   * DownPress watcher
   */
  useEffect(() => {
    if (cursor < maxVisibleItems) {
      dropDownComponent.current.scrollTop = 0;

      return;
    }

    if (cursor > options.length - maxVisibleItems - 1) {
      dropDownComponent.current.scrollTop = (options.length - maxVisibleItems - 1) * optionHeight;

      return;
    }

    dropDownComponent.current.scrollTop = cursor * optionHeight;
  }, [cursor]);

  /**
   * Cursor, EnterPress watcher
   */
  useEffect(() => {
    if (cursor) {
      setCurrentOption(options[cursor]);
    }
  }, [selected]);

  /**
   * Selected watcher
   */
  useEffect(() => {
    if (currentOption) {
      addOptionInInput(currentOption);
      setSelected(false);
    }
  }, [currentOption]);

  const renderItems = () => {
    if (isLoading) {
      return (
        <div className="search-box__loading search-box__option not-clickable">
          Loading <Spinner color="pink" className="search-box__spinner" />
        </div>
      );
    }

    if (options.length > 0) {
      return options.map((item, i) => (
        <OptionItem key={item.id} active={i === cursor} item={item} setSelected={setSelected} />
      ));
    }

    return <span className="search-box__option not-clickable">no result</span>;
  };

  return (
    <div
      ref={dropDownComponent}
      className={`drop-down-component ${isDropDownOpen ? 'active' : ''}`}
    >
      {renderItems()}
    </div>
  );
};

DropDownBlock.propTypes = {
  options: PropTypes.arrayOf(PropTypes.object).isRequired,
  isDropDownOpen: PropTypes.bool.isRequired,
  addOptionInInput: PropTypes.func.isRequired,
  isLoading: PropTypes.bool,
  showCountry: PropTypes.bool,
};

DropDownBlock.defaultProps = {
  isLoading: false,
  showCountry: false,
};

export default ErrorBoundaryDecorator()(DropDownBlock);
