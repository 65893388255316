import Cookies from 'browser-cookies';

const { shared_data: sharedData } = window.__SERVER_DATA__;

/**
 * Get url param using new URLSearchParams()
 *
 * @param param {string}
 * @param hasBase64 {boolean}
 *
 * @return {string}
 */
export const getUrlParam = (param, hasBase64 = false) => {
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const res = urlParams.get(param);

  return hasBase64 ? atob(res) : res;
};

/**
 * Delete url param using new URLSearchParams()
 *
 * @param param {string}
 *
 * @return {Object}
 */
export const deleteUrlParam = (param) => {
  const url = new URL(window.location.href);
  url.searchParams.delete(param);

  return url;
};

/**
 * Get link to go to Landing
 *
 * @return {string}
 */
export const getLinkToGo = () => sharedData.currentLandingPageUrl;

/**
 * Get static page url
 *
 * @return {string}
 */
export const getStaticPageUrl = (key) => {
  const { staticPages } = sharedData;

  if (staticPages && staticPages[key]) {
    return staticPages[key];
  }

  return '/';
};

/**
 * Check if url is absolute
 *
 * // https://github.com/sindresorhus/is-absolute-url/blob/master/index.js
 *
 * @return {Boolean}
 */
export const isAbsoluteUrl = (url) => /^([a-zA-Z][a-zA-Z\d+\-.]*:)?\/\//.test(url);

/**
 * Helper to join paths
 *
 * @return {String}
 */
export const joinPaths = (...args) =>
  args
    .map((part, i) => {
      if (i === 0) {
        return part.trim().replace(/\/*$/g, '');
      }

      return part.trim().replace(/(^\/*|\/*$)/g, '');
    })
    .filter((x) => x.length)
    .join('/');

/**
 * Check if url is from Facebook
 *
 * @return {Boolean}
 */
export const isFacebookUrl = () => getUrlParam('fbclid') || Cookies.get('userFromFacebook');

/**
 * Creating a parameter for the url to be used for the back button
 *
 * @return {String}
 */
export const getBackLinkToGo = () => {
  const backLinkPathname = window.location.pathname;
  const backLinkSearch = window.location.search;

  return btoa(backLinkPathname + backLinkSearch);
};

/**
 * Generate url with our storage
 *
 * @param url {String}
 *
 * @return {String}
 */
export const storageURL = (url) => {
  const baseUrl = window.STORAGE_URL || '/media';

  if (!baseUrl || isAbsoluteUrl(url)) {
    return url;
  }

  return joinPaths(baseUrl, url);
};

/**
 * Combine storage url with path and name parts.
 *
 * @param path {String}
 * @param name {String}
 *
 * @return {String}
 */
export const combineStorageUrl = (path, name) => storageURL([path, name].join('/'));

/**
 * Generate url with our resource
 *
 * @param url {String}
 *
 * @return {String}
 */
export const resourceURL = (url) => {
  const baseUrl = window.RESOURCE_URL;

  if (!baseUrl || isAbsoluteUrl(url)) {
    return url;
  }

  return joinPaths(baseUrl, url);
};
