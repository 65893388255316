import React from 'react';
import PropTypes from 'prop-types';

import ProgressBar from '@jsv2/components/Authorization/ProgressBar';
import SummaryDefault from './MembershipSummary/SummaryDefault';

const MembershipDefault = ({ hideProgressBar }) => (
  <div className="sidebar sidebar--step3">
    <div className="wrapper membership">
      {!hideProgressBar && <ProgressBar activeStep={3} />}

      <div className="summary__heading">
        <h3 className="heading">{t('membership summary')}</h3>
      </div>

      <SummaryDefault />
    </div>
  </div>
);

MembershipDefault.propTypes = {
  hideProgressBar: PropTypes.bool,
};

MembershipDefault.defaultProps = {
  hideProgressBar: false,
};

export default MembershipDefault;
