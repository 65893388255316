export const DESKTOP_SCREEN_WIDTH = 1920;
export const TABLET_SCREEN_WIDTH = 1195;
export const SMALL_TABLET_SCREEN_WIDTH = 1024;
export const MOBILE_SCREEN_WIDTH = 768;
export const MINI_SCREEN_WIDTH = 375;

export const DESKTOP_SCREEN_SMALL_HEIGHT = 800;

export const IMAGE_SIZES = {
  EXTRA_LARGE: 1920,
  LARGE: 1440,
  MEDIUM: 900,
  SMALL: 600,
  IMAGE_W350: 350,
  IMAGE_W150: 150,
  IMAGE_W100: 100,
};

export const AD_TYPES = {
  LAYOVER: 'layover',
  LANDING_PAGE: 'landing-page',
  BANNER: 'banner',
};

export const SCREEN_TYPES = {
  DESKTOP: 'desktop',
  TABLET: 'tablet',
  MOBILE: 'mobile',
  MINI: 'mini',
};

export const PAYMENT_TYPES = {
  CARD: 'card',
  GETAWAY: 'gateway',
};

export const PAYMENT_METHODS = {
  CREDIT_CARD: 'stripe',
  PAYPAL: 'paypal_payment',
  AMAZON_PAY: 'amazon',
};

export const DIALOG_TYPES = {
  ALERT: 'alert',
  CONFIRM: 'confirm',
};

export const PRODUCT_TYPES = {
  package: 'package',
  group: 'group',
  travelFind: 'travel-find',
  customProduct: 'product',
  listing: 'listing',
};

export const VENDOR_TYPES = {
  CODE_HOTEL: 'hotel',
  CODE_AIRLINE: 'airline',
  CODE_TOUR: 'tour',
  CODE_CRUISE: 'cruise',
};

export const HIDDEN_ATTR_POLYFILL_MAP = {
  hidden: 'visibilitychange',
  msHidden: 'msvisibilitychange',
  webkitHidden: 'webkitvisibilitychange',
  mozHidden: 'mozvisibilitychange',
};

export const CONVERSATION_MESSAGES_SOURCE_CODES = {
  CHAT: 'chat',
  EMAIL: 'email',
};
