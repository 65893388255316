import React from 'react';

/**
 * Bucket List Context
 *
 * @type {React.Context<{}>}
 */
const BucketListContext = React.createContext({});

export default BucketListContext;
