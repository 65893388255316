import React from 'react';
import PropTypes from 'prop-types';

import BaseMessageTemplate from '@jsv2/templates/vipTraveler/ModalMessages/BaseMessageTemplate';
import AuthMessage from '@jsv2/components/Authorization/AuthMessage';

/**
 * Pre Q Pop C (Paid Member).
 */

const MESSAGE_CONTENT = {
  image: '/images/lp/promo2/vip_illustration_hands.png',
  heading: 'Welcome to VIP Traveler!',
  tagline: 'Give us 2 minutes to learn what makes you travel happy.',
  buttonText: 'get started',
};

const PaidWelcomeMessage = ({ step, getNextStep }) => (
  <AuthMessage
    step={step}
    getNextStep={getNextStep}
    render={({ handleClick }) => (
      <BaseMessageTemplate
        handleClick={(handleClick)}
        {...MESSAGE_CONTENT}
      />
    )}
  />
);
PaidWelcomeMessage.propTypes = {
  step: PropTypes.shape({
    component: PropTypes.func.isRequired,
    onComplete: PropTypes.func.isRequired,
  }).isRequired,
  getNextStep: PropTypes.func.isRequired,
};

export default PaidWelcomeMessage;
