import Cost from '@jsv2/models/Cost';

export default class CostParameter {
  constructor(params) {
    this._current = params?.current ? new Cost(params.current) : null;
    this._initial = params?.initial ? new Cost(params.initial) : null;
  }

  get current() {
    return this._current;
  }

  get initial() {
    return this._initial;
  }
}
