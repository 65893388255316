export const EXPIRED_SESSION_RELOADED_KEY = 'expiredSessionPageReloaded';
export const EXPIRED_SESSION_CHAT_MESSAGE_KEY = 'expiredSessionChatData';
export const EXPIRED_SESSION_IS_LIVE_CHAT_KEY = 'expiredSessionIsLiveChat';
export const EXPIRED_SESSION_ALERT_MESSAGE = 'Your session has expired... Please, log in to continue.';
export const EXPIRED_SESSION_ALERT_BTN_CONTENT = 'Log in';
export const loginLayoverAdConfig = {
  type: 'layover',
  settings: {
    click_outside_out: false,
    esc_out: false,
    template_path: 'viptraveler.LoginLayover',
    x_out: false,
  },
};
