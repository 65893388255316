import React from 'react';
import PropTypes from 'prop-types';

import Image from '@jsv2/components/Image';
import { IMAGE_SIZES } from '@js/constants';

/**
 * Cards with an image, title and basic information.
 *
 * @param {object} item
 * @param {JSXElement} children
 * @param {boolean} isBackSlide
 * @param {string} customClass
 * @param {boolean} isContentOutside
 * @returns {JSX.Element}
 * @constructor
 */
const DestinationCardPreview = ({ item, children, isBackSlide, customClass, isContentOutside }) => {
  const Component = item.image ? Image : 'div';

  const params = item.image
    ? {
        data: item.image,
        config: {
          size: IMAGE_SIZES.SMALL,
          squared: false,
        },
        background: true,
      }
    : {};

  return (
    <div
      className={`destination-card-item ${
        isBackSlide ? 'destination-card-item--back-slide' : ''
      } ${customClass}`}
      data-qa-id="attraction-card-item"
    >
      <Component
        className={`destination-card-item__image destination-card-item__front ${
          item.image ? '' : 'new-destination'
        }`}
        {...params}
      >
        {/* eslint-disable-next-line jsx-a11y/anchor-has-content */}
        {item.link && <a className="destination-card-item__link" href={`/${item.link}`} />}

        {item.name && !isContentOutside && (
          <div className="destination-card-item__title" data-qa-id="item_title">
            {item.name}
          </div>
        )}
      </Component>

      {isBackSlide && (
        <Component className="destination-card-item__image destination-card-item__back" {...params}>
          {children}
        </Component>
      )}

      {isContentOutside && (
        <div className="destination-card-item__outside-content">
          {item.name && (
            <div className="destination-card-item__outside-title" data-qa-id="attraction_title">
              {item.name}
            </div>
          )}
          {item.description && (
            <div
              className="destination-card-item__outside-sub-title"
              data-qa-id="attraction_description"
            >
              {item.description}
            </div>
          )}
        </div>
      )}
    </div>
  );
};

DestinationCardPreview.propTypes = {
  item: PropTypes.object.isRequired,
  isBackSlide: PropTypes.bool,
  isContentOutside: PropTypes.bool,
  customClass: PropTypes.string,
  children: PropTypes.node,
};

DestinationCardPreview.defaultProps = {
  isBackSlide: false,
  isContentOutside: false,
  customClass: '',
  children: null,
};

export default DestinationCardPreview;
