import apiClient from '@js/apiClient';

// region TravelPreferences

/**
 * Get Countries List
 *
 * @return {Promise<AxiosResponse<any>>}
 */
export function getCountriesList() {
  return apiClient.get('countries');
}
