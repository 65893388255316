import axios from 'axios';

const defaultHeaders = {
  'X-Requested-With': 'XMLHttpRequest',
};

const tokenHeader = 'x-csrf-token';

let token = document.head.querySelector('meta[name="csrf-token"]');
if (token) {
  token = token.content;
} else {
  // eslint-disable-next-line no-console
  console.warn('CSRF token not found: https://laravel.com/docs/csrf#csrf-x-csrf-token');
}

const instanceDefaults = {
  baseURL: window.API_URL,
  headers: defaultHeaders,
};

const setRequestToken = (config) => {
  if (token) {
    config.headers[tokenHeader] = token;
  }
  return config;
};

const updateRequestToken = (response) => {
  const { headers } = response;
  if (headers[tokenHeader]) {
    token = headers[tokenHeader];
  }
  return response;
};

const updateRequestTokenFromError = (error) => {
  if (!error.response) {
    return error;
  }
  const { headers } = error.response;
  if (headers[tokenHeader]) {
    token = headers[tokenHeader];
  }

  if (error.config && error.response && error.response.status === 419) {
    error.config.headers[tokenHeader] = token;
    return axios.request(error.config);
  }

  return Promise.reject(error);
};

export const getApiClientInstance = () => {
  const instance = axios.create(instanceDefaults);
  instance.interceptors.request.use(setRequestToken);
  instance.interceptors.response.use(updateRequestToken, updateRequestTokenFromError);

  return instance;
};

const apiClient = getApiClientInstance();

export default apiClient;
