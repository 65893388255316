import STEPS from '@jsv2/config/SignUp/steps';
import ROLES from '@jsv2/config/userRoles';
import BASE_STEP_WELCOME_MULTIPLE from '@jsv2/templates/vipTraveler/SignUp/Steps/BaseStepWelcomeMultiple';

const welcomeFlowMultipleConfig = {
  [STEPS.BASE_STEP_WELCOME_MULTIPLE]: {
    name: STEPS.BASE_STEP_WELCOME_MULTIPLE,
    component: BASE_STEP_WELCOME_MULTIPLE,
    onComplete: () => {
      window.location.reload();
    },
    isClosable: true,
    roles: {
      [ROLES.ANONYMOUS]: true,
    },
  },
};

export default welcomeFlowMultipleConfig;
