import React from 'react';

import ProgressBar from '@jsv2/components/Authorization/ProgressBar';

const AddressSideBar = () => (
  <div className="sidebar sidebar--step2">
    <div className="wrapper">
      <ProgressBar activeStep={2} />

      <img
        src="/images/lp/promo2/airplane.svg"
        alt=""
        className="signup__image signup__image--plane"
      />

      <h2 className="heading">{t('Let’s Go!')}</h2>

      <p className="tagline">{t("A few basic details please")}</p>
    </div>
  </div>
);

export default AddressSideBar;
