import React, { useContext } from 'react';

import { hasSectionContent } from '@jsv2/utils/HTMLContentUtils/Utils';
import ErrorBoundaryDecorator from '@components/decorators/ErrorBoundaryDecorator';
import EditFieldContext, { Editable } from '@jsv2/LandingPage/context/EditFieldContext';
import LPContext from '@jsv2/LandingPage/context/LPContext';

const { isPreviewPage } = window;

const ContentSection = React.forwardRef(({ id, showBecomeButton, showQuote }, ref) => {
  const { editableFields } = useContext(EditFieldContext);
  const { renderBecomeButton } = useContext(LPContext);

  return (
    editableFields && (
      <section id={id} ref={ref} className="only-content-section pt-50 pb-50">
        <div className="main-container">
          {(hasSectionContent(editableFields.simple_section_heading.content) || isPreviewPage) && (
            <div className="section-title section-title--with-underline section-title--center pt-0">
              <Editable fieldValue={editableFields.simple_section_heading} />
            </div>
          )}

          <div className="section-testimonial">
            <Editable fieldValue={editableFields.simple_section_sub_heading} />
          </div>

          {showBecomeButton &&
            renderBecomeButton(
              'become-btn become-btn--with-border',
              window.templateVariables.body_cta_text.content,
              'become-join-btn-1',
            )
          }

          {showQuote && ((hasSectionContent(editableFields.simple_section_quote.content) || isPreviewPage)) && (
            <div className="quote-section__quote">
              <Editable fieldValue={editableFields.simple_section_quote} />
            </div>
          )}
        </div>
      </section>
    )
  );
});

ContentSection.displayName = 'ContentSection';

export default ErrorBoundaryDecorator()(ContentSection);
