import React from 'react';
import PropTypes from 'prop-types';

import ErrorBoundaryDecorator from '@components/decorators/ErrorBoundaryDecorator';

/**
 * @param {object} question
 * @return {JSX.Element}
 * @constructor
 */
const DummyQuestion = ({ question }) => (
  <div className="mt-30 mb-30 dummy-question-type" key={question.question}>
    <p className="dummy-question-type__title m-0 p-0">{question.question}</p>
  </div>
);

DummyQuestion.propTypes = {
  question: PropTypes.object.isRequired,
};

export default ErrorBoundaryDecorator()(DummyQuestion);
