/* eslint-disable import/no-useless-path-segments */
import Lang from 'lang.js';
import messages from '../../../resources/assets/lang/messages.json';

const lang = new Lang({ messages });
lang.setFallback('en');
window.lang = lang;

window.t = (template, map) => {
  const translated = window.lang.get(template);
  if (map && typeof map === 'object') {
    return translated.replace(
      /:(\w*)/g,
      (matchKey, cleanedKey) => {
        switch (typeof map[cleanedKey]) {
          case 'string':
            return window.lang.get(map[cleanedKey]);
          case 'number':
            return map[cleanedKey];
          default:
            return matchKey;
        }
      },
    );
  }
  return translated;
};
