import STEPS from '@jsv2/config/SignUp/steps';
import ROLES from '@jsv2/config/userRoles';
import URLS from '@jsv2/config/SignUp/urls';
import { makeUserFreeMember } from '@jsv2/utils/UserUtils/APIRequest';
import { isWaitListMember } from '@jsv2/utils/UserUtils/userStatuses';
import BASE_STEP_UPGRADE from '@jsv2/templates/vipTraveler/SignUp/Steps/BaseStepUpgrade';
import ADDRESS_STEP_DEFAULT from '@jsv2/templates/vipTraveler/SignUp/Steps/AddressStepDefault';
import CHOOSE_PLAN_STEP from '@jsv2/templates/vipTraveler/SignUp/Steps/ChoosePlanStep';
import INCOMPLETE_SIGNUP_MESSAGE from '@jsv2/templates/vipTraveler/SignUp/Messages/IncompleteSignUpMessage';
import FREE_WELCOME_MESSAGE from '@jsv2/templates/vipTraveler/SignUp/Messages/FreeWelcomeMessage';
import PAID_WELCOME_MESSAGE from '@jsv2/templates/vipTraveler/SignUp/Messages/PaidWelcomeMessage';
import TRAVEL_PROFILER from '@jsv2/templates/vipTraveler/SignUp/Steps/TravelProfiler';
import TRAVEL_HISTORY from '@jsv2/templates/vipTraveler/SignUp/Steps/TravelHistory';
import TRAVEL_PREFERENCES from '@jsv2/templates/vipTraveler/SignUp/Steps/TravelPreferences';
import TRAVEL_AIRLINE from '@jsv2/templates/vipTraveler/SignUp/Steps/TravelAirline';
import FREE_THANKS_MESSAGE from '@jsv2/templates/vipTraveler/SignUp/Messages/FreeThanksMessage';
import PAID_THANKS_MESSAGE from '@jsv2/templates/vipTraveler/SignUp/Messages/PaidThanksMessage';
import PAYMENT_STEP_DEFAULT from '@jsv2/templates/vipTraveler/SignUp/Steps/PaymentStepDefault';

const {
  shared_data: { defaultHomePageUrl },
} = window.__SERVER_DATA__;

const upgradeFlowConfig = {
  [STEPS.BASE_STEP_UPGRADE]: {
    name: STEPS.BASE_STEP_UPGRADE,
    component: BASE_STEP_UPGRADE,
    onComplete: () => STEPS.ADDRESS_STEP_DEFAULT,
    isClosable: true,
    style: 'closable-sign-up-step',
    url: URLS[STEPS.BASE_STEP_UPGRADE],
    roles: {
      [ROLES.ANONYMOUS]: true,
    },
  },
  [STEPS.ADDRESS_STEP_DEFAULT]: {
    name: STEPS.ADDRESS_STEP_DEFAULT,
    component: ADDRESS_STEP_DEFAULT,
    onComplete: () => STEPS.CHOOSE_PLAN_STEP,
    isClosable: true,
    style: 'closable-sign-up-step',
    url: URLS[STEPS.ADDRESS_STEP_DEFAULT],
    roles: {
      [ROLES.INCOMPLETE_SIGN_UP]: true,
    },
  },
  [STEPS.CHOOSE_PLAN_STEP]: {
    name: STEPS.CHOOSE_PLAN_STEP,
    component: CHOOSE_PLAN_STEP,
    onComplete: () => STEPS.PAYMENT_STEP_DEFAULT,
    onUpgrade: () => {
      makeUserFreeMember();
      return STEPS.FREE_WELCOME_MESSAGE;
    },
    isClosable: true,
    style: 'closable-sign-up-step',
    url: URLS[STEPS.CHOOSE_PLAN_STEP],
    roles: {
      [ROLES.INCOMPLETE_SIGN_UP]: false,
      [ROLES.MISSING_PAYMENT_METHOD]: true,
    },
  },
  [STEPS.PAYMENT_STEP_DEFAULT]: {
    name: STEPS.PAYMENT_STEP_DEFAULT,
    component: PAYMENT_STEP_DEFAULT,
    onComplete: () => STEPS.PAID_WELCOME_MESSAGE,
    onClose: () => {
      makeUserFreeMember('step_interrupted');
      return STEPS.INCOMPLETE_SIGNUP_MESSAGE;
    },
    isClosable: true,
    style: 'closable-sign-up-step',
    url: URLS[STEPS.PAYMENT_STEP_DEFAULT],
  },
  [STEPS.INCOMPLETE_SIGNUP_MESSAGE]: {
    name: STEPS.INCOMPLETE_SIGNUP_MESSAGE,
    component: INCOMPLETE_SIGNUP_MESSAGE,
    onComplete: () => STEPS.TRAVEL_PROFILER,
    isClosable: false,
    url: URLS[STEPS.INCOMPLETE_SIGNUP_MESSAGE],
    roles: {
      [ROLES.PAYMENT_STEP_INTERRUPTED]: true,
      [ROLES.INCOMPLETE_PROFILER]: true,
    },
  },
  [STEPS.FREE_WELCOME_MESSAGE]: {
    name: STEPS.FREE_WELCOME_MESSAGE,
    component: FREE_WELCOME_MESSAGE,
    onComplete: () => STEPS.TRAVEL_PROFILER,
    isClosable: false,
    url: URLS[STEPS.FREE_WELCOME_MESSAGE],
    roles: {
      [ROLES.PAYMENT_STEP_INTERRUPTED]: false,
      [ROLES.FREE_MEMBER]: true,
      [ROLES.INCOMPLETE_PROFILER]: true,
    },
  },
  [STEPS.PAID_WELCOME_MESSAGE]: {
    name: STEPS.PAID_WELCOME_MESSAGE,
    component: PAID_WELCOME_MESSAGE,
    onComplete: () => STEPS.TRAVEL_PROFILER,
    isClosable: false,
    url: URLS[STEPS.PAID_WELCOME_MESSAGE],
    roles: {
      [ROLES.WAIT_LIST_MEMBER]: true,
      [ROLES.INCOMPLETE_PROFILER]: true,
    },
  },
  [STEPS.TRAVEL_PROFILER]: {
    name: STEPS.TRAVEL_PROFILER,
    component: TRAVEL_PROFILER,
    onComplete: () => STEPS.TRAVEL_HISTORY,
    isClosable: false,
    url: URLS[STEPS.TRAVEL_PROFILER],
    style: 'travel-profiler',
    roles: {
      [ROLES.INCOMPLETE_PROFILER]: true,
    },
  },
  [STEPS.TRAVEL_HISTORY]: {
    name: STEPS.TRAVEL_HISTORY,
    component: TRAVEL_HISTORY,
    onComplete: () => STEPS.TRAVEL_PREFERENCES,
    isClosable: false,
    url: URLS[STEPS.TRAVEL_HISTORY],
    style: 'travel-preferences',
    background: '/images/layover/viptraveler/travel-preferences-1.jpg',
    roles: {
      [ROLES.INCOMPLETE_PROFILER]: false,
      [ROLES.INCOMPLETE_TRAVEL_HISTORY]: true,
      [ROLES.INCOMPLETE_TRAVEL_PREFERENCES]: true,
    },
  },
  [STEPS.TRAVEL_PREFERENCES]: {
    name: STEPS.TRAVEL_PREFERENCES,
    component: TRAVEL_PREFERENCES,
    onComplete: () => STEPS.TRAVEL_AIRLINE,
    isClosable: false,
    url: URLS[STEPS.TRAVEL_PREFERENCES],
    style: 'travel-preferences',
    background: '/images/layover/viptraveler/travel-preferences-2.jpg',
    roles: {
      [ROLES.INCOMPLETE_PROFILER]: false,
      [ROLES.INCOMPLETE_TRAVEL_HISTORY]: false,
      [ROLES.INCOMPLETE_TRAVEL_PREFERENCES]: true,
    },
  },
  [STEPS.TRAVEL_AIRLINE]: {
    name: STEPS.TRAVEL_AIRLINE,
    component: TRAVEL_AIRLINE,
    onComplete: (userRoles) =>
      isWaitListMember(userRoles) ? STEPS.PAID_THANKS_MESSAGE : STEPS.FREE_THANKS_MESSAGE,
    isClosable: false,
    url: URLS[STEPS.TRAVEL_AIRLINE],
    style: 'travel-airline',
    roles: {
      [ROLES.INCOMPLETE_PROFILER]: false,
      [ROLES.INCOMPLETE_TRAVEL_HISTORY]: false,
      [ROLES.INCOMPLETE_TRAVEL_PREFERENCES]: false,
    },
  },
  [STEPS.FREE_THANKS_MESSAGE]: {
    name: STEPS.FREE_THANKS_MESSAGE,
    component: FREE_THANKS_MESSAGE,
    onComplete: () => (window.location.href = defaultHomePageUrl),
    isClosable: false,
    url: URLS[STEPS.FREE_THANKS_MESSAGE],
  },
  [STEPS.PAID_THANKS_MESSAGE]: {
    name: STEPS.PAID_THANKS_MESSAGE,
    component: PAID_THANKS_MESSAGE,
    onComplete: () => (window.location.href = defaultHomePageUrl),
    isClosable: false,
    url: URLS[STEPS.PAID_THANKS_MESSAGE],
    roles: {
      [ROLES.WAIT_LIST_MEMBER]: true,
      [ROLES.INCOMPLETE_PROFILER]: false,
    },
  },
};

export default upgradeFlowConfig;
