import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import ScreenTypeContext, { isDesktop } from '@js/context/ScreenTypeContext';
import { Expiration, Number } from 'react-credit-card-primitives-upd';

import CardIcon from '@components/checkout/SecureCheckout/CardIcon';

const defaultFieldState = {
  value: '',
  touched: false,
  error: null,
};

const defaultZip = window.user ? window.user.zip : null;

export default class CreditCardDataInput extends PureComponent {
  static contextType = ScreenTypeContext;

  static propTypes = {
    toggleValid: PropTypes.func,
    visible: PropTypes.bool.isRequired,
    showNameOnCardField: PropTypes.bool,
    showZipCodeField: PropTypes.bool,
    markFieldsRequired: PropTypes.bool,
    isEdit: PropTypes.bool,
    selectedPaymentMethod: PropTypes.object,
    autoFocus: PropTypes.bool,
    errors: PropTypes.object,
  };

  static defaultProps = {
    toggleValid: () => {},
    showNameOnCardField: false,
    showZipCodeField: false,
    markFieldsRequired: false,
    isEdit: false,
    selectedPaymentMethod: null,
    autoFocus: true,
    errors: null,
  };

  focusMovingMap = {
    card_number: 'card_exp_month',
    card_exp_month: 'card_cvc',
  };

  state = {
    fields: {
      card_number: defaultFieldState,
      card_exp_month: {
        value: null,
        touched: false,
        error: null,
      },
      expirationYear: {
        value: null,
        touched: true,
        error: null,
      },
      card_cvc: defaultFieldState,
      cardholder_name: defaultFieldState,
      cardholder_zip: {
        error: null,
        touched: !!defaultZip,
        value: defaultZip,
      },
    },
    errorMessage: null,
  };

  componentDidMount() {
    const { autoFocus } = this.props;
    if (autoFocus && isDesktop(this.context)) {
      this.getFieldRef('card_number').focus();
    }
  }

  componentDidUpdate(prevProps) {
    const { errors } = this.props;

    if (!_.isEqual(prevProps.errors, errors) && errors !== null) {
      this.setErrors(errors);
    }
  }

  getData = () => {
    const { fields } = this.state;

    return Object.entries(fields).reduce((res, [key, val]) => {
      res[key] = val.value;
      return res;
    }, {});
  };

  getRefName = fieldName => `${fieldName}Ref`;

  getFieldRef = fieldName => this[this.getRefName(fieldName)];

  setErrorMessage = message => this.setState({ errorMessage: message });

  setIsValid = () => {
    const { fields } = this.state;
    const isValid = Object.values(fields)
      .every(field => field.touched && !field.error);

    this.props.toggleValid(isValid);
  };

  getField(name) {
    return this.state.fields[name];
  }

  getFieldError(name) {
    const field = this.getField(name);
    return field.touched && field.error;
  }

  clearErrorMessage = () => this.setErrorMessage(null);

  setErrors = (errorsObj, errorMessage = null) => {
    const errorsEntries = Object.entries(errorsObj);

    let needsUpdate = !!errorMessage;
    const fields = { ...this.state.fields };

    errorsEntries.forEach(([key, err]) => {
      if (fields[key]) {
        const error = Array.isArray[err] ? err[0] : err;
        fields[key] = { ...fields[key], error, touched: true };
        needsUpdate = true;
      }
    });

    if (needsUpdate) {
      this.setState({ fields, errorMessage }, this.setIsValid);
    }
  };

  updateField = (name, props, moveFocus) => {
    const prevFieldProps = this.state.fields[name];
    this.setState(({ fields }) => ({
      fields: {
        ...fields,
        [name]: {
          ...prevFieldProps,
          ...props,
        },
      },
    }), () => {
      if (!props.error && moveFocus) {
        this.moveFocusToNextItem(name);
      }
      this.setIsValid();
      this.clearErrorMessage();
    });
  };

  moveFocusToNextItem = (fieldName) => {
    if (this.focusMovingMap[fieldName]) {
      this.focusField(this.focusMovingMap[fieldName]);
    }
  };

  focusField = (fieldName) => {
    const fieldRef = this.getFieldRef(fieldName);
    if (fieldRef && isDesktop(this.context)) {
      fieldRef.focus();
    }
  };

  handleFieldChange = (name, value, error) => {
    const fieldVal = { value, error };
    if (!error) {
      fieldVal.touched = true;
    }
    this.updateField(name, fieldVal, true);
  };

  onBlur = (name) => {
    const field = this.getField(name);
    if (field.touched) {
      // we need do nothing
      return;
    }
    this.updateField(name, { touched: !!field.value });
  };

  renderField = ({
    label, labelClassName = '', name, className = '', inputClassName = '', onBlur, renderInput, dataQaId, ...props
  }) => {
    const error = this.getFieldError(name);

    const handleBlur = (e) => {
      if (onBlur) {
        onBlur(e);
      }
      this.onBlur(name);
    };

    const inputProps = {
      ref: node => this[this.getRefName(name)] = node,
      className: error ? `${inputClassName} error` : inputClassName,
      ...props,
      onBlur: handleBlur,
    };
    return (
      <div className={`input ${className}`}>
        <label className={`label-content ${error ? 'label-content--error' : labelClassName}`} data-qa-id={`qa_error_${label.replace(':', '').split(' ').join('_')}`}> {error || label} </label>
        { renderInput ? renderInput(inputProps) : (
          <input {...inputProps} data-qa-id={dataQaId} />
        )}
      </div>
    );
  };

  renderNameOnCardField = () => {
    const { fields } = this.state;
    const { markFieldsRequired } = this.props;
    return (
      this.renderField({
        label: 'name on Card',
        labelClassName: markFieldsRequired ? 'required' : '',
        className: 'holder-name',
        name: 'cardholder_name',
        value: fields.cardholder_name.value,
        autoComplete: 'cc-name',
        maxLength: 255,
        dataQaId: 'cc-name_field',
        onChange: (e) => {
          const {value} = e.target;
          let error = '';
          if (!value) {
            error = t('this field is required');
          } else if (!/^[a-zA-Z'-. ]+$/.test(value)) {
            error = t('name is invalid');
          }
          this.handleFieldChange('cardholder_name', value, error);
        },
      })
    );
  };

  renderZipCodeField = () => {
    const { fields } = this.state;
    const { markFieldsRequired } = this.props;
    return (
      <>
        {this.renderField({
          label: 'billing zip code',
          labelClassName: markFieldsRequired ? 'required' : '',
          className: 'zipCode',
          name: 'cardholder_zip',
          value: fields.cardholder_zip.value,
          placeholder: 'xxxxx',
          autoComplete: 'postal-code',
          maxLength: 20,
          dataQaId: 'postal-code_field',
          onChange: (e) => {
            const { value } = e.target;
            let error = '';
            if (!value) {
              error = t('this field is required');
            }
            this.handleFieldChange('cardholder_zip', value, error);
          },
        })}
        <div className="filler" />
      </>
    );
  };

  render() {
    const {
      visible,
      showNameOnCardField,
      showZipCodeField,
      markFieldsRequired,
      isEdit,
      selectedPaymentMethod,
    } = this.props;
    const { fields, errorMessage } = this.state;
    const labelClassName = markFieldsRequired ? 'required' : '';

    return (
      <div
        className="credit-card-container"
        style={{ display: visible ? 'block' : 'none' }}
      >
        {errorMessage && <div className="error"> {errorMessage} </div>}
        <div className="profile-group-input">
          <div className="form-field">
            <Number
              value={fields.card_number.value}
              onChange={({ value, valid }) => {
                let error = null;
                if (!valid) {
                  error = t('Card Number Format is Invalid');
                }
                this.handleFieldChange('card_number', value.substring(0, 19), error);
              }}
              render={({ getInputProps, type }) => {
                const cardType = type ? type.toLowerCase().replace(/ /g, '-') : '';
                return this.renderField({
                  ...getInputProps(),
                  name: 'card_number',
                  label: 'card number:',
                  labelClassName,
                  className: 'card-number',
                  inputClassName: cardType,
                  placeholder: selectedPaymentMethod ? `**** **** **** ${selectedPaymentMethod.card_last4}` : '0000 0000 0000 0000',
                  autoComplete: 'cc-number',
                  inputMode: 'numeric',
                  disabled: isEdit,
                  dataQaId: 'cc_number_field',
                  renderInput: inputProps => (
                    <div className="input-wrapper">
                      <input {...inputProps} data-qa-id="cc_number_field" />
                      <CardIcon imageName={
                        selectedPaymentMethod ? selectedPaymentMethod.card_brand_code : cardType
                      }
                      />
                    </div>
                  ),
                });
              }}
            />
            <Expiration
              month={fields.card_exp_month.value}
              year={fields.expirationYear.value}
              onChange={({ rawValue, valid }) => {
                let [month, year] = rawValue.split('/').map(v => +v.trim()); // eslint-disable-line
                let error = null;

                // Trim year if there is more than 2 digits
                if (year >= 100) {
                  year = +`${year}`.substring(0, 2);
                }

                if (!valid) {
                  error = t('Invalid date');
                }
                this.handleFieldChange('card_exp_month', month, error);
                this.handleFieldChange('expirationYear', year, error);
              }}
              render={({ getInputProps }) => this.renderField({
                ...getInputProps(),
                name: 'card_exp_month', // doesn't matter month or year
                label: 'expiration date:',
                labelClassName,
                className: 'expiration-date',
                placeholder: 'mm / yy',
                autoComplete: 'cc-exp',
                inputMode: 'numeric',
                dataQaId: 'cc_exp_field',
              })}
            />
            {this.renderField({
              label: 'security code:',
              labelClassName,
              className: 'cvc',
              placeholder: isEdit ? '***' : 'cvc',
              type: 'password',
              inputMode: 'numeric',
              name: 'card_cvc',
              value: fields.card_cvc.value,
              autoComplete: 'cc-csc',
              maxLength: 4,
              disabled: isEdit,
              dataQaId: 'cc-csc_field',
              onChange: (e) => {
                const { value } = e.target;
                let error = '';
                if (!value || !/^[0-9]{3,4}$/.test(value)) {
                  error = t('invalid CVC');
                }
                this.handleFieldChange('card_cvc', value, error);
                if (!error && value.length >= 4) {
                  this.focusField('cardholder_name');
                }
              },
            })}
          </div>
          <div className="form-field">
            {showNameOnCardField && this.renderNameOnCardField()}
            {showZipCodeField && this.renderZipCodeField()}
          </div>
        </div>
      </div>
    );
  }
}
