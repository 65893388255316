import React, { Component } from 'react';
import PropTypes from 'prop-types';

import Modal from '@components/common/Modal';
import { DIALOG_TYPES } from '@js/constants';

class Container extends Component {
  static propTypes = {
    type: PropTypes.string.isRequired,
    data: PropTypes.object,
    closeOnOverlayClick: PropTypes.bool,
    labelOk: PropTypes.string,
    labelCancel: PropTypes.string,
    onClose: PropTypes.func,
    onOk: PropTypes.func,
    onCancel: PropTypes.func,
    withCloseButton: PropTypes.bool,
    shouldCloseOnEsc: PropTypes.bool,
  };

  static defaultProps = {
    data: {},
    closeOnOverlayClick: false,
    labelOk: '', // default: t('OK')
    labelCancel: '', // default: t('Cancel')
    onClose: () => {},
    onOk: () => {},
    onCancel: () => {},
    withCloseButton: false,
    shouldCloseOnEsc: false,
  };

  state = {
    isOpen: true,
  };

  /**
   * Close the modal dialog and call `onCancel` callback.
   */
  onClickCancel = () => {
    this.closeModal();
    this.props.onCancel();
  };

  /**
   * Close the modal dialog and call `onOk` callback.
   */
  onClickOk = () => {
    this.closeModal();
    this.props.onOk();
  };

  /**
   * Close the modal dialog and call `onClose` callback.
   */
  closeModal = () => {
    this.setState({ isOpen: false });
    this.props.onClose();
  };

  renderFooter() {
    const { type, labelOk, labelCancel } = this.props;

    return (
      <div className="content-footer text-center">
        {type === DIALOG_TYPES.CONFIRM && (
          <button
            type="button"
            className="layover-popup__button layover-popup__button--dark"
            onClick={() => this.onClickCancel()}
            data-qa-id={labelCancel || 'cancel'}
          >
            {labelCancel || t('Cancel')}
          </button>
        )}

        <button
          type="button"
          className="layover-popup__button"
          onClick={() => this.onClickOk()}
          data-qa-id={labelOk || 'ok'}
        >
          {labelOk || t('OK')}
        </button>
      </div>
    );
  }

  render() {
    const { type, closeOnOverlayClick, withCloseButton, shouldCloseOnEsc, data } = this.props;

    return (
      <Modal
        isOpen={this.state.isOpen}
        shouldReturnFocusAfterClose={false}
        shouldCloseOnOverlayClick={closeOnOverlayClick}
        shouldCloseOnEsc={type === DIALOG_TYPES.CONFIRM || shouldCloseOnEsc}
        onRequestClose={() => this.closeModal()}
        size="md"
        withCloseButton={withCloseButton}
        data={data}
      >
        {this.props.children}

        {this.renderFooter()}
      </Modal>
    );
  }
}

export default Container;
