import { useContext, useState } from 'react';
import PropTypes from 'prop-types';

import UserContext from '@jsv2/context/UserContext';
import { subscribeUser } from '@jsv2/utils/UserUtils/APIRequest';

const PaymentStepDefault = ({ step, getNextStep, render, ...rest }) => {
  const { updateUserRoles } = useContext(UserContext);

  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  const handleSubmit = (value) => {
    setError(null);
    setIsLoading(true);

    subscribeUser(value)
      .then(() => {
        setIsLoading(false);
        getNextStep(step.onComplete());
        updateUserRoles();
      })
      .catch(({ response }) => {
        const {
          status,
          data: { data, message },
        } = response;
        const errorsObj = {};

        if (status !== 422) {
          errorsObj.message = message;
        }

        errorsObj.data = data;

        setIsLoading(false);
        setError(errorsObj);
      });
  };

  return render({
    isLoading,
    error,
    handleSubmit,
    ...rest,
  });
};

PaymentStepDefault.propTypes = {
  render: PropTypes.func.isRequired,
  step: PropTypes.shape({
    component: PropTypes.func.isRequired,
    onComplete: PropTypes.func.isRequired,
  }).isRequired,
  getNextStep: PropTypes.func.isRequired,
};

export default PaymentStepDefault;
