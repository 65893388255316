import React from 'react';
import PropTypes from 'prop-types';

import TextFit from '@components/common/TextFit';
import ErrorBoundaryDecorator from '@components/decorators/ErrorBoundaryDecorator';

const SliderItemTemplate = ({
  item,
  onClick,
  maxFontSize,
  minFontSize,
  handleFontSizeDetection,
}) => (
  <div className="destination-list__item">
    <TextFit
      mode="multi"
      max={maxFontSize}
      min={minFontSize}
      onReady={handleFontSizeDetection}
      className="destination-name"
      title={item.name}
    >
      {item.name}
    </TextFit>

    <button
      className="destination-list__item-backdrop closer"
      data-qa-id="btn_closer"
      type="button"
      onClick={() => onClick(item)}
    />
  </div>
);

SliderItemTemplate.propTypes = {
  item: PropTypes.object.isRequired,
  onClick: PropTypes.func.isRequired,
  maxFontSize: PropTypes.number.isRequired,
  minFontSize: PropTypes.number.isRequired,
  handleFontSizeDetection: PropTypes.func.isRequired,
};

export default ErrorBoundaryDecorator()(SliderItemTemplate);
