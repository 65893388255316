import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import Spinner from '@jsv3/components/molecules/Spinner';

/**
 * Overlay spinner
 */
const OverlaySpinner = ({ isDarkMode, isAbsolute, noOpacity }) => (
  <div
    className={classNames('spinner-overlay', {
      'dark-overlay': isDarkMode,
      'absolute-overlay': isAbsolute,
      'no-opacity-overlay': noOpacity,
    })}
  >
    <Spinner />
  </div>
);

OverlaySpinner.propTypes = {
  isDarkMode: PropTypes.bool,
  isAbsolute: PropTypes.bool,
};

OverlaySpinner.defaultProps = {
  isDarkMode: false,
  isAbsolute: false,
};

export default OverlaySpinner;
