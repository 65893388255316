import React, { useState, useEffect, useCallback, useRef } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';

import { IMAGE_SIZES, MOBILE_SCREEN_WIDTH, SMALL_TABLET_SCREEN_WIDTH } from '@js/constants';
import ErrorBoundaryDecorator from '@components/decorators/ErrorBoundaryDecorator';
import WishListSkeleton from '@jsv2/pages/BucketListPage/UI/WishListSkeleton';
import Slider from '@jsv2/components/Slider/Slider';
import { PrevArrow, NextArrow } from '@jsv2/components/Slider/SliderArrows';
import Image from '@jsv2/components/Image';
import DiscoverDestinationModal from '@jsv2/components/BucketList/discoverTool/DiscoverDestinationModal';
import DestinationCardPreview from '@jsv3/components/molecules/DestinationCardPreview';
import LayoverComponent from '@components/LayoverComponent';
import sliderSettings from './sliderSettings';

/**
 * The number of blocks that are displayed with the text "Your list is still empty" on the desktop
 *
 * @type {number}
 */
const NUMBER_OF_EMPTY_DESKTOP_DESTINATIONS = 4;

/**
 * The number of blocks that are displayed with the text "Your list is still empty" on the tablet
 *
 * @type {number}
 */
const NUMBER_OF_EMPTY_TABLET_DESTINATIONS = 3;

/**
 * The number of blocks that are displayed with the text "Your list is still empty" on the mobile
 *
 * @type {number}
 */
const NUMBER_OF_EMPTY_MOBILE_DESTINATIONS = 2;

/**
 * Render Wish List Slider
 *
 * @param settings
 * @param placeholder
 * @param wishList
 * @param removeDestinationListItemByName
 * @param moveToTop
 * @param moveUp3Stops
 * @param SliderItemTemplate
 * @param showLearnMore
 * @param isDestinationAdding
 * @param showSkeleton
 *
 * @return {*}
 *
 * @constructor
 */

const WishListSlider = ({
  settings,
  placeholder,
  wishList,
  removeDestinationListItemByName,
  moveToTop,
  moveUp3Stops,
  SliderItemTemplate,
  showLearnMore,
  isDestinationAdding,
  showSkeleton,
}) => {
  const minFontSize = useRef(10);
  const [maxFontSize, setMaxFontSize] = useState(34);

  const [addedDestination, setAddedDestination] = useState(0);
  const [numberOfEmptyDestinations, setNumberOfEmptyDestinations] = useState(
    NUMBER_OF_EMPTY_DESKTOP_DESTINATIONS,
  );
  const [activeSlide, setActiveSlide] = useState(0);

  const [flippedItem, setFlippedItem] = useState(null);
  const layoverComponent = useRef(null);
  const navigationPrevRef = useRef(null);
  const navigationNextRef = useRef(null);

  const [activeDestination, setActiveDestination] = useState({});
  const [activeGroup, setActiveGroup] = useState([]);

  // eslint-disable-next-line no-unused-vars
  const [wishListSwiper, setWishListSwiper] = useState(null);

  const numberOfSkeletonItems =
    wishList.length <= numberOfEmptyDestinations
      ? numberOfEmptyDestinations
      : numberOfEmptyDestinations * 2;

  const handleFontSizeDetection = (newFontSize) => {
    if (maxFontSize > newFontSize && newFontSize >= minFontSize.current) {
      setMaxFontSize(newFontSize);
    }
  };

  /**
   * Gets the current width of the window
   */
  const getRealWidth = () => document.documentElement.clientWidth || window.innerWidth;

  /**
   * Returns the number of empty blocks based on the screen width.
   */
  const getNumberOfEmptyDestinations = useCallback(() => {
    if (getRealWidth() <= MOBILE_SCREEN_WIDTH) {
      return setNumberOfEmptyDestinations(NUMBER_OF_EMPTY_MOBILE_DESTINATIONS);
    }

    if (getRealWidth() < SMALL_TABLET_SCREEN_WIDTH && getRealWidth() > MOBILE_SCREEN_WIDTH) {
      return setNumberOfEmptyDestinations(NUMBER_OF_EMPTY_TABLET_DESTINATIONS);
    }

    return setNumberOfEmptyDestinations(NUMBER_OF_EMPTY_DESKTOP_DESTINATIONS);
  }, [setNumberOfEmptyDestinations]);

  useEffect(() => {
    // Gets the number of empty blocks for initial rendering
    getNumberOfEmptyDestinations();

    // Fires getNumberOfEmptyDestinations() on resize event.
    window.addEventListener('resize', getNumberOfEmptyDestinations, { passive: true });

    return () => {
      window.removeEventListener('resize', getNumberOfEmptyDestinations);
    };
  }, [getNumberOfEmptyDestinations]);

  /**
   * Watcher of the adding destination in the wish list
   */
  useEffect(() => {
    setAddedDestination(wishList.length);
  }, [wishList]);

  /**
   * Remove Destination ListItem Handler
   *
   * @param item
   */
  const removeListItem = (item) => {
    removeDestinationListItemByName(item.name);
  };

  /**
   * Checking if whishlist item from our DB
   *
   * @param {Object} item
   * @returns {Boolean}
   */
  const isDestinationFromDataBase = (item) =>
    !!Object.keys(item).find((property) => property === 'blog_entry');

  /**
   * Handle Open Explore Popup
   *
   * @param {Object} destination
   */
  const handleOpenExplorePopup = (destination) => {
    setActiveDestination(destination);

    const filteredDestinations = wishList.filter((item) => isDestinationFromDataBase(item));

    setActiveGroup(filteredDestinations);

    layoverComponent.current.openModal();
  };

  /**
   * Flip Destination card
   *
   * @param {Number} item
   */
  const handleFlipDestination = (item) => setFlippedItem(flippedItem !== item ? item : null);

  /**
   * Render sort buttons by number of destinations
   */
  const renderSortButtons = (index, item) => {
    const items = [];

    if (index !== 0) {
      items.push(
        <button
          key={`move-to-top-${index}`}
          type="button"
          data-qa-id="make_first_btn"
          className="destination-list__make-first"
          onClick={() => moveToTop(item.id)}
        >
          {t('move to top')}
        </button>,
      );
    }

    if (index > 3) {
      items.push(
        <button
          key={`up-3-spots-${index}`}
          type="button"
          data-qa-id="make_top_3_btn"
          className="destination-list__make-first mt-15"
          onClick={() => moveUp3Stops(item.id, index)}
        >
          {t('up 3 spots')}
        </button>,
      );
    }

    return items;
  };

  /**
   * Render learn more button
   */
  const renderLearnMoreButton = (item) => {
    if (isDestinationFromDataBase(item) && showLearnMore) {
      return (
        <button
          type="button"
          className="destination-list__more"
          data-qa-id="learn_more_btn"
          onClick={() => handleOpenExplorePopup(item)}
        >
          Learn more
        </button>
      );
    }

    return null;
  };

  const defaultItemTemplate = (item, index) => {
    const Component = item.primary_image ? Image : 'div';
    const params = item.primary_image
      ? {
          data: item.primary_image,
          config: {
            size: IMAGE_SIZES.SMALL,
          },
          background: true,
        }
      : {};

    return (
      <div key={item.id} className="destination-list__item">
        <div className="destination-number" data-qa-id="destination_number">
          {index + 1}
        </div>

        {/* Desktop */}

        <DestinationCardPreview
          item={{
            image: item.primary_image,
            name: item.name,
          }}
          isBackSlide
          customClass="mobile-hide"
        >
          <button
            className="destination-list__item-backdrop closer"
            data-qa-id="btn_closer"
            type="button"
            onClick={() => removeListItem(item)}
          />

          {renderSortButtons(index, item)}
          {renderLearnMoreButton(item)}
        </DestinationCardPreview>

        {/* Mobile */}
        {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
        <div
          className={`destination_sides_mobile destination-sides ${
            flippedItem === index ? 'is-flipped' : ''
          }`}
          onClick={() => handleFlipDestination(index)}
        >
          <Component
            className={`destination-side ${item.primary_image ? '' : 'new-destination'} ${
              flippedItem === index ? 'is-flipped' : ''
            }`}
            {...params}
          >
            <div className="title_box">
              <h2 className="destination-white-name" data-qa-id="destination_white_name">
                {item.name}
              </h2>
            </div>

            <div className="btn_box">
              <button
                className="destination-list__item-backdrop closer"
                data-qa-id="btn_closer"
                type="button"
                onClick={() => removeListItem(item)}
              />

              {renderSortButtons(index, item)}
              {renderLearnMoreButton(item)}
            </div>
          </Component>
        </div>
      </div>
    );
  };

  const renderSlide = (item, index) => {
    if (SliderItemTemplate) {
      return (
        <SliderItemTemplate
          item={item}
          onClick={removeListItem}
          maxFontSize={maxFontSize}
          minFontSize={minFontSize.current}
          handleFontSizeDetection={handleFontSizeDetection}
        />
      );
    }
    return defaultItemTemplate(item, index);
  };

  /**
   * @param {Array} slidesList
   * @param {Number} emptyItemCount
   */
  const renderEmptyItem = (emptyItemCount, slidesList) => {
    _.times(emptyItemCount, (i) =>
      slidesList.push(
        <div key={i} className="destination-list__item-wrapper">
          <div className="destination-list__item destination-list__item--empty destination-list__item--full">
            {placeholder}
            <div className="slide-number" style={{ display: 'none' }}>
              {i + 1 + addedDestination}
            </div>
          </div>
        </div>,
      ),
    );
  };

  const sliderContent = () => {
    const slides = [];

    wishList.map((item, index) =>
      slides.push(
        <div
          key={item.name}
          className="destination-list__item-wrapper"
          data-qa-id="destination_list"
        >
          {renderSlide(item, index)}
        </div>,
      ),
    );

    if (wishList.length > numberOfEmptyDestinations) {
      renderEmptyItem(0);
    } else {
      renderEmptyItem(numberOfEmptyDestinations - wishList.length, slides);
    }

    return slides;
  };

  return (
    <>
      <div className="destination-list__arrows">
        <PrevArrow ref={navigationPrevRef} />
        <NextArrow ref={navigationNextRef} />
      </div>

      {isDestinationAdding && showSkeleton ? (
        <WishListSkeleton numberOfSkeletonItems={numberOfSkeletonItems} />
      ) : (
        <Slider
          sliderProps={{
            initialSlide: activeSlide,
            navigation: {
              prevEl: navigationPrevRef.current,
              nextEl: navigationNextRef.current,
            },
            onSwiper: (swiper) => {
              setWishListSwiper(swiper);
            },
            onInit: (swiper) => {
              swiper.params.navigation.prevEl = navigationPrevRef.current;
              swiper.params.navigation.nextEl = navigationNextRef.current;

              setActiveSlide(swiper.activeIndex);
            },
            onSlideChange: (swiper) => {
              setActiveSlide(swiper.activeIndex);
            },
            ...settings,
          }}
        >
          {sliderContent()}
        </Slider>
      )}
      <LayoverComponent
        ref={layoverComponent}
        LayoverContentComponent={DiscoverDestinationModal}
        layoverContentProps={{
          activeDestination,
          activeGroup,
          hideUserReactions: true,
        }}
        modalSettings={{
          overlayClassName: 'modal-overlay explore-popup__overlay',
          className: 'explore-popup',
          data: {
            'qa-id': 'explorer_popup',
          },
        }}
      />
    </>
  );
};

WishListSlider.propTypes = {
  settings: PropTypes.object,
  placeholder: PropTypes.string,
  wishList: PropTypes.arrayOf(PropTypes.object),
  removeDestinationListItemByName: PropTypes.func,
  moveToTop: PropTypes.func,
  moveUp3Stops: PropTypes.func,
  SliderItemTemplate: PropTypes.func,
  showLearnMore: PropTypes.bool,
  isDestinationAdding: PropTypes.bool,
  showSkeleton: PropTypes.bool,
};

WishListSlider.defaultProps = {
  settings: sliderSettings,
  placeholder: 'Your list is still empty',
  wishList: [],
  removeDestinationListItemByName: () => {},
  moveToTop: () => {},
  moveUp3Stops: () => {},
  SliderItemTemplate: null,
  showLearnMore: false,
  isDestinationAdding: false,
  showSkeleton: false,
};

export default ErrorBoundaryDecorator()(WishListSlider);
