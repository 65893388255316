import React from 'react';
import PropTypes from 'prop-types';

import classNames from 'classnames';
import ErrorBoundaryDecorator from '@components/decorators/ErrorBoundaryDecorator';

const StarRating = ({ stars }) => (
  <div className="star-rating">
    {[1, 2, 3, 4, 5].map((rating) => (
      <div key={rating} className={classNames('star', { empty: stars < rating })} />
    ))}
  </div>
);

StarRating.propTypes = {
  stars: PropTypes.number.isRequired,
};

export default ErrorBoundaryDecorator()(StarRating);
