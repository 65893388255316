import React from 'react';
import ErrorBoundaryDecorator from '@components/decorators/ErrorBoundaryDecorator';
import { getUrlParam } from '@jsv3/utils/urlUtils';

/**
 * Render a link button "Back to Welcome Offer"

 * @return {*}
 *
 * @constructor
 */
const BackButton = () => (
  <a href={getUrlParam('go_back_url', true)} className="back__btn" data-qa-id="back_btn">
    <span className="back-arrow" />
    {t('Back to Offer')}
  </a>
);

export default ErrorBoundaryDecorator()(BackButton);
