import React from 'react';
import PropTypes from 'prop-types';

import Slider from '@jsv2/components/Slider/Slider';
import ErrorBoundaryDecorator from '@components/decorators/ErrorBoundaryDecorator';

const pubsSlides = [
  {
    image: '/images/lp/promo2/publication-image/nbc-news.svg',
    title: 'nbc-news',
  },
  {
    image: '/images/lp/promo2/publication-image/new-york-times.svg',
    title: 'new-york-times',
  },
  {
    image: '/images/lp/promo2/publication-image/mens-journal.png',
    title: 'mens-journal',
  },
  {
    image: '/images/lp/promo2/publication-image/dr-phil-icon.svg',
    title: 'dr-phil',
  },
  {
    image: '/images/lp/promo2/publication-image/conde-naste-traveler.png',
    title: 'conde-naste-traveler',
  },
  {
    image: '/images/lp/promo2/publication-image/forbes.png',
    title: 'forbes',
  },
  {
    image: '/images/lp/promo2/publication-image/insiderhook.png',
    title: 'insiderhook',
  },
  {
    image: '/images/lp/promo2/publication-image/luxury-travel-blog.png',
    title: 'luxury-travel-blog',
  },
];

const PublicationsSlider = ({ mobileAutoplay, mobileAutoplaySpeed }) => {
  const pubsSliderSettings = {
    speed: mobileAutoplaySpeed,
    direction: 'horizontal',
    slidesPerView: 'auto',
    className: 'pubs-slider',
    autoplay: {
      enable: mobileAutoplay,
      delay: 0,
    },
    loop: true,

    breakpoints: {
      767: {
        speed: 8000,
      },
    },
  };

  return (
    <Slider sliderProps={{ ...pubsSliderSettings }}>
      {pubsSlides.map((item) => (
        <div key={item.title}>
          <div
            style={{ backgroundImage: `url(${item.image})` }}
            className={`pubs__image pubs__image--${item.title}`}
          />
        </div>
      ))}
    </Slider>
  );
};

PublicationsSlider.propTypes = {
  mobileAutoplay: PropTypes.bool,
  mobileAutoplaySpeed: PropTypes.number,
};

PublicationsSlider.defaultProps = {
  mobileAutoplay: false,
  mobileAutoplaySpeed: 7000,
};

export default ErrorBoundaryDecorator()(PublicationsSlider);
