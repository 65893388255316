import apiClient from '@js/apiClient';

/**
 * Upload a file request api.
 *
 * @param {formData} formData
 * @return {*}
 */
export const uploadFileDataRequest = (formData) =>
  apiClient({
    url: `/file/upload`,
    method: 'POST',
    data: formData,
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
