import React, { useContext } from 'react';
import PropTypes from 'prop-types';

import Price from '@components/Price';
import SignUpContext from '@jsv2/context/SignUpContext';
import ProgressBar from '@jsv2/components/Authorization/ProgressBar';

const BenefitsDefault = ({ hideBenefits }) => {
  const { baseStepSidebarTagline, membershipSummary } = useContext(SignUpContext).signUpProcess;

  const { signupBonus, benefits } = membershipSummary;

  return (
    <div className="sidebar sidebar--step1 sidebar--benefits">
      <ProgressBar activeStep={1} />

      <div className="wrapper benefits-wrapper">
        <img
          src="/images/lp/promo6/1.png"
          alt=""
          className={`benefits-wrapper__image${
            hideBenefits ? ' benefits-wrapper__image--centered' : ''
          }`}
        />

        <h2 className="heading">{t('join us!')}</h2>

        <p className="tagline">{baseStepSidebarTagline}</p>

        {benefits.current && benefits.current.length > 0 && !hideBenefits && (
          <div className="benefits">
            <p className="benefits__heading">{t('You get:')}</p>

            <ul className="benefits__list inclusions__list list" data-qa-id="benefit_list">
              {benefits.current.map(({ id, description, name }) => (
                <li key={id} className="list__item" data-qa-id="benefit_list_item">
                  {name && <strong>{name}</strong>} {description}
                </li>
              ))}

              {signupBonus.current && (
                <li className="list__item card" data-qa-id="membership-credit-issuance">
                  {t('Plus ')}
                  <Price
                    inline
                    price={signupBonus.current.amount}
                    currency_iso3={signupBonus.current.currencyIso3}
                    hideDecimalsForIntegers
                    dataQaId="credit_value"
                  />
                  {t(' VIP Traveler Dollars to get you started')}
                </li>
              )}
            </ul>
          </div>
        )}
      </div>
    </div>
  );
};

BenefitsDefault.propTypes = {
  hideBenefits: PropTypes.bool,
};

BenefitsDefault.defaultProps = {
  hideBenefits: false,
};

export default BenefitsDefault;
