import React from 'react';
import PropTypes from 'prop-types';

import AuthMessage from '@jsv2/components/Authorization/AuthMessage';
import BaseMessageTemplate from '@jsv2/templates/vipTraveler/ModalMessages/BaseMessageTemplate';

/**
 * One-of booking success abandoned message.
 */

const MESSAGE_CONTENT = {
  image: '/images/lp/promo2/vip_illustration-ok.svg',
  heading: 'Welcome!',
  tagline:
    'If you’re ready to move forward with a custom booking, click below to submit a deposit and give us some more information about your travel goals. If you’re still just exploring, feel free to take a look at some of the exclusive travel packages we currently offer.',
  buttonText: 'MAKE A DEPOSIT',
  secondButtonText: 'VIEW VIP EXCLUSIVES™',
};

const DepositSuccessMessage = ({ step, getNextStep }) => (
  <AuthMessage
    step={step}
    getNextStep={getNextStep}
    render={({ handleClick, handleSecondButtonClick }) => (
      <BaseMessageTemplate
        handleClick={handleClick}
        handleSecondButtonClick={handleSecondButtonClick}
        {...MESSAGE_CONTENT}
      />
    )}
  />
);

DepositSuccessMessage.propTypes = {
  step: PropTypes.shape({
    component: PropTypes.func.isRequired,
    onComplete: PropTypes.func.isRequired,
    onSecondComplete: PropTypes.func,
  }).isRequired,
  getNextStep: PropTypes.func.isRequired,
};

export default DepositSuccessMessage;
