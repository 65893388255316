import React from 'react';

import ErrorBoundaryDecorator from '@components/decorators/ErrorBoundaryDecorator';
import AccordionItem from './UI/AccordionItem';

const Accordion = ({ faqs }) => (
  <span className="accordion-block d-block">
    <span className="accordion d-block">
      <span className="accordion-header d-block">{faqs.name}</span>

      <span className="accordion-body d-block">
        {faqs.items.map((item) => (
          <AccordionItem key={item.question} question={item.question} answer={item.answer} />
        ))}
      </span>
    </span>
  </span>
);

export default ErrorBoundaryDecorator()(Accordion);
