import ROLES from '@jsv2/config/userRoles';

export const isAnonymous = (userRoles) => userRoles.includes(ROLES.ANONYMOUS);

export const isEmailOnly = (userRoles) => userRoles.includes(ROLES.EMAIL_ONLY);

export const isViewedOffers = (userRoles) => userRoles.includes(ROLES.VIEWED_OFFERS);

export const isRegistered = (userRoles) => !userRoles.includes(ROLES.ANONYMOUS);

export const isPartiallyIdentified = (userRoles) => userRoles.includes(ROLES.PARTIALLY_IDENTIFIED);

export const isFreeMember = (userRoles) => userRoles.includes(ROLES.FREE_MEMBER);

export const isPaidMember = (userRoles) => userRoles.includes(ROLES.PAID_MEMBER);

export const isPreviouslyPaidMember = (userRoles) =>
  userRoles.includes(ROLES.PREVIOUSLY_PAID_MEMBER);

export const isWaitListMember = (userRoles) => userRoles.includes(ROLES.WAIT_LIST_MEMBER);

export const isIncompleteSignUp = (userRoles) => userRoles.includes(ROLES.INCOMPLETE_SIGN_UP);

export const isMissingPaymentMethod = (userRoles) =>
  userRoles.includes(ROLES.MISSING_PAYMENT_METHOD);

export const isIncompleteProfiler = (userRoles) => userRoles.includes(ROLES.INCOMPLETE_PROFILER);

export const isIncompleteTravelPreferences = (userRoles) =>
  userRoles.includes(ROLES.INCOMPLETE_TRAVEL_PREFERENCES);

export const isIncompleteTravelHistory = (userRoles) =>
  userRoles.includes(ROLES.INCOMPLETE_TRAVEL_HISTORY);

export const hasTravelFinds = (userRoles) => userRoles.includes(ROLES.HAS_TRAVEL_FINDS);

export const isImpersonated = (userRoles) => userRoles.includes(ROLES.IS_IMPERSONATED);

export const isFullyVerified = (userRoles) => userRoles.includes(ROLES.FULLY_VERIFIED);

export const getMembershipSummary = (membershipOptions) => {
  const { userRoles, settings } = window;

  if (membershipOptions.length === 0) {
    return {};
  }

  if (isAnonymous(userRoles)) {
    return membershipOptions[0];
  }

  const flow = settings?.sign_up_flow || 'paid_flow';

  return membershipOptions.find((summary) => summary.flow === flow) || membershipOptions[0];
};
