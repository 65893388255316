import React, { useContext } from 'react';

import ErrorBoundaryDecorator from '@components/decorators/ErrorBoundaryDecorator';
import EditFieldContext, { Editable } from '@jsv2/LandingPage/context/EditFieldContext';

const SimpleBanner = React.forwardRef(({ id }, ref) => {
  const { editableFields } = useContext(EditFieldContext);

  return (
    editableFields && (
      <section id={id} ref={ref} className="simple-banner pt-50 pb-50">
        <div className="small-container">
          <div className="section-title section-title--white section-title--center mb-0">
            <Editable fieldValue={editableFields.simple_banner_heading} />
          </div>
        </div>
      </section>
    )
  );
});

SimpleBanner.displayName = 'SimpleBanner';

export default ErrorBoundaryDecorator()(SimpleBanner);
