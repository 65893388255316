import React from 'react';
import PropTypes from 'prop-types';
import { ErrorMessage } from 'formik';

import ErrorBoundaryDecorator from '@components/decorators/ErrorBoundaryDecorator';

const LabelComponent = ({ name, labelText, errors, dataQaId }) => {
  const renderLabel = () => <div className="label-content">{labelText}</div>;

  const renderError = () => (
    <ErrorMessage name={name} component="div" data-qa-id={dataQaId} className="label-content label-content--error" />
  );

  return <div className="label">{errors[name] ? renderError() : renderLabel()}</div>;
};

LabelComponent.propTypes = {
  name: PropTypes.string.isRequired,
  labelText: PropTypes.string.isRequired,
  errors: PropTypes.object,
  dataQaId: PropTypes.string,
};

LabelComponent.defaultProps = {
  errors: {},
  dataQaId: 'error',
};

export default ErrorBoundaryDecorator()(LabelComponent);
