const SHOW = 'FOOTER/SHOW';
const HIDE = 'FOOTER/HIDE';
const TOGGLE = 'FOOTER/TOGGLE';

const defaultState = {
  show: true,
  phoneNumber: null,
};

export default function reducer(state = defaultState, action) {
  switch (action.type) {
    case SHOW: {
      if (typeof action.payload !== 'undefined') {
        return {
          show: true,
          phoneNumber: action.payload,
        };
      }
      return { show: true };
    }
    case HIDE: return { show: false };
    case TOGGLE: {
      if (typeof action.payload !== 'undefined') {
        return { show: action.payload };
      }
      return { show: !state.show };
    }
    default: return state;
  }
}

export const showFooter = phoneNumber => ({
  type: SHOW,
  payload: phoneNumber,
});

export const hideFooter = () => ({
  type: HIDE,
});

export const toggleFooter = show => ({
  type: TOGGLE,
  payload: show,
});
