import STEPS from '@jsv2/config/SignUp/steps';
import ROLES from '@jsv2/config/userRoles';
import URLS from '@jsv2/config/SignUp/urls';
import BASE_STEP_DEFAULT from '@jsv2/templates/vipTraveler/SignUp/Steps/BaseStepDefault';
import ADDRESS_STEP_DEFAULT from '@jsv2/templates/vipTraveler/SignUp/Steps/AddressStepDefault';
import TRAVEL_PROFILER from '@jsv2/templates/vipTraveler/SignUp/Steps/TravelProfiler';
import TRAVEL_HISTORY from '@jsv2/templates/vipTraveler/SignUp/Steps/TravelHistory';
import TRAVEL_PREFERENCES from '@jsv2/templates/vipTraveler/SignUp/Steps/TravelPreferences';
import TRAVEL_AIRLINE from '@jsv2/templates/vipTraveler/SignUp/Steps/TravelAirline';
import FREE_THANKS_MESSAGE from '@jsv2/templates/vipTraveler/SignUp/Messages/FreeThanksMessage';
import FREE_WELCOME_MESSAGE from '@jsv2/templates/vipTraveler/SignUp/Messages/FreeWelcomeMessage';
import { makeUserFreeMember } from '@jsv2/utils/UserUtils/APIRequest';

const {
  shared_data: { defaultHomePageUrl },
} = window.__SERVER_DATA__;

const freeFlowConfig = {
  [STEPS.BASE_STEP_DEFAULT]: {
    name: STEPS.BASE_STEP_DEFAULT,
    component: BASE_STEP_DEFAULT,
    onComplete: () => STEPS.ADDRESS_STEP_DEFAULT,
    isClosable: true,
    style: 'closable-sign-up-step',
    url: URLS[STEPS.BASE_STEP_DEFAULT],
    roles: {
      [ROLES.ANONYMOUS]: true,
    },
  },
  [STEPS.ADDRESS_STEP_DEFAULT]: {
    name: STEPS.ADDRESS_STEP_DEFAULT,
    component: ADDRESS_STEP_DEFAULT,
    onComplete: () => {
      makeUserFreeMember();
      return STEPS.FREE_WELCOME_MESSAGE;
    },
    isClosable: true,
    style: 'closable-sign-up-step',
    url: URLS[STEPS.ADDRESS_STEP_DEFAULT],
    roles: {
      [ROLES.INCOMPLETE_SIGN_UP]: true,
    },
  },
  [STEPS.FREE_WELCOME_MESSAGE]: {
    name: STEPS.FREE_WELCOME_MESSAGE,
    component: FREE_WELCOME_MESSAGE,
    onComplete: () => STEPS.TRAVEL_PROFILER,
    isClosable: false,
    url: URLS[STEPS.FREE_WELCOME_MESSAGE],
    roles: {
      [ROLES.INCOMPLETE_SIGN_UP]: false,
      [ROLES.INCOMPLETE_PROFILER]: true,
    },
  },
  [STEPS.TRAVEL_PROFILER]: {
    name: STEPS.TRAVEL_PROFILER,
    component: TRAVEL_PROFILER,
    onComplete: () => STEPS.TRAVEL_HISTORY,
    isClosable: false,
    style: 'travel-profiler',
    url: URLS[STEPS.TRAVEL_PROFILER],
    roles: {
      [ROLES.INCOMPLETE_PROFILER]: true,
    },
  },
  [STEPS.TRAVEL_HISTORY]: {
    name: STEPS.TRAVEL_HISTORY,
    component: TRAVEL_HISTORY,
    onComplete: () => STEPS.TRAVEL_PREFERENCES,
    isClosable: false,
    style: 'travel-preferences',
    background: '/images/layover/viptraveler/travel-preferences-1.jpg',
    url: URLS[STEPS.TRAVEL_HISTORY],
    roles: {
      [ROLES.INCOMPLETE_PROFILER]: false,
      [ROLES.INCOMPLETE_TRAVEL_HISTORY]: true,
      [ROLES.INCOMPLETE_TRAVEL_PREFERENCES]: true,
    },
  },
  [STEPS.TRAVEL_PREFERENCES]: {
    name: STEPS.TRAVEL_PREFERENCES,
    component: TRAVEL_PREFERENCES,
    onComplete: () => STEPS.TRAVEL_AIRLINE,
    isClosable: false,
    style: 'travel-preferences',
    background: '/images/layover/viptraveler/travel-preferences-2.jpg',
    url: URLS[STEPS.TRAVEL_PREFERENCES],
    roles: {
      [ROLES.INCOMPLETE_PROFILER]: false,
      [ROLES.INCOMPLETE_TRAVEL_HISTORY]: false,
      [ROLES.INCOMPLETE_TRAVEL_PREFERENCES]: true,
    },
  },
  [STEPS.TRAVEL_AIRLINE]: {
    name: STEPS.TRAVEL_AIRLINE,
    component: TRAVEL_AIRLINE,
    onComplete: () => STEPS.FREE_THANKS_MESSAGE,
    isClosable: false,
    style: 'travel-airline',
    url: URLS[STEPS.TRAVEL_AIRLINE],
    roles: {
      [ROLES.INCOMPLETE_PROFILER]: false,
      [ROLES.INCOMPLETE_TRAVEL_HISTORY]: false,
      [ROLES.INCOMPLETE_TRAVEL_PREFERENCES]: false,
    },
  },
  [STEPS.FREE_THANKS_MESSAGE]: {
    name: STEPS.FREE_THANKS_MESSAGE,
    component: FREE_THANKS_MESSAGE,
    onComplete: () => (window.location.href = defaultHomePageUrl),
    isClosable: false,
    url: URLS[STEPS.FREE_THANKS_MESSAGE],
  },
};

export default freeFlowConfig;
