import React from 'react';
import PropTypes from 'prop-types';

import AuthMessage from '@jsv2/components/Authorization/AuthMessage';
import BaseMessageTemplate from '@jsv2/templates/vipTraveler/ModalMessages/BaseMessageTemplate';

/**
 * Pre Q Register Pop 1.
 */

const MESSAGE_CONTENT = {
  image: '/images/lp/promo2/vip_illustration-ok.svg',
  heading: 'So close!',
  tagline: 'You’re (almost) on your way to living your best travel life. Just click below to finish signing up.',
  buttonText: 'Join Now',
};

const RegistrationMessage = ({ step, getNextStep }) => (
  <AuthMessage
    step={step}
    getNextStep={getNextStep}
    render={({ handleClick }) => (
      <BaseMessageTemplate
        handleClick={(handleClick)}
        {...MESSAGE_CONTENT}
      />
    )}
  />
);

RegistrationMessage.propTypes = {
  step: PropTypes.shape({
    component: PropTypes.func.isRequired,
    onComplete: PropTypes.func.isRequired,
  }).isRequired,
  getNextStep: PropTypes.func.isRequired,
};

export default RegistrationMessage;
