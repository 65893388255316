import React from 'react';
import Select, { components } from 'react-select';
import PropTypes from 'prop-types';

import ErrorBoundaryDecorator from '@components/decorators/ErrorBoundaryDecorator';

/**
 * The header of an explorer popup with a filter box.
 *
 * @param animateFilter
 * @param filterOptions
 * @param handleChangeFilterValue
 * @param activeFilterValue
 *
 * @return {*}
 *
 * @constructor
 */
const PopupHeader = ({
  animateFilter,
  filterOptions,
  handleChangeFilterValue,
  activeFilterValue,
}) => {
  const Input = (props) => {
    if (props.isHidden) {
      return <components.Input {...props} />;
    }

    return null;
  };

  return (
    <div className="explore-popup__heading">
      <div className="explore-popup__title">{t('Explore')}</div>

      {!!filterOptions.length && (
        <Select
          className={`explore-popup__filter ${
            animateFilter ? 'explore-popup__filter--animate' : ''
          }`}
          options={filterOptions}
          classNamePrefix="destination-filter"
          placeholder="Filter By"
          onChange={(value) => handleChangeFilterValue(value)}
          value={activeFilterValue}
          defaultValue={activeFilterValue}
          components={{ Input }}
        />
      )}
    </div>
  );
};

PopupHeader.propTypes = {
  animateFilter: PropTypes.bool.isRequired,
  filterOptions: PropTypes.arrayOf(PropTypes.object).isRequired,
  handleChangeFilterValue: PropTypes.func.isRequired,
  activeFilterValue: PropTypes.object.isRequired,
};

export default ErrorBoundaryDecorator()(PopupHeader);
