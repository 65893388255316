import React, { useContext, useEffect, useState } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import { getArticleListAPI } from '@jsv3/utils/api/articleListAPI';
import ErrorBoundaryDecorator from '@components/decorators/ErrorBoundaryDecorator';
import ArticleGrid from '@jsv2/LandingPage/sections/ArticleGrid';
import EditFieldContext, { Editable } from '@jsv2/LandingPage/context/EditFieldContext';
import LPContext from '@jsv2/LandingPage/context/LPContext';

const BlogSectionGridView = ({ showBecomeButton, className, style, id }) => {
  const { editableFields } = useContext(EditFieldContext);
  const { renderBecomeButton } = useContext(LPContext);

  const [articles, setArticles] = useState([]);

  useEffect(() => {
    getArticleListAPI().then(({ data }) => {
      setArticles(data || []);
    });
  }, []);

  if (articles.length === 0) {
    return null;
  }

  return (
    <section className={classNames('trending', className)} style={style} id={id}>
      <div className="main-container trending__container">
        <div className="trending__title">
          <div className="section-title section-title--center section-title--with-underline">
            <Editable fieldValue={editableFields.blog_heading} />
          </div>
          <div className="section-testimonial">
            <Editable fieldValue={editableFields.blog_testimonial} />
          </div>
        </div>

        <ArticleGrid articles={articles.slice(0, 3)} />

        {showBecomeButton &&
          renderBecomeButton(
            'become-btn become-btn--with-border',
            window.templateVariables.body_cta_text.content,
            'become-join-btn',
          )}
      </div>
    </section>
  );
};

BlogSectionGridView.propTypes = {
  showBecomeButton: PropTypes.bool,
  className: PropTypes.string,
  style: PropTypes.object,
};

BlogSectionGridView.defaultProps = {
  showBecomeButton: false,
  className: '',
  style: {},
};

export default ErrorBoundaryDecorator()(BlogSectionGridView);
