import React from 'react';

import BaseMessageTemplate from '@jsv2/templates/vipTraveler/ModalMessages/BaseMessageTemplate';
import ErrorBoundaryDecorator from '@components/decorators/ErrorBoundaryDecorator';

/**
 * Profiler Successful Message
 */

const MESSAGE_CONTENT = {
  image: '/images/lp/promo2/vip_illustration-ok.svg',
  heading: 'Thanks! You’re en route.',
  tagline:
    'You’ve signed up for a Free Membership plan! Soon, we’ll reach out to share travel finds we know you’ll love' +
    ' and to update you on our progress as we continue to craft your personalized VIP Traveler experience. In the' +
    " meantime, check out our featured offers to the destinations we're most excited about this month.",
  buttonText: 'VIEW VIP EXCLUSIVES™',
};

const {
  shared_data: { defaultHomePageUrl },
} = window.__SERVER_DATA__;

const SuccessCompleteProfilerMessage = () => {
  const handleClick = () => {
    window.location.href = defaultHomePageUrl;
  };

  return <BaseMessageTemplate handleClick={handleClick} {...MESSAGE_CONTENT} />;
};

export default ErrorBoundaryDecorator()(SuccessCompleteProfilerMessage);
