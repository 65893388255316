import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import ReactModal from 'react-modal';
import { connect } from 'react-redux';

import ErrorBoundaryDecorator from '@components/decorators/ErrorBoundaryDecorator';
import ScreenTypeContext, { isMobile } from '@js/context/ScreenTypeContext';
import { toggleLayoverAsClosed, toggleLayoverAsOpen } from '../ducks/layover';

/**
 * Default layover setting. Can be overwritten with parent {modalSettings}
 * or with {static modalSettings} of exec layover component
 */
const defaultSettings = {
  overlayClassName: 'modal-overlay',
  className: 'modal-window',
  bodyOpenClassName: 'noScroll',
};

const rootPageElement = document.getElementsByTagName('html')[0] || null;

ReactModal.setAppElement('#root');

class LayoverComponent extends PureComponent {
  static contextType = ScreenTypeContext;

  static propTypes = {
    LayoverContentComponent: PropTypes.oneOfType([PropTypes.func, PropTypes.object]).isRequired,
    modalSettings: PropTypes.object,
    enableCloseButton: PropTypes.bool,
    openByDefault: PropTypes.bool,
    layoverContentProps: PropTypes.object,
    afterClose: PropTypes.func,
    onRequestClose: PropTypes.func,
  };

  static defaultProps = {
    modalSettings: {},
    enableCloseButton: true,
    openByDefault: false,
    layoverContentProps: {},
    afterClose: () => {}, // @todo make sure that noting uses this callback and remove it; Use static component afterClose method instead
    onRequestClose: () => {},
  };

  state = {
    isOpen: this.props.openByDefault || false,
    popupOffset: 0,
  };

  constructor(props) {
    super(props);
    this.layoverRef = React.createRef();
  }

  componentDidMount() {
    if (this.props.openByDefault) {
      this.props.toggleLayoverAsOpen();
    }
  }

  closeModal = () => {
    this.props.onRequestClose();
    this.props.toggleLayoverAsClosed();
    this.props.afterClose();
    if (this.layoverRef.current && this.layoverRef.current.afterClose) {
      // in order for ref to work, don't forget to pass
      // 4th arg { forwardRef: true } to the 'connect' method
      // if you are connecting LayoverContentComponent to redux store
      this.layoverRef.current.afterClose();
    }
    this.setState({ isOpen: false });
  };

  openModal = () => {
    this.setState({ isOpen: true });
    this.props.toggleLayoverAsOpen();
  };

  // Disable body scrolling on mobile when modal is opened.
  handleAfterOpen = () => {
    if (isMobile(this.context)) {
      const { pageYOffset } = window;
      this.setState({ popupOffset: pageYOffset });

      if (rootPageElement) {
        rootPageElement.style.marginTop = pageYOffset * -1 + 'px';
        rootPageElement.classList.add('disable-page-scrolling');
      }
    }
  };

  // Allow body scrolling on mobile when modal is closed.
  handleAfterClose = () => {
    if (isMobile(this.context)) {
      if (rootPageElement) {
        rootPageElement.style.marginTop = '';
        rootPageElement.classList.remove('disable-page-scrolling');
      }

      window.scrollTo(0, this.state.popupOffset);
      this.setState({ popupOffset: 0 });
    }
  };

  render() {
    const {
      modalSettings,
      enableCloseButton,
      LayoverContentComponent,
      layoverContentProps,
    } = this.props;

    return (
      <ReactModal
        // default props for layover
        {...defaultSettings}
        // some layover props which we can get from the parent. will overwrite defaultSettings
        {...modalSettings}
        // some specific props which we can set in particular layover. will overwrite prev settings
        {...LayoverContentComponent.modalSettings}
        isOpen={this.state.isOpen}
        onRequestClose={this.closeModal}
        onAfterOpen={this.handleAfterOpen}
        onAfterClose={this.handleAfterClose}
      >
        <div className="layover-wrapper">
          {enableCloseButton && (
            <button
              type="button"
              tabIndex="0"
              onClick={this.closeModal}
              className="btn-close"
              data-qa-id="btn_close"
            />
          )}
          <LayoverContentComponent
            ref={this.layoverRef}
            onClose={this.closeModal}
            {...layoverContentProps}
          />
        </div>
      </ReactModal>
    );
  }
}

const mapDispatchToProps = { toggleLayoverAsOpen, toggleLayoverAsClosed };

export default ErrorBoundaryDecorator()(
  connect(null, mapDispatchToProps, null, { forwardRef: true })(LayoverComponent),
);
