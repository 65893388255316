import React from 'react';
import PropTypes from 'prop-types';

import ErrorBoundaryDecorator from '@components/decorators/ErrorBoundaryDecorator';

const ToggleSwitchCheckbox = ({ id, checked, onChange, label }) => (
  <div className="toggle-switch-checkbox-wrapper">
    <div className="toggle-switch-checkbox">
      <input type="checkbox" name={id} id={id} defaultChecked={checked} onChange={onChange} />
      <span className="toggle-switch-slider" />
    </div>

    {/* eslint-disable-next-line jsx-a11y/label-has-for */}
    <label htmlFor={id}>{label}</label>
  </div>
);

ToggleSwitchCheckbox.propTypes = {
  id: PropTypes.string.isRequired,
  checked: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
  label: PropTypes.string.isRequired,
};

export default ErrorBoundaryDecorator()(ToggleSwitchCheckbox);
