import ROLES from '@jsv2/config/userRoles';
import FLOWS, { PREMIUM_FLOWS } from '@jsv2/config/SignUp/flows';
import PROFILER_FLOWS from '@jsv2/config/Profiler/flows';

const matchStepToUser = (steps, userRoles) =>
  Object.keys(steps).every((key) => userRoles.includes(key) === steps[key]);

const getTargetStep = (steps, userRoles) =>
  steps.find(({ roles }) => matchStepToUser(roles, userRoles));

const getTargetStepByShowMark = (steps) => steps.find(({ onShowStep }) => onShowStep() === true);

export const getInitialStep = ({ flow, userRoles, config }) => {
  const steps = Object.values(config[flow]);
  const handledSteps = steps.filter((step) => step.roles);
  const handleStepsByShowMark = steps.filter((step) => step.onShowStep);
  const currentStep =
    getTargetStepByShowMark(handleStepsByShowMark) || getTargetStep(handledSteps, userRoles);
  const firstStep = getTargetStep(handledSteps, [ROLES.ANONYMOUS]) || handledSteps[0];

  return currentStep ? currentStep.name : firstStep.name;
};

export const getSignUpFlow = (flow) => {
  const isFlowExist = Object.values(FLOWS).includes(flow);
  return isFlowExist ? flow : FLOWS.UPGRADE;
};

export const getProfilerFlow = (flow) => {
  const isFlowExist = Object.values(PROFILER_FLOWS).includes(flow);
  return isFlowExist ? flow : PROFILER_FLOWS.DEFAULT;
};

export const detectPremiumMembership = (flow) => PREMIUM_FLOWS.includes(flow);

export const isEmailMissing = () => {
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);

  return urlParams.get('social_reg') === '1';
};
