import React from 'react';
import PropTypes from 'prop-types';

import LinkItem from '../../components/LinkItem';

const Settings = ({ isSubItem, onMenuItemClick }) => (
  <LinkItem
    href="/profile#/account-settings"
    id="account-setting-button"
    title="Account"
    isSubItem={isSubItem}
    onMenuItemClick={onMenuItemClick}
  />
);

Settings.propTypes = {
  isSubItem: PropTypes.bool,
  onMenuItemClick: PropTypes.func,
};

Settings.defaultProps = {
  isSubItem: false,
  onMenuItemClick: () => {},
};

export default Settings;
