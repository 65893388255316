import React from 'react';
import PropTypes from 'prop-types';

import ErrorBoundaryDecorator from '@components/decorators/ErrorBoundaryDecorator';

/* eslint-disable camelcase */
const SocialNetworks = ({ buttons, onlyIcon, className, flow, promoCampaignId }) => {
  const processLink = (provider) => {
    window.location.href =
      window.__SERVER_DATA__.social_links[provider] +
      '?signup_flow=' +
      flow +
      '&promo_campaign_id=' +
      promoCampaignId;
  };

  return (
    <div className={`social-login ${className}`}>
      {buttons.map((item) => (
        <button
          type="button"
          key={item.name}
          className={`social-btn btn-${item.className}-login`}
          title={item.title}
          onClick={() => processLink(item.name)}
          data-qa-id={`${item.className}_oauth_button`}
        >
          <span>{onlyIcon ? '' : `${item.title}`}</span>
        </button>
      ))}
    </div>
  );
};

SocialNetworks.propTypes = {
  buttons: PropTypes.arrayOf(PropTypes.object).isRequired,
  onlyIcon: PropTypes.bool,
  className: PropTypes.string,
  flow: PropTypes.string,
  promoCampaignId: PropTypes.number,
};

SocialNetworks.defaultProps = {
  onlyIcon: false,
  className: '',
  flow: '',
  promoCampaignId: 0,
};

export default ErrorBoundaryDecorator()(SocialNetworks);
