import React, { useContext, useEffect, useState, useRef } from 'react';

import Modal from '@jsv2/components/Wizard/Modal';
import Wizard from '@jsv2/components/Wizard/Wizard';
import SignUpContext from '@jsv2/context/SignUpContext';
import SignUpConfig from '@jsv2/config/SignUp';
import { getInitialStep } from '@jsv2/utils/flowUtils';
import ErrorBoundaryDecorator from '@components/decorators/ErrorBoundaryDecorator';

const { userRoles } = window;

const SignUpModal = () => {
  const { modalIsOpen, closeModal } = useContext(SignUpContext);
  const { flow, initialStep } = useContext(SignUpContext).signUpProcess;

  const [isModalClosable, setModalType] = useState(false);
  const [currentStep, getStep] = useState(initialStep);

  useEffect(() => {
    const step = getInitialStep({ flow, userRoles, config: SignUpConfig });
    getStep(step);
  }, [flow]);

  const step = SignUpConfig[flow][currentStep];

  const layoverRef = useRef(null);

  const handleCloseModal = () => {
    if (typeof step.onClose === 'function') {
      getStep(step.onClose());
    } else {
      closeModal();
    }
  };

  useEffect(() => {
    const isStepClosable = step?.isClosable;
    setModalType(isStepClosable);

    if (layoverRef.current) {
      layoverRef.current.scrollIntoView();
    }
  }, [step]);

  useEffect(() => {
    if (!currentStep) {
      closeModal();
    }
  }, [closeModal, currentStep]);

  return (
    <Modal
      withCloseButton={isModalClosable}
      overlayClassName={step?.style}
      isModalOpen={modalIsOpen}
      closeModal={handleCloseModal}
      contentRef={(node) => (layoverRef.current = node)}
      background={step?.background}
      data={{
        'qa-id': `qa_popup_sign_up_${currentStep}`,
      }}
    >
      <div className="layover-wrapper landing-layover-wrapper">
        <Wizard getStep={getStep} stepConfig={step} />
      </div>
    </Modal>
  );
};

export default ErrorBoundaryDecorator()(SignUpModal);
