import React from 'react';
import PropTypes from 'prop-types';

import ErrorBoundaryDecorator from '@components/decorators/ErrorBoundaryDecorator';
import getDataQaId from '@jsv3/utils/helpers/getDataQaIdHelper';

const FooterLinks = ({ links }) => (
  <div className="navlist">
    {links.map((link) => (
      <div key={link.title}>
        <a className="navlist__btn" href={link.link} data-qa-id={getDataQaId('btn', link.title)}>
          {link.title}
        </a>
      </div>
    ))}

    <div>
      <a className="navlist__btn" href="/contact-us" data-qa-id="btn_contact_us">
        Contact
      </a>
    </div>

    <div>
      <a className="navlist__btn" href="/destinations" data-qa-id="btn_destinations_list">
        Popular Destinations
      </a>
    </div>
  </div>
);

FooterLinks.propTypes = {
  links: PropTypes.arrayOf(PropTypes.object),
};

FooterLinks.defaultProps = {
  links: [],
};

export default ErrorBoundaryDecorator()(FooterLinks);
