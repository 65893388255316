import SectionWithAdditionalContentHOC from '@jsv2/utils/SectionWithAdditionalContentHOC';
import TechnologySection from './sections/TechnologySection';
import ChatSection from './sections/ChatSection';
import ExclusiveOffersSection, { ExclusiveOffersImage } from './sections/ExclusiveOffersSection';
import BecomeSection from './sections/BecomeSection';
import VideosSectionWide from './sections/VideosSectionWide';
import WhySection from './sections/WhySection';
import About from './sections/AboutSection';
import AboutWithTwoRow from './sections/AboutSectionWithTwoRow';
import Banner from './sections/BannerSection';
import BannerWithAnimation from './sections/BannerSectionWithAnimation';
import ResearchScheme from './sections/ResearchScheme';
import HowItWorks from './sections/HowItWorks';
import VideosSection from './sections/VideosSection';
import Reviews from './sections/Reviews';
import PubsSection from './sections/PubsSection';
import OffersSliderWithReviews from './sections/OffersSliderWithReviews';
import OffersSliderWithBenefits from './sections/OffersSliderWithBenefits';
import BenefitsSection from './sections/BenefitsSection';
import FreeUpSection from './sections/FreeUpSection';
import ReviewsWithAvatar from './sections/ReviewsWithAvatar';
import ChoosePlanSection from './sections/ChoosePlanSection';
import BlogSection from './sections/BlogSection';
import VipBenefits from './sections/VipBenefitsSection';
import ExclusiveOffersSectionWide from './sections/ExclusiveOffersSectionWide';
import TrustFactors from './sections/TrustFactors';
import BlogSectionGridView from './sections/BlogSectionGridView';
import TeamSection from './sections/TeamSection';
import DestinationExpertsSection from './sections/DestinationExpertsSection';
import Testimonial from './sections/Testimonial';
import ContentGridSection from './sections/ContentGridSection';
import TripBannerSection from './sections/TripBannerSection';
import ReviewsWithBorder from './sections/ReviewsWithBorder';
import FaqsSection from './sections/FaqsSection';
import ContentSection from './sections/ContentSection';
import SimpleBanner from './sections/SimpleBanner';
import LargeBenefitsSection from './sections/LargeBenefitsSection';
import SingleImage from './sections/SingleImage';
import HowStartedWithGallery from './sections/HowStartedWithGallery';
import Quote from './sections/Quote';
import ContentWithBenefitsSection from './sections/ContentWithBenefitsSection';
import ContentWithChatSection from './sections/ContentWithChatSection';
import ContentWithOffersSection from './sections/ContentWithOffersSection';
import RedesignChoosePlanSection from './sections/RedesignChoosePlanSection';

export default {
  banner: Banner,
  bannerWithAnimation: BannerWithAnimation,
  about: About,
  aboutWithTwoRow: AboutWithTwoRow,
  pubsSection: PubsSection,
  whySection: WhySection,
  videosSectionWide: VideosSectionWide,
  technologySection: TechnologySection,
  chatSection: ChatSection,
  exclusiveOffersWithImageSection: SectionWithAdditionalContentHOC(
    ExclusiveOffersSection,
    ExclusiveOffersImage,
  ),
  exclusiveOffersWithSliderBenefitsSection: SectionWithAdditionalContentHOC(
    ExclusiveOffersSection,
    OffersSliderWithBenefits,
  ),
  exclusiveOffersWithSliderReviewsSection: SectionWithAdditionalContentHOC(
    ExclusiveOffersSection,
    OffersSliderWithReviews,
  ),
  howItWorks: HowItWorks,
  reviews: Reviews,
  becomeSection: BecomeSection,
  researchScheme: ResearchScheme,
  videosSection: VideosSection,
  offersSliderWithReviews: OffersSliderWithReviews,
  offersSliderWithBenefits: OffersSliderWithBenefits,
  benefitsSection: BenefitsSection,
  freeUpSection: FreeUpSection,
  reviewsWithAvatar: ReviewsWithAvatar,
  choosePlanSection: ChoosePlanSection,
  blogSection: BlogSection,
  blogSectionGridView: BlogSectionGridView,
  vipBenefits: VipBenefits,
  exclusiveOffersSectionWide: ExclusiveOffersSectionWide,
  trustFactors: TrustFactors,
  teamSection: TeamSection,
  destinationExpertsSection: DestinationExpertsSection,
  testimonial: Testimonial,
  contentGrid: ContentGridSection,
  tripBannerSection: TripBannerSection,
  reviewsWithBorder: ReviewsWithBorder,
  faqs: FaqsSection,
  contentSection: ContentSection,
  simpleBanner: SimpleBanner,
  largeBenefitsSection: LargeBenefitsSection,
  singleImage: SingleImage,
  howStartedWithGallery: HowStartedWithGallery,
  quote: Quote,
  contentWithBenefits: ContentWithBenefitsSection,
  contentWithChat: ContentWithChatSection,
  contentWithOffers: ContentWithOffersSection,
  redesignChoosePlan: RedesignChoosePlanSection,
};
