import React, { useState } from 'react';

import Spinner from '@components/Spinner';
import PaymentInformationMessage from './PaymentInformationMessage';
import AddCreditCardForm from './FormParts/AddCreditCardForm';

const StepForm = ({ handleSubmit, errors }) => {
  const [isLoading] = useState(false);

  return (
    <div className="signup-step signup-step-3">
      <PaymentInformationMessage />

      { isLoading
        ? <Spinner />
        : (
          <AddCreditCardForm
            onSubmit={handleSubmit}
            showNameOnCardFields
            errors={errors}
          />
        )
      }
    </div>
  );
};

export default StepForm;
