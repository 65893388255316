import STEPS from '@jsv2/config/SignUp/steps';
import PAYMENT_STEP from '@jsv2/templates/vipTraveler/SignUp/Steps/UpgradePlanPaymentStep';
import PAID_THANKS_MESSAGE from '@jsv2/templates/vipTraveler/SignUp/Messages/PaidThanksMessagePlanUpgrade';

const {
  shared_data: { defaultHomePageUrl },
} = window.__SERVER_DATA__;

const upgradePlanConfig = {
  [STEPS.PAYMENT_STEP_DEFAULT]: {
    name: STEPS.PAYMENT_STEP_DEFAULT,
    component: PAYMENT_STEP,
    onComplete: () => STEPS.PAID_WELCOME_MESSAGE,
    isClosable: true,
    style: 'closable-sign-up-step',
    roles: {},
  },

  [STEPS.PAID_WELCOME_MESSAGE]: {
    name: STEPS.PAID_THANKS_MESSAGE,
    component: PAID_THANKS_MESSAGE,
    onComplete: () => (window.location.href = defaultHomePageUrl),
    isClosable: false,
    style: 'paid-welcome-message',
    roles: {},
  },
};

export default upgradePlanConfig;
