import React from 'react';
import PropTypes from 'prop-types';

import currencyFormatter from 'currency-formatter';
import classNames from 'classnames';
import ErrorBoundaryDecorator from '@components/decorators/ErrorBoundaryDecorator';

/* eslint-disable camelcase */
const Price = ({ currency_iso3, price, inline, hideDecimalsForIntegers, isDiscount, divLess }) => {
  const { symbol = '', symbolOnLeft = false, spaceBetweenAmountAndSymbol = false } =
    currencyFormatter.findCurrency(currency_iso3) || {};

  const isPriceInteger = price % 1 === 0;
  const defaultPrecision = 2;

  const val = currencyFormatter.format(price, {
    code: currency_iso3,
    symbol: '',
    spaceBetweenAmountAndSymbol: false,
    precision: hideDecimalsForIntegers && isPriceInteger ? 0 : defaultPrecision,
  });

  const symbolHtml = (
    <div className="price-currency" data-qa-id="qa_price_currency">
      {' '}
      {symbol}{' '}
    </div>
  );
  return (
    <>
      {divLess ? (
        <>
          {isDiscount && '- '}
          {symbolOnLeft && symbol}
          {symbolOnLeft && spaceBetweenAmountAndSymbol && ' '}
          {val}
          {!symbolOnLeft && spaceBetweenAmountAndSymbol && ' '}
          {!symbolOnLeft && symbol}
        </>
      ) : (
        <div className={classNames('offer-price', { inline })}>
          {isDiscount && '- '}
          {symbolOnLeft && symbolHtml}
          {symbolOnLeft && spaceBetweenAmountAndSymbol && ' '}
          <div className="price-value" data-qa-id="qa_price_value">
            {' '}
            {val}
          </div>
          {!symbolOnLeft && spaceBetweenAmountAndSymbol && ' '}
          {!symbolOnLeft && symbolHtml}
        </div>
      )}
    </>
  );
};

Price.propTypes = {
  currency_iso3: PropTypes.string.isRequired,
  price: PropTypes.number.isRequired,
  inline: PropTypes.bool,
  hideDecimalsForIntegers: PropTypes.bool,
  isDiscount: PropTypes.bool,
  // eslint-disable-next-line react/no-unused-prop-types
  dataQaId: PropTypes.string,
};

Price.defaultProps = {
  inline: false,
  hideDecimalsForIntegers: true,
  isDiscount: false,
  dataQaId: '',
};

export default ErrorBoundaryDecorator()(Price);
