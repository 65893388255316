import React, { useRef } from 'react';
import PropTypes from 'prop-types';

import Slider from '@jsv2/components/Slider/Slider';
import Image from '@jsv2/components/Image';
import { IMAGE_SIZES } from '@js/constants';
import ErrorBoundaryDecorator from '@components/decorators/ErrorBoundaryDecorator';
import { PrevArrow, NextArrow } from '@jsv2/components/Slider/SliderArrows';

// eslint-disable-next-line react/display-name
const BlogSlider = React.memo(({ className, articles }) => {
  const navigationPrevRef = useRef(null);
  const navigationNextRef = useRef(null);

  const sliderSettings = {
    slidesPerView: 1,
    speed: 500,
    className: `blog-slider ${className} swiper-custom-arrows swiper-custom-arrows--black`,
    noSwiping: true,
    allowTouchMove: false,
    breakpoints: {
      1: {
        slidesPerView: 1,
      },
      1024: {
        slidesPerView: 3,
      },
    },
    lazy: {
      checkInView: true,
      loadOnTransitionStart: true,
    },
  };

  return (
    <div className="blog-slider-wrapper">
      <PrevArrow ref={navigationPrevRef} isAbsolute />
      <NextArrow ref={navigationNextRef} isAbsolute />

      <Slider
        sliderProps={{
          navigation: {
            prevEl: navigationPrevRef.current,
            nextEl: navigationNextRef.current,
          },
          onBeforeInit: (swiper) => {
            swiper.params.navigation.prevEl = navigationPrevRef.current;
            swiper.params.navigation.nextEl = navigationNextRef.current;
          },
          ...sliderSettings,
        }}
        isPreloader
      >
        {articles.map((article) => (
          <a key={article.id} href={article.url} className="slide">
            {article.image ? (
              <Image
                className="slide__image swiper-lazy"
                data={article.image}
                config={{
                  size: IMAGE_SIZES.SMALL,
                }}
                background
                lazy
              />
            ) : (
              <div className="slide__image" />
            )}

            <h4 className="slide__title">{article.title}</h4>
          </a>
        ))}
      </Slider>
    </div>
  );
});

BlogSlider.propTypes = {
  className: PropTypes.string,
  articles: PropTypes.arrayOf(PropTypes.object).isRequired,
};

BlogSlider.defaultProps = {
  className: '',
};

export default ErrorBoundaryDecorator()(BlogSlider);
