/* eslint-disable camelcase */
import { useContext, useState } from 'react';
import PropTypes from 'prop-types';

import { saveUserEmail } from '@jsv2/utils/UserUtils/APIRequest';
import SignUpContext from '@jsv2/context/SignUpContext';
import BaseStep from '@jsv2/components/Authorization/BaseStep';

const BaseStepWelcome = ({ step, getNextStep, render }) => {
  const { onClose } = useContext(SignUpContext);
  const [serverError, setError] = useState(null);

  /**
   * Email form submit handler
   *
   * @param {Object} values
   * @param {Object} actions
   */
  const handleSubmit = (values, actions) => {
    const { offer } = window.__SERVER_DATA__;

    if (offer?.id) {
      values.offer_id = offer.id; // TODO:
    }

    saveUserEmail(values)
      .then(() => {
        getNextStep(step.onComplete());

        const homePageUrl = window?.__SERVER_DATA__?.shared_data?.defaultHomePageUrl;

        if (homePageUrl) {
          // TODO:
          document.getElementById('logo-link').setAttribute('href', homePageUrl);
        }
      })
      .catch(({ response }) => {
        if (response?.status !== 422) {
          setError(response.data.message);
        }

        if (response?.data?.data) {
          actions.setErrors(response.data.data);
        }
      })
      .then(() => {
        actions.setSubmitting(false);
      });
  };

  /**
   * Social sign up form handler
   *
   * @param {Boolean} already_registered
   */
  const handleSocialSignUp = ({ already_registered }) => {
    if (already_registered) {
      return window.location.reload();
    }

    getNextStep(step.onComplete());

    return onClose(); // use it for closing last step
  };

  return render({ serverError, handleSocialSignUp, handleSubmit });
};

BaseStep.propTypes = {
  render: PropTypes.func.isRequired,
  step: PropTypes.shape({
    component: PropTypes.func.isRequired,
    onComplete: PropTypes.func.isRequired,
  }).isRequired,
  getNextStep: PropTypes.func.isRequired,
};

export default BaseStepWelcome;
