import Echo from 'laravel-echo';

import { alert } from '@utils/Dialogs';

const {
  user,
  __SERVER_DATA__: { websocket_host: websocketHost },
} = window;

const getEchoProxyLogger = (obj = {}) =>
  new Proxy(obj, {
    get(target, name, receiver) {
      if (!Reflect.has(target, name)) {
        Logger.error(`Getting non-existent Echo property '${name}'`);

        return () => getEchoProxyLogger();
      }

      return Reflect.get(target, name, receiver);
    },
  });

const getEchoClientInstance = () => {
  try {
    const echo = new Echo({
      broadcaster: 'socket.io',
      host: `${window.location.protocol}//${websocketHost}`,
      auth: {
        headers: {
          Authorization: `Bearer ${user && user.api_token}`,
        },
      },
      namespace: 'Common.Events',
    });

    return getEchoProxyLogger(echo);
  } catch (e) {
    const onOk = () => {
      window.location.reload();

      return false;
    };

    setTimeout(() => {
      Logger.error('Could not load Echo client!', e);

      alert(t('Something went wrong, please refresh the page!'), {
        labelOk: 'Refresh',
        withCloseButton: true,
        onOk,
      });
    });

    return getEchoProxyLogger();
  }
};

const echoClient = user ? getEchoClientInstance() : {};

export default echoClient;
