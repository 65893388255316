import _ from 'lodash';

/**
 * Groups all destinations by tags and add the custom tag as randomTag.
 *
 * @param destinationsList
 * @param tags
 * @param randomTag
 *
 * @return {Object}
 */
export function groupDestinationsByTag(destinationsList, tags, randomTag) {
  const result = {};

  tags.forEach((tag) => {
    result[tag.name] = [];

    destinationsList.forEach((item) => {
      if (item.tags.some((obj) => obj.name === tag.name)) {
        result[tag.name].push(item);
      }
    });

    // TODO: API should not return tags which does not have destinations.
    if (result[tag.name].length < 1) {
      delete result[tag.name];
    }
  });

  if (destinationsList.length > 0) {
    result[randomTag] = _.shuffle(destinationsList).sort((a, b) => a.id - b.id);
  }

  return result;
}

/**
 * get Not Preferred Destinations
 *
 * @param destinations
 * @param preferences
 *
 * @return {[]}
 */
export const getNotPreferred = (destinations, preferences) => {
  const newArr = [];

  const destinationsIds = destinations.map((el) => el.id);
  const preferencesIds = preferences.map((el) => el.entity_id);

  const uniqueDestinations = destinationsIds.filter((val) => !preferencesIds.includes(val));
  const uniquePreferences = preferencesIds.filter((val) => !destinationsIds.includes(val));

  [...uniqueDestinations, ...uniquePreferences].forEach((el) =>
    newArr.push(...destinations.filter((i) => i.id === el)),
  );

  return newArr.sort((a, b) => a.id - b.id);
};

/**
 * Get Destination from passed destinations list By Name.
 *
 * @param destinationsList
 * @param destination
 *
 * @return {{}}
 */
export const getDestinationByName = (destinationsList, destination) => {
  const result = destinationsList.filter((item) => item.name === destination);

  if (result.length) {
    return result[0].id;
  }

  return false;
};
