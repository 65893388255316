/**
 * This file is responsible for registration/login form state management
 */
import { isPartiallyIdentified } from '@utils/user';
// eslint-disable-next-line import/no-cycle
import formEventReducer, {
  EMAIL_IS_ALREADY_REGISTERED,
  MAGIC_LINK_EXPIRED,
  RESTORE_SIGN_UP_LINK,
  FORM_ERROR,
  SET_FORM_EVENT,
  INVALID_PASSWORD,
} from './formEvents';

const CHANGE_ACTIVE_FORM = 'registrationsForm/CHANGE_ACTIVE_FORM';

export const FORM_TYPES = {
  LOGIN: 'LOGIN',
  REGISTRATION: 'REGISTRATION',
  FORGOT_PASSWORD: 'FORGOT_PASSWORD',
  NEWSLETTER: 'NEWSLETTER',
  REGISTRATION_NEW_CUSTOMER: 'REGISTRATION_NEW_CUSTOMER',
  REQUEST_MAGIC_LINK: 'REQUEST_MAGIC_LINK',
};

export const FORM_EVENTS = {
  EMAIL_IS_ALREADY_REGISTERED,
  MAGIC_LINK_EXPIRED,
  RESTORE_SIGN_UP_LINK,
  FORM_ERROR,
  INVALID_PASSWORD,
};

const defaultState = {
  activeForm: isPartiallyIdentified() ? FORM_TYPES.LOGIN : FORM_TYPES.REGISTRATION,
  formEvent: null,
};

export default function reducer(state = defaultState, action) {
  switch (action.type) {
    case CHANGE_ACTIVE_FORM: {
      return {
        ...state,
        activeForm: action.payload,
        formEvent: formEventReducer(action.formEvent),
      };
    }
    case SET_FORM_EVENT: {
      return { ...state, formEvent: formEventReducer(action.payload) };
    }
    default:
      return state;
  }
}

export function changeActiveForm(formType, formEvent) {
  return {
    type: CHANGE_ACTIVE_FORM,
    payload: formType,
    formEvent,
  };
}
