import apiClient from '@js/apiClient';

/**
 * Get Top Picks list.
 *
 * @return {Promise<AxiosResponse<any>>}
 */
export function getTopPicks() {
  return apiClient.get('top-picks');
}

/**
 * Get Top Picks list by property.
 *
 * @return {Promise<AxiosResponse<any>>}
 */
export function getTopPicksByProperty(propertyId) {
  return apiClient.get(`top-picks?property_ids[]=${propertyId}`);
}

/**
 * Get Top Picks list by destination.
 *
 * @return {Promise<AxiosResponse<any>>}
 */
export function getTopPicksByDestination(destinationId) {
  return apiClient.get(`top-picks?destination_ids[]=${destinationId}`);
}
