import React, { useState } from 'react';
import { connect, Field } from 'formik';
import PropTypes from 'prop-types';

import ErrorBoundaryDecorator from '@components/decorators/ErrorBoundaryDecorator';

// TODO: use this component instead of class one (maybe rename on FormikPasswordField?)
// applications/front/resources/assets/js/landing/vipTravelerPromo2/auth/PasswordField.js
const PasswordField = ({ name, placeholder, ...rest }) => {
  const [showPassword, toggleShowPassword] = useState(false);

  return (
    <div className="input password">
      <div className="input-wrapper">
        <Field
          type={showPassword ? 'text' : 'password'}
          name={name}
          placeholder={placeholder}
          {...rest}
        />

        <button
          type="button"
          tabIndex="0"
          className={`show-password ${showPassword ? 'active' : ''}`}
          onClick={() => toggleShowPassword(!showPassword)}
        />
      </div>
    </div>
  );
};

PasswordField.propTypes = {
  name: PropTypes.string,
  placeholder: PropTypes.string,
};

PasswordField.defaultProps = {
  name: 'password',
  placeholder: 'password',
};

export default ErrorBoundaryDecorator()(connect(PasswordField));
