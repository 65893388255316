const SHOW = 'LIVE_CHAT/SHOW';
const HIDE = 'LIVE_CHAT/HIDE';
const TOGGLE = 'LIVE_CHAT/TOGGLE';

const defaultState = {
  show: true,
};

export default function reducer(state = defaultState, action) {
  switch (action.type) {
    case SHOW:
      return { show: true };
    case HIDE:
      return { show: false };
    case TOGGLE: {
      if (typeof action.payload !== 'undefined') {
        return { show: action.payload };
      }
      return { show: !state.show };
    }
    default:
      return state;
  }
}

export const showLiveChat = () => ({
  type: SHOW,
});

export const hideLiveChat = () => ({
  type: HIDE,
});

export const toggleLiveChat = (show) => ({
  type: TOGGLE,
  payload: show,
});
