import React, { useContext, useState } from 'react';
import PropTypes from 'prop-types';

import Modal from '@components/common/Modal';
import ErrorBoundaryDecorator from '@components/decorators/ErrorBoundaryDecorator';
import LPContext from '@jsv2/LandingPage/context/LPContext';
import EditFieldContext, { Editable } from '@jsv2/LandingPage/context/EditFieldContext';
import ResearchScheme from './ResearchScheme';

const WhySection = React.forwardRef(({ id, exampleButton, lastColumnTitle, className }, ref) => {
  const { editableFields } = useContext(EditFieldContext);
  const { renderBecomeButton } = useContext(LPContext);

  const [isOpen, setOpen] = useState(false);

  const openModal = () => {
    setOpen(true);
  };

  const closeModal = () => {
    setOpen(false);
  };

  return (
    <>
      {editableFields && (
        <section className={`why-section ${className}`} id={id} ref={ref}>
          <div className="main-container why-section__container">
            <div className="section-title section-title--center">
              <Editable fieldValue={editableFields.why_us_heading} />
            </div>

            {editableFields.why_us_testimonial && (
              <div className="section-description section-description--center">
                <Editable fieldValue={editableFields.why_us_testimonial} />
              </div>
            )}

            <div className="why-section__grid">
              <div className="why-section__item">
                <div className="why-section__item-number">01</div>

                <div className="common-title">
                  <Editable fieldValue={editableFields.why_us_benefit_1_title} />
                </div>

                {editableFields.why_us_benefit_1_content && (
                  <div className="common-text">
                    <Editable fieldValue={editableFields.why_us_benefit_1_content} />
                  </div>
                )}
              </div>

              <div className="why-section__item">
                <div className="why-section__item-number">02</div>

                <div className="common-title">
                  <Editable fieldValue={editableFields.why_us_benefit_2_title} />
                </div>

                {editableFields.why_us_benefit_2_content && (
                  <div className="common-text">
                    <Editable fieldValue={editableFields.why_us_benefit_2_content} />
                  </div>
                )}
              </div>

              <div className="why-section__item">
                <div className="why-section__item-number">03</div>

                <div className="common-title">
                  <Editable fieldValue={editableFields.why_us_benefit_3_title} />
                </div>

                {editableFields.why_us_benefit_3_content && (
                  <div className="common-text">
                    <Editable fieldValue={editableFields.why_us_benefit_3_content} />
                  </div>
                )}
              </div>

              {editableFields.why_us_benefit_4_title && (
                <div className="why-section__item">
                  <div className={`why-section__item-number ${lastColumnTitle ? 'text' : ''}`}>
                    {lastColumnTitle || '04'}
                  </div>

                  <div className="common-title">
                    <Editable fieldValue={editableFields.why_us_benefit_4_title} />
                  </div>

                  <div className="common-text">
                    <Editable fieldValue={editableFields.why_us_benefit_4_content} />

                    {exampleButton && (
                      <>
                        {t(' See an example of a')}

                        <button
                          type="button"
                          onClick={openModal}
                          className="see-in-action-btn"
                          data-qa-id="see-in-action-btn"
                        >
                          {t('stylist in action')}
                        </button>
                      </>
                    )}
                  </div>
                </div>
              )}
            </div>

            {renderBecomeButton(
              'become-btn become-btn--with-border',
              window.templateVariables.body_cta_text.content,
              'become-join-btn-2',
            )}
          </div>

          <Modal
            isOpen={isOpen}
            withCloseButton
            onRequestClose={closeModal}
            shouldCloseOnOverlayClick
            className="vt-common-promo-landing"
            isOverlay
            data={{
              'qa-id': `qa_popup_stylist_in_action`,
            }}
          >
            <ResearchScheme
              renderBecomeButton={renderBecomeButton}
              onRequestCloseModal={closeModal}
            />
          </Modal>
        </section>
      )}
    </>
  );
});

WhySection.propTypes = {
  id: PropTypes.string.isRequired,
  exampleButton: PropTypes.bool,
  lastColumnTitle: PropTypes.string,
  className: PropTypes.string,
};

WhySection.defaultProps = {
  exampleButton: false,
  lastColumnTitle: '',
  className: '',
};

export default ErrorBoundaryDecorator()(WhySection);
