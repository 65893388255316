import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { ErrorMessage, Field, Form, Formik } from 'formik';
import ReactCodeInput from 'react-verification-code-input';

import { getUrlParam } from '@jsv3/utils/urlUtils';
import { ERRORS } from '@js/messages';
import Spinner from '@components/Spinner';
import Modal from '@components/common/Modal';
import apiClient from '@js/apiClient';
import GenericFormError from '@components/formComponents/GenericFormError';
import ErrorBoundaryDecorator from '@components/decorators/ErrorBoundaryDecorator';

/* eslint camelcase: ["error", {properties: "never"}] */

class OAuthUserEmail extends PureComponent {
  initialValues = {
    email: '',
  };

  static propTypes = {
    isOpen: PropTypes.bool,
    onCancel: PropTypes.func,
  };

  static defaultProps = {
    onCancel: () => {},
    isOpen: false,
  };

  state = {
    codeSubmitting: false,
    isOpen: this.props.isOpen,
    email: null,
    generalError: null,
    isCloseModalEnabled: true,
  };

  closeModal = () => {
    this.props.onCancel();
    this.setState({ isOpen: false });
  };

  handleSubmit = (values, actions) => {
    actions.setSubmitting(true);

    this.setState({ generalError: null });

    apiClient
      .post('/confirm/oauth-add-email', values)
      .then(() => {
        this.setState({ email: values.email });
      })
      .catch((error) => {
        Logger.error(error);
        actions.setSubmitting(false);
        this.handleResponseByStatus(error.response.data, actions);
      });
  };

  handleResponseByStatus = (responseData, actions) => {
    if (responseData.status) {
      this.setState({
        generalError: responseData.message,
      });
    } else {
      actions.setErrors(responseData.data);
    }
  };

  checkConfirmCode = (code) => {
    const { email } = this.state;

    this.setState({ generalError: null, codeSubmitting: true });
    apiClient
      .post('/register', {
        email,
        email_confirmation_code: code,
        social_reg: 1,
        social_provider: getUrlParam('social_provider'),
      })
      .finally(() => {
        this.setState({ codeSubmitting: false });
      })
      .then((response) => {
        if (response.data.logged_in) {
          // user was successfully logged in
          this.setState({ codeSubmitting: true });

          window.location.href = response.data.redirect_url;
        } else {
          this.setState({ codeSubmitting: true, isCloseModalEnabled: false });

          window.location.reload();
        }
      })
      .catch((e) => {
        Logger.error(e);
        let generalError = ERRORS.REQUEST_NOT_COMPLETED;
        try {
          generalError = e.response.data.message;
        } catch {
          Logger.error(e);
        }
        this.setState({ generalError });
      });
  };

  render() {
    const { email, generalError, isOpen, codeSubmitting, isCloseModalEnabled } = this.state;

    return (
      <Modal
        isOpen={isOpen}
        shouldCloseOnEsc={isCloseModalEnabled}
        withCloseButton={isCloseModalEnabled}
        onRequestClose={this.closeModal}
        shouldCloseOnOverlayClick={false}
        className="email-confirmation-modal"
        dataQaForCloseButton="activation_code_close_button"
      >
        {email ? (
          <div className="email-confirmation-container" data-qa-id="email-confirmation-container">
            {codeSubmitting && <Spinner />}

            <div style={codeSubmitting ? { display: 'none' } : {}}>
              <div className="email-confirmation-inner">
                <span className="layover-popup__title fsz-22 message">
                  {t('We have sent confirmation code to')}{' '}
                </span>

                <span className="text-bold d-inline-block">{email}</span>
              </div>

              {generalError && (
                <GenericFormError
                  message={generalError}
                  className="label-content label-content--error"
                />
              )}

              <ReactCodeInput
                type="number"
                fields={6}
                className="react-code-input mb-20 oauth-email-confirmation-code"
                onComplete={this.checkConfirmCode}
                autoFocus
              />

              <button
                type="button"
                className="layover-popup__button layover-popup__button--dark"
                onClick={() => this.setState({ email: null })}
                data-qa-id="activation_code_back_button"
              >
                {t('BACK')}
              </button>
            </div>
          </div>
        ) : (
          <>
            <h2 className="layover-popup__title fsz-22" data-qa-id="oauth_no_email_header">
              {t('Please provide your email address.')}
            </h2>

            <Formik
              onSubmit={this.handleSubmit}
              validateOnBlur={false}
              validateOnChange={false}
              initialValues={this.initialValues}
            >
              {({ isSubmitting }) =>
                isSubmitting ? (
                  <Spinner />
                ) : (
                  <Form className="form oauth-user-email" data-qa-id="oauth_no_email_form">
                    <div className="input mb-10">
                      <Field
                        type="email"
                        name="email"
                        autoComplete="email"
                        placeholder="Email Address"
                        autoFocus
                        data-qa-id="email_field"
                      />

                      <ErrorMessage
                        name="email"
                        component="div"
                        className="label-content label-content--error"
                      />
                    </div>

                    <div className="btn-login-wrap">
                      <button
                        className="layover-popup__button"
                        type="submit"
                        disabled={isSubmitting}
                        data-qa-id="save"
                      >
                        {t('Save')}
                      </button>
                    </div>

                    {generalError && <GenericFormError message={generalError} />}
                  </Form>
                )
              }
            </Formik>
          </>
        )}
      </Modal>
    );
  }
}

export default ErrorBoundaryDecorator()(OAuthUserEmail);
