import React from 'react';

import ErrorBoundaryDecorator from '@components/decorators/ErrorBoundaryDecorator';

const MenuItems = ({ items, scrollTo }) =>
  items.map((item) => (
    <button
      key={item.id}
      type="button"
      className="navlist__btn"
      onClick={() => scrollTo(item.sectionId)}
      data-qa-id={item.sectionId}
    >
      {t(item.name)}
    </button>
  ));

export default ErrorBoundaryDecorator()(MenuItems);
