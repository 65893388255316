import React, { useContext, useState, useRef } from 'react';
import PropTypes from 'prop-types';

import { PlayButtonSvg } from '@components/PlayPauseButtonSvg';
import VideoBlock from '@jsv2/components/Video/VideoBlock';
import ErrorBoundaryDecorator from '@components/decorators/ErrorBoundaryDecorator';
import Slider from '@jsv2/components/Slider/Slider';
import LPContext from '@jsv2/LandingPage/context/LPContext';
import EditFieldContext, { Editable } from '@jsv2/LandingPage/context/EditFieldContext';
import { NextArrow, PrevArrow } from '@jsv2/components/Slider/SliderArrows';

const {
  templateVariables: { body_cta_text: bodyCtaText },
} = window;

const sliderSettings = {
  allowTouchMove: true,
  pagination: {
    clickable: true,
  },
  className: 'videos-block__slider',
  slidesPerView: 1,
  breakpoints: {
    767: {
      slidesPerView: 3,
      allowTouchMove: false,
    },
  },
};

const VideosSection = React.forwardRef(({ videos, showBecomeButton, className, id }, ref) => {
  const { toggleAudioPlayback, isAudioPlay, renderBecomeButton } = useContext(LPContext);
  const { editableFields } = useContext(EditFieldContext);

  const [isVideoShow, setIsVideoShow] = useState(false);

  const navigationPrevRef = useRef(null);
  const navigationNextRef = useRef(null);

  const renderContent = () =>
    videos.map((item, index) => (
      <div key={item.posterSrc} className="videos-block__item">
        <div className="videos-block__preview">
          <VideoBlock
            posterSrc={item.posterSrc}
            videoSrc={item.videoSrc}
            playButton={PlayButtonSvg}
            toggleAudioPlayback={toggleAudioPlayback}
            isAudioPlay={isAudioPlay}
            videoShowPropsHandler={setIsVideoShow}
          />
        </div>

        <div className="videos-block__item-title">
          <Editable fieldValue={editableFields[`video_title_${index + 1}`]} />
        </div>
      </div>
    ));

  return (
    <section className={`trending videos-section ${className}`} ref={ref} id={id}>
      <div className="main-container trending__container">
        <div className="trending__title">
          <div className="section-title section-title--center section-title--with-underline">
            <Editable fieldValue={editableFields.videos_heading} />
          </div>
          <div className="section-testimonial">
            <Editable fieldValue={editableFields.videos_testimonial} />
          </div>
        </div>

        <div className={`videos-block ${isVideoShow ? 'video-show' : ''}`}>
          {videos.length > 3 ? (
            <>
              <PrevArrow ref={navigationPrevRef} isAbsolute smallArrow />
              <NextArrow ref={navigationNextRef} isAbsolute smallArrow />

              <Slider
                sliderProps={{
                  navigation: {
                    prevEl: navigationPrevRef.current,
                    nextEl: navigationNextRef.current,
                  },
                  onBeforeInit: (swiper) => {
                    swiper.params.navigation.prevEl = navigationPrevRef.current;
                    swiper.params.navigation.nextEl = navigationNextRef.current;
                  },
                  onBreakpoint: (swiper) => {
                    swiper.params.navigation.prevEl = navigationPrevRef.current;
                    swiper.params.navigation.nextEl = navigationNextRef.current;
                  },
                  ...sliderSettings,
                }}
              >
                {renderContent()}
              </Slider>
            </>
          ) : (
            renderContent()
          )}
        </div>

        {showBecomeButton &&
          renderBecomeButton(
            'become-btn become-btn--with-border',
            bodyCtaText.content,
            'become-join-btn-4',
          )}
      </div>
    </section>
  );
});

VideosSection.propTypes = {
  showBecomeButton: PropTypes.bool,
  className: PropTypes.string,
  videos: PropTypes.arrayOf(PropTypes.object),
};

VideosSection.defaultProps = {
  showBecomeButton: false,
  className: '',
  videos: [
    {
      posterSrc: '/images/lp/promo3/video-images/the-inside-word-video-1-compressed.jpg',
      videoSrc: '/lp-videos/the-inside-word-video-1.mp4',
    },
    {
      posterSrc: '/images/lp/promo3/video-images/the-inside-word-video-2-compressed.jpg',
      videoSrc: '/lp-videos/the-inside-word-video-2.mp4',
    },
    {
      posterSrc: '/images/lp/promo3/video-images/the-inside-word-video-3-compressed.jpg',
      videoSrc: '/lp-videos/the-inside-word-video-3.mp4',
    },
  ],
};

VideosSection.displayName = 'VideosSection';

export default ErrorBoundaryDecorator()(VideosSection);
