import React, { Component } from 'react';

import ErrorBoundaryDecorator from '@components/decorators/ErrorBoundaryDecorator';

class AccordionItem extends Component {
  state = {
    isOpen: false,
  };

  toggleAccordion = () => {
    this.setState((state) => ({ isOpen: !state.isOpen }));
  };

  render() {
    const { isOpen } = this.state;
    const { question, answer } = this.props;

    return (
      <span className={`sub-accordion d-block ${isOpen ? 'active' : ''}`}>
        {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
        <span className="sub-accordion-header d-block" onClick={this.toggleAccordion}>
          {question}
        </span>
        <span className="sub-accordion-body">
          <span className="html-content" dangerouslySetInnerHTML={{ __html: answer }} />
        </span>
      </span>
    );
  }
}

export default ErrorBoundaryDecorator()(AccordionItem);
