import React from 'react';
import _ from 'lodash';

import SkeletonLoadingItem from '@jsv3/components/atoms/SkeletonLoadingItem';

/**
 *
 * Skeleton for bucket list.
 *
 * @param {number} numberOfSkeletonItems
 *
 * @return {*}
 *
 * @constructor
 */
const WishListSkeleton = ({ numberOfSkeletonItems }) => (
  <div className="destination-list__skeleton">
    {_.times(numberOfSkeletonItems, (i) => (
      <SkeletonLoadingItem key={i} className="destination-list__skeleton-item" />
    ))}
  </div>
);

export default WishListSkeleton;
